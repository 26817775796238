import * as React from "react";
import '../resources/styles/toggle-menu.css'

export default class ToggleMenu extends React.Component {
    state = {
        expanded: false
    }
    render() {
        return <div className={"toggle-menu"}>
            <div className={"toggle-menu-title"} onClick={() => this.setState({expanded: !this.state.expanded})}>
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="14" cy="14" r="14" fill="#FF4ADD"/>
                    <path d="M13.0454 18.3485C13.3383 18.6414 13.8132 18.6414 14.1061 18.3485L18.879 13.5756C19.1719 13.2827 19.1719 12.8078 18.879 12.5149C18.5862 12.222 18.1113 12.222 17.8184 12.5149L13.5757 16.7576L9.3331 12.5149C9.04021 12.222 8.56534 12.222 8.27244 12.5149C7.97955 12.8078 7.97955 13.2827 8.27244 13.5756L13.0454 18.3485ZM12.8257 16.9697V17.8182H14.3257V16.9697H12.8257Z" fill="white"/>
                </svg>
                {this.props.title}
            </div>

            {this.state.expanded && <div className={"toggle-menu-content"}>
                {this.props.children}
            </div>}
        </div>
    }
}