import Wrapper from '../../components/Wrapper'

import * as React from "react";
import api from "../../services/api";
import withParams from "../../services/withParams";
import Image from "react-bootstrap/Image";
import img_qr from '../../resources/images/qr.png'
import {Link} from "react-router-dom";
import Paginator from "../../components/Paginator";
import moment from "moment";
import Datetime from "react-datetime";
import Button from "react-bootstrap/Button";
import CurrencyFormat from "react-currency-format";
import Big from "big.js";
import Moment from "react-moment";
import {Form} from "react-bootstrap";

export default withParams(class AccountingList extends React.Component {
    constructor(props) {
        super(props);
        const { restaurant } = this.props.params;
        const [searchParams] = this.props.search;

        this.state = {
            loading: true,
            loadingDay: true,

            items: [],
            from: moment(),
            to: moment(),
            date: searchParams.get("date") || moment().format("YYYY-MM-DD"),
            disabledNewReport: false,
            allRestaurants: false,

            //enabled: searchParams.get('enabled') || true,
        };

        this.reload();
        this.reloadDay();
    }

    reload() {
        const [searchParams, setSearchParams] = this.props.search;
        setSearchParams({page: this.state.page});

        api.getAccounting({page: this.state.page}).then(response => {
            if(response.status === 200) {
                this.setState({
                    data: response.data,
                    loading: false
                });
            }
            else {
                this.setState({
                    error: response.data,
                    loading: false
                });
            }
        });
    }

    reloadDay() {
        const [searchParams, setSearchParams] = this.props.search;
        setSearchParams({date: this.state.date});

        this.setState({
            loadingDay: true
        });

        api.getDayAccounting(this.state.date, this.state.allRestaurants).then(response => {
            if(response.status === 200) {
                this.setState({
                    dataDay: response.data,
                    loadingDay: false
                });
            }
            else {
                this.setState({
                    loadingDay: false,
                    errorDay: response.data
                });
            }
        });
    }

    render() {
        let totalRefunds = this.state.dataDay === undefined ? 0 : (this.state.dataDay.totalRefunds ?? 0);
        return <Wrapper navigate={this.props.navigate}>
            {this.state.loading && <p>Загрузка бухотчетов...</p>}

            {!this.state.loading && <Form className={"form form-wrapper"}>
                <div className={"row mobile"} style={{marginBottom: 20}}>
                    {window.user.role === "ADMIN" && <Link to={`/accounting-settings`} className={"flex"}><Button style={{marginBottom: 5}}>Настройки</Button></Link>}
                    &nbsp;
                    {<Link to={`/accounting-acts`} className={"flex"}><Button style={{marginBottom: 5}}>Акты</Button></Link>}
                </div>
            </Form>}

            {window.user.role === "ADMIN" && <div className={"table-checkbox"} style={{alignItems: "start", justifyContent: "start"}}>
                <input type="checkbox"
                       checked={this.state.allRestaurants}
                       onChange={() => {
                           this.setState({ allRestaurants: !this.state.allRestaurants}, () => {
                               this.reloadDay();
                           });
                       }} />
                <span>Все рестораны *</span>
            </div>}

            {!this.state.loading && <div className={"gray-wrapper gray-table"}>
                <div className={"table-search"} style={window.user.role === "ADMIN"? { marginBottom: 0} : null}>
                    <Datetime value={this.state.from.toDate()}
                              locale={moment.locale()}
                              onChange={(val) => {
                                  this.setState({
                                      from: moment(val),
                                      to: moment(val.diff(this.state.to) > 0 ? val : this.state.to)
                                  });
                              }}
                              closeOnSelect={ true }
                              dateFormat="YYYY.MM.DD" timeFormat={false} />
                    <Datetime value={this.state.to.toDate()}
                              locale={moment.locale()}
                              onChange={(val) => {
                                  this.setState({
                                      to: moment(val),
                                      from: moment(this.state.from.diff(val) > 0 ? val : this.state.from)
                                  });
                              }}
                              closeOnSelect={ true }
                              dateFormat="YYYY.MM.DD" timeFormat={false} />
                    <Button style={{marginBottom: 5}} disabled={this.state.disabledNewReport} onClick={() => {
                        this.setState({
                            disabledNewReport: true
                        })

                        api.createNewReport(this.state.from, this.state.to, this.state.allRestaurants).then((response) => {
                            if(response.status === 200) {
                                this.props.navigate("/accounting/" + response.data.id);
                            }
                            else {
                                alert("Ошибка при создании тестового счёта!");
                            }
                        });
                    }}>Создать новый</Button>
                </div>

                {!this.state.loading && this.state.data.content.length === 0 && <p className={"table-not-found"}>Бухотчетов пока нет...</p>}

                {this.state.data.content.length > 0 && <div className={"col"}>
                    <div className={"table"}>
                        <div className={"thead"}>
                            <div className={"th"}>Дата</div>
                            <div className={"th"}>Статус</div>
                        </div>

                        {this.state.data.content.map((item, i) => {
                            return <Link className={"tbody"} key={item.id} to={`/accounting/${item.id}`}>
                                <div className={"td col-2 word-wrap"}>{item.from} - {item.to}</div>
                                <div className={"td col-2 word-wrap"}>
                                    {item.status === "ACTIVE" ? "В процессе" : item.status === "FINISHED" ? "Готов" : item.status === "ERROR" ? "Ошибка" : "В очереди"}
                                    {item.status === "ACTIVE" && ` ${item.progress} из ${item.progressCount}`}
                                        </div>
                            </Link>
                        })}
                    </div>
                </div>}

                <Paginator page={this.state.data} onChange={(handlePageChange) => {
                    this.setState({ page: handlePageChange }, () => {
                        this.reload();
                    });
                }}/>
            </div>}

            <br/>

            {this.state.loadingDay && <p>Загрузка дневного бухотчета...</p>}


            {!this.state.loadingDay && !this.state.error && <div className={"gray-wrapper gray-table"}>
                <div className={"table-search"} style={window.user.role === "ADMIN"? { marginBottom: 0} : null}>
                    <Datetime value={this.state.date}
                              locale={moment.locale()}
                              onChange={(val) => {
                                  this.setState({ date: moment(val).format("YYYY-MM-DD")}, () => {
                                      this.reloadDay();
                                  });
                              }}
                              closeOnSelect={ true }
                              dateFormat="YYYY.MM.DD" timeFormat={false} />
                    <Button onClick={() => {
                        this.setState({
                            date: moment(this.state.date).subtract(1, 'days').format("YYYY-MM-DD"),
                        }, () => {
                            this.reloadDay();
                        })
                    }}>‹ назад 1 день</Button>
                    <Button onClick={() => {
                        let interval = moment(this.state.date).diff(moment(this.state.date), 'days') + 1;

                        this.setState({
                            date: moment(this.state.date).add(interval, 'days').format("YYYY-MM-DD"),
                        }, () => {
                            this.reloadDay();
                        })
                    }}>вперед 1 день ›</Button>
                </div>

                {this.state.dataDay.map(data => <div>
                    <div className={"table"} style={{width: '100%'}}>
                        <div className={"thead"}>
                            <div className={"th"}><b>{data.name}</b></div>
                            <div className={"th"}></div>
                        </div>
                        <div className={"thead"}>
                            <div className={"th"}>Параметр</div>
                            <div className={"th"}>Значение</div>
                        </div>
                        <div className={"tbody"}>
                            <div className={"td col-2 word-wrap"}>Сумма транзакций общая</div>
                            <div className={"td col-2 end"}><CurrencyFormat value={(data.totalSum).toFixed(2)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} /></div>
                        </div>

                        <div className={"tbody"}>
                            <div className={"td col-2 word-wrap"}>Сумма транзакций по картам</div>
                            <div className={"td col-2 end"}><CurrencyFormat value={(data.totalCard).toFixed(2)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} /></div>
                        </div>

                        <div className={"tbody"}>
                            <div className={"td col-2 word-wrap"}>Сумма транзакций по СБП</div>
                            <div className={"td col-2 end"}><CurrencyFormat value={(data.totalSbp).toFixed(2)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} /></div>
                        </div>


                        {window.user.role === "ADMIN" && <div className={"tbody"}>
                            <div className={"td col-2 word-wrap"}>Сумма транзакций по СБП сверка *</div>
                            <div className={"td col-2 end"}><CurrencyFormat value={(data.totalSbpRestore).toFixed(2)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} /></div>
                        </div>}

                        {window.user.role === "ADMIN" &&  <div className={"tbody"}
                                                               style={Math.abs(data.totalReSum -(data.totalSum)) > 0.5 ? {backgroundColor: '#ff6565'}
                                                                   : Math.abs(data.totalReSum -(data.totalSum)) === 0 ? {backgroundColor: '#81ff65'}: {backgroundColor: '#ffb265'}}>
                            <div className={"td col-2 word-wrap"}>Сумма для сверки *</div>
                            <div className={"td col-2 end"}><CurrencyFormat value={(data.totalReSum).toFixed(2)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} /></div>
                        </div>}
                        <div className={"tbody"}>
                            <div className={"td col-2 word-wrap"}>Коммиссия эквайринга</div>
                            <div className={"td col-2 end"}><CurrencyFormat value={data.totalFee.toFixed(2)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} /></div>
                        </div>

                        <div className={"tbody"}>
                            <div className={"td col-2 word-wrap"}>Возвраты</div>
                            <div className={"td col-2 end"}><CurrencyFormat value={totalRefunds.toFixed(2)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} /></div>
                        </div>
                        <div className={"tbody"}>
                            <div className={"td col-2 word-wrap"}>Сумма для выплаты</div>
                            <div className={"td col-2 end"}><CurrencyFormat value={data.totalProfit.toFixed(2)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} /></div>
                        </div>
                    </div>

                    {window.user.role === "ADMIN" && <div className={"table"} style={{width: '100%'}}>
                        <div className={"thead"}>
                            <div className={"th"}>Дата</div>
                            <div className={"th"}>Заказ</div>
                            <div className={"th"}>YPMN</div>
                            <div className={"th"}>Сумма</div>
                            <div className={"th"}>Возврат</div>
                            <div className={"th"}>Сверка</div>
                            <div className={"th"}>+</div>
                            <div className={"th"}>Комиссия</div>
                        </div>

                        {data.pays.totalSum().map((item, i) => {
                            return <div className={"tbody " + (item.last ? "last": "")} key={"fb"+i+""+item.i}>
                                <div className={"td word-wrap"}>{!item.last && <Moment format="HH:mm:ss">{item.date}</Moment>}</div>
                                <div className={"td word-wrap"}>{!item.last && <><Link to={"/orders/" + item.orderId}>{item.orderId}</Link> {item.sbp && "СБП"}</>}</div>
                                <div className={"td word-wrap"}>{!item.last && <Link to={"https://secure.ypmn.ru/cpanel/reports.php?Submit=DoIT&seekIn=externalrefno&product_name=%D0%92%D1%81%D0%B5+%D1%82%D0%BE%D0%B2%D0%B0%D1%80%D1%8B&interval=lastYear&seekFor=" + item.ypmn} target={"_blank"}>PAYU</Link>}</div>
                                <div className={"td word-wrap"}><CurrencyFormat value={parseFloat(item.localSum).toFixed(2)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} /></div>
                                <div className={"td word-wrap"}><CurrencyFormat value={parseFloat(item.refunds).toFixed(2)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} /></div>
                                <div className={"td word-wrap"}><CurrencyFormat value={parseFloat(item.sum).toFixed(2)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} /></div>
                                <div className={"td word-wrap"}><CurrencyFormat value={parseFloat(item.totalSum).toFixed(2)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} /></div>
                                <div className={"td word-wrap"}><CurrencyFormat value={parseFloat(item.commission).toFixed(2)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} /></div>
                            </div>
                        })}
                    </div>}
                </div>)}

            </div>}

        </Wrapper>;
    }
});