import Wrapper from '../../components/Wrapper'

import * as React from "react";
import Button from "react-bootstrap/Button";
import api from "../../services/api";
import Form from "react-bootstrap/Form";
import withParams from "../../services/withParams";
import QRCode from "react-qr-code";
import {Link} from "react-router-dom";
import Image from "react-bootstrap/Image";
import iikoPlugin from "../../resources/images/iiko-plugin.png"
import iikoOffice from "../../resources/images/iiko-office.png"
import iikoUser from "../../resources/images/iikoUser.png"
import eatAct from "../../resources/images/eatAct.png"
import iikoPrec from "../../resources/images/iikoPrec.png"

export default withParams(class IikoInstruction extends React.Component {
    state = {
        loading: true,
        id: null,
        serial: null,
        type: null,
        tableNum: "0",
        restaurant: {}
    };

    constructor(props) {
        super(props);
        const { restaurant, id } = this.props.params;
    }

    render() {
        return <Wrapper navigate={this.props.navigate}>
            <h4>Инструкция iiko</h4>

            <h5>Установка плагина</h5>
            <p>Для установки плагина скачайте <a href="/iiko.zip">архив</a> и разархивируйте его в следующую директорию:
                <pre>C:\Program Files\iiko\iikoRMS\Front.Net\Plugins</pre>
                <Image src={iikoPlugin} height={120} />
            </p>

            <h5>Настройка iikoOffice</h5>
            <p>Активируйте внешнюю оплату у банковских карт:
                <pre>Розничные продажи -> Типы оплат -> Банковские карты -> ☑ Может приниматься извне</pre>
                <Image src={iikoOffice} height={350} />
            </p>

            <p>Добавьте пользователя для внешних платежей
                <pre>Сотрудники -> Список сотрудников -> Добавить...</pre>
                <Image src={eatAct} height={350} />
            </p>

            <p>Добавьте QR код на пречек, шаблона пречека скопируйте из списка терминалов <Link to='/restaurants'>ресторана</Link>
                <pre>Розничные продажи -> Типы оплат -> Банковские карты -> ☑ Может приниматься извне</pre>
                <Image src={iikoPrec} height={350} />
            </p>

            <h5>Настройка ресторана в EatAndSplit</h5>
            <p>Добавьте ПИН код в поле IIKO пин
                <pre>Рестораны -> Мой ресторан -> Поле IIKO пин</pre>
                <Image src={eatAct} height={350} />
            </p>

        </Wrapper>;
    }
})