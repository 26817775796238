import * as React from "react";
import {useEffect} from "react";
import {useSearchParams, useNavigate} from 'react-router-dom';
import api from "../services/api";

export default function ShortLinkLogin () {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        if(!searchParams.has('l'))
        {
          navigate("/");
        } else {
            api.getTokenFromLink(searchParams.get('l')).then(response => {
                if(response.status === 200) {
                    navigate("/restore?token="+response.data.token);
                } else {
                    navigate("/");
                }
            });
        }
    }, []);

    return <></>
}