import Wrapper from '../../components/Wrapper'

import * as React from "react";
import api from "../../services/api";
import {Button, Form, Image} from "react-bootstrap";
import Moment from "react-moment";
import withParams from "../../services/withParams";
import order_ok from "../../resources/images/order_ok.svg"
import order_err from "../../resources/images/order_err.svg"
import order_lock from "../../resources/images/keylock.svg"
import moment from "moment";
import ru from "moment/locale/ru"
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import {Link} from "react-router-dom";
import Paginator from "../../components/Paginator";
import CurrencyFormat from "react-currency-format";

export default withParams(class RKeeperOrderList extends React.Component {
    constructor(props) {
        super(props);

        const [searchParams] = this.props.search;

        this.state = {
            loading: true,
            data: [],
            page: searchParams.get('page') || 0,
            visited: searchParams.get('visited') ? (searchParams.get('visited') === 'true') : true,
            date: moment(searchParams.get('date') ?? new Date(), 'YYYY-MM-DD')
        };

        this.reload();
    }

    reload() {
        this.setState({
            loading: true
        });

        const [searchParams, setSearchParams] = this.props.search;

        setSearchParams({page: this.state.page, date: moment(this.state.date).format("YYYY-MM-DD"), visited: this.state.visited});

        api.getPageableRKeeperOrders({page: this.state.page, date: this.state.date, visited: this.state.visited}).then(response => {
            if(response.status === 200) {
                this.setState({
                    data: response.data,
                    loading: false
                });
            }
            else {
                this.setState({
                    error: response.data,
                    loading: false
                });
            }
        });
    }

    render() {
        return <Wrapper navigate={this.props.navigate}>
            {this.state.loading && <span className={"title"}>Загрузка...</span>}
            {this.state.error && <span className={"title red"}>Ошибка: {this.state.error}</span>}




            {!this.state.loading && <div className={"gray-wrapper gray-table"}>
                <div className={"table-search"}>
                    <div>
                        <div >Адрес для подключения плагина:</div>
                        <input readOnly={true} defaultValue={process.env.REACT_APP_API_URL + "/api/v1/debugger/rkeeper/integration"}/>
                    </div>
                </div>

                <div className={"flex"} style={{paddingLeft: 30, paddingRight: 30, paddingBottom: 20}}><Button
                    onClick={() => api.createRKeeperOrder().then(response => {
                        if(response.status === 200) {
                            this.props.navigate(`/advanced/rkeeper/${response.data.id}`);
                        }
                        else {
                            alert("Ошибка при создании тестового счёта!");
                        }
                    })}>Создать новый R-Keeper счёт</Button>
                </div>

                {!this.state.loading && this.state.data.content.length === 0 && <p className={"table-not-found"}>Счетов пока нет...</p>}

                {this.state.data.content.length > 0 && <div className={"col"}>
                    <div className={"table"}>
                        <div className={"thead"}>
                            <div className={"th"}>Дата</div>
                            <div className={"th"}>Официант</div>
                            <div className={"th break"}/>
                            <div className={"th"}>Стол</div>
                            <div className={"th"}>Сумма</div>
                            <div className={"th"}></div>
                        </div>

                        {this.state.data.content.map((item, i) => {
                            return <Link className={"tbody"} key={item.id} to={`/advanced/rkeeper/${item.id}`}>
                                <div className={"td col-2"}><Moment format="DD.MM.YY&nbsp;&nbsp;&nbsp;HH:mm">{item.created}</Moment></div>
                                <div className={"td col-2 end xs-visible-bold"}>{item.waiter.name}</div>
                                <div className={"td break"}/>
                                <div className={"td"}><div className={"xs-visible"}>Стол</div>&nbsp;{item.table.name}</div>
                                <div className={"td"}><div className={"xs-visible"}>Сумма</div>&nbsp;<CurrencyFormat value={Math.floor(item.total - item.discount)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} /></div>
                                <div className={"td end"}>{item.closed !== null ? <Image src={order_lock} /> : null}</div>
                            </Link>
                        })}
                    </div>

                    <Paginator page={this.state.data} onChange={(handlePageChange) => {
                        this.setState({ page: handlePageChange }, () => {
                            this.reload();
                        });
                    }}/>
                </div>}
            </div>}
        </Wrapper>;
    }
});