import * as React from "react";
import {useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import Modal from "react-modal";

import api from "../../services/api";
import getUID from "../../services/randomValues";

import Wrapper from "../../components/Wrapper";
import ToggleMenu from "../../components/ToggleMenu";
import SettingsItem from "../../components/SettingsItem";

const RestaurantCashbackCheckActs = (props) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [searchText, setSearchText] = useState("");
    const [restaurantActs, setRestaurantActs] = useState([]);
    const [filteredRestaurantActs, setFilteredRestaurantActs] = useState([]);
    const [newActInfo, setNewActInfo] = useState(null);
    const [needDeleteActInfo, setNeedDeleteActInfo] = useState(null);

    useEffect(() => {
        setLoading(true);
        api.getBaseObject(`/restaurants-cashback-acts/get-all`).then(response => {
            if(response.status === 200) {
                setLoading(false);

                const data = response.data;
                if(data === null) {
                    return;
                }

                for (const r of data) {
                    r.key = getUID();
                    for (const l of r.les) {
                        l.key = getUID();
                        for (const a of l.acts) {
                            a.key = getUID();
                        }
                    }
                }
                setSearchText("");
                setNewActInfo(null);
                setRestaurantActs([...data]);
                setFilteredRestaurantActs([...data]);
            } else {
                setLoading(false);
                setError(response.data);
            }
        });
    }, []);

    const findLeFunc = (collection, restaurantId, leId) => {
        let result = null;
        let restaurant = collection.find(p => p.id === restaurantId);
        if(restaurant !== undefined) {
            let le = restaurant.les.find(p => p.id === leId);
            if(le !== undefined) {
                result = le;
            }
        }
        return result;
    };

    const onSearchRestaurant = (text) => {
        if(text === "") {
            setFilteredRestaurantActs([...restaurantActs]);
        } else {
            const list = restaurantActs.filter(p => p.title.toLowerCase().startsWith(text.toLowerCase()));
            if(list === undefined || list === null) {
                setFilteredRestaurantActs([]);
            } else {
                setFilteredRestaurantActs([...list]);
            }
        }
        setSearchText(text);
    };

    const onDownload = (fileName, restaurantTitle) => {
        api.getBaseObjectAsBlob(`/restaurants-cashback-acts/get-file?fileName=${fileName}`).then(response => {
            if(response.status === 200) {
                const fileNameParts = fileName.split(["."]);
                const newFileName = restaurantTitle + "." + fileNameParts[fileNameParts.length - 1];

                const serverData = new Blob([response.data], { type: "application/octet-stream" });
                const blobUrl = URL.createObjectURL(serverData);

                const link = document.createElement("a");
                link.href = blobUrl;
                link.download = newFileName;
                link.dispatchEvent(
                    new MouseEvent("click", {
                        bubbles: true,
                        cancelable: true,
                        view: window
                    })
                );
                setTimeout(() => {
                    window.URL.revokeObjectURL(blobUrl);
                    link.remove();
                }, 100);
            } else {
                setError(response.data);
            }
        });
    };

    const onUpload = (restaurantId) => {
        let input = document.createElement("input");
        input.type = "file";
        input.multiple = false;
        input.onchange = _ => {
            api.setBaseObject(`/restaurants-cashback-acts/upload-file`, {
                restaurantId: restaurantId,
                file: input.files[0],
            }).then(response => {
                setTimeout(() => {
                    input.remove();
                }, 100);

                if(response.status === 200) {
                    const newAct = response.data;
                    newAct.key = getUID();
                    const newRestaurantActs = [...restaurantActs];
                    const le = findLeFunc(newRestaurantActs, newAct.parentRestaurantId, newAct.parentLeId);
                    if(le !== null) {
                        le.acts.push(newAct);
                        setRestaurantActs(newRestaurantActs);
                        onSearchRestaurant(searchText);
                    }
                } else {
                    setError(response.data);
                }
            });
        };
        input.oncancel = _ => {
            setTimeout(() => {
                input.remove();
            }, 100);
        };
        input.click();
    };

    const onCreate = (restaurantId, month, year) => {
        api.setBaseObject(`/restaurants-cashback-acts/make-file?restaurantId=${restaurantId}&month=${month}&year=${year}`).then(response => {
            if(response.status === 200) {
                const newAct = response.data;
                newAct.key = getUID();
                const newRestaurantActs = [...restaurantActs];
                const le = findLeFunc(newRestaurantActs, newAct.parentRestaurantId, newAct.parentLeId);
                if(le !== null) {
                    le.acts.push(newAct);
                    setRestaurantActs(newRestaurantActs);
                    onSearchRestaurant(searchText);
                }
            } else {
                setError(response.data);
            }
        });
    };

    const onChangeAccepted = (actId) => {
        api.setBaseObject(`/restaurants-cashback-acts/change-accepted?actId=${actId}`).then(response => {
            if(response.status === 200) {
                const changedAct = response.data;
                const newRestaurantActs = [...restaurantActs];
                const le = findLeFunc(newRestaurantActs, changedAct.parentRestaurantId, changedAct.parentLeId);
                if(le !== null) {
                    const foundAct = le.acts.find(p => p.id === changedAct.id);
                    if(foundAct !== undefined) {
                        foundAct.accepted = changedAct.accepted;
                        setRestaurantActs(newRestaurantActs);
                        onSearchRestaurant(searchText);
                    }
                }
            } else {
                setError(response.data);
            }
        });
    };

    const onDelete = (actId, restaurantId, leId) => {
        api.setBaseObject(`/restaurants-cashback-acts/delete?actId=${actId}`).then(response => {
            if(response.status === 200) {
                const newRestaurantActs = [...restaurantActs];
                const le = findLeFunc(newRestaurantActs, restaurantId, leId);
                if(le !== null) {
                    le.acts = le.acts.filter(p => p.id !== actId);
                    setRestaurantActs(newRestaurantActs);
                    onSearchRestaurant(searchText);
                }
            } else {
                setError(response.data);
            }
        });
    };

    return <>
        {loading && <span className={"title"}>Загрузка...</span>}
        {error && <span className={"title red"}>Ошибка: {error}</span>}

        {!loading && !error && <Wrapper navigate={props.navigate}>
            <Modal isOpen={newActInfo !== null}>
                {newActInfo !== null && <>
                    <SettingsItem name="Ресторан" type="text" value={newActInfo.restaurantTitle} readOnly={true} />
                    <label>
                        <div>Месяц</div>
                        <select
                            value={newActInfo.month}
                            onChange={e => {
                                setNewActInfo({
                                    ...newActInfo,
                                    month: e.target.value,
                                })
                            }}
                        >
                            <option value="1">Январь</option>
                            <option value="2">Февраль</option>
                            <option value="3">Март</option>
                            <option value="4">Апрель</option>
                            <option value="5">Май</option>
                            <option value="6">Июнь</option>
                            <option value="7">Июль</option>
                            <option value="8">Август</option>
                            <option value="9">Сентябрь</option>
                            <option value="10">Октябрь</option>
                            <option value="11">Ноябрь</option>
                            <option value="12">Декабрь</option>
                        </select>
                    </label>
                    <SettingsItem
                        name="Год"
                        type="number"
                        value={newActInfo.year} onChange={v => {
                            setNewActInfo({
                                ...newActInfo,
                                year: v,
                            })
                        }}
                        min="1"
                        max="3000"
                        step="1"
                    />

                    <Button type="button" onClick={() => {
                       onCreate(newActInfo.restaurantId, newActInfo.month, newActInfo.year);
                       setNewActInfo(null);
                    }}>Сделать</Button>
                    <br/>
                    <Button type="button" onClick={() => {
                        setNewActInfo(null);
                    }}>Отменить</Button>
                </>}
            </Modal>

            {needDeleteActInfo !== null && <div className='mail-confirm-popup'>
                <div className='mail-confirm-popup-body'>
                    <div className='mail-confirm-text'>Вы уверены, что хотите удалить акт {needDeleteActInfo.created}?</div>
                    <Button variant="primary" className='mail-confirm-cancel' onClick={() => {
                        setNeedDeleteActInfo(null);
                    }}>Отменить</Button>
                    <Button variant="primary" onClick={() => {
                        onDelete(needDeleteActInfo.actId, needDeleteActInfo.restaurantId, needDeleteActInfo.leId);
                        setNeedDeleteActInfo(null);
                    }}>Подтвердить</Button>
                </div>
            </div>}

            <input
                type="text"
                placeholder="Поиск"
                value={searchText}
                autoFocus
                style={{width: "100%"}}
                onChange={e => {
                    onSearchRestaurant(e.target.value);
                }}
            />

            {filteredRestaurantActs !== null && filteredRestaurantActs.map((r, ri) => {
                return <ToggleMenu key={r.key} title={r.title}>
                    {r.les !== null && r.les.map((l, li) => {
                        return <ToggleMenu key={l.key} title={l.legalName}>
                            {l.acts !== null && l.acts.map((a, ai) => {
                                return <div>
                                    <ToggleMenu key={a.key} title={a.created}>
                                        <SettingsItem
                                            name="Проверено"
                                            type="checkbox"
                                            value={a.accepted ?? false}
                                            onChange={(v) => {
                                                onChangeAccepted(a.id);
                                            }}
                                        />
                                        <SettingsItem
                                            name="Вложено вручную"
                                            type="checkbox"
                                            disabled
                                            value={a.manual ?? false}
                                            onChange={(v) => {}}
                                        />
                                        <Button type="button" onClick={() => {
                                            onDownload(a.fileName, r.title);
                                        }}>Скачать</Button>
                                        <br/>
                                        <Button type="button" onClick={() => {
                                            setNeedDeleteActInfo({
                                                created: a.created,
                                                actId: a.id,
                                                restaurantId: r.id,
                                                leId: l.id,
                                            })
                                        }}>Удалить</Button>
                                    </ToggleMenu>
                                </div>
                            })}
                            {l.formingAct && <>
                                <Button type="button" onClick={() => {
                                    setNewActInfo({
                                        restaurantId: r.id,
                                        restaurantTitle: r.title,
                                        month: ((new Date()).getMonth() + 1),
                                        year: ((new Date()).getFullYear()),
                                    });
                                }}>Сделать</Button>
                                <br/>
                                <Button type="button" onClick={() => {
                                    onUpload(r.id);
                                }}>Вложить вручную</Button>
                            </>}
                        </ToggleMenu>
                    })}
                </ToggleMenu>
            })}
        </Wrapper>}
    </>
};

export default RestaurantCashbackCheckActs;
