import * as React from "react";
import {useState, useEffect} from "react";
import { useNavigate} from 'react-router-dom';
import {Button, Form } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import Modal from 'react-modal';

import api from "../../services/api";
import getUID from "../../services/randomValues";

import Wrapper from '../../components/Wrapper'
import SettingsItem from '../../components/SettingsItem';
import Category from './Category';
import ProductInList from './ProductInList';

import "../../resources/styles/online-menu.css";
import arrowIcon from '../../resources/images/arrow.svg';

const Menu = () => {
    const [addCategoryModal, setAddCategoryModal] = useState(false);
    const [categoryName, setCategoryName] = useState("");
    const [categoryId, setCategoryId] = useState(null);
    const [categoryEnabled, setCategoryEnabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [saveError, setSaveError] = useState(false);

    const [showSelectProduct, setShowSelectProduct] = useState(false);
    const [addProductModal, setAddProductModal] = useState(false);
    const [productId, setProductId] = useState(null);
    // const [selectedProduct, setSelectedProduct] = useState(null);
    const [productName, setProductName] = useState("");
    const [productDescription, setProductDescription] = useState("");
    const [productSearch, setProductSearch] = useState("");
    const [productPrice, setProductPrice] = useState(0);
    const [productWeight, setProductWeight] = useState("");
    const [productVolume, setProductVolume] = useState("");
    const [productEnabled, setProductEnabled] = useState(true);
    

    const [productsList, setProductsList] = useState([]);
    const [productImage, setProductImage] = useState("");
    const [productImageLoaded, setProductImageLoaded] = useState(null);
    const [productImageNameCopy, setProductImageNameCopy] = useState("");
    const [productOriginalImageNameCopy, setProductOriginalImageNameCopy] = useState("");
    const [realProductId, setRealProductId] = useState(null);
    const [filteredProductsList, setFilteredProductsList] = useState([]);
    const [realProductData, setRealProductData] = useState({});
    const [dragging, setDragging] = useState(false);

    const [categories, setCategories] = useState([]);
    
    const navigate = useNavigate();

    Modal.setAppElement("body");

    const isAdmin = (window.user !==undefined && window.user.role === "ADMIN");
    const isSupport = (window.user !==undefined && window.user.role === "SUPPORT");

    const loadData = () => {
        setLoading(true);
        api.getBaseObject("/menu").then(r => {
            if(r.status === 200) {
                setCategories(r.data);
                if(r.data !== null) {
                    r.data.forEach(c => {
                        c.key = getUID();
                        c.products.forEach(p => {
                            p.key = getUID()
                        });
                    });
                }
            } else {
                setSaveError(true);
            }
            setLoading(false);
        });
    }

    const loadProducts = () => {
        api.getBaseObject("/menu/restaurant-products").then(r => {
            if(r.status === 200) {
                if(r.data !== null) {
                    r.data.forEach(p => {
                        p.key = getUID();
                    });
                }
                setProductsList(r.data);
            } else {
                setSaveError(true);
            }
        });
    }

    const onUpdateCategory = () => {
        let data = {
            name: categoryName ?? "",
            enabled: categoryEnabled ?? true,
        }
        if(categoryId!=null)
        {
            data.id = categoryId;
        }
        api.setBaseObject("/menu/category", { ...data }).then(r => {
            if(r.status === 200) {
                addOrEditCategory(r.data);
                setCategoryName("");
                setCategoryEnabled(true);
                setAddCategoryModal(false);
            } else {
                setSaveError(true);
            }
        });
    }

    const onUpdateProduct = () => {
        let data = {
            categoryId: categoryId*1,
            name: productName ?? "",
            productId: realProductId,
            description: productDescription ?? "",
            imageFile: productImage ?? null,
            price: productPrice ?? "",
            weight: productWeight ?? "",
            volume: productVolume ?? "",
            enabled: productEnabled ?? true,
            imageNameCopy: productImageNameCopy ?? null,
            originalImageNameCopy: productOriginalImageNameCopy ?? null,
        };
        if(productId!=null)
        {
            data.id = productId;
        }
        api.setBaseObject("/menu/product", { ...data }).then(r => {
            if(r.status === 200) {
                addOrEditProduct(r.data);
                flushProductData();
                setAddProductModal(false);
            }  else {
                setSaveError(true);
            }
        });
    }

    const onDeleteProduct = (prodId) => {
        api.setBaseObject("/menu/product/remove", { id: prodId }).then(r => {
            if(r.status === 200) {
                removeProduct(prodId);
                flushProductData();
                setAddProductModal(false);
            } else {
                setSaveError(true);
            }
        });
    }

    const onDeleteCategory = (catId) => {
        api.setBaseObject("/menu/category/remove", { id: catId }).then(r => {
            if(r.status === 200) {
                removeCategory(catId);
                flushProductData();
                setAddProductModal(false);
            } else {
                setSaveError(true);
            }
        });
    }

    const onLoadInfo = (prodId) => {
        if(((productName ?? "") === "") && ((productDescription ?? "") === ""))
        {
            api.getBaseObject(`/menu/product/get-info?productId=${prodId}`).then(r => {
                if(r.status === 200) {
                    setProductImageNameCopy(r.data.imageName);
                    setProductOriginalImageNameCopy(r.data.originalImageName);
                    setProductImage(r.data.imageName);
                    setProductDescription(r.data.description);
                    setProductWeight(r.data.weight);
                    setProductPrice(r.data.price);
                    setProductVolume(r.data.volume);
                } else {
                    setSaveError(true);
                }
            });
        }
    };

    const flushProductData = () => {
        setProductName("");
        setProductDescription("");
        setProductPrice(0);
        setProductWeight("");
        setProductVolume("");
        setProductEnabled(true);
    }

    const setSelectList = (item) => {
        let newList = [];
        for(let j=0;j<productsList.length;j++)
        {
            let found = false;
            for(let i=0;i<item.products.length;i++) 
            {
                if((item.products[i].productId ?? 0) === productsList[j].id)
                {
                    found = true;
                    break;
                }
            }
            if(!found)
            {
                newList.push(productsList[j]);
            }
        }

        setFilteredProductsList(newList);
    }

    const onChangeProduct = () => {
        setSelectList({products: []});
        setShowSelectProduct(true);
    }

    const onAddProduct = (item) => {
        setSelectList(item);
        flushProductData();
        setCategoryId(item.id);
        setShowSelectProduct(true);
        setProductId(null);
    }

    const onProductUp = (item) => {
        moveCategory('product', item.id, 'up');
    }

    const onProductDown = (item) => {
        moveCategory('product', item.id, 'down');
    }

    const onCategoryUp = (item) => {
        moveCategory('category', item.id, 'up');
    }

    const onCategoryDown = (item) => {
        moveCategory('category', item.id, 'down');
    }

    const moveCategory = (itemType, id, direction) => {
        api.setBaseObject("/menu/move/"+itemType+"/"+direction, { id: id }).then(r => {
            if(r.status === 200) {
                if(itemType === "category") {
                    reorderCategory(id, r.data);
                }
                if(itemType === "product") {
                    reorderProduct(id, r.data);
                }
                flushProductData();
                setAddProductModal(false);
            } else {
                setSaveError(true);
            }
        });
    }

    const onEditCategory = (item) => {
        setCategoryId(item.id);
        setCategoryName(item.name);
        setCategoryEnabled(item.enabled);
        setAddCategoryModal(true);
    }

    const onEditProduct = (item) => {
        if((item.productId ?? 0)>0)
        {
            let cProduct = productsList.filter(x => x.id === item.productId)
            if(cProduct.length>0)
            {
                setRealProductData(cProduct[0]);
                console.log(JSON.stringify(cProduct[0]));
            }
        } else {
            setRealProductData({name: ''});
        }
        setProductDescription(item.description);
        setProductId(item.id);
        setProductName(item.name);
        setProductPrice(item.price);
        setProductWeight(item.weight);
        setProductVolume(item.volume ?? "");
        setProductEnabled(item.enabled);

        setProductImage(item.imageName ?? "");
        setCategoryId(item.categoryId);
        setRealProductId(item.productId);
        setProductImageLoaded(null);
        setProductImageNameCopy("");
        setProductOriginalImageNameCopy("");
        setAddProductModal(true);
    }

    const onSelectProduct = (item) => {
        // setSelectedProduct(item);
        if(productId == null)
        {
            setProductName(item.name);
            setProductDescription("");
            setProductImageLoaded(null);
            setProductImageNameCopy("");
            setProductOriginalImageNameCopy("");
            setProductImage("");
        }
        setRealProductId(item.id);
        setRealProductData(item);
        setAddProductModal(true);
        setShowSelectProduct(false);
    }

    useEffect(() => {
        loadData();
        loadProducts();
    }, []);

    useEffect(() => {
        if(saveError) {
            setInterval(() => {
                setSaveError(false);
            }, 5 * 1000);
        }
    }, [saveError]);

    const prodImage = productImageLoaded!=null ? productImageLoaded : 
                            ((productImage ?? "") === "" ? "" : api.getMenuImageUrl(encodeURI(productImage)));

    const preventDrag = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }

    const dragEnter = (e) => {
        e.dataTransfer.mozCursor = "copy";
        e.dataTransfer.dropEffect = 'copy';
        setDragging(true);
        preventDrag(e);
    }
        
    const dragLeave = (e) => {
        setDragging(false);
        preventDrag(e);
    }

    const dropFile = (e) => {
        let dt = e.dataTransfer;
        let files = dt.files;
        ([...files]).forEach(uploadProductImage);
        dragLeave(e);
    }

    const uploadProductImage = (ev) => {
        let reader = new FileReader();
        reader.readAsDataURL(ev);
        reader.onloadend = (e) => {
            let img = new window.Image();

            img.onload = () => {
                setProductImage(ev);
                setProductImageLoaded(reader.result);
            };
            img.src = reader.result;
        }
    }

    const uploadExcel = (ev) => {
        let reader = new FileReader();
        reader.readAsDataURL(ev);
        reader.onloadend = (e) => {
            const data = {
                xls: ev,
            };
            setLoading(true);
            api.setBaseObject("/menu/upload", { ...data }).then(r => {
                if(r.status === 200 && r.data.status === "ok") {
                    setSaveError(false);
                    setLoading(false);
                    loadProducts();
                }  else {
                    setLoading(false);
                    setSaveError(true);
                }
            });
        }
    }

    const compareOrdr = (a, b) => {
        if (a.ordr < b.ordr) {
            return -1;
        }
        if (a.ordr > b.ordr ){
            return 1;
        }
        return 0;
    };

    const addOrEditCategory = (category) => {
        const newCategories = [...categories];
        let index = newCategories.findIndex(c => c.id === category.id);
        if(index === -1) {
            category.key = getUID();
            newCategories.push(category);
        } else {
            category.key = newCategories[index].key;
            newCategories[index] = category;
        }
        newCategories.sort(compareOrdr);

        setCategories(newCategories);
    };

    const removeCategory = (id) => {
        setCategories(categories.filter(c => c.id !== id));
    };

    const reorderCategory = (id, newOrdr) => {
        const newCategories = [...categories];
        newCategories.sort(compareOrdr);
        let index = newCategories.findIndex(c => c.id === id);
        const isUp = newCategories[index].ordr > newOrdr;

        if(isUp && index > 0) {
            newCategories[index - 1].ordr++;
            newCategories[index].ordr = newOrdr;
        } else if(!isUp && index !== (newCategories.length - 1)) {
            newCategories[index + 1].ordr--;
            newCategories[index].ordr = newOrdr;
        }
        newCategories.sort(compareOrdr);

        setCategories(newCategories);
    };

    const addOrEditProduct = (product) => {
        const newCategories = [...categories];
        let indexCategory = newCategories.findIndex(c => c.id === categoryId);
        if(indexCategory !== -1) {
            let indexProduct = newCategories[indexCategory].products.findIndex(p => p.id === product.id);
            if(indexProduct === -1) {
                product.key = getUID();
                newCategories[indexCategory].products.push(product);
            } else {
                product.key = newCategories[indexCategory].products[indexProduct].key;
                newCategories[indexCategory].products[indexProduct] = product;
            }
            newCategories[indexCategory].products.sort(compareOrdr);
        }

        setCategories(newCategories);
    };

    const removeProduct = (id) => {
        const newCategories = [...categories];
        newCategories.forEach((c) => {
            c.products = c.products.filter(p => p.id !== id);
        });

        setCategories(newCategories);
    };

    const reorderProduct = (id, newOrdr) => {
        const newCategories = [...categories];
        newCategories.forEach((c) => {
            let index = c.products.findIndex(p => p.id === id);
            if(index !== -1) {
                const isUp = c.products[index].ordr > newOrdr;
                if(isUp && index > 0) {
                    c.products[index - 1].ordr++;
                    c.products[index].ordr = newOrdr;
                } else if(!isUp && index !== (newCategories.length - 1)) {
                    c.products[index + 1].ordr--;
                    c.products[index].ordr = newOrdr;
                }
                c.products.sort(compareOrdr);
            }
        });

        setCategories(newCategories);
    };

    return <Wrapper navigate={navigate}>
            <Modal isOpen={addCategoryModal}>
                <Form className="add-type-modal full-height">
                    <SettingsItem value={categoryName} type="text" name="Название категории" onChange={v => setCategoryName(v)} />
                    <SettingsItem value={categoryEnabled} type="checkbox" name="Включена" onChange={v => setCategoryEnabled(v)} />

                    <div className='add-type-buttons'>
                    <Button type="button" onClick={() => { 
                        onUpdateCategory();
                    }}>
                        {categoryId == null ? "Добавить" : "Обновить"}
                    </Button>
                    <Button type="button"  onClick={() => {setCategoryName(""); setCategoryId(null); setAddCategoryModal(false);}}>
                        Отменить
                    </Button>
                    </div>
                </Form>
            </Modal>

            <Modal isOpen={showSelectProduct}>
                <div className="add-type-modal modal-products-container full-height">
                    <SettingsItem value={productSearch} type="text" name="Поиск" onChange={v => setProductSearch(v)} />
                    <div className='modal-products-list'>
                        {
                           filteredProductsList.filter(p => p.name.toLowerCase().includes(productSearch.toLowerCase()) 
                                                        || (p.categoriesChain ?? '').toLowerCase().includes(productSearch.toLowerCase())).
                                map(p => {
                                    return <ProductInList key={p.key} item={p} onSelectProduct={onSelectProduct} onLoadInfo={onLoadInfo}/>;
                                })
                        }
                    </div>

                    <div className='add-type-buttons'>
                    <Button type="button"  onClick={() => {setShowSelectProduct(false);}}>
                        Отменить
                    </Button>
                    </div>
                </div>
            </Modal>

            <Modal isOpen={addProductModal}>
                <Form className="add-type-modal full-height">
                    <label className="list-product-name">{realProductData.name}
                        <div className="list-product-category">{(realProductData.categoriesChain ?? '').replace('\n',' \\ ')}</div>
                        <div className="list-product-button">
                            <Button type="button" onClick={() => {
                                setAddProductModal(false);
                                onChangeProduct();
                            }}>
                                <Image src={arrowIcon} className="arrow-right-icon" />
                            </Button>
                        </div>
                    </label>
                    <SettingsItem value={productEnabled} type="checkbox" name="Включен" onChange={v => setProductEnabled(v)} />
                    <SettingsItem value={productName} type="text" name="Название блюда" onChange={v => setProductName(v)} />
                    <SettingsItem value={productDescription} type="text" name="Описание" onChange={v => setProductDescription(v)} />
                    <SettingsItem value={productPrice} type="text" name="Цена блюда" onChange={v => setProductPrice(v)} />
                    <SettingsItem value={productWeight} type="text" name="Вес блюда" onChange={v => setProductWeight(v)} />
                    <SettingsItem value={productVolume} type="text" name="Объем блюда" onChange={v => setProductVolume(v)} />

                    <div className={(dragging ? " dragging" : "")} onDragEnter={dragEnter} onDragOver={dragEnter} onDragLeave={dragLeave} onDrop={dropFile}>
                            <label>Картинка (максимум 500 px ширина)
                            <div className='product-image-container'>
                                <input type="file" accept="image/*" onChange={ev => { uploadProductImage(ev.target.files[0]);} } />

                                <div style={{backgroundImage: ('url('+prodImage+')')}} className='product-image'/>
                            </div>

                            </label>
                        </div>


                    <div className='add-type-buttons'>
                    <Button type="button" onClick={() => { 
                        onUpdateProduct();
                    }}>
                        {(productId ?? 0) === 0 ? 'Добавить' : 'Обновить'}
                    </Button>
                    <Button type="button"  onClick={() => {
                            setProductName(""); 
                            setProductDescription("");
                            setProductPrice(0);
                            setProductWeight("");
                            setProductVolume("");
                            setAddProductModal(false); 
                            if((productId ?? 0) === 0)
                            {
                                setShowSelectProduct(true);
                            }
                        }}>
                        Назад
                    </Button>
                    </div>
                </Form>
            </Modal>

            {loading && <span className={"title"}>Загрузка...</span>}
            {saveError && <span className={"title"} style={{color: "red"}}>Ошибка</span>}

            {!loading && (isAdmin || isSupport) && <>
                <div className={(dragging ? " dragging" : "")} onDragEnter={dragEnter} onDragOver={dragEnter} onDragLeave={dragLeave} onDrop={dropFile}>
                            <label>
                            <Button type="button" onClick={()=>{
                                document.getElementById('xls-upload').click();
                            }}>Загрузить Excel файл с меню</Button>
                            <div className='xls-upload'>
                                <input id="xls-upload" type="file" accept="application/*" onChange={ev => { uploadExcel(ev.target.files[0]);} } />
                            </div>

                            </label>
                        </div>
            </>}
            {!loading && <>
            {categories.map((item, i) => {
                return <Category key={item.key} item={item} onAddProduct={onAddProduct} 
                        isFirst={i === 0} isLast={i === categories.length-1} 
                        onEditCategory={onEditCategory}
                        onUp={onCategoryUp} 
                        onDown={onCategoryDown}
                        onEditProduct={onEditProduct}
                        onProductUp={onProductUp}
                        onProductDown={onProductDown}
                        onDeleteCategory={() => {onDeleteCategory(item.id)}}
                        onDeleteProduct={onDeleteProduct}
                    />
            })}
            <div className='add-category'>
            <Button type="button" onClick={() => { setCategoryName(""); setCategoryId(null); setAddCategoryModal(true); }}>
                Добавить категорию
            </Button>
            </div>
            </>}

    </Wrapper>;
};

export default Menu;

