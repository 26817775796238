import Wrapper from '../components/Wrapper';
import withParams from "../services/withParams";
import * as React from "react";
import "react-datetime/css/react-datetime.css";
import '../resources/styles/manager.css'
import api from "../services/api";
import Datetime from "react-datetime";
import moment from "moment/moment";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import calendar from "../resources/images/calendar.svg";
import Moment from "react-moment";
import CurrencyFormat from "react-currency-format";
import Paginator from "../components/Paginator";

export default withParams(class Agent extends React.Component {
    state = {
        error: null,
        from: moment(),
        to: moment(),
        data: [],
        loading: true
    };

    constructor(props) {
        super(props);

        this.reloadStats();
    }

    reloadStats() {
        this.setState({
            loading: true
        });

        api.getAgentStats(this.state.from, this.state.to).then(result => {
            this.setState({
                data: result.data.months,
                loading: false
            })
        });
    }

    render() {
        return <Wrapper navigate={this.props.navigate}>
            {this.state.error && <span className={"title red"}>Ошибка: {this.state.error}</span>}


            <div className={"col-2"}>
                <div className={"title"}>Ваш номер агента</div>
                <input type="text" readOnly={true} value={window.user.id}/>

                <div className={"title"}>Оплаты</div><br/>

                <div className={"gray-wrapper gray-table"}>
                    <div className={"col"}>
                        <div className={"table-search"} style={window.user.role === "ADMIN"? { marginBottom: 0} : null}>
                            <Datetime value={this.state.from.toDate()}
                                      locale={moment.locale()}
                                      onChange={(val) => {
                                          this.setState({
                                              from: moment(val),
                                              to: moment(val.diff(this.state.to) > 0 ? val : this.state.to)
                                          }, () => {
                                              this.reloadStats();
                                          });
                                      }}
                                      closeOnSelect={ true }
                                      dateFormat="YYYY.MM.DD" timeFormat={false} />
                            <Datetime value={this.state.to.toDate()}
                                      locale={moment.locale()}
                                      onChange={(val) => {
                                          this.setState({
                                              to: moment(val),
                                              from: moment(this.state.from.diff(val) > 0 ? val : this.state.from)
                                          }, () => {
                                              this.reloadStats();
                                          });
                                      }}
                                      closeOnSelect={ true }
                                      dateFormat="YYYY.MM.DD" timeFormat={false} />
                        </div>
                    </div>
                </div>

                {!this.state.loading && <div><br/>
                    {this.state.data.map((dat, i) => {
                        return <div className={"list row between middle"} key={"wa_tr_"+i}>
                            <div className={"flex"}>
                                <Image src={calendar}/>
                                <Moment className={"list-text"} format="DD.MM.YY&nbsp;&nbsp;">{dat.day}</Moment>
                            </div>
                            <div className={"list-sum green"}>
                            +<CurrencyFormat value={(dat.amountOrders ).toFixed(2)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} /> блюда
    &nbsp;&nbsp;&nbsp;&nbsp;
                            +<CurrencyFormat value={(dat.amountTips).toFixed(2)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} /> чаевые
                            </div>
                        </div>
                    })}
                    <input type="text" readOnly={true} value={"Блюда " + this.state.data.reduce(function(a, b) {
                    return a + b.amountOrders
                    }, 0).toFixed(2) + ' руб'}/>

                    <input type="text" readOnly={true} value={"Чаевые " + this.state.data.reduce(function(a, b) {
                    return a + b.amountTips
                    }, 0).toFixed(2) + ' руб'}/>

                </div>}

{/*
                <input type="text" readOnly={true} value={this.state.data.reduce(function(a, b) { return a.amountOrders + a.amountTips + b.amountOrders + b.amountTips }, 0) + ' руб'}/>
*/}

            </div>
        </Wrapper>;
    }
})