import Wrapper from '../../components/Wrapper';
import withParams from "../../services/withParams";
import * as React from "react";
import api from "../../services/api";
import {Button, Col, Form, Image, Row, Table} from "react-bootstrap";
import Moment from "react-moment";
import order_err from "../../resources/images/order_err.png";
import order_ok from "../../resources/images/order_ok.png";
import '../../resources/styles/order.css'
import {Link} from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import like from "../../resources/images/like.svg";
import dislike from "../../resources/images/dislike.svg";
import declOfNum from "../../components/declOfNum";

export default withParams(class AgentEdit extends React.Component {
    state = {
        loading: true,
        fullName: '',
        id: '',
        phone: '',
        active: true
    };

    constructor(props) {
        super(props);
        const { id } = this.props.params;


        if(id !== undefined) {
            api.getAgent(id).then(response => {
                if(response.status === 200) {
                    this.setState({
                        fullName: response.data.fullName,
                        phone: response.data.phone,
                        email: response.data.email,
                        enabled: response.data.enabled ?? false,
                        id: response.data.id,
                        loading: false
                    });
                }
                else {
                    this.setState({
                        error: response.data,
                        loading: false
                    });
                }
            });
        }
        else {
            this.state.loading = false;
        }
    }

    async submit(event) {
        event.preventDefault();

        this.setState({
            loading: true
        });

        let result;

        try {
            if(this.props.params.id === undefined) {
                result = await api.addAgent({...this.state});
            }
            else {
                result = await api.editAgent({...this.state});
            }

            window.location.href = '/advanced/agent/' + result.data.id
        }
        catch (e) {
            this.setState({
                error: e.message
            })
        }
    }

    render() {
        return <Wrapper navigate={this.props.navigate}>
            {this.state.loading && <span className={"title"}>Загрузка агента...</span>}
            {this.state.error && <span className={"title red"}>Ошибка: {this.state.error}</span>}

            <Form onSubmit={this.submit.bind(this)} className={"form form-wrapper"}>
                <label>
                    ФИО
                    <input type={"hidden"}  value={this.state.id} />
                    <input type={"text"} value={this.state.fullName} onChange={e => this.setState({ fullName: e.target.value })} />
                </label>

                <label>
                    Телефон
                    <input type={"text"} value={this.state.phone} onChange={e => this.setState({ phone: e.target.value })} />
                </label>

                <label>
                    Почта
                    <input type={"text"} value={this.state.email} onChange={e => this.setState({ email: e.target.value })} />
                </label>

                <label>
                    <div>Активен <span className={"gray"}>админ</span></div>
                    <select value={this.state.enabled} onChange={e => this.setState({ enabled: e.target.value === "true" })}>
                        <option disabled={true}>-- Выберите --</option>
                        <option value="false">Нет</option>
                        <option value="true">Да</option>
                    </select>
                </label>

                <div className={"flex"}>
                    <Button type="submit">{this.props.params.id === undefined ? "Создать":"Обновить"}</Button>
                </div>

            </Form>

        </Wrapper>;
    }
})
