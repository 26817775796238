import * as React from "react";
import Pagination from "react-js-pagination";
// import ReactPaginate from 'react-paginate';

export default class Paginator extends React.Component {
    render() {
        console.log(this.props.page);
/*
        <ReactPaginate
            breakLabel="..."
            nextLabel={null}
            onPageChange={this.props.onChange}
            pageRangeDisplayed={10}
            pageCount={pageCount}
            previousLabel={null}
            renderOnZeroPageCount={null}
        />*/

        return <Pagination
            hideNavigation
            activePage={this.props.page.number+1}
            itemsCountPerPage={this.props.page.size}
            totalItemsCount={this.props.page.totalElements}
            itemClass='page-item'
            linkClass='admin-pageable'
            activeLinkClass='admin-pageable active'
            onChange={this.props.onChange}
        />
    }
}