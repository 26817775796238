import * as React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import Image from 'react-bootstrap/Image'
import api from '../services/api'
import {Link} from "react-router-dom";
import logo_panel from '../resources/images/logo_min.svg'

export default class Login extends React.Component {
    state = {
        email: "",
        password: "",
        error: "",
        roles: ["ADMIN"],
        fullName: ""
    };

    async submit(event) {
        event.preventDefault();

        let result = await api.registration(this.state);

        if(result.status === 200) {
            localStorage.setItem('Authorization', result.data.token)
            this.props.navigate("/");
        }
        else {
            this.setState({
                error: result.data
            });
        }
    }

    render() {
        return <Container>
            <Row className="align-items-center" style={{"minHeight" :"100%"}}>
                <Col md>
                    <div style={{justifyContent: "flex-start", display: "flex", marginBottom: 30}}>
                        <Image src={logo_panel} className={"admin-logo"} />
                        <span style={{fontSize: 20, marginTop: 10}}>Регистрация</span>
                    </div>

                    {this.state.error && <Alert variant='danger'>
                        {this.state.error}
                    </Alert>}

                    <Form onSubmit={this.submit.bind(this)} className={"gray-wrapper gray-form"}>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                            <Form.Label column sm="2">
                                Название ресторана
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control type="email" placeholder="ООО Волна" onChange={e => this.setState({ email: e.target.value })}/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                            <Form.Label column sm="2">
                                ИНН ресторана
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control type="email" placeholder="123456789" onChange={e => this.setState({ inn: e.target.value })}/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                            <Form.Label column sm="2">
                                Адрес ресторана
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control type="email" placeholder="123456789" onChange={e => this.setState({ address: e.target.value })}/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                            <Form.Label column sm="2">
                                Email владельца (служит для входа)
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control type="email" placeholder="email@example.com" onChange={e => this.setState({ email: e.target.value })}/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                            <Form.Label column sm="2">
                                ФИО владельца
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control type="name" onChange={e => this.setState({ fullName: e.target.value })}/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                            <Form.Label column sm="2">
                                Пароль для входа в личный кабинет
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control type="password" onChange={e => this.setState({ password: e.target.value })} />
                            </Col>
                        </Form.Group>


                        <Link to="/login/" style={{float: 'left'}}>Авторизация</Link>

                        <div className={"flex"}>
                            <Button variant="primary" type="submit" style={{float: 'right'}}>
                                Регистрация
                            </Button>
                        </div>
                    </Form>
                </Col>
            </Row>
        </Container>;
    }
}