import Wrapper from '../../components/Wrapper'

import * as React from "react";
import api from "../../services/api";
import {Alert, Button} from "react-bootstrap";
import withParams from "../../services/withParams";
import Form from "react-bootstrap/Form";
import Paginator from "../../components/Paginator";
import {Link} from "react-router-dom";
import ToggleMenu from "../../components/ToggleMenu";
import Moment from "react-moment";
import moment from "moment/moment";

export default withParams(class Packages extends React.Component {
    state = {
        loading: true,
        data: [],
        current: [],
        name: "",
        installer: false,
        restName: "Любой",
        restaurantId: ""
    };

    constructor(props) {
        super(props);

        const [searchParams] = this.props.search;

        this.state.restName = searchParams.get('rest_name') || "Любой";
        this.state.restaurantId = searchParams.get('rest_id') || "";

        this.getPage(0);
    }

    getPage(number) {
        this.setState({
            loading: true
        });
        const [searchParams, setSearchParams] = this.props.search;
        setSearchParams({page: number});

        api.getPageablePackages({page: number, restaurantId: this.state.restaurantId}).then(response => {
            if(response.status === 200) {
                this.setState({
                    data: response.data,
                    loading: false
                });
            }
            else {
                this.setState({
                    error: response.data,
                    loading: false
                });
            }
        });
    }

    async submit(event) {
        event.preventDefault();

        this.setState({
            loading: true,
            error: false
        });

        let response = await api.createPackage(this.state);

        this.setState({
            loading: false
        });

        if(response.status !== 200) {
            this.setState({
                error: response.data
            });
        }
        else {
            this.getPage(0);
        }
    }


    render() {
        return <Wrapper navigate={this.props.navigate}>
            {this.state.loading && <span className={"title"}>Загрузка...</span>}
            {this.state.error && <span className={"title red"}>Ошибка: {this.state.error}</span>}

            {!this.state.loading && <Form onSubmit={this.submit.bind(this)} className={"form form-wrapper xs-hidden"}>
                <div className={"flex"}>
                    <label style={{"flex": 1, marginRight: 5}}>
                        Версия
                        <input type={"text"} value={this.state.version} onChange={e => this.setState({ version: e.target.value })} />
                    </label>

                    <label style={{"flex": 1, marginLeft: 5}}>
                        <div>Файл</div>
                        <input type={"file"} onChange={e => {
                            this.setState({
                                pack: e.target.files[0]
                            });
                        }} />
                    </label>
                </div>

                <div className={"flex"}>
                    <label style={{"flex": 1, marginRight: 5}}>
                        <div>Система</div>
                        <select value={this.state.type} onChange={e => this.setState({ type: e.target.value })}>
                            <option>-- Выберите --</option>
                            <option value="R_KEEPER">R-Keeper</option>
                            <option value="IIKO">Iiko</option>
                        </select>
                    </label>

                    <label style={{"flex": 1, marginLeft: 5}}>
                        <div>Тип</div>
                        <select value={this.state.installer} onChange={e => this.setState({ installer: e.target.value })}>
                            <option>-- Выберите --</option>
                            <option value="false">Пакет обновления</option>
                            <option value="true">Установщик</option>
                        </select>
                    </label>
                </div>

                <div className={"flex"}>
                    <label style={{"flex": 1, marginRight: 5}}>
                        Ресторан
                        <input type={"text"} value={this.state.restName} disabled={true} />
                    </label>
                </div>

                <br/>

                <div className={"flex"}>
                    <Button type="submit">
                        Добавить сборку
                    </Button>
                </div>
                <br/>
            </Form>}

            {!this.state.loading && !this.state.error && this.state.data.content.length === 0 && <p>Сборок пока нет...</p>}
            <br/>

            {!this.state.loading && this.state.data.content.length > 0 && <div className={"gray-wrapper gray-table"}>
                <div className={"table-search"}>

                </div>

                <div className={"table"}>
                    <div className={"thead"}>
                        <div className={"th"}>Версия</div>
                        <div className={"th"}>Система</div>
                        <div className={"th"}>Тип</div>
                        <div className={"th"}>Создан</div>
                    </div>
                    {this.state.data.content.map((item, i) => {
                        return <div className={"tbody"} key={item.id}>
                            <div className={"td"}>{item.version}</div>
                            <div className={"td"}>{item.type}</div>
                            <div className={"td"}>{!item.installer ? "Плагин" : "Установщик"}</div>
                            <div className={"td"}><Moment format="DD.MM.YY&nbsp;&nbsp;&nbsp;HH:mm">{item.created}</Moment></div>
                        </div>
                    })}
                </div>

                <Paginator page={this.state.data} onChange={(handlePageChange) => {
                    this.getPage(handlePageChange)
                }}/>
            </div>}
        </Wrapper>;
    }
});