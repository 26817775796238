const ReportTable = ({keyPrefix, columns, children}) => {

    return <div className={"table"}>
        {((columns ?? []).length>0) && <div className={"thead"}>
            {columns.map((i, idx) => {
                return <div className={"th"} key={(keyPrefix ?? "rth") + idx}>{i}</div>;
            })}
        </div>}
              {children}
    </div>
}

export default ReportTable;

