import Wrapper from '../../components/Wrapper'
import withParams from "../../services/withParams";
import * as React from "react";
import api from "../../services/api";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {Alert} from "react-bootstrap";
import MaskedInput from "../../components/MaskedInput";

export default withParams(class TipsOut extends React.Component {
    state = {
        loading: true,
        cardNumber: null,
        cardOwner: null,
        city: "Москва",
        amount: 0
    };

    constructor(props) {
        super(props);
        const { id } = this.props.params;

        api.getTipsAmount().then(response => {
            if(response.status === 200) {
                this.setState({
                    loading: false,
                    // amount: response.data
                    amount: response.data.total,
                    premium: response.data.premium,
                })
            }
            else {
                this.setState({
                    rest: response.data,
                    loading: false
                });
            }
        });
    }

    async submit(event) {
        event.preventDefault();

        this.setState({
            loading: true,
            error: null
        });

        let response = await api.requestTipsOut(this.state);

        this.setState({
            loading: false
        });

        console.log(response);


        if(response.status !== 200 || response.data !== "SUCCESS") {
            this.setState({
                error: response.data
            });
        }
        else {
            this.setState({
                info: "Запрос на вывод чаевых успешно обработан!"
            });
        }
    }

    render() {
        return <Wrapper navigate={this.props.navigate}>
            <div style={{"fontSize": "1.25rem"}}>Вывести чаевые себе на карту</div>
            <br/>
            {this.state.loading && <div>Загрузка...</div>}

            {!this.state.loading && this.state.error && <Alert variant='danger'>
                {this.state.error}
            </Alert>}

            {this.state.info && <Alert variant='info'>
                {this.state.info}
            </Alert>}

            {!this.state.loading && !this.state.info && <Form onSubmit={this.submit.bind(this)}>
                <b>Баланс {(this.state.amount).toFixed(2)} Р</b><br/>
                <div>Комиссия за вывод 0.8%, но не менее 30руб</div>
                <br/> <br/>

                <Form.Group className="mb-3">
                    <Form.Label>Номер карты</Form.Label>

                    <Form.Control mask="1111 1111 1111 1111" as={MaskedInput} type="text" autoComplete="cc-number" value={this.state.cardNumber} onChange={e => this.setState({ cardNumber: e.target.value })} />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Держатель карты</Form.Label>
                    <Form.Control type="text" autoComplete="cc-name" value={this.state.cardOwner} onChange={e => this.setState({ cardOwner: e.target.value })} />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Город</Form.Label>
                    <Form.Control type="text" value={this.state.city}  onChange={e => this.setState({ city: e.target.value })} />
                </Form.Group>

                <br/>

               {/* <Form.Group className="mb-3">
                    <Form.Label>Сумма для вывода</Form.Label>
                    <Form.Control type="number" value={this.state.amount} onChange={e => this.setState({ amount: e.target.value })} />
                </Form.Group>*/}

                <div style={{clear: "both"}} />
                <Button variant="primary" type="submit">
                    Запросить выплату
                </Button>
            </Form>}

            <br />
        </Wrapper>;
    }
})