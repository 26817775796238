import Wrapper from '../../components/Wrapper'

import * as React from "react";
import api from "../../services/api";
import {Button} from "react-bootstrap";
import withParams from "../../services/withParams";
import Form from "react-bootstrap/Form";
import Paginator from "../../components/Paginator";

export default withParams(class RestaurantList extends React.Component {
    state = {
        loading: true,
        data: [],
        current: [],
        name: "",
        enabled: true,
        page: 0,
        searchText: "",
        focusSearch: false,
        actualQty: null,
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.reload();
    }

    reload() {
        if(!this.state.focusSearch)
        {
            this.setState({
                loading: true
            });
        }
        const [searchParams, setSearchParams] = this.props.search;
        setSearchParams({page: this.state.page, searchText: this.state.searchText});

        api.getPageableRestaurants({page: this.state.page, enabled: this.state.enabled, searchText: this.state.searchText}).then(response => {
            if(response.status === 200) {
                if(window.user.role === "ADMIN") {
                    api.getBaseObject("/restaurants/actual-qty").then(response => {
                        if(response.status === 200) {
                            this.setState({
                                actualQty: response.data,
                            });
                        }
                    });
                }

                this.setState({
                    data: response.data,
                    loading: false,
                    focusSearch: false
                });
            }
            else {
                this.setState({
                    error: response.data,
                    loading: false,
                    focusSearch: false
                });
            }
        });
    }

    async selectRestaurant(id) {
        let result = await api.selectRestaurant(id);

        if(result.status === 200) {
            window.user.restaurant = result.data;
            this.props.navigate("/");
        }
        else {
            this.setState({
                error: result.data
            });
        }
    }

    addRest() {
        this.props.navigate("/restaurant/add");
    }

    render() {
        return <Wrapper navigate={this.props.navigate}>
            {this.state.loading && <span className={"title"}>Загрузка...</span>}
            {this.state.error && <span className={"title red"}>Ошибка: {this.state.error}</span>}

            {/* {!this.state.loading && !this.state.error && <p>Ресторанов пока нет...</p>} */}

            {!this.state.loading && this.state.data.content.length >= 0 && <div className={"gray-wrapper gray-table"}>
                {window.user.role === "ADMIN" && <div className={"table-search"} style={{marginBottom: 0}}>
                    <div>
                        <Button onClick={() => {this.addRest()}}>Добавить</Button>
                    </div>
                </div>
                }
                <div className={"table-search"}>
                    <div className={"table-checkbox"}>
                        <input type="radio"
                               checked={this.state.enabled}
                               onChange={e => {
                                   this.setState({ enabled: e.target.checked }, () => {
                                       this.reload();
                                   });
                               }} />
                        <span>Активные</span>
                    </div>
                    <div className={"table-checkbox"}>
                        <input type="radio"
                               checked={!this.state.enabled}
                               onChange={e => {
                                   this.setState({ enabled: !e.target.checked }, () => {
                                       this.reload();
                                   });
                               }} />
                        <span>Не активные</span>
                    </div>
                    <div className={"table-checkbox"}>
                        <input type="text" placeholder='Поиск'
                               value={this.state.searchText}
                               autoFocus
                               onChange={e => {
                                   this.setState({ searchText: e.target.value, focusSearch: true, page: 0}, () => {
                                    this.reload();
                                });
                               }} style={{width: "auto"}} />
                    </div>
                </div>

                {window.user.role === "ADMIN" && <div style={{textAlign: 'right'}}>Реальных ресторанов: {this.state.actualQty}</div>}
                <div className={"table"}>
                    <div className={"thead"}>
                        <div className={"th"}>Название</div>
                        <div className={"th"}>Адрес</div>
                        <div className={"th"}></div>
                    </div>
                    {this.state.data.content.map((item, i) => {
                        return <div className={"tbody"} key={item.id}>
                            <div className={"td word-wrap"}>{item.title || item.name}</div>
                            <div className={"td break"} style={{marginTop:0}}/>
                            <div className={"td word-wrap"}>{item.address}</div>
                            <div className={"td break"}/>
                            <div className={"td end"}><Button onClick={() => this.selectRestaurant(item.id)} style={{height: 45}}>Выбрать</Button></div>
                        </div>
                    })}
                </div>

                <Paginator page={this.state.data} onChange={(handlePageChange) => {
                    this.setState({ page: handlePageChange}, () => {
                        this.reload();
                    });
                }}/>
            </div>}
        </Wrapper>;
    }
});