import Wrapper from '../../components/Wrapper'
import {useState, useEffect} from "react";
import api from "../../services/api";
import {Button} from "react-bootstrap";
import '../../resources/styles/styles.css';
import closeImage from '../../resources/images/close.svg';
import pencilimage from '../../resources/images/pencil.svg';
import fruitImage from '../../resources/images/avatar.png';
import { Link } from 'react-router-dom';
import {FormCheck} from "react-bootstrap";
import ReportTable from '../../components/ReportTable';
import ReportLine from '../../components/ReportLine';
import ToggleMenu from '../../components/ToggleMenu';

const OfflineTerminals = (props) => {

    const [terminalsList, setTerminalsList] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        api.getBaseObject("/zabbix/terminals").then(r => {
            if(r.status == 200)
            {
                setTerminalsList(r.data);
            }
            setLoading(false);
        });
    }, []);

    const columns = ["Ресторан", "Терминал", "Последнее соединение"];

    return <>
        {loading && <span className={"title"}>Загрузка...</span>}
        {!loading &&
        <ToggleMenu title="Терминалы offline">
            <ReportTable keyPrefix="oft" columns={columns}>
                {terminalsList.map((item, i) => {
                    return <ReportLine keyPrefix="oftl" data={[item.title, item.name, item.connected]} isChild={false}></ReportLine>
                })}
            </ReportTable>
        </ToggleMenu>}
    </>
}

export default OfflineTerminals;
