import Wrapper from '../../components/Wrapper'
import withParams from "../../services/withParams";
import * as React from "react";
import api from "../../services/api";
import {Button, Col, Form, Image, Row, Table} from "react-bootstrap";
import Moment from "react-moment";
import order_err from "../../resources/images/order_err.png";
import order_ok from "../../resources/images/order_ok.png";
import '../../resources/styles/order.css'
import {Link} from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import like from "../../resources/images/like.svg";
import dislike from "../../resources/images/dislike.svg";
import declOfNum from "../../components/declOfNum";

export default withParams(class OrderEdit extends React.Component {
    state = {
        loading: true,
        loadingTransaction: true,
        loadingComments: true
    };

    constructor(props) {
        super(props);
        const { id } = this.props.params;

        api.getRKeeperOrder(id).then(response => {
            if(response.status === 200) {
                this.setState({
                    order: response.data,
                    loading: false
                });
            }
            else {
                this.setState({
                    error: response.data,
                    loading: false
                });
            }
        });
    }

    render() {
        return <Wrapper navigate={this.props.navigate}>
            {this.state.loading && <span className={"title"}>Загрузка счёта...</span>}
            {this.state.error && <span className={"title red"}>Ошибка: {this.state.error}</span>}

            {!this.state.loading && !this.state.error && <div>
                <div>Счёт: {this.state.order.created}</div>
                <div>Сумма: {this.state.order.total}</div>
                <div>Скидка: {this.state.order.discount}</div>

            </div>}
        </Wrapper>;
    }
})