import Wrapper from '../../components/Wrapper'
import {useState, useEffect, Fragment} from "react";
import api from "../../services/api";
import {Button, Image} from "react-bootstrap";
import '../../resources/styles/styles.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import moment from "moment";
import Datetime from "react-datetime";
import SettingsItem from '../../components/SettingsItem';
import prepaySend from "../../resources/images/prepay-send.svg";

const Prepay = (props) => {

    const navigate = useNavigate();

    const { id } = useParams();

    const [name, setName] = useState("");
    const [mail, setMail] = useState("");
    const [phone, setPhone] = useState("");
    const [date, setDate] = useState(new Date());
    const [total, setTotal] = useState(0);
    const [comment, setComment] = useState("");
    const [guestComment, setGuestComment] = useState("");
    const [error, setError] = useState("");
    const [table, setTable] = useState(0);
    const [tables, setTables] = useState([]);
    const [tablesFiltered, setTablesFiltered] = useState([]);
    const [tableSearch, setTableSearch] = useState("");
    const [loading, setLoading] = useState(true);
    const [prepayUrl, setPrepayUrl] = useState("");

    const saveData = () => {
        setLoading(true);

        api.setBaseObject("prepay", {
            id: 0,
            created: null,
            reserveDate: date,
            name: name,
            mail: mail,
            phone: phone,
            total: total,
            comment: comment,
            guestComment: "",
            uuid: "",
            tableId: table,
            prepayUrl: "",
        }, false).then(res => {
            if(res.status === 200) {
                navigate("/prepay/"+res.data.id);
            } else {
                setError(res.data);
            }
            setLoading(false);
        });
    }

    const sendLink =() => {
        navigator.clipboard.writeText(`${process.env.REACT_APP_MOBILE_APP_URL}${prepayUrl}`);
    }

    useEffect(() => {
        if(id!==undefined)
        {
            setLoading(true);
            api.getBaseObject("prepay/"+id).then(res => {
                if(res.status === 200) {
                    const d = res.data;
                    setName(d.name);
                    setMail(d.mail);
                    setPhone(d.phone);
                    setDate(moment(d.reserveDate));
                    setComment(d.comment);
                    setTotal(d.total);
                    setGuestComment(d.guestComment ?? "");
                    setTable(d.tableId);
                    setPrepayUrl(d.url ?? "");
                }
                else {
                    setError(res.data);
                }
                setLoading(false);
            });
        }
    }, [id]);

    useEffect(() => {
        api.getRestaurantTables4Prepay().then(response_tables => {
            if(response_tables.status === 200) {
                setTables(response_tables.data);
            }
            else {
                setError(response_tables.data);
            }
            setLoading(false);
        });
    }, []);

    useEffect(()=>{
        if(tables.length>0) {
            let fTables = [];
            tables.forEach(t => {
                if(tableSearch === "" || t.name.toLocaleLowerCase().indexOf(tableSearch.toLocaleLowerCase())>=0){
                    fTables.push(t);
                }
            });
            setTablesFiltered(fTables);
        }
    },[tables, tableSearch]);


    return <Wrapper navigate={props.navigate}>
    <div className='prepay-modal'>
        <div className='prepay-modal-content'>
            <div className='prepay-modal-title'>Предоплата</div>
            <div className='prepay-subtitle'>Проверьте, пожалуйста, данные перед отправкой</div>
            {loading && <div className='prepay-modal-title'>Идет загрузка...</div>}
            {!loading && <Fragment>
                <input className='prepay-input' placeholder='ФИО' onChange={(e) => {setName(e.target.value)}} value={name}></input>
                <input className='prepay-input' placeholder='Почта' onChange={(e) => {setMail(e.target.value)}} value={mail}></input>
                <input className='prepay-input' placeholder='Телефон' onChange={(e) => {setPhone(e.target.value)}} value={phone}></input>
                <input className='prepay-input' placeholder='Поиск' onChange={(e) => {setTableSearch(e.target.value)}} value={tableSearch}></input>
                <SettingsItem value={table} type="list" name="" mandatory={false} className="prepay-input"
                        options={tablesFiltered} onChange={v => setTable(v*1)} ></SettingsItem>

                <Datetime value={date}
                                locale={moment.locale("ru")}
                                onChange={(val) => { setDate(val); }}
                                closeOnSelect={ true }
                                className="prepay-date"
                                dateFormat="DD.MM.YYYY" timeFormat={"HH:mm"} />

                <input className='prepay-input' placeholder='Сумма' type='number' onChange={(e) => {setTotal(e.target.value)}} value={total}></input>
                <div className='prepay-title'>Комментарий</div>
                <textarea className='prepay-text-input' placeholder='' 
                onChange={(e) => {setComment(e.target.value)}}
                value={comment}>
                </textarea>
                {guestComment !== "" && 
                    <textarea className='prepay-text-input' placeholder='' readOnly={true} value={guestComment}>
                    </textarea>
                }
                {id === undefined &&
                <Button variant="primary" className='tipswish-save' 
                    disabled={((name === "" && phone === "" && mail === "") || table === 0 || total <=0)}
                onClick={saveData}>Сформировать</Button>
                }
                {id !== undefined && prepayUrl !== "" && 
                <Button variant="primary" className='tipswish-save' 
                disabled={((name === "" && phone === "" && mail === "") || total <=0)}
                onClick={sendLink}>Отправить гостю
                    <Image src={prepaySend} />
                </Button>
                }
                <Link to="/prepays">
                    <Button variant="primary" className='mail-confirm-cancel'>Отменить</Button>
                </Link>
            </Fragment>}
        </div>
    </div>
    </Wrapper>
}

export default Prepay;
