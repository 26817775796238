import Wrapper from '../../components/Wrapper'

import * as React from "react";
import api from "../../services/api";
import Moment from "react-moment";
import CurrencyFormat from "react-currency-format";
import Image from "react-bootstrap/Image";
import calendar from "../../resources/images/calendar.svg";
import Paginator from "../../components/Paginator";
import '../../resources/styles/tips.css'
import {Alert, Button} from "react-bootstrap";
import card from '../../resources/tips/card.svg'
import rub from '../../resources/tips/rub.svg'
import master from '../../resources/tips/master.svg'
import mir from '../../resources/tips/mir.png'
import visa from '../../resources/tips/visa.svg'
import removeIcon from '../../resources/images/remove.svg';
import MaskedInput from "../../components/MaskedInput";
import Form from "react-bootstrap/Form";
import Datetime from "react-datetime";
import moment from "moment";
import ru from "moment/locale/ru";
import {Link} from "react-router-dom";
import like from "../../resources/images/like.svg";
import dislike from "../../resources/images/dislike.svg";
import withParams from "../../services/withParams";


export default withParams(class TipsList extends React.Component {
    constructor(props) {
        super(props);

        const [searchParams] = this.props.search;

        this.state = {
            loadingOut: true,
            loadingTips: true,
            loadingAmount: true,
            error: false,
            info: "",
            cardNumber: "",
            amount: 0,
            tips: [],
            out: [],
            page: searchParams.get('page') || 0,
            from: moment(searchParams.get('from') ?? new Date(), 'YYYY-MM-DD'),
            to: moment(searchParams.get('to') ?? new Date(), 'YYYY-MM-DD'),
            cardOwner: "",
            city: "Москва",
            saveCard: true,
            noSaveCards: false,
            totalTipsSum: 0,
            totalTipsCount: 0,
            myTips: false,
            cardCorrect: false,
            cardFilled: false,
            cardFocused: false,
            cardNeedConfirm: false,
            deletedCardNumber: null,
        };

        api.getTipsAmount().then(response => {
            if(response.status === 200) {
                this.setState({
                    loadingAmount: false,
                    // amount: response.data || 0,
                    amount: response.data.total,
                    premium: response.data.premium,
                })
            }
            else {
                this.setState({
                    error: response.data,
                    loadingAmount: false
                });
            }
        });

        this.reloadOut(0);
        this.reloadTips();
    }

    reloadOut(number) {
        this.setState({
            loadingOut: true
        });

        api.getPageableTipsOut({page: number, size: 4}).then(responseList => {
            if(responseList.status === 200) {
                this.setState({
                    loadingOut: false,
                    out: responseList.data
                })
            }
            else {
                this.setState({
                    error: responseList.message,
                    loadingOut: false
                });
            }
        });
    }

    reloadTips() {
        this.setState({
            loadingTips: true
        });

        const [searchParams, setSearchParams] = this.props.search;

        setSearchParams({page: this.state.page, from: moment(this.state.from).format("YYYY-MM-DD"),
                                                    to: moment(this.state.to).format("YYYY-MM-DD"),
                                                myTips: this.state.myTips});

        api.getPageableTips({page: this.state.page, from: this.state.from, to: this.state.to, tips: true, size: 10, myTips: this.state.myTips}).then(response => {
            if(response.status === 200) {
                this.setState({
                    tips: response.data,
                    loadingTips: false,
                    totalTipsCount: response.data.totalElements,
                    totalTipsSum: response.data.content.length > 0 ? response.data.content[0].totalPageSum : 0,
                });
            }
            else {
                this.setState({
                    error: response.data,
                    loadingTips: false
                });
            }
        });
    }

    async submit(event) {
        event.preventDefault();

        if(this.state.cardCorrect && this.state.cardFilled)
        {
            this.setState({
                loadingOut: true,
                error: null
            });

            let response = await api.requestTipsOut(this.state);

            this.setState({
                loadingOut: false
            });

            if(response.status !== 200 || response.data !== "SUCCESS") {
                this.setState({
                    error: response.data
                });
            }
            else {
                this.setState({
                    info: "Запрос на вывод чаевых успешно обработан!"
                });

                this.reloadOut(0);

                api.getTipsAmount().then(response => {
                    if(response.status === 200) {
                        this.setState({
                            loadingAmount: false,
                            // amount: response.data,
                            amount: response.data.total,
                            premium: response.data.premium,    
                        })
                    }
                    else {
                        this.setState({
                            error: response.data,
                            loadingAmount: false
                        });
                    }
                });
            }
        }
    }

    checkCardNumber (text) {
        let correct = true;
        let full = false;

        if(text.length >= 19) {
            full = true;
            let cardNo = text.replace(/ /g,"");
            let s = 0;
            let doubleDigit = false;
            for (let i = cardNo.length - 1; i >= 0; i--) {
                let digit = +cardNo[i];
                if (doubleDigit) {
                    digit *= 2;
                    if (digit > 9)
                        digit -= 9;
                }
                s += digit;
                doubleDigit = !doubleDigit;
            }

            correct = s % 10 === 0;
        } else {
            correct = false;
        }

        this.setState({
            card: text,
            cardCorrect: correct,
            cardFilled: full,
        })
    };

    deleteCard() {
        api.setBaseObject("/tips/delete-card", { number: this.state.deletedCardNumber }).then(r => {
            if(r.status === 200) {
                this.setState({
                    cardNeedConfirm: false,
                    deletedCardNumber: null,
                });
                window.location.reload();
            }  else {
                this.setState({
                    error: r.data,
                });
            }
        });
    };

    render() {
        /*
        if(!window.user.manageTips && !(window.user.restaurant.tips && window.user.restaurantRole !== "MANAGER")) {
            return <Wrapper navigate={this.props.navigate}>
                <span className={"title red"}>Чаевые может выводить только менеджер!</span>
            </Wrapper>
        }
        */
        const isSupport = window.user.role === "SUPPORT";

        return <Wrapper navigate={this.props.navigate}>
            {(this.state.loadingOut || this.state.loadingAmount) && <span className={"title"}>Загрузка...</span>}
            {this.state.error && <span className={"title red"}>Ошибка: {this.state.error}</span>}

            <div className={"col"}>
                {!this.state.loadingOut && !window.user.noTipsOut && !this.state.loadingAmount && !this.state.error && <div className={"row between tips-row"}>
                    <div className={"col-2"}>
                        <div className={"xs-hidden"}>
                            <div className={"title"}>Баланс</div>
                            <div className={"manager-balance"}>
                                <CurrencyFormat value={Math.floor(this.state.amount)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} />
                            </div>
                        </div>

                        <div className={"title title-margin"}>История</div>
                        {this.state.out.content.length === 0 && <p className={"table-not-found"}>Вывода средств пока нет...</p>}

                        {this.state.out.content.length > 0 && <div>
                            {this.state.out.content.map((item, i) => {
                                return <div className={"list row between middle"} key={"hist"+i}>
                                    <div className={"flex"}>
                                        <Image src={calendar}/>
                                        <Moment className={"list-text"} format="DD.MM.YY&nbsp;&nbsp;&nbsp;HH:mm">{item.created}</Moment>
                                    </div>
                                    <div className={"list-sum red"}>
                                        -<CurrencyFormat value={item.sum.toFixed(2)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} />
                                    </div>
                                </div>
                            })}

                            <Paginator page={this.state.out} onChange={(handlePageChange) => {
                                this.reloadOut(handlePageChange)
                            }}/>
                        </div>}
                    </div>
                    <div className={"manager-margin-40"} />
                    <div className={"col-2 tips-card-out"}>
                        {this.state.info && <Alert variant='info'>
                            {this.state.info}
                        </Alert>}

                        <div className={"xs-visible"}>
                            <div className={"title"}>Баланс</div>
                            <div className={"manager-balance"}>
                                <CurrencyFormat value={Math.floor(this.state.amount)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} />
                            </div>
                        </div>

                        {this.state.cardNeedConfirm && <div className='mail-confirm-popup'>
                            <div className='mail-confirm-popup-body'>
                                <div className='mail-confirm-text'>Вы уверены, что хотите удалить карту?</div>
                                <Button
                                    variant="primary"
                                    className='mail-confirm-cancel'
                                    onClick={() => {
                                        this.setState({
                                            cardNeedConfirm: false,
                                            deletedCardNumber: null,
                                        });
                                    }}>Отменить
                                </Button>
                                <Button
                                    variant="primary"
                                    onClick={() => {
                                        this.deleteCard();
                                    }}
                                >
                                    Подтвердить
                                </Button>
                            </div>
                        </div>}

                        {!this.state.info && !isSupport && <div className={"block col"}>
                            <div className={"tips-cards"}>
                                <div>
                                    Банковская карта
                                </div>

                                <div className={"tips-cards-wrapper"}>
                                    <Image src={visa}/>
                                    <Image src={master}/>
                                    <Image src={mir} height={15}/>
                                </div>
                            </div>

                            {window.user.cards.length > 0 && !this.state.noSaveCards && <div>
                                <div className={"tips-saved-info"}>Сохраненные карты</div>
                                {window.user.cards.map((card, i) => {
                                    return <>
                                        <div key={"card"+i}
                                            onClick={() => {
                                                this.setState({
                                                    cardNumber: card.number,
                                                    cardOwner: card.fullName,
                                                    saveCard: false,
                                                    cardCorrect: true,
                                                    cardFilled: true,
                                                });
                                                // this.checkCardNumber(card.number);
                                            }}
                                            className={"saved-card " + (this.state.cardNumber === card.number ? "active" : "")}
                                        >
                                            <div>
                                                {card.fullName}
                                            </div>
                                            <div>
                                                <b>{card.number.slice(-4)}</b>  <Image
                                                                                    src={removeIcon}
                                                                                    className="menu-remove-icon"
                                                                                    width={"20px"}
                                                                                    height={"20px"}
                                                                                    style={{padding: 0, verticalAlign:"middle"}}
                                                                                    onClick={() => {
                                                                                        this.setState({
                                                                                            cardNeedConfirm: true,
                                                                                            deletedCardNumber: card.number,
                                                                                        });
                                                                                    }}
                                                                                />
                                            </div>
                                        </div>

                                    </>})}

                                <div className={"tips-saved-info"}>Новая карта</div>

                                <div
                                    onClick={() => {
                                        this.setState({
                                            noSaveCards: true
                                        })
                                    }}
                                    className={"saved-card"}>
                                    <div style={{textAlign: "center"}}>
                                        Добавить новую карту
                                    </div>
                                </div>
                            </div>}

                            {(window.user.cards.length === 0 || this.state.noSaveCards) && <div>
                                <div className={"input"}>
                                    <Image src={card}/>
                                    <Form.Control className={"long"} mask="1111 1111 1111 1111" as={MaskedInput} type="text" autoComplete="cc-number" value={this.state.cardNumber} onChange={e => {this.setState({ cardNumber: e.target.value });
                                    this.checkCardNumber(e.target.value);
                                }}
                                onFocus={() => {this.setState({cardFocused: true})}}
                                onBlur={() => {this.setState({cardFocused: false})}}
                                style={(!this.state.cardFilled || this.state.cardCorrect ? {} : (this.state.cardFocused ? {border: '2px solid #ff9696', outlineStyle: 'none'}: {border: '1px solid #ff9696', outlineStyle: 'none'}))}
                                />
                                </div>

                                <div className={"input"}>
                                    <Image src={card}/>
                                    <Form.Control className={"long"} placeholder="Держатель карты" type="text" autoComplete="cc-name" value={this.state.cardOwner} onChange={e => this.setState({ cardOwner: e.target.value })} />
                                </div>

                                <div className={"input rub"}>
                                    <Image src={rub}/><input className={"long"} disabled={true} value={this.state.amount} />
                                </div>

                                <div className={"input save"}>
                                    <label><input checked={this.state.saveCard} onChange={() => {
                                        this.setState({
                                            saveCard: !this.state.saveCard
                                        })
                                    }} type={'checkbox'} /> Сохранить карту</label>
                                </div>
                            </div>}

                            <div className={"flex"}>
                                <Button onClick={this.submit.bind(this)} disabled={this.state.cardOwner.indexOf(" ") < 0 || this.state.cardOwner.indexOf(" ") === this.state.cardOwner.length - 1 || this.state.cardNumber.replace(/[ _]/g, ' ').length < 16 || !this.state.cardCorrect || this.state.amount<100}>
                                    Вывести средства
                                </Button>
                            </div>

                            <div className={"flex"} style={{marginTop: 20, justifyContent: "center"}}>
                                {this.state.premium > 0 && <>Комиссия: &nbsp;&nbsp;
                                    <CurrencyFormat value={Math.floor(this.state.premium)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} />
                                 </>
                                }
                                {this.state.premium === 0 && 'Без комиссии'}
                            </div>
                        </div>}
                    </div>
                </div>}

                {!this.state.loadingTips && <div className={"gray-wrapper gray-table tips-list"}>
                    <div className={"table-search"}>

                        <Datetime value={this.state.from}
                                locale={moment.locale()}
                                onChange={(val) => {
                                    this.setState({ from: val, page: 0}, () => {
                                        this.reloadTips();
                                    });
                                }}
                                closeOnSelect={ true }
                                dateFormat="YYYY.MM.DD" timeFormat={false} />
                        <Datetime value={this.state.to}
                                locale={moment.locale()}
                                onChange={(val) => {
                                    this.setState({ to: val, page: 0 }, () => {
                                        this.reloadTips();
                                    });
                                }}
                                closeOnSelect={ true }
                                dateFormat="YYYY.MM.DD" timeFormat={false} />
                    </div>
                    {((window.user.manageTips ?? false) || window.user.role === 'ADMIN') && <div className={"table-search"}
                    style={{marginTop: 0}}>
                    <select value={this.state.myTips} onChange={e => this.setState({ myTips: e.target.value }, () => {
                                        this.reloadTips();
                                    })}
                    style={{width: '100%'}}>
                        <option disabled={true}>-- Выберите --</option>
                        <option value="false">Все</option>
                        <option value="true">Только мои</option>
                    </select>
                    </div>
                    }

                    {this.state.tips.content.length === 0 && <p className={"table-not-found"}>Чаевых пока нет...</p>}

                    {this.state.tips.content.length > 0 && <div className={"col"}>
                        <div className={"table"}>
                            <div className={"tbody"}>
                                <div className={"td"}>Итого: &nbsp;{this.state.totalTipsCount}</div>
                                <div className={"td end xs-visible-bold"}>Сумма: &nbsp;&nbsp;<CurrencyFormat value={this.state.totalTipsSum.toFixed(2)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} /></div>
                            </div>
                        </div>

                        <div className={"table"}>
                            <div className={"thead"}>
                                <div className={"th"}>Дата</div>
                                <div className={"th"}>Чаевые</div>
                                <div className={"th"}>Официант</div>
                                <div className={"th"}>Отзыв</div>
                                <div className={"th"}>Стол</div>
                                <div className={"th"}>Комментарий</div>
                            </div>

                            {this.state.tips.content.map((item, i) => {
                                return <Link className={"tbody"} key={item.id+'_'+i} to={"/orders/" + item.orderId} title={item.id} >
                                    <div className={"td"}><Moment format="DD.MM.YY&nbsp;&nbsp;&nbsp;HH:mm">{item.closed}</Moment></div>
                                    <div className={"td end xs-visible-bold"}>
                                        {item.tipsLeft !== undefined && item.tipsLeft != null && item.tipsLeft !== item.tips &&
                                            <>
                                            <CurrencyFormat value={item.tipsLeft.toFixed(2)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} /> / &nbsp;
                                            <CurrencyFormat style={{color: '#AAAAAA'}} value={item.tips.toFixed(2)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} />
                                            </>
                                        }
                                        {(item.tipsLeft === undefined || item.tipsLeft == null || item.tipsLeft === item.tips) &&
                                            <CurrencyFormat value={item.tips.toFixed(2)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} />
                                        }
                                    </div>
                                    <div className={"td break"}/>
                                    <div className={"td"}>{item.waiter}</div>
                                    <div className={"td"}>{item.like === true ? <Image src={like} height={30}/> : item.like === false ? <Image src={dislike} height={30}/> : null}</div>
                                    <div className={"td"}><div className={"xs-visible"}>Стол&nbsp;</div>{item.table}</div>
                                    <div className={"td end word-wrap"}>{item.comment}</div>
                                </Link>
                            })}
                        </div>

                        <Paginator page={this.state.tips} onChange={(handlePageChange) => {
                            this.setState({ page: handlePageChange }, () => {
                                this.reloadTips();
                            });
                        }}/>
                    </div>}
                </div>}
            </div>
        </Wrapper>;
    }
});