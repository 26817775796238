import Wrapper from '../../components/Wrapper'

import * as React from "react";
import Button from "react-bootstrap/Button";
import api from "../../services/api";
import withParams from "../../services/withParams";
import QRCode from "react-qr-code";
import {Link} from "react-router-dom";
import Moment from "react-moment";

export default withParams(class TerminalEdit extends React.Component {
    state = {
        loading: true,
        id: null,
        serial: null,
        type: null,
        tableNum: "0",
        memory: null,
        x64: null,
        restaurant: {},
        needRestart: false,
        enabled: true,
    };

    constructor(props) {
        super(props);

        this.reload();
    }

    reload() {
        const { id } = this.props.params;
        const [searchParams] = this.props.search;

        api.getTerminal(id).then(response => {
            if(response.status === 200) {
                api.getRestaurant(response.data.restaurantId).then(responseRest => {
                    if(responseRest.status === 200) {
                        this.setState({
                            restaurant: responseRest.data,
                            id: response.data.id,
                            uuid: response.data.uuid,
                            type: response.data.type,
                            name: response.data.name,
                            x64: response.data.x64,
                            memory: response.data.memory,
                            memoryLimit: response.data.memoryLimit,
                            version: response.data.version,
                            connected: response.data.connected,
                            organizationId: response.data.organizationId,
                            loading: false,
                            tableNum: searchParams.get("table") || 0,
                            needRestart: response.data.needRestart,
                            enabled: response.data.enabled,
                        });

                    }
                    else {
                        this.setState({
                            error: response.data,
                            loading: false
                        });
                    }
                });
            }
            else {
                this.setState({
                    error: response.data,
                    loading: false
                });
            }
        });
    }

    render() {
        let url = `${process.env.REACT_APP_MOBILE_APP_URL}?r=${this.state.restaurant.id}&t=${this.state.id}&n=${this.state.tableNum}`;

        return <Wrapper navigate={this.props.navigate}>
            {this.state.loading && <p>Загрузка ресторана...</p>}
            {this.state.error && <p>Ошибка: {this.state.error}</p>}

            {(window.user.role === "ADMIN" || window.user.role === "SUPPORT") && !this.state.loading && !this.state.error && <div className={"gray-wrapper gray-table"} style={{marginBottom: 20}}>

                <div className={"col"}>
                    <div className={"table"}>
                        <div className={"thead"}>
                            <div className={"th"}>Параметр</div>
                            <div className={"th"}>Значение</div>
                        </div>

                        <div className={"tbody"}>
                            <div className={"td"}>Номер кассы</div>
                            <div className={"td"}>{this.state.uuid}</div>
                        </div>

                        <div className={"tbody"}>
                            <div className={"td"}>Дата подключения</div>
                            <div className={"td"}><Moment format="DD.MM.YY&nbsp;&nbsp;&nbsp;HH:mm:ss">{this.state.connected}</Moment></div>
                        </div>

                        <div className={"tbody"}>
                            <div className={"td"}>Тип кассы</div>
                            <div className={"td"}>{this.state.type}</div>
                        </div>

                        <div className={"tbody"}>
                            <div className={"td"}>Версия</div>
                            <div className={"td"}>{this.state.version}</div>
                        </div>

                        <div className={"tbody"}>
                            <div className={"td"}>Битность</div>
                            <div className={"td"}>{this.state.x64 === true ? "x64" : this.state.x64 === false ? "x86" : "не указана"}</div>
                        </div>

                        <div className={"tbody"}>
                            <div className={"td"}>Потребление памяти</div>
                            <div className={"td"}>{this.state.memory != null ? (this.state.memory + " МБ") : "не указано"}</div>
                        </div>

                        <div className={"tbody"}>
                            <div className={"td"}>Лимит памяти</div>
                            <div className={"td"}>{this.state.memoryLimit != null ? (this.state.memoryLimit + " МБ") : "не указано"}</div>
                        </div>

                        <div className={"tbody"}>
                            <div className={"td"}>Перезапуск</div>
                            <div className={"td"}><input type={"checkbox"} checked={this.state.needRestart} onClick={() => {
                                this.setState({
                                    loading: true
                                })
                                api.setTerminalRestart(this.state.id, !this.state.needRestart).then(() => {
                                    this.reload();
                                })
                            }} /></div>
                        </div>

                        <div className={"tbody"}>
                            <div className={"td"}>Онлайн</div>
                            <div className={"td"}><input type={"checkbox"} checked={this.state.enabled} onClick={() => {
                                this.setState({
                                    loading: true
                                });
                                api.changeTerminalEnabled(this.state.id).then(() => {
                                    this.reload();
                                })
                            }} /></div>
                        </div>
                    </div>
                </div>

            </div>}

            {!this.state.loading && !this.state.error && <div className={"form form-wrapper"}>
                <b>Настройка шаблона пречека</b>

                {this.state.type === "IIKO" && <><div>
                    В шаблоне пречека укажите следующее: <br/><br/>

                    <pre style={{whiteSpace: 'pre-wrap', borderLeft: '3px black solid', marginTop: 10, marginBottom: 10, padding: 10, backgroundColor: "#f3f3f3"}}>

                    @&#123;<br/>
                        UInt16[] crctab = &#123;
                        0x0000, 0xc0c1, 0xc181, 0x0140, 0xc301, 0x03c0, 0x0280, 0xc241,
                        0xc601, 0x06c0, 0x0780, 0xc741, 0x0500, 0xc5c1, 0xc481, 0x0440,
                        0xcc01, 0x0cc0, 0x0d80, 0xcd41, 0x0f00, 0xcfc1, 0xce81, 0x0e40,
                        0x0a00, 0xcac1, 0xcb81, 0x0b40, 0xc901, 0x09c0, 0x0880, 0xc841,
                        0xd801, 0x18c0, 0x1980, 0xd941, 0x1b00, 0xdbc1, 0xda81, 0x1a40,
                        0x1e00, 0xdec1, 0xdf81, 0x1f40, 0xdd01, 0x1dc0, 0x1c80, 0xdc41,
                        0x1400, 0xd4c1, 0xd581, 0x1540, 0xd701, 0x17c0, 0x1680, 0xd641,
                        0xd201, 0x12c0, 0x1380, 0xd341, 0x1100, 0xd1c1, 0xd081, 0x1040,
                        0xf001, 0x30c0, 0x3180, 0xf141, 0x3300, 0xf3c1, 0xf281, 0x3240,
                        0x3600, 0xf6c1, 0xf781, 0x3740, 0xf501, 0x35c0, 0x3480, 0xf441,
                        0x3c00, 0xfcc1, 0xfd81, 0x3d40, 0xff01, 0x3fc0, 0x3e80, 0xfe41,
                        0xfa01, 0x3ac0, 0x3b80, 0xfb41, 0x3900, 0xf9c1, 0xf881, 0x3840,
                        0x2800, 0xe8c1, 0xe981, 0x2940, 0xeb01, 0x2bc0, 0x2a80, 0xea41,
                        0xee01, 0x2ec0, 0x2f80, 0xef41, 0x2d00, 0xedc1, 0xec81, 0x2c40,
                        0xe401, 0x24c0, 0x2580, 0xe541, 0x2700, 0xe7c1, 0xe681, 0x2640,
                        0x2200, 0xe2c1, 0xe381, 0x2340, 0xe101, 0x21c0, 0x2080, 0xe041,
                        0xa001, 0x60c0, 0x6180, 0xa141, 0x6300, 0xa3c1, 0xa281, 0x6240,
                        0x6600, 0xa6c1, 0xa781, 0x6740, 0xa501, 0x65c0, 0x6480, 0xa441,
                        0x6c00, 0xacc1, 0xad81, 0x6d40, 0xaf01, 0x6fc0, 0x6e80, 0xae41,
                        0xaa01, 0x6ac0, 0x6b80, 0xab41, 0x6900, 0xa9c1, 0xa881, 0x6840,
                        0x7800, 0xb8c1, 0xb981, 0x7940, 0xbb01, 0x7bc0, 0x7a80, 0xba41,
                        0xbe01, 0x7ec0, 0x7f80, 0xbf41, 0x7d00, 0xbdc1, 0xbc81, 0x7c40,
                        0xb401, 0x74c0, 0x7580, 0xb541, 0x7700, 0xb7c1, 0xb681, 0x7640,
                        0x7200, 0xb2c1, 0xb381, 0x7340, 0xb101, 0x71c0, 0x7080, 0xb041,
                        0x5000, 0x90c1, 0x9181, 0x5140, 0x9301, 0x53c0, 0x5280, 0x9241,
                        0x9601, 0x56c0, 0x5780, 0x9741, 0x5500, 0x95c1, 0x9481, 0x5440,
                        0x9c01, 0x5cc0, 0x5d80, 0x9d41, 0x5f00, 0x9fc1, 0x9e81, 0x5e40,
                        0x5a00, 0x9ac1, 0x9b81, 0x5b40, 0x9901, 0x59c0, 0x5880, 0x9841,
                        0x8801, 0x48c0, 0x4980, 0x8941, 0x4b00, 0x8bc1, 0x8a81, 0x4a40,
                        0x4e00, 0x8ec1, 0x8f81, 0x4f40, 0x8d01, 0x4dc0, 0x4c80, 0x8c41,
                        0x4400, 0x84c1, 0x8581, 0x4540, 0x8701, 0x47c0, 0x4680, 0x8641,
                        0x8201, 0x42c0, 0x4380, 0x8341, 0x4100, 0x81c1, 0x8081, 0x4040
                        &#125;;<br/>
                        byte[] bytes = System.Text.Encoding.Default.GetBytes(order.Waiter.Name);<br/>
                        int crc = 0x0000;<br/>
                        foreach (byte b in bytes) &#123; crc = (crc &gt;&gt; 8) ^ crctab[(crc ^ b) &amp; 0xff]; &#125;<br/>
                        var bit = BitConverter.ToInt16(System.Text.Encoding.ASCII.GetBytes(order.Waiter.Name), 0);<br/>
                    &#125;<br/>
                    &lt;f1&gt;&lt;center&gt;Оплата счёта и чаевых&lt;/center&gt;&lt;/f1&gt;<br/>
                    &lt;f1&gt;&lt;center&gt;Eat @System.Uri.UnescapeDataString(&quot;%26&quot;) Split&lt;/center&gt;&lt;/f1&gt;<br/>
                    &lt;np /&gt;
                    &lt;center&gt;&lt;qrcode size=&quot;large&quot; correction=&quot;low&quot;&gt;https://eatandsplit.com/?z={this.state.restaurant.id}q@(order.Number)s@(order.GetFullSum().ToString().Replace(",", "."))n@(order.Table.Number)w@(crc)&lt;/qrcode&gt;&lt;/center&gt;
                   </pre>
                </div>
                
                <div>
                    Если несколько заведений разделяются по терминальным группам, то скорректируйте в шаблоне название терминальной группы и id других заведений: <br/><br/>

                    <pre style={{whiteSpace: 'pre-wrap', borderLeft: '3px black solid', marginTop: 10, marginBottom: 10, padding: 10, backgroundColor: "#f3f3f3"}}>

                    @&#123;<br/>
                        UInt16[] crctab = &#123;
                        0x0000, 0xc0c1, 0xc181, 0x0140, 0xc301, 0x03c0, 0x0280, 0xc241,
                        0xc601, 0x06c0, 0x0780, 0xc741, 0x0500, 0xc5c1, 0xc481, 0x0440,
                        0xcc01, 0x0cc0, 0x0d80, 0xcd41, 0x0f00, 0xcfc1, 0xce81, 0x0e40,
                        0x0a00, 0xcac1, 0xcb81, 0x0b40, 0xc901, 0x09c0, 0x0880, 0xc841,
                        0xd801, 0x18c0, 0x1980, 0xd941, 0x1b00, 0xdbc1, 0xda81, 0x1a40,
                        0x1e00, 0xdec1, 0xdf81, 0x1f40, 0xdd01, 0x1dc0, 0x1c80, 0xdc41,
                        0x1400, 0xd4c1, 0xd581, 0x1540, 0xd701, 0x17c0, 0x1680, 0xd641,
                        0xd201, 0x12c0, 0x1380, 0xd341, 0x1100, 0xd1c1, 0xd081, 0x1040,
                        0xf001, 0x30c0, 0x3180, 0xf141, 0x3300, 0xf3c1, 0xf281, 0x3240,
                        0x3600, 0xf6c1, 0xf781, 0x3740, 0xf501, 0x35c0, 0x3480, 0xf441,
                        0x3c00, 0xfcc1, 0xfd81, 0x3d40, 0xff01, 0x3fc0, 0x3e80, 0xfe41,
                        0xfa01, 0x3ac0, 0x3b80, 0xfb41, 0x3900, 0xf9c1, 0xf881, 0x3840,
                        0x2800, 0xe8c1, 0xe981, 0x2940, 0xeb01, 0x2bc0, 0x2a80, 0xea41,
                        0xee01, 0x2ec0, 0x2f80, 0xef41, 0x2d00, 0xedc1, 0xec81, 0x2c40,
                        0xe401, 0x24c0, 0x2580, 0xe541, 0x2700, 0xe7c1, 0xe681, 0x2640,
                        0x2200, 0xe2c1, 0xe381, 0x2340, 0xe101, 0x21c0, 0x2080, 0xe041,
                        0xa001, 0x60c0, 0x6180, 0xa141, 0x6300, 0xa3c1, 0xa281, 0x6240,
                        0x6600, 0xa6c1, 0xa781, 0x6740, 0xa501, 0x65c0, 0x6480, 0xa441,
                        0x6c00, 0xacc1, 0xad81, 0x6d40, 0xaf01, 0x6fc0, 0x6e80, 0xae41,
                        0xaa01, 0x6ac0, 0x6b80, 0xab41, 0x6900, 0xa9c1, 0xa881, 0x6840,
                        0x7800, 0xb8c1, 0xb981, 0x7940, 0xbb01, 0x7bc0, 0x7a80, 0xba41,
                        0xbe01, 0x7ec0, 0x7f80, 0xbf41, 0x7d00, 0xbdc1, 0xbc81, 0x7c40,
                        0xb401, 0x74c0, 0x7580, 0xb541, 0x7700, 0xb7c1, 0xb681, 0x7640,
                        0x7200, 0xb2c1, 0xb381, 0x7340, 0xb101, 0x71c0, 0x7080, 0xb041,
                        0x5000, 0x90c1, 0x9181, 0x5140, 0x9301, 0x53c0, 0x5280, 0x9241,
                        0x9601, 0x56c0, 0x5780, 0x9741, 0x5500, 0x95c1, 0x9481, 0x5440,
                        0x9c01, 0x5cc0, 0x5d80, 0x9d41, 0x5f00, 0x9fc1, 0x9e81, 0x5e40,
                        0x5a00, 0x9ac1, 0x9b81, 0x5b40, 0x9901, 0x59c0, 0x5880, 0x9841,
                        0x8801, 0x48c0, 0x4980, 0x8941, 0x4b00, 0x8bc1, 0x8a81, 0x4a40,
                        0x4e00, 0x8ec1, 0x8f81, 0x4f40, 0x8d01, 0x4dc0, 0x4c80, 0x8c41,
                        0x4400, 0x84c1, 0x8581, 0x4540, 0x8701, 0x47c0, 0x4680, 0x8641,
                        0x8201, 0x42c0, 0x4380, 0x8341, 0x4100, 0x81c1, 0x8081, 0x4040
                        &#125;;<br/>
                        byte[] bytes = System.Text.Encoding.Default.GetBytes(order.Waiter.Name);<br/>
                        int crc = 0x0000;<br/>
                        foreach (byte b in bytes) &#123; crc = (crc &gt;&gt; 8) ^ crctab[(crc ^ b) &amp; 0xff]; &#125;<br/>
                        var bit = BitConverter.ToInt16(System.Text.Encoding.ASCII.GetBytes(order.Waiter.Name), 0);<br/>
                        var zid = "{this.state.restaurant.id}";<br/>
                        if(Model.CommonInfo.Group.Name == "Название другой терминальной группы")<br/>
                        &#123;<br/>
                        &nbsp;&nbsp;zid="id другого заведения";<br/>
                        &#125;<br/>
                    &#125;<br/>
                    &lt;f1&gt;&lt;center&gt;Оплата счёта и чаевых&lt;/center&gt;&lt;/f1&gt;<br/>
                    &lt;f1&gt;&lt;center&gt;Eat @System.Uri.UnescapeDataString(&quot;%26&quot;) Split&lt;/center&gt;&lt;/f1&gt;<br/>
                    &lt;np /&gt;
                    &lt;center&gt;&lt;qrcode size=&quot;large&quot; correction=&quot;low&quot;&gt;https://eatandsplit.com/?z=@(zid)q@(order.Number)s@(order.GetFullSum().ToString().Replace(",", "."))n@(order.Table.Number)w@(crc)&lt;/qrcode&gt;&lt;/center&gt;
                   </pre>
                </div>
                </>
                }

                {this.state.type === "R_KEEPER" && <div>
                    Создайте 2 виртуальных бэнда, в каждом добавьте по memo.<br/>
                    Укажите выравнивание текста по центру и добавьте в первый memo текст:
                    <pre style={{whiteSpace: 'pre-wrap', borderLeft: '3px black solid', marginTop: 10, marginBottom: 10, padding: 10, backgroundColor: "#f3f3f3"}}>
                    Оплата счёта и чаевых<br/>
                    Eat &amp; Split<br/>
                    </pre>
                    Во втором memo укажите выравнивание текста по центру и добавьте в текст:
                    <pre style={{whiteSpace: 'pre-wrap', borderLeft: '3px black solid', marginTop: 10, marginBottom: 10, padding: 10, backgroundColor: "#f3f3f3"}}>
                    &lt;QRCode https://eatandsplit.com/?z={this.state.restaurant.id}q[Заказы.Порядковыйномер]s[Чеки.Сумма]n[Заказы.Стол.Код]w[Заказы.Основнойофициант.Код]&gt;
                    </pre>
                </div>}

                {/* {this.state.type === "R_KEEPER" && <div>
                    В скрипте мемо укажите следующее:
                    <pre style={{whiteSpace: 'pre-wrap', borderLeft: '3px black solid', marginTop: 10, marginBottom: 10, padding: 10, backgroundColor: "#f3f3f3"}}>

var<br/>
&nbsp;&nbsp;num: string;<br/>
                        &nbsp;&nbsp;waiter_code: string;<br/>
                        &nbsp;&nbsp;sum: string;<br/>
                        &nbsp;&nbsp;qr: string;<br/>
                        &nbsp;&nbsp;tbl: string;<br/>
begin<br/>
                        &nbsp;&nbsp;num := IntToStr([Заказы.Порядковыйномер]);<br/>
                        &nbsp;&nbsp;waiter_code := IntToStr([Заказы.Основнойофициант.Код]);<br/>
                        &nbsp;&nbsp;sum := IntToStr(Trunc([Чеки.Сумма]));<br/>
                        &nbsp;&nbsp;tbl := IntToStr([Заказы.Стол.Код]);<br/>
<br/>
                        &nbsp;&nbsp;begin<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;qr := 'https://eatandsplit.com/?z={this.state.restaurant.id}q' + num + 's' + sum + 'n' + tbl + 'w' + waiter_code;<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;Memo7.text  :=  Char(#10)<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+ 'Оплата счёта и чаевых ' + Char(#10)<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+ 'Eat &amp; Split' + Char(#10) + Char(#10)<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+ '&lt;QRCode ' + qr + '&gt;' + Char(#10)<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;end;<br/>
end
                    </pre>
                </div>} */}
                <br />
                <br />
                <br />

                <b>QR код для печати на обычном принтере</b>
                <br />

                <div>
                    Введите номер стола для копирования ссылки и создания QR кода
                    <input type="text" value={this.state.tableNum} onChange={e => this.setState({ tableNum: e.target.value })}/>
                </div>

                <br />
                <br />

                Ссылка для оплаты (нажмите для копирования):
                <div className={"flex"} style={{marginBottom: 40}}>
                    <Button onClick={() => {navigator.clipboard.writeText(url)}} className={"flex"}>
                        {url}
                    </Button>
                </div>

                <br /><br />

                <div style={{ height: "auto", margin: "0 auto", width: "50%" }}>
                    <QRCode
                        size={256}
                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        value={url}
                        viewBox={`0 0 256 256`}
                    />
                </div>

                <br />

                <Link to={`qr?table=${this.state.tableNum}`} target="_blank" rel="noopener noreferrer"> <Button variant="primary">Печать QR кода</Button></Link>

                <br/>
            </div>}
        </Wrapper>;
    }
})