import Wrapper from '../../components/Wrapper'

import * as React from "react";
import api from "../../services/api";
import withParams from "../../services/withParams";
import Image from "react-bootstrap/Image";
import img_qr from '../../resources/images/qr.png'
import {Link} from "react-router-dom";

export default withParams(class RestaurantTables extends React.Component {
    constructor(props) {
        super(props);
        const [searchParams] = this.props.search;

        this.state = {
            loading: true,
            items: [],
            enabled: searchParams.get('enabled') || true,
            onlyUsed: searchParams.get('onlyUsed') || true,
        };

        this.reload();
    }

    reload() {
        const [searchParams, setSearchParams] = this.props.search;
        setSearchParams({enabled: this.state.enabled, onlyUsed: this.state.onlyUsed});

        api.getRestaurantTables({enabled: this.state.enabled, onlyUsed: this.state.onlyUsed}).then(response => {
            if(response.status === 200) {
                this.setState({
                    items: response.data,
                    loading: false
                });
            }
            else {
                this.setState({
                    error: response.data,
                    loading: false
                });
            }
        });
    }

    render() {
        return <Wrapper navigate={this.props.navigate}>
            {this.state.loading && <p>Загрузка столов...</p>}

            {!this.state.loading && this.state.items.length === 0 && <p>Столов пока нет...</p>}
            {!this.state.loading && this.state.items.length !== 0 && <p>Нажмите на стол для получения QR кода</p>}

            {!this.state.loading && <div className={"gray-wrapper gray-table"}>
                <div className={"table-search"}>
                    <div className={"table-checkbox"}>
                        <input type="checkbox"
                               checked={this.state.enabled}
                               onChange={e => {
                                   this.setState({ enabled: e.target.checked }, () => {
                                       this.reload();
                                   });
                               }} />
                        <span>Только активные</span>
                    </div>
                    <div className={"table-checkbox"}>
                        <input type="checkbox"
                               checked={this.state.onlyUsed}
                               onChange={e => {
                                   this.setState({ onlyUsed: e.target.checked }, () => {
                                       this.reload();
                                   });
                               }} />
                        <span>Только использующиеся</span>
                    </div>
                </div>

                {!this.state.loading && this.state.items.length === 0 && <p className={"table-not-found"}>Столов пока нет...</p>}

                {this.state.items.length > 0 && <div className={"col"}>
                    <div className={"table"}>
                        <div className={"thead"}>
                            <div className={"th"}>Номер</div>
                            <div className={"th"}>Название</div>
                            <div className={"th break"}/>
                            <div className={"th"}>Секция</div>
                            <div className={"th"}>QR</div>
                        </div>

                        {this.state.items.sort((a,b) => {
                            if(a.sectionName < b.sectionName) { return -1; }
                            if(a.sectionName > b.sectionName) { return 1; }
                            return 0;
                        }).map((item, i) => {
                            return <Link className={"tbody"} key={item.id} to={`/restaurant/tables/${item.id}`}>
                                <div className={"td col-2"}><div className={"xs-visible"}>Номер</div>&nbsp;{item.number}</div>
                                <div className={"td col-2 end"}><div className={"xs-visible"}>Название стола </div>&nbsp;{item.name}</div>
                                <div className={"td break"}/>
                                <div className={"td col-2 end"}><div className={"xs-visible"}>Секция </div>&nbsp;{item.sectionName}</div>
                                <div className={"td end"}><Image src={img_qr} width={20} /></div>
                            </Link>
                        })}
                    </div>
                </div>}
            </div>}
        </Wrapper>;
    }
});