import * as React from "react";
import {Button} from "react-bootstrap";
import Product from "./Product";
import Image from "react-bootstrap/Image";
import editIcon from '../../resources/images/edit.svg';
import arrowIcon from '../../resources/images/arrow.svg';
import removeIcon from '../../resources/images/remove.svg';
import ToggleMenu from "../../components/ToggleMenu";

const Category = ({item, onAddProduct, onEditCategory, isFirst, isLast, onUp, onDown,
    onEditProduct, onProductUp, onProductDown, onDeleteCategory, onDeleteProduct}) => 
{
    const [needConfirm, setNeedConfirm] = React.useState(false);

    return <div className="category">
            <div className="category-item">
                <div className={"category-name" + (item.enabled ? "" : " disabled")}>{item.name}</div>
                <Button onClick={() => {onEditCategory(item)}}>
                    <Image src={editIcon} className="edit-icon" />
                </Button>
                <Button disabled={isFirst} onClick={() => {onUp(item)}}>
                    <Image src={arrowIcon} className="arrow-up-icon" />
                </Button>
                <Button disabled={isLast} onClick={() => {onDown(item)}}>
                    <Image src={arrowIcon} className="arrow-down-icon" />
                </Button>
                <Image src={removeIcon} disabled={(item.id ?? 0) > 0} className="menu-remove-icon"  onClick={() => {setNeedConfirm(true)}} />
            </div>
            {item.products.length>0 && <ToggleMenu name="блюда">
                {item.products.map((product, j) => {
                    return <Product key={product.key} item={product} 
                    onEditProduct={onEditProduct}
                    onUp={onProductUp}
                    onDown={onProductDown}
                    isFirst={j === 0}
                    isLast={j === item.products.length-1}
                    onDeleteProduct={() => {onDeleteProduct(product.id);}}
                    />
                })}
            </ToggleMenu>}
            <div>
                <Button type="button" onClick={() => { onAddProduct(item); }}>
                    Добавить продукт
                </Button>
            </div>
            {needConfirm && <div className='mail-confirm-popup'>
                    <div className='mail-confirm-popup-body'>
                        <div className='mail-confirm-text'>Вы уверены, что хотите удалить категорию с продуктами в ней?</div>
                        <Button variant="primary" className='mail-confirm-cancel' onClick={() => {setNeedConfirm(false)}}>Отменить</Button>
                        <Button variant="primary" onClick={onDeleteCategory}>Подтвердить</Button>
                    </div>
                </div>
            }

        </div>
};

export default Category;

