import Wrapper from '../../components/Wrapper'

import * as React from "react";
import api from "../../services/api";
import {Form} from "react-bootstrap";
import Moment from "react-moment";
import withParams from "../../services/withParams";
import moment from "moment";
import Datetime from "react-datetime";
import ru from "moment/locale/ru"
import {Link} from "react-router-dom";
import Paginator from "../../components/Paginator";

export default withParams(class CommentList extends React.Component {
    constructor(props) {
        super(props);

        const [searchParams] = this.props.search;

        this.state = {
            loading: true,
            data: [],
            page: searchParams.get('page') || 0,
            badly: searchParams.get('badly') || false,
            commented: searchParams.get('commented') ? (searchParams.get('commented') === 'true') : true,
            date: searchParams.get('date') || moment().format("YYYY-MM-DD"),
            from: moment(searchParams.get('from') ?? new Date(), 'YYYY-MM-DD'),
            to: moment(searchParams.get('to') ?? new Date(), 'YYYY-MM-DD'),
            myTips: false,
        };

        this.reload();
    }

    reload() {
        this.setState({
            loading: true
        });

        const [searchParams, setSearchParams] = this.props.search;

        setSearchParams({page: this.state.page, commented: this.state.commented, badly: this.state.badly,
            from: moment(this.state.from).format("YYYY-MM-DD"),
            to: moment(this.state.to).format("YYYY-MM-DD"),
            myTips: this.state.myTips});

        api.getPageableComments({page: this.state.page, commented: this.state.commented, badly: this.state.badly, 
                from: this.state.from, to: this.state.to, myTips: this.state.myTips}).then(response => {
            if(response.status === 200) {
                this.setState({
                    data: response.data,
                    loading: false
                });
            }
            else {
                this.setState({
                    error: response.data,
                    loading: false
                });
            }
        });
    }

    render() {
        return <Wrapper navigate={this.props.navigate}>
            {this.state.loading && <span className={"title"}>Загрузка...</span>}
            {this.state.error && <span className={"title red"}>Ошибка: {this.state.error}</span>}


            {!this.state.loading && <div className={"gray-wrapper gray-table"}>
                <div className={"table-search"}>
                    <div className={"table-checkbox"}>
                        <input type="checkbox"
                               checked={this.state.badly}
                               onChange={e => {
                                   this.setState({ badly: e.target.checked }, () => {
                                       this.reload();
                                   });
                               }} />
                        <span>Только 3 звезды и меньше</span>
                    </div>

                    <div className={"table-checkbox"}>
                        <input type="checkbox"
                               checked={this.state.commented}
                               onChange={e => {
                                   this.setState({ commented: e.target.checked }, () => {
                                       this.reload();
                                   });
                               }} />
                        <span>Только с комментарием</span>
                    </div>
                </div>
                <div className={"table-search"}>
                    <Datetime value={this.state.from}
                            locale={moment.locale()}
                            onChange={(val) => {
                                this.setState({ from: val, page: 0}, () => {
                                    this.reload();
                                });
                            }}
                            closeOnSelect={ true }
                            dateFormat="YYYY.MM.DD" timeFormat={false} />
                    <Datetime value={this.state.to}
                            locale={moment.locale()}
                            onChange={(val) => {
                                this.setState({ to: val, page: 0 }, () => {
                                    this.reload();
                                });
                            }}
                            closeOnSelect={ true }
                            dateFormat="YYYY.MM.DD" timeFormat={false} />
                </div>
                {(window.user !== undefined && ((window.user.manageTips ?? false) && window.user.role !== 'ADMIN')) && <div className={"table-search"}
                style={{marginTop: 0}}>
                <select value={this.state.myTips} onChange={e => this.setState({ myTips: e.target.value }, () => {
                                this.reload();
                            })}
                style={{width: '100%'}}>
                <option disabled={true}>-- Выберите --</option>
                <option value="false">Все</option>
                <option value="true">Только мои</option>
                </select>
                </div>
                }


                {!this.state.loading && this.state.data.content.length === 0 && <p className={"table-not-found"}>Отзывов пока нет...</p>}

                {this.state.data.content.length > 0 && <div className={"col"}>
                    <div className={"table"}>
                        <div className={"thead"}>
                            <div className={"th"}>Оценка</div>
                            <div className={"th"}>Дата</div>
                            <div className={"th break"}/>
                            <div className={"th"}>Комментарий</div>
                        </div>

                        {this.state.data.content.map((item, i) => {
                            return <Link className={"tbody"} key={item.id} to={"/orders/" + item.order.id}>
                                <div className={"td col-2"}><div className={"xs-visible"}>Оценка</div>&nbsp;{item.stars}/5</div>
                                <div className={"td col-2 end"}><Moment format="DD.MM.YY&nbsp;&nbsp;&nbsp;HH:mm">{item.created}</Moment></div>
                                <div className={"td break"}/>
                                <div className={"td word-wrap"}>{item.comment ? item.comment : <span style={{color: "rgb(185,185,185)"}}>Без отзыва</span>}</div>
                            </Link>
                        })}
                    </div>

                    <Paginator page={this.state.data} onChange={(handlePageChange) => {
                        this.setState({ page: handlePageChange }, () => {
                            this.reload();
                        });
                    }}/>
                </div>}
            </div>}
        </Wrapper>;
    }
});