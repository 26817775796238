const SettingsItem = ({name, value, type, options, mandatory, disabled, readOnly, onChange, className}) => {

    const rndData = Math.random();

    if(type === "checkbox")
    {
        return <div className={"table-checkbox"}>
                    <input type="checkbox"
                        checked={value} readOnly={(readOnly ?? false)}
                        onChange={e => {onChange(e.target.checked);}} />
                    <span>{name}</span>
                </div>;
    }

    if(type === "list")
    {
        return <label className={className ?? ""}>
            <select value={value} onChange={e => onChange(e.target.value)}>
                <option disabled={(mandatory ?? true)}>-- Выберите --</option>
                {(options ?? []).map((item, i) => {
                    return <option value={item.id} key={"xx_"+rndData+"_"+i}>{item.name}</option>
                })}
            </select>
        </label>;
    }

    return <label>{name}
         <input type={type ?? "text"} disabled={(disabled ?? false)} readOnly={(readOnly ?? false)} value={value} onChange={(e) => onChange(e.target.value)} />
    </label>
}

export default SettingsItem;

