import Wrapper from '../../components/Wrapper'
import withParams from "../../services/withParams";
import * as React from "react";
import api from "../../services/api";
import {Button, Col, Form, Image, Row, Table} from "react-bootstrap";
import Moment from "react-moment";
import order_err from "../../resources/images/order_err.png";
import order_ok from "../../resources/images/order_ok.png";
import '../../resources/styles/order.css'
import {Link} from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import like from "../../resources/images/like.svg";
import dislike from "../../resources/images/dislike.svg";
import declOfNum from "../../components/declOfNum";
import QRCode from "react-qr-code";
import Modal from 'react-modal';
import SettingsItem from '../../components/SettingsItem';
import OrderTransactionLine from './components/OrderTransactionLine';
import arrowIcon from '../../resources/images/arrow.svg';
import playIcon from '../../resources/images/play.svg';

export default withParams(class OrderEdit extends React.Component {
    state = {
        loading: true,
        loadingTransaction: true,
        loadingComments: true,
        buttonLoading: false,
        loadingPayments: true,
        loadingErrors: true,
        refund: null,
        refundLoading: null,
        order: null,
        refundProducts: "",
        refundAlcohol: "",
        refundTips: "",
        refundService: "",
        refundServiceSbp: "",
        refunds: {},
        addProductModal: false,
        newProductName: "Дополнения к блюдам",
        newProductTotal: 0,
        newProductType: null,
        waiters: [],
        changeWaiterModal: false,
        newWaiter: null,
        waiterSearch: "",
        payedBonuses: 0,
        needConfirmRefundBonuses: false,
        refundBonuses: null,
    };
    fruits = {
        "Питахайя": "fruit1.png",
        "Дуриан": "fruit2.png",
        "Апельсин": "fruit3.png",
        "Лайм": "fruit4.png",
        "Клубника": "fruit5.png",
        "Авокадо": "fruit6.png",
        "Киви": "fruit7.png",
        "Слива": "fruit8.png",
        "Виноград": "fruit9.png",
        "Вишня": "fruit10.png",
        "Банан": "fruit11.png",
        "Груша": "fruit12.png",
        "Черника": "fruit13.png",
        "Мандарин": "fruit14.png",
        "Яблоко": "fruit15.png",
        "Смородина": "fruit16.png",
        "Ананас": "fruit17.png",
        "Папайя": "fruit18.png",
        "Манго": "fruit19.png",
        "Гранат": "fruit20.png",
    };


    constructor(props) {
        super(props);
        const { id } = this.props.params;

        const isAdmin = window.user.role === "ADMIN";
        const isSupport = window.user.role === "SUPPORT";

        this.loadTypes();

        api.getOrder(id).then(response => {
            if(response.status === 200) {
                this.setState({
                    order: response.data,
                    loading: false
                });
            }
            else {
                this.setState({
                    error: response.data,
                    loading: false
                });
            }
        });

        api.getTransactionsByOrder(id).then(response => {
            if (response.status === 200) {
                this.setState({
                    transactions: response.data.map((el, i) => {
                        if(el.sbp)
                        {
                            el.sbpDiff = el.sbpService - el.service;
                        } else {
                            el.sbpDiff = 0;
                        }
                        return el;
                    }),
                    loadingTransaction: false
                });
                this.calcPayedBonuses();
            } else {
                this.setState({
                    error: response.data,
                    loadingTransaction: false
                });
            }
        });

        api.getCommentsByOrder(id).then(response => {
            if (response.status === 200) {
                this.setState({
                    comments: response.data,
                    loadingComments: false
                });
            } else {
                this.setState({
                    error: response.data,
                    loadingComments: false
                });
            }
        });

        api.getPaymentsByOrder(id).then(response => {
            if (response.status === 200) {
                this.setState({
                    payments: response.data,
                    loadingPayments: false
                });
            } else {
                this.setState({
                    error: response.data,
                    loadingPayments: false
                });
            }
        });

        if(isAdmin || isSupport)
        {
            api.getErrorsByOrder(id).then(response => {
                if (response.status === 200) {
                    this.setState({
                        errors: response.data,
                        loadingErrors: false
                    });
                } else {
                    this.setState({
                        error: response.data,
                        loadingErrors: false
                    });
                }
            });

            if(isAdmin) {
                api.getRefundsByOrder(id).then(response => {
                    if (response.status === 200) {

                        this.setState({
                            refunds: Object.groupBy(response.data, ({ transactionId }) => transactionId),
                            loadingRefunds: false
                        });
                    } else {
                        this.setState({
                            error: response.data,
                            loadingRefunds: false
                        });
                    }
                });

                api.getBaseObject("orders/waiters").then(r => {
                    if(r.status === 200)
                    {
                        this.setState({
                            waiters: r.data,
                        })
                    }
                });
            }
        }
    }

    loadTypes() {
        api.getBaseObject("/restaurants/goods").then(response => {
            if(response.status === 200) {
                let gtHash = {};
                response.data.map((v) => {
                    return gtHash[v.name] = v.id;
                });
                this.setState({
                    goodsTypes: response.data,
                    goodsTypesByName: gtHash,
                });
            }
        });
    }


    addProductToOrder(total, goodsType, name) {
        if(total != null) {
            api.addOrderProduct(this.state.order.id, total, goodsType, name).then((response) => {
                if(response.status === 200) {
                    window.location.reload();
                }
                else {
                    alert("Ошибка: " + response.data);
                }
            });
        }
    }

    changeWaiter(waiterId) {
        api.setBaseObject("orders/"+this.state.order.id+"/waiter/"+waiterId).then((response) => {
            if(response.status === 200) {
                window.location.reload();
            }
            else {
                alert("Ошибка: " + response.data);
            }
        });
    };

    calcPayedBonuses() {
        if(this.state.transactions !== undefined) {
            this.state.transactions.forEach(t => {
                if(t.goodsList !== undefined) {
                    t.goodsList.forEach(g => {
                        if(g.valueBonus !== undefined) {
                            this.state.payedBonuses += g.valueBonus;
                        }
                    })
                }
            });
        }
    };

    refundBonuses(transactionId) {
        api.refundBonuses(transactionId).then(res => {
            if(res.status === 200) {
                window.location.reload();
            } else {
                alert("Ошибка: " + res.data);
            }
        });
    };

    render() {
        let goodsTypes = [];

        if(!this.state.loadingTransaction)
        {
            // this.state.transactions.map((tr) => {
            this.state.transactions.forEach((tr) => {
                if(tr['goodsList'] !== undefined)
                {
                    tr.goodsList.forEach((i) => {
                    // tr.goodsList.map((i) => {
                        if(goodsTypes.indexOf(i.name)<0)
                        {
                            goodsTypes.push(i.name);
                        }
                    });
                }
            });
        }

        const isAdmin = window.user.role === "ADMIN";
        const isSupport = window.user.role === "SUPPORT";
        let waitersList = [];
        if(isAdmin && this.state.waiters.length>0)
        {
            if(this.state.waiterSearch === "")
            {
                waitersList = this.state.waiters;
            } else {
                this.state.waiters.forEach(w => {
                    if(w.name.toLocaleLowerCase().indexOf(this.state.waiterSearch.toLocaleLowerCase())>=0)
                    {
                        waitersList.push(w);
                    }
                });
            }
        }

        return <Wrapper navigate={this.props.navigate}>
            {this.state.loading && <span className={"title"}>Загрузка счёта...</span>}
            {this.state.loadingTransaction && <span className={"title"}>Загрузка транзакций...</span>}
            {this.state.loadingComments && <span className={"title"}>Загрузка отзывов...</span>}
            {this.state.loadingRefunds && <span className={"title"}>Загрузка возвратов...</span>}

            {this.state.error && <span className={"title red"}>Ошибка: {this.state.error}</span>}

            <div className={"col"}>
                {!this.state.loading && !this.state.error && <div className={"row mobile"}>
                    <div className={"col-2"}>
                        <div className={"order-title"}>Счёт от&nbsp;<Moment format="DD.MM.YY&nbsp;HH:mm">{this.state.order.created}</Moment></div>
                        {(isAdmin || isSupport) && <div>{this.state.order.uuid}, {this.state.order.number} <a target='_blank' href={"https://orpl.eatandsplit.ru/2/sessions?_order=is|"+this.state.order.uuid+"&range=LAST_30_DAYS"}> <Image src={playIcon} width={32} height={32} alt='OpenReplay' title='OpenReplay' /></a></div>}

                        <Button className={"order-button"}
                                onClick={() => {navigator.clipboard.writeText(`${process.env.REACT_APP_MOBILE_APP_URL}?u=${this.state.order.uuid}&t=${this.state.order.terminal.id}&w=${this.state.order.waiter ? this.state.order.waiter.code : ""}&s=${this.state.order.total}&n=${this.state.order.tableNum}`)}}>
                            Ссылка UUID (скопировать)
                        </Button>

                        <Button className={"order-button"}
                                onClick={() => {navigator.clipboard.writeText(`${process.env.REACT_APP_MOBILE_APP_URL}?r=${this.state.order.restaurant.id}&q=${this.state.order.number}&s=${this.state.order.total}&n=${this.state.order.tableNum}&w=${this.state.order.waiter ? this.state.order.waiter.code : ""}`)}}>
                            Ссылка на номер (скопировать)
                        </Button>

                        <Button className={"order-button"}  onClick={() => {navigator.clipboard.writeText(`${process.env.REACT_APP_MOBILE_APP_URL}?r=${this.state.order.restaurant.id}&t=${this.state.order.terminal.id}&n=${this.state.order.tableNum}&w=${this.state.order.waiter ? this.state.order.waiter.code: ""}&s=${this.state.order.total}${this.state.order.table != null && this.state.order.table.section != null ? "&sc="+this.state.order.table.section.id: ''}`)}}>
                            ссылка на стол (скопировать)
                        </Button>

                        {isAdmin && <Button disabled={this.state.buttonLoading} className={"order-button "}
                                                                 onClick={() => {
                                                                     this.setState({
                                                                         buttonLoading: true
                                                                     });

                                                                     api.copyOrder(this.state.order.id).then(response => {
                                                                         if(response.status === 200) {
                                                                             window.location.href = "/orders/" + response.data.id;
                                                                         }
                                                                         else {
                                                                             alert("Ошибка при создании тестового счёта!");
                                                                         }

                                                                         this.setState({
                                                                             buttonLoading: false
                                                                         });
                                                                     })}}>Скопировать счёт</Button>}

                        {isAdmin && <Button disabled={this.state.buttonLoading} className={"order-button " + (this.state.order.closed ? "": "green")}
                                                                 onClick={() => {

                                                                     this.setState({
                                                                         buttonLoading: true
                                                                     });
                                                                     (this.state.order.closed ? api.openOrder(this.state.order.id) : api.closeOrder(this.state.order.id)).then(response => {
                                                                         if(response.status === 200) {
                                                                             this.setState(prevState => {
                                                                                 prevState.order.closed = response.data.closed;

                                                                                 return {
                                                                                     order: prevState.order,
                                                                                     loading: false
                                                                                 }
                                                                             });
                                                                         }
                                                                         else {
                                                                             this.setState({
                                                                                 rest: response.data,
                                                                                 loading: false
                                                                             });
                                                                         }

                                                                         this.setState({
                                                                             buttonLoading: false
                                                                         });
                                                                     })}}>
                            {this.state.order.closed ? 'Открыть счёт' : 'Закрыть счёт'}
                        </Button>}

                        <div className={"title order-waiter-title"}>Официант</div>
                        <div className={"order-waiter"}>{this.state.order.waiter ? this.state.order.waiter.fullName : "Официанта нет"}</div>
                        {isAdmin && <><br/>
                            <Button style={{height: 40}} onClick={() => {this.setState({changeWaiterModal: true, newWaiter: null, waiterSearch: ""})}}>
                                Поменять официанта
                            </Button></>}

                        <div className={"order-table"}>Стол {this.state.order.table != null && this.state.order.table.name!="" ? this.state.order.table.name : this.state.order.tableNum}</div>
                        {this.state.order.table != null && this.state.order.table.section != null && <div className={"order-total"}>Секция {this.state.order.table.section.name}</div>}
                        <div className={"order-total"}>Общая стоимость {(this.state.order.total - this.state.order.discount).toFixed(2) } ₽</div>
                        <div className={"order-total"}>Скидка {(this.state.order.discount).toFixed(2) } ₽</div>

                        {this.state.order.orderType !== undefined && <div className={"order-total"}>{this.state.order.orderType}</div>}

                        {this.state.order.discountBonus > 0 && <div className={"order-total"}>Оплата бонусами {(this.state.order.discountBonus ?? 0).toFixed(2) } ₽</div>}
                    </div>
                    <div className={"manager-margin-40"} />
                    <div className={"col-2"}>
                        <div className={"title"}>Гости</div>

                        <div className={"order-guests"}>Всего:&nbsp;<span>{this.state.order.guests.length}</span>&nbsp;{declOfNum(this.state.order.guests.length, ["человек", "человека", "человек"])}</div>
                        <div className={"block order-guests-block"}>
                            {this.state.order.guests.map((item, i) => {
                                if((isAdmin || isSupport)) {
                                    return <a href={"https://orpl.eatandsplit.ru/2/sessions?uid=is|"+ item.uuid+"&range=LAST_30_DAYS"} target={'_blank'} key={"guestimg"+i}>
                                        <Image title={item.id} width={76} height={76} style={{"margin": "10px"}} src={require("../../resources/fruits/" + this.fruits[item.fullName.split(" ")[0]])}/>
                                    </a>
                                }
                                else {
                                    return  <Image  key={"guestimg"+i} title={item.id} width={76} height={76} style={{"margin": "10px"}} src={require("../../resources/fruits/" + this.fruits[item.fullName.split(" ")[0]])}/>
                                }
                            })}
                        </div>

                        <div style={{display: 'flex',justifyContent: 'center', marginTop: 25}}>
                            <QRCode
                                value={`${process.env.REACT_APP_MOBILE_APP_URL}?u=${this.state.order.uuid}&t=${this.state.order.terminal ? this.state.order.terminal.id : ""}&w=${this.state.order.waiter ? this.state.order.waiter.code : ''}&s=${this.state.order.total}&n=${this.state.order.tableNum}`} />
                        </div>
                    </div>
                </div>}

                {!this.state.loading && !this.state.error && <div>
                    <div className={"title order-title-tables"}>Позиции</div>

                    <div className={"gray-wrapper gray-order"}>
                        {this.state.order.products.length === 0 && <p className={"table-not-found"}>Позиций пока нет...</p>}

                        {this.state.order.products.length > 0 && <div className={"table"}>
                            <div className={"thead"}>
                                <div className={"th"}>Блюдо</div>
                                <div className={"th"}>Кол-во</div>
                                <div className={"th"}>Цена</div>
                                <div className={"th"}>Итого</div>
                                <div className={"th xs-hidden"}>Скидка</div>
                            </div>

                            {this.state.order.products.sort((a,b) => (a.deleted || a.size === 0) - (b.deleted || b.size === 0)).totalSum(prod => !prod.deleted).map((item, i) => {
                                return <div  key={"prod"+i} className={!item.last ? "tbody": "tbody last"} style={{opacity: (item.deleted || item.size === 0) ? 0.3 : 1}} title={item.id}>
                                    <div className={"td word-wrap"}>{item.last ? "Итого" : item.ru} {!item.last && item.alcohol && "(акциз)"}</div>
                                    <div className={"td end xs-visible-bold"}>{item.size}шт</div>
                                    <div className={"td break "}/>
                                    {item.last && <div className={"td"}></div>}
                                    {!item.last && <div className={"td word-no-wrap"}><div className={"xs-visible"}>Цена</div>&nbsp;<CurrencyFormat value={item.price.toFixed(2)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} /></div>}
                                    <div className={"td word-no-wrap"}><div className={"xs-visible"}>Итого</div>&nbsp;<CurrencyFormat value={item.total.toFixed(2)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} /></div>
                                    <div className={"td end word-no-wrap"}><div className={"xs-visible"}>Скидка</div>&nbsp;<CurrencyFormat value={item.discount.toFixed(2)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} /></div>
                                </div>
                            })}
                        </div>}

                        {isAdmin && !this.state.order.closed && <><br/>
                            <Button style={{height: 40}} onClick={() => {this.setState({addProductModal: true})}}>
                                Добавить блюдо
                            </Button></>}
                    </div>

                    <div className={"title order-title-tables"}>Позиции гостей</div>

                    <div className={"gray-wrapper gray-order"}>
                        {this.state.order.productGuests.length === 0 && <p className={"table-not-found"}>Позиций гостей пока нет...</p>}

                        {this.state.order.productGuests.length > 0 && <div className={"table"}>
                            <div className={"thead"}>
                                <div className={"th"} style={{width: 50}}>Гость</div>
                                <div className={"th"}>Блюдо</div>
                                <div className={"td break"}/>
                                <div className={"th"}>Части</div>
                                <div className={"th"}>Позиция</div>
                                <div className={"th"}>Итого</div>
                            </div>

                            {this.state.order.productGuests.totalSum().map((item, i) => {
                                return <div key={"prodguest"+i} className={!item.last ? "tbody": "tbody last"} title={item.id}>
                                    <div className={"td"}>{!item.last && <Image width={30} height={30} src={require("../../resources/fruits/" + this.fruits[item.guest.fullName.split(" ")[0]])}/>}</div>
                                    <div className={"td sx-height-51 end xs-visible-bold word-wrap"}>{item.last ? "Итого" : item.product.ru}</div>
                                    <div className={"td break"}/>
                                    <div className={"td word-no-wrap"}><div className={"xs-visible"}>Части</div>&nbsp;{item.parts}</div>
                                    <div className={"td word-no-wrap"}><div className={"xs-visible"}>Части</div>&nbsp;{item.position}</div>
                                    <div className={"td end word-no-wrap"}><div className={"xs-visible"}>Итого</div>&nbsp;<CurrencyFormat value={item.total.toFixed(2)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} /></div>
                                </div>
                            })}
                        </div>}
                    </div>
                </div>}

                {!this.state.loadingTransaction && !this.state.error && <div>
                    <div className={"title order-title-tables"}>Транзакции</div>

                    <div className={"gray-wrapper gray-order"}>
                        {this.state.transactions.length === 0 && <p className={"table-not-found"}>Транзакций пока нет...</p>}

                        {this.state.transactions.length > 0 && <div className={"table"}>
                            <div className={"thead"}>
                                <div className={"th"} style={{width: 50}}>Гость</div>
                                {goodsTypes.map((g, ig) => {
                                    return <div className={"th"} key={'th-'+ig}>{g}</div>
                                })}
                                {/* <div className={"th"}>Бонус</div> */}
                                {/* <div className={"th"}>Позиции</div>
                                {window.user.alcohol && <div className={"th"}>Алкоголь</div>} */}
                                {window.user.restaurant.modelService !== "Pays" && <div className={"th"}>Чаевые</div>}
                                {isAdmin && <div className={"th"}>Сервис</div>}
                                {isAdmin && <div className={"th"}>Сервис СБП</div>}
                                <div className={"th"}>Статус</div>
                                <div className={"th"}>Отзыв</div>
                                {isAdmin && <div className={"th"}>Админ</div>}
                            </div>

                            {this.state.transactions.totalSum((tran) => tran.closed != null).map((item, i) => {
                                return <div key={"totalsum"+i} className={!item.last ? "tbody xs-visible": "tbody xs-visible last"} title={item.id}>
                                    <div className={"td sx-height-51"}>{!item.last && <div>Статус:</div>}&nbsp;{!item.closed && "не оплачено"}{item.closed && !item.last && <b>оплачено <Moment format="DD-MM-YY HH:mm">{item.closed}</Moment></b>}</div>
                                    <div className={"td sx-height-51 end xs-visible-bold"}>
                                        {item.like != null && <div>Отзыв</div>}
                                        {item.like === true ? <Image src={like} height={30}/> : item.like === false ? <Image src={dislike} height={30}/> : "Без отзыва"}
                                    </div>
                                    <div className={"td break"}/>

                                    <div className={"td"}>{!item.last && <Image width={30} height={30} src={require("../../resources/fruits/" + this.fruits[item.guest.split(" ")[0]])}/>}</div>


                                    {goodsTypes.map((g, ig) => {
                                        if(item['goodsList'] !== undefined)
                                        {
                                            for(let x=0;x<item.goodsList.length;x++)
                                            {
                                                let xitem = item.goodsList[x];
                                                if(xitem.name == g)
                                                {
                                                    return <div className={"td sx-height-51 word-no-wrap"} style={{flexDirection: 'column', alignItems: 'start'}}>
                                                    <div>{g+" "} 
                                                        <CurrencyFormat value={(xitem.value).toFixed(2)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} />
                                                    </div>
                                                    {(isAdmin || isSupport) && 
                                                        (item.sbp || (item.last && xitem.valueSbp>0 ))
                                                        && <div style={{fontSize: 12}}>СБП <CurrencyFormat value={xitem.valueSbp.toFixed(2)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} /></div>}
                                                    {(isAdmin || isSupport) && xitem.valueBonus > 0 &&
                                                        <div style={{fontSize: 12}}>Бонусы <CurrencyFormat value={xitem.valueBonus.toFixed(2)} displayType={'text'} suffix={' баллами'} thousandSeparator={' '} /></div>}
                                                    </div>
                                                }
                                            }
                                        }
                                    })}


                                    {window.user.restaurant.modelService !== "Pays" && <div className={"td sx-height-51 end word-no-wrap"}><div>Чаевые</div>&nbsp;<CurrencyFormat value={item.tips.toFixed(2)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} /></div>}

                                    <div className={"td break"} style={{marginTop: 10}}/>

                                    {isAdmin && <div className={"td sx-height-51 end v"} style={{flexDirection: 'column', alignItems: 'end'}}>
                                        <div>Сервис <CurrencyFormat value={item.service.toFixed(2)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} /></div>
                                        <div>Сервис СБП <CurrencyFormat value={(item.sbp || item.last) ? item.sbpDiff.toFixed(2) : 0} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} /></div>
                                        {isAdmin && (item.sbp || item.last) && <div style={{fontSize: 12}}>СБП <CurrencyFormat value={(item.sbpDiff).toFixed(2)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} /></div>}
                                    </div>}
                                </div>
                            })}


                        {this.state.needConfirmRefundBonuses && <div className='mail-confirm-popup'>
                            <div className='mail-confirm-popup-body'>
                                <div className='mail-confirm-text'>Вы уверены, что хотите вернуть бонусы?</div>
                                <Button variant="primary" className='mail-confirm-cancel' onClick={() => {
                                    this.setState({
                                        needConfirmRefundBonuses: false,
                                        refundBonuses: null,
                                    });
                                }}>Отменить</Button>
                                <Button variant="primary" onClick={() => {
                                    this.setState({
                                        needConfirmRefundBonuses: false,
                                    });
                                    this.refundBonuses(this.state.refundBonuses);
                                    this.setState({
                                        needConfirmRefundBonuses: null,
                                    });
                                }}>Подтвердить</Button>
                            </div>
                        </div>}

                        {this.state.transactions.totalSum((tran) => tran.closed != null).map((item, i) => {
                            return <OrderTransactionLine
                                        key={"otr-"+i}
                                        item={item}
                                        refunds={this.state.refunds}
                                        goodsTypes={goodsTypes}
                                        goodsTypesByName={this.state.goodsTypesByName}
                                        refund={this.state.refund}
                                        fruits={this.fruits}
                                        onSetRefund={(idx) => {this.setState({refund: idx})}}
                                        onSetRefundBonuses={(transactionId) => {
                                            this.setState({
                                                needConfirmRefundBonuses: true,
                                                refundBonuses: transactionId,
                                            });
                                        }}
                                    />
                        })}

                        </div>}
                    </div>

                    <div className={"order-paid"}>
                        Оплачено
                        <span>
                            <CurrencyFormat value={this.state.transactions.filter(transaction => transaction.closed).map(transaction => parseFloat((transaction.products).toFixed(2)) + parseFloat((transaction.alcohol).toFixed(2)) + parseFloat((transaction.tips).toFixed(2))).reduce((a, b) => a + b, 0).toFixed(2)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} />
                        </span>,
                        из них блюд
                        <span>
                            <CurrencyFormat value={this.state.transactions.filter(transaction => transaction.closed).map(transaction => parseFloat((transaction.products).toFixed(2)) + parseFloat((transaction.alcohol).toFixed(2))).reduce((a, b) => a + b, 0).toFixed(2)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} />
                        </span>
                        {this.state.payedBonuses > 0 && <span>
                            , бонусами<span>
                                <CurrencyFormat value={this.state.payedBonuses.toFixed(2)} displayType={'text'} suffix={''} thousandSeparator={' '} />
                            </span>
                        </span>}
                    </div>
                </div>}

                {!this.state.loadingComments && !this.state.error && <div>
                    <div className={"title order-title-tables"}>Отзывы</div>

                    <div className={"gray-wrapper gray-order"}>
                        {this.state.comments.length === 0 && <p className={"table-not-found"}>Отзывов пока нет...</p>}

                        {this.state.comments.length > 0 && <div className={"table"}>
                            <div className={"thead"}>
                                <div className={"th"} style={{width: 50}}>Гость</div>
                                <div className={"th"}>Создан</div>
                                <div className={"th"}>Оценка</div>
                                <div className={"th"}>Комментарий</div>
                            </div>

                            {this.state.comments.map((item, i) => {
                                return <div  key={"comments"+i} className={"tbody"} >
                                    <div className={"td"}><Image width={30} height={30} src={require("../../resources/fruits/" + this.fruits[item.guest.fullName.split(" ")[0]])}/></div>
                                    <div className={"td"}><Moment format="DD-MM-YY HH:mm">{item.created}</Moment></div>
                                    <div className={"td"}>{item.stars}/5</div>
                                    <div className={"td break"}/>
                                    <div className={"td word-wrap"}>{item.comment}</div>
                                </div>
                            })}
                        </div>}
                    </div>
                </div>}

                {(isAdmin || isSupport) && !this.state.loadingPayments && !this.state.error && <div>
                    <div className={"title order-title-tables"}>Платежи на кассе *</div>

                    <div className={"gray-wrapper gray-order"}>
                        {this.state.payments.length === 0 && <p className={"table-not-found"}>Платежей на кассе пока нет....</p>}

                        {this.state.payments.length > 0 && <div className={"table"}>
                            <div className={"thead"}>
                                <div className={"th"}>Создан</div>
                                <div className={"th"}>Валюта</div>
                                <div className={"th"}>Статус</div>
                                <div className={"th"}>Сумма</div>
                            </div>

                            {this.state.payments.map((item, i) => {
                                return <div  key={"payments"+i} className={"tbody"} title={item.id}>
                                    <div className={"td"}><Moment format="DD-MM-YY HH:mm">{item.created}</Moment></div>
                                    <div className={"td word-wrap"}>{item.name} ({item.code})</div>
                                    <div className={"td break"}/>
                                    <div className={"td"}>{item.success ? "Проведён" : "Отменён"}</div>
                                    <div className={"td"}><CurrencyFormat value={item.total.toFixed(2)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} /></div>
                                </div>
                            })}
                        </div>}
                    </div>
                </div>}

                {(isAdmin || isSupport) && !this.state.loadingErrors && !this.state.error && <div>
                    <div className={"title order-title-tables"}>Ошибки *</div>

                    <div className={"gray-wrapper gray-order"}>
                        {this.state.errors.length === 0 && <p className={"table-not-found"}>Ошибок пока нет....</p>}

                        {this.state.errors.length > 0 && <div className={"table"}>
                            <div className={"thead"}>
                                <div className={"th"}>Создан</div>
                                <div className={"th"}>Ошибка</div>
                                <div className={"th"}>Терминал</div>
                            </div>

                            {this.state.errors.map((item, i) => {
                                return <div  key={"errors"+i} className={"tbody"} title={item.id}>
                                    <div className={"td"}><Moment format="DD-MM-YY HH:mm:ss">{item.created}</Moment></div>
                                    <div className={"td word-wrap"}>{item.error}</div>
                                    <div className={"td word-wrap"}>{item.terminal}</div>
                                </div>
                            })}
                        </div>}
                    </div>
                </div>}
            </div>

            <Modal isOpen={this.state.addProductModal}>
                <Form className="add-type-modal">
                    <SettingsItem value={this.state.newProductName} type="text" name="Название"
                      onChange={v => this.setState({ newProductName: v })}/>

                    <SettingsItem value={this.state.newProductTotal} type="number" name="Сумма"
                      onChange={v => this.setState({ newProductTotal: v*1 })}/>

                    <SettingsItem value={this.state.newProductType} type="list" name="Тип" mandatory={false} options={this.state.goodsTypes}
                      onChange={v => this.setState({ newProductType: v*1 })}/>

                    <div className='add-type-buttons'>
                        <Button type="button" onClick={() => {
                            this.addProductToOrder(
                                this.state.newProductTotal,
                                this.state.newProductType,
                                this.state.newProductName
                        ); }}>
                            Добавить
                        </Button>
                        <Button type="button"  onClick={() => {
                            this.setState({addProductModal: false});}}>
                            Отменить
                        </Button>
                    </div>
                </Form>
            </Modal>


                {isAdmin && !this.state.loading &&
                <Modal isOpen={this.state.changeWaiterModal}>
                    <Form className="add-type-modal">

                        <SettingsItem value={this.state.waiterSearch} type="text" name="Поиск"
                            onChange={v => this.setState({ waiterSearch: v })} 
                        />

                        {waitersList.map(w => {
                            return <div key={"w"+w.id} className='order-waiter-item'>
                                <div className='order-waiter-item-name'>{w.name}</div>
                                <Button onClick={() => {
                                    this.changeWaiter(w.id);
                                }}>
                                    <Image src={arrowIcon} className="order-waiter-item-image"/>
                                </Button>

                                </div>
                        })}

                        <div className='add-type-buttons'>
                        <Button type="button"  onClick={() => {
                            this.setState({changeWaiterModal: false});
                        }}>Отменить</Button>
                        </div>
                    </Form>
                </Modal>}
        </Wrapper>;
    }
})
