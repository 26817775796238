import Wrapper from '../../components/Wrapper'

import * as React from "react";
import api from "../../services/api";
import {Alert, Button} from "react-bootstrap";
import withParams from "../../services/withParams";
import Form from "react-bootstrap/Form";
import Paginator from "../../components/Paginator";
import {Link} from "react-router-dom";
import ToggleMenu from "../../components/ToggleMenu";
import Moment from "react-moment";

export default withParams(class AgentList extends React.Component {
    state = {
        loading: true,
        data: [],
        current: [],
        name: "",
        installer: false
    };

    constructor(props) {
        super(props);

        this.getPage(0);
    }

    getPage(number) {
        this.setState({
            loading: true
        });
        const [searchParams, setSearchParams] = this.props.search;
        setSearchParams({page: number});

        api.getPageableAgents({page: number}).then(response => {
            if(response.status === 200) {
                this.setState({
                    data: response.data,
                    loading: false
                });
            }
            else {
                this.setState({
                    error: response.data,
                    loading: false
                });
            }
        });
    }

    render() {
        return <Wrapper navigate={this.props.navigate}>
            {this.state.loading && <span className={"title"}>Загрузка...</span>}
            {this.state.error && <span className={"title red"}>Ошибка: {this.state.error}</span>}

            <div className={"row mobile"} style={{marginBottom: 20}}>
                <Link to={"add"} className={"flex"}><Button style={{marginBottom: 5}}>Создать</Button></Link>
            </div>

            {!this.state.loading && !this.state.error && this.state.data.content.length === 0 && <p>Агентов пока нет...</p>}
            <br/>

            {!this.state.loading && this.state.data.content.length > 0 && <div className={"gray-wrapper gray-table"}>
                <div className={"table-search"}>
                </div>

                <div className={"table"}>
                    <div className={"thead"}>
                        <div className={"th"}>ФИО</div>
                        <div className={"th"}>Телефон</div>
                        <div className={"th"}>Активен</div>
                    </div>
                    {this.state.data.content.map((item, i) => {
                        return <Link className={"tbody"} key={item.id} to={"" + item.id}>
                            <div className={"td"}>{item.fullName}</div>
                            <div className={"td"}>{item.phone}</div>
                            <div className={"td"}>{item.enabled ? "Да" : "Нет"}</div>
                        </Link>
                    })}
                </div>

                <Paginator page={this.state.data} onChange={(handlePageChange) => {
                    this.getPage(handlePageChange)
                }}/>
            </div>}
        </Wrapper>;
    }
});
