import Wrapper from '../../components/Wrapper'

import * as React from "react";
import Button from "react-bootstrap/Button";
import api from "../../services/api";
import Form from "react-bootstrap/Form";
import withParams from "../../services/withParams";
import QRCode from "react-qr-code";
import {Link} from "react-router-dom";
import Image from "react-bootstrap/Image";
import iikoPlugin from "../../resources/images/iiko-plugin.png";
import iikoOffice from "../../resources/images/iiko-office.png";
import iikoUser from "../../resources/images/iikoUser.png";
import eatAct from "../../resources/images/eatAct.png";

export default withParams(class RKeeperInstruction extends React.Component {
    state = {
        loading: true,
        id: null,
        serial: null,
        type: null,
        tableNum: "0",
        restaurant: {}
    };

    constructor(props) {
        super(props);
        const { restaurant, id } = this.props.params;

        api.getTerminal(restaurant, id).then(response => {
            if(response.status === 200) {
                api.getRestaurant(restaurant).then(responseRest => {
                    if(responseRest.status === 200) {
                        console.log(responseRest);
                        this.setState({
                            restaurant: responseRest.data,
                            id: response.data.id,
                            uuid: response.data.uuid,
                            type: response.data.type,
                            loading: false
                        });

                    }
                    else {
                        this.setState({
                            error: response.data,
                            loading: false
                        });
                    }
                });
            }
            else {
                this.setState({
                    error: response.data,
                    loading: false
                });
            }
        });
    }

    render() {
        return <Wrapper navigate={this.props.navigate}>
            <h4>Инструкция iiko</h4>

            <h5>Установка плагина</h5>
            <p>Для установки плагина скачайте <a href="/iiko.zip">архив</a> и разархивируйте его в следующую директорию:
                <pre>C:\Program Files\iiko\iikoRMS\Front.Net\Plugins</pre>
                <Image src={iikoPlugin} height={120} />
            </p>

            <h5>Настройка iikoOffice</h5>
            <p>Активируйте внешнюю оплату у банковских карт:
                <pre>Розничные продажи -> Типы оплат -> Банковские карты -> ☑ Может приниматься извне</pre>
                <Image src={iikoOffice} height={550} />
            </p>

            <p>Добавьте пользователя для внешних платежей
                <pre>Розничные продажи -> Типы оплат -> Банковские карты -> ☑ Может приниматься извне</pre>
                <Image src={iikoUser} height={550} />
            </p>

            <h5>Настройка ресторана в EatAndSplit</h5>
            <p>Добавьте ПИН код в поле IIKO пин
                <pre>Рестораны -> Мой ресторан -> Поле IIKO пин</pre>
                <Image src={eatAct} height={550} />
            </p>

        </Wrapper>;
    }
})