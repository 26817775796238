import * as React from "react";
import api from "../../../services/api";
import {useState} from "react";
import {Button, Image} from "react-bootstrap";
import Moment from "react-moment";
import '../../../resources/styles/order.css'
import CurrencyFormat from "react-currency-format";
import like from "../../../resources/images/like.svg";
import dislike from "../../../resources/images/dislike.svg";
import OrderTransactionRefundItem from "./OrderTransactionRefundItem";

const OrderTransactionLine = ({item, refunds, goodsTypes, goodsTypesByName, refund, onSetRefund, fruits, onSetRefundBonuses }) => {
    const isAdmin = window.user.role === "ADMIN";
    const [makeRefundData, setMakeRefundData] = useState({});
    const [refundLoading, setRefundLoading] = useState(false);

    const enableRefundBonuses = function (item) {
        if(item.goodsList !== undefined) {
            if(item.goodsList.find(i => i.valueBonus !== undefined && i.valueBonus > 0) !== undefined) {
                return true;
            }
        }

        return false;
    };

return <>
    <div className={!item.last ? "tbody xs-hidden": "tbody xs-hidden last"} title={item.id}>
        <div className={"td"} style={{textAlign: "center"}}>
            {!item.last && <Image width={30} height={30} src={require("../../../resources/fruits/" + fruits[item.guest.split(" ")[0]])}/>}
            {!item.last && window.user.role === "ADMIN" && <div><Moment style={{fontSize: 12}} format="HH:mm">{item.changed}</Moment></div>}
        </div>

        {goodsTypes.map((g, ig) => {
            if(item['goodsList'] !== undefined)
            {
                for(let x=0;x<item.goodsList.length;x++)
                {
                    let xitem = item.goodsList[x];
                    if(xitem.name === g)
                    {
                        let refundItem = refunds[item.id] ?? null;
                        let goodsId = goodsTypesByName[g] ?? g;
                        return <div className={"td sx-height-51 word-no-wrap"}>
                        <div>
                            <CurrencyFormat value={(xitem.value).toFixed(2)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} />
                        </div>
                        {isAdmin && 
                            (item.sbp || (item.last && xitem.valueSbp>0 )) &&
                                <div style={{fontSize: 12}}><CurrencyFormat value={xitem.valueSbp.toFixed(2)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} /></div>
                        }
                        {isAdmin &&
                            xitem.valueBonus > 0 &&
                            <div style={{fontSize: 12}}><CurrencyFormat value={xitem.valueBonus.toFixed(2)} displayType={'text'} suffix={' баллами'} thousandSeparator={' '} /></div>
                        }

                        {refundItem!==null && !item.last && 
                        <>
                            <div style={{marginTop: 10, fontSize: 12}}>Возвраты</div>
                            {refundItem.map((val) => {
                                let cVal = val.goodsRefunds[goodsId];
                                if(cVal !== undefined)
                                {
                                    return <div style={{fontSize: 12}}>-<CurrencyFormat value={cVal.toFixed(2)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} /></div>
                                }
                                return <></>
                            })}
                        </>}
                        </div>
                    }
                }
                return <div className={"td sx-height-51 word-no-wrap"}>
                        <div>
                            <CurrencyFormat value={(0).toFixed(2)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} />
                        </div>
                    </div>
            }
            return <></>
        })}

        {window.user.restaurant.modelService !== "Pays" && <div className={"td sx-height-51 end word-no-wrap"}>
            <div className={"xs-visible"}>Чаевые</div>&nbsp;<CurrencyFormat value={item.tips.toFixed(2)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} />

            {refunds[item.id] != null && !item.last && <>
                <div style={{marginTop: 10, fontSize: 12}}>Возвраты</div>
                {refunds[item.id].map(val => <div style={{fontSize: 12}}>-<CurrencyFormat value={val.refundTips.toFixed(2)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} /></div>)}
            </>}
        </div>}
        <div className={"td break"}/>
        {isAdmin && <div className={"td sx-height-51 word-no-wrap"}>
            <div><CurrencyFormat value={item.service.toFixed(2)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} /></div>
            {refunds[item.id] != null && !item.last && <>
                <div style={{marginTop: 10, fontSize: 12}}>Возвраты</div>
                {refunds[item.id].map(val => <div style={{fontSize: 12}}>-<CurrencyFormat value={val.refundService.toFixed(2)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} /></div>)}
            </>}
        </div>}

        {isAdmin && <div className={"td sx-height-51 word-no-wrap"}>
            <div><CurrencyFormat value={((item.sbp || item.last) ? item.sbpDiff : 0).toFixed(2)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} /></div>
            {refunds[item.id] != null && !item.last && <>
                <div style={{marginTop: 10, fontSize: 12}}>Возвраты</div>
                {refunds[item.id].map((val, valIdx) => <div key={"t"+item.id+"g"+valIdx} style={{fontSize: 12}}>-<CurrencyFormat value={val.refundServiceSbp.toFixed(2)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} /></div>)}
            </>}
        </div>}

        <div className={"td sx-height-51 end"}>{!item.closed && "не оплачено"}{item.closed && !item.last && <b>оплачено <Moment format="DD-MM-YY HH:mm">{item.closed}</Moment></b>}</div>

        <div className={"td sx-height-51"}>
            {!item.last && (item.like === true ? <Image src={like} height={30}/> : item.like === false ? <Image src={dislike} height={30}/> : "Без отзыва")}
        </div>
        {isAdmin && <div className={"td"}>
            {item.closed == null &&<Button style={{height: 40}} onClick={() => {
                if(window.confirm("Вы действительно хотите оплатить счёт?\nСообщение об оплате уйдет на кассу без внесения реальных денег!!!")) {
                    let pass = window.prompt("Для подтверждения введите ваш пароль");

                    if(pass != null) {
                        api.payTransaction(item.id, pass).then((response) => {
                            if(response.status === 200) {
                                window.location.reload();
                            }
                            else {
                                alert("Ошибка: " + response.data);
                            }
                        });
                    }
                }
            }}>Оплатить</Button>}

            {item.closed != null && !item.last && refund !== item.id && 
            <Button style={{height: 40}} onClick={() => {
                setMakeRefundData({});
                onSetRefund(item.id);
            }}>Возврат</Button>}

            {!item.last && refund !== item.id && enableRefundBonuses(item) && <>
                <Button style={{height: 40, marginTop: 5}} onClick={() => {
                    onSetRefundBonuses(item.id);
                }}>Возврат бонусов</Button></>}
        </div>}
    </div>

    {refund === item.id && !item.last && <div className={!item.last ? "tbody xs-hidden": "tbody xs-hidden last"} title={item.id}>
        <div className={"td"} style={{textAlign: "center"}}></div>

        
        {goodsTypes.map((g, ig) => {
            if(item['goodsList'] !== undefined)
            {
                for(let x=0;x<item.goodsList.length;x++)
                {
                    let xitem = item.goodsList[x];
                    if(xitem.name === g)
                    {
                        let goodsId = goodsTypesByName[g] ?? g;
                        let val = (makeRefundData["goodsValues"] ?? {})[goodsId] ?? "";
                        return <OrderTransactionRefundItem key={"rfi"+ig+"t"+goodsId} val={val} onChange={ (v) => {
                            let newGoodsValues = {...makeRefundData.goodsValues};
                            newGoodsValues[goodsId] = v*1;
                            let newValue = {...makeRefundData};
                            newValue['goodsValues'] = newGoodsValues;
                            setMakeRefundData(newValue);
                        }} />
                    }
                }
                return <OrderTransactionRefundItem val={0} onChange={ (v) => {}} />
            }
            return <></>
        })}

        <OrderTransactionRefundItem val={makeRefundData.refundTips ?? ""} 
                    onChange={ (v) => { 
                        let newValue = {...makeRefundData};
                        newValue['refundTips'] = v*1;
                        setMakeRefundData(newValue);
                        }}  />

        <OrderTransactionRefundItem val={makeRefundData.refundService ?? ""} 
                            onChange={ (v) => { 
                                let newValue = {...makeRefundData};
                                newValue['refundService'] = v*1;
                                setMakeRefundData(newValue);
                             }}  />

        <OrderTransactionRefundItem val={makeRefundData.refundServiceSbp ?? ""} 
                            onChange={ (v) => { 
                                let newValue = {...makeRefundData};
                                newValue['refundServiceSbp'] = v*1;
                                setMakeRefundData(newValue);
                             }}  />

        <div className={"td sx-height-51 end"}></div>

        <div className={"td sx-height-51"}></div>
        {window.user.role === "ADMIN" && <div className={"td"}>
            <Button className={"green"} disabled={refundLoading} style={{height: 40}} onClick={() => {
                setRefundLoading(true);
                api.refund(item.id, makeRefundData.goodsValues ?? {}, makeRefundData.refundTips, makeRefundData.refundService
                    , makeRefundData.refundServiceSbp
                ).then(res => {
                    if(res.status === 200) {
                        window.location.reload();
                    }
                    else {
                        alert("Ошибка: " + res.data);
                    }
                });
            }}>Вернуть</Button>
        </div>}
    </div>}
    </>

}

export default OrderTransactionLine;
