import Wrapper from '../../components/Wrapper'

import * as React from "react";
import Button from "react-bootstrap/Button";
import api from "../../services/api";
import Form from "react-bootstrap/Form";
import withParams from "../../services/withParams";
import QRCode from "react-qr-code";
import {Link} from "react-router-dom";
import {Alert} from "react-bootstrap";
import Image from "react-bootstrap/Image";
import SettingsItem from '../../components/SettingsItem';

export default withParams(class CategoryEdit extends React.Component {
    state = {
        loading: true,
        name: null,
        id: null,
        type: null
    };

    constructor(props) {
        super(props);
        const { id } = this.props.params;

        this.loadTypes();
        api.getCategory(id).then(response => {
            if(response.status === 200) {
                this.setState({
                    id: id,
                    name: response.data.name,
                    loading: false,
                    type: (response.data.goodsType ?? {id: null}).id,
                });
            }
            else {
                this.setState({
                    error: response.data,
                    loading: false
                });
            }
        });
    }

    loadTypes() {
        api.getBaseObject("/restaurants/goods").then(response => {
            if(response.status === 200) {
                this.setState({
                    goodsTypes: response.data,
                });
            }
        });
    }

    async submit(event) {
        event.preventDefault();

        this.setState({
            loading: true,
            error: false
        });

        let response = await api.editCategory(this.state);

        this.setState({
            loading: false
        });

        if(response.status !== 200) {
            this.setState({
                error: response.data
            });
        }
    }

    render() {
        return <Wrapper navigate={this.props.navigate}>
            {this.state.loading && <span className={"title"}>Загрузка...</span>}
            {this.state.error && <Alert variant={"danger"}>Ошибка: {this.state.error}</Alert>}

            {!this.state.loading && <Form onSubmit={this.submit.bind(this)} className={"form form-wrapper"}>
                <label>
                    Название категории (не изменяемое)
                    <input type={"text"} disabled={true} value={this.state.name} />
                </label>

                <div className={"flex"} style={{marginBottom: 40}}>
                    {<Button variant="primary" type="submit">
                        Обновить блюдо
                    </Button>}
                </div>
            </Form>}

        </Wrapper>;
    }
})