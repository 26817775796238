import Wrapper from '../../components/Wrapper';
import withParams from "../../services/withParams";
import * as React from "react";
import api from "../../services/api";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import CurrencyFormat from "react-currency-format";
import '../../resources/styles/reports.css';
import Button from "react-bootstrap/Button";
import Workbook from 'react-excel-workbook'
import { Link } from 'react-router-dom';
import '../../resources/styles/styles.css';
import {useState, useEffect, useRef} from "react";
import Select from 'react-select';
import DateRangeFilter from '../../components/DateRangeFilter';
import ReportTable from '../../components/ReportTable';
import ReportLine from '../../components/ReportLine';

const PnLReport = (props) => {
    const [loading, setLoading] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const [from, setFrom] = useState(new Date());
    const [to, setTo] = useState(new Date());
    const [userRestaurants, setUserRestaurants] = useState([]);
    const [restaurants, setRestaurants] = useState([]);
    const [data, setData] = useState([]);
    const [allRestaurants, setAllRestaurants] = useState(false);
    const [error, setError] = useState('');

    const isAdmin = (window.user !==undefined && window.user.role === "ADMIN");

    useEffect(() => {
        reload();
    }, []);

    useEffect(() => {
        if(!firstLoad)
        {
            reload();
        }
    }, [from, to, allRestaurants, restaurants]);

    const reload = () => {
        if(!loading)
        {
            setLoading(true);
            const params = new URLSearchParams({from: moment(from).format("YYYY-MM-DD"), to: moment(to).format("YYYY-MM-DD"), allRestaurants: allRestaurants, selectedRestaurants: restaurants.map(v => v.id).join(',')});

            if(userRestaurants.length == 0)
            {
                api.getBaseObject("/restaurants?sort=ordr%2Ctitle%2Caddress&enabled=true&size=1000").then(response => {
                    if(response.status === 200) {
                        setUserRestaurants(response.data.content);
                        if(restaurants.length == 0)
                        {
                            setRestaurants([window.user.restaurant]);
                        }
                    }
                });
            }

            api.getBaseObject("/pnl-report?"+params.toString()).then(response => {
                if(response.status === 200) {
                    let report = {...response.data};

                    report.sumOrders = report.sumOrdersSBP + report.sumOrdersCards;
                    report.countOrders = report.countOrdersSBP + report.countOrdersCards;

                    report.sumTipsWithOrders = report.sumTipsWithOrdersSBP + report.sumTipsWithOrdersCards;
                    report.countTipsWithOrders = report.countTipsWithOrdersSBP + report.countTipsWithOrdersCards;

                    report.sumTipsWithoutOrders = report.sumTipsWithoutOrdersSBP + report.sumTipsWithoutOrdersCards;
                    report.countTipsWitouthOrders = report.countTipsWithoutOrdersSBP + report.countTipsWithoutOrdersCards;

                    report.avgTipsOutSum = report.countTipsOut == 0 ? 0 : report.sumTipsOut / report.countTipsOut;
                    report.avgTipsOutPremium = report.countTipsOut == 0 ? 0 : report.sumPremiumTipsOut / report.countTipsOut;

                    report.sumService = report.sumServiceSBP + report.sumServiceCard;
                    report.countService = report.countServiceSBP + report.countServiceCard;

                    /*
                    report.sumTipsWithOrdersSBPCosts = report.sumTipsWithOrdersSBP * report.sbpCost / 100;
                    report.sumTipsWithOrdersCardCosts = report.sumTipsWithOrdersCards * report.cardCost / 100;
                    */
                    report.sumTipsWithOrdersTotalCosts = report.sumTipsWithOrdersSBPCosts + report.sumTipsWithOrdersCardCosts;

                    /*
                    report.sumTipsWithoutOrdersSBPCosts = report.sumTipsWithoutOrdersSBP * report.sbpCost / 100;
                    report.sumTipsWithoutOrdersCardCosts = report.sumTipsWithoutOrdersCards * report.cardCost / 100;
                    */
                    report.sumTipsWithoutOrdersTotalCosts = report.sumTipsWithoutOrdersSBPCosts + report.sumTipsWithoutOrdersCardCosts;

                    /*
                    report.sumServiceSBPCosts = report.sumServiceSBP * report.sbpCost / 100;
                    report.sumServiceCardCosts = report.sumServiceCard * report.cardCost / 100;
                    */
                    report.sumServiceTotalCosts = report.sumServiceSBPCosts + report.sumServiceCardCosts;

                    report.sumAgentCosts = report.sumAgentOrderCosts + report.sumAgentTipsCosts;
                    
                    report.finalResult = report.sumService - report.sumTipsWithoutOrdersTotalCosts - report.sumTipsWithOrdersTotalCosts - report.sumCardEqDiff - report.sumServiceTotalCosts - report.sumAgentCosts - report.sumExtraCosts - report.sumCashbacks;

                    setError('');
                    setData(report);
                    setLoading(false);
                    setFirstLoad(false);
                }
                else {
                    setError(response.data);
                    setLoading(false);
                    setFirstLoad(false);
                }
            });
        }
    };

    const downloadXls = () => {
        if(!loading && data.length>0)
        {
            let reports = [...data];

            return <Workbook filename="reports.xlsx" element={<Button style={{marginBottom: 5}}>Выгрузить XLS</Button>}>
                <Workbook.Sheet data={reports} name="PnL">
                    <Workbook.Column label="Параметр" value="name"/>
                    <Workbook.Column label="Значение" value="value"/>
                </Workbook.Sheet>
            </Workbook>
        }
    }

    const hasRestaurantData = restaurants.length <=1 && data['restaurantFee'] !== undefined && data['restaurantFee'] != null;

    return <Wrapper navigate={props.navigate}>
            {loading && <span className={"title"}>Загрузка...</span>}
            {error && <span className={"title red"}>Ошибка: {error}</span>}

            {!loading && isAdmin && <div className={"gray-wrapper gray-table"}>
                <DateRangeFilter from={from} to={to} onChange={(f, t) => {setFrom(f); setTo(t);}} className="bottom30"/>

                <div className={"table-search"} style={{marginTop: "0px"}}>
                    <div className={"table-checkbox"}>
                        <Select options={userRestaurants} 
                          getOptionLabel={option => option.title}
                          getOptionValue={option => option.id}
                         isMulti isDisabled={allRestaurants}
                         value={restaurants}
                         onChange={(v) => {
                            if(v.length == 0)
                            {
                                setRestaurants([window.user.restaurant]);
                            } else {
                                setRestaurants(v);
                            }
                         }}
                         ></Select>
                    </div>
                </div>
                <div className={"table-search"} style={{marginTop: "0px", display: "flex", justifyContent: "space-between" }}>
                    <div className={"table-checkbox"}>
                        <input type="checkbox"
                                    checked={allRestaurants}
                                    onChange={() => { setAllRestaurants(!allRestaurants); }} />
                        <span>Все рестораны *</span>
                    </div>
                    {downloadXls()}
                </div>

                <div className={"order-title reports-title"}>Общие значения</div>

                <ReportTable>
                    {hasRestaurantData && <ReportLine data={["Ставка ресторана "+data['restaurantSbpFee']+" %", "Комиссия счета "+data['restaurantFee']+" %", "Комиссия чая "+data['restaurantTipsFee']+" %"]} />}

                    <ReportLine data={["Себестоимость СБП "+data['sbpCost']+" %", "Себестоимость карты "+data['cardCost']+" %", data['columnHeaderText']]} />
                </ReportTable>

                <ReportTable columns={[" ", "Сумма", "Количество", "Статья расходов"]}>

                    <ReportLine data={["Оплата счета", {value: data.sumOrders, type: "money"}, data.countOrders, {value: data.sumCardEqDiff, type: "money"}]}>
                        <ReportLine data={["СБП", {value: data.sumOrdersSBP, type: "money"}, data.countOrdersSBP, ""]} isChild={true} />
                        <ReportLine data={["карта", {value: data.sumOrdersCards, type: "money"}, data.countOrdersCards, {value: data.sumCardEqDiff, type: "money"}]} isChild={true} />
                    </ReportLine>

                    <ReportLine data={["Чаевые со счетом", {value: data.sumTipsWithOrders, type: "money"}, data.countTipsWithOrders, {value: data.sumTipsWithOrdersTotalCosts, type: "money"}]}>
                        <ReportLine data={["СБП", {value: data.sumTipsWithOrdersSBP, type: "money"}, data.countTipsWithOrdersSBP, {value: data.sumTipsWithOrdersSBPCosts, type: "money"}]} isChild={true} />
                        <ReportLine data={["карта", {value: data.sumTipsWithOrdersCards, type: "money"}, data.countTipsWithOrdersCards, {value: data.sumTipsWithOrdersCardCosts, type: "money"}]} isChild={true} />
                    </ReportLine>

                    <ReportLine data={["Чаевые отдельно", {value: data.sumTipsWithoutOrders, type: "money"}, data.countTipsWitouthOrders, {value: data.sumTipsWithoutOrdersTotalCosts, type: "money"}]}>
                        <ReportLine data={["СБП", {value: data.sumTipsWithoutOrdersSBP, type: "money"}, data.countTipsWithoutOrdersSBP, {value: data.sumTipsWithoutOrdersSBPCosts, type: "money"}]} isChild={true} />
                        <ReportLine data={["карта", {value: data.sumTipsWithoutOrdersCards, type: "money"}, data.countTipsWithoutOrdersCards, {value: data.sumTipsWithoutOrdersCardCosts, type: "money"}]} isChild={true} />
                    </ReportLine>

                    <ReportLine data={["Выводы чаевых", {value: data.sumTipsOut, type: "money"}, data.countTipsOut, {value: data.sumOurPremiumTipsOut, type: "money"}]}>
                        <ReportLine data={["Средняя сумма вывода", {value: data.avgTipsOutSum, type: "money"}, "",""]} isChild={true} />
                        <ReportLine data={["Средняя стоимость вывода", {value: data.avgTipsOutPremium, type: "money"}, "",""]} isChild={true} />
                        <ReportLine data={["Среднее количество выводов в день", "", data.avgCountTipsOutPerDay,""]} isChild={true} />
                    </ReportLine>
                    <ReportLine data={["Сервисный сбор", {value: data.sumService, type: "money"}, data.countService, {value: data.sumServiceTotalCosts, type: "money"}]}>
                        <ReportLine data={["СБП", {value: data.sumServiceSBP, type: "money"}, data.countServiceSBP, {value: data.sumServiceSBPCosts, type: "money"}]} isChild={true} />
                        <ReportLine data={["карта", {value: data.sumServiceCard, type: "money"}, data.countServiceCard, {value: data.sumServiceCardCosts, type: "money"}]} isChild={true} />
                        {/*<ReportLine data={["Сервисный сбор, получаемый с разницы ставки ресторана и стоимости СБП", {value: data.sumServiceSBPDiff, type: "money"}, "", ""]} isChild={true} />*/}
                    </ReportLine>

                    <ReportLine data={["Комиссия агента", (hasRestaurantData ? {value: data.agentOrderCost, type: "percent"} : ""),  (hasRestaurantData ? {value: data.agentTipsCost, type: "percent"} : ""), {value: data.sumAgentCosts, type: "money"}]}>
                        <ReportLine data={["Счет", {type: "money", value: data.sumAgentOrderCosts}, "", ""]} isChild={true} />
                        <ReportLine data={["Чаевые", {type: "money", value: data.sumAgentTipsCosts}, "", ""]} isChild={true} />
                    </ReportLine>

                    <ReportLine data={["Доп. расходы", "", "", {value: data.sumExtraCosts, type: "money"}]}>
                    </ReportLine>

                    <ReportLine data={["Сумма кэшбека", "", "", {value: data.sumCashbacks, type: "money"}]}>
                    </ReportLine>

                    <ReportLine data={["Фин. результат", {value: data.finalResult, type: "money"}, "", ""]} />

                </ReportTable>
            </div>}
        </Wrapper>;
}

export default PnLReport;
