import * as React from "react";
import Wrapper from '../../components/Wrapper';
import {Link} from "react-router-dom";

const Advanced = ({navigate}) => {

    const isAdmin = window.user.role === 'ADMIN';
    const isSupport = window.user.role === 'SUPPORT';

    return <Wrapper navigate={navigate}>
            {isAdmin && <>
            {/*<Link to='rkeeper' className={"advanced"}>Эмуляция заказов RKeeper</Link>*/}
            <Link to='logging' className={"advanced"}>История действий</Link>
            <Link to='packages' className={"advanced"}>Версии плагинов</Link>
            <Link to='telegram' className={"advanced"}>Telegram</Link>
            <Link to='pnlreport' className={"advanced"}>PnL отчет</Link>
            <Link to='noservicereport' className={"advanced"}>Отчет по отжатия "Взять расходы сервиса на себя"</Link>
            <Link to='contacts' className={"advanced"}>Контакты ресторана</Link>
            </>}
            {(isAdmin || isSupport) && <Link to='monitoring' className={"advanced"}>Мониторинг</Link>}
            {isAdmin && <>
            <Link to='processing-verification' className={"advanced"}>Сверка с процессингом</Link>
            <Link to='by-restaurants' className={"advanced"}>Результаты по ресторанам</Link>
            </>}
            {(isAdmin || isSupport) && <Link to='notransactionsreport' className={"advanced"}>Рестораны без оплат</Link>}
            {isAdmin && <>
            <Link to='registers' className={"advanced"}>Реестры</Link>
            <Link to='agent' className={"advanced"}>Агенты</Link>
            <Link to='check-cachback-acts' className={"advanced"}>Проверка актов кэшбека</Link>
            </>}
        </Wrapper>;
}

export default Advanced;
