import Wrapper from "../../components/Wrapper";
import {useEffect, useState} from "react";
import api from "../../services/api";
import SettingsItem from "../../components/SettingsItem";
import Button from "react-bootstrap/Button";
import * as React from "react";

const AccountingSettings = (props) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [email, setEmail] = useState("");
    const [enableMonthly, setEnableMonthly] = useState(false);
    const [enableWeekly, setEnableWeekly] = useState(false);
    const [selectedWeekday, setSelectedWeekday] = useState("Monday");

    useEffect(() => {
        onLoadFunc();
    }, []);

    const weekdays = [
        {
            id: "Monday",
            name: "Понедельник",
        },
        {
            id: "Tuesday",
            name: "Вторник",
        },
        {
            id: "Wednesday",
            name: "Среда",
        },
        {
            id: "Thursday",
            name: "Четверг",
        },
        {
            id: "Friday",
            name: "Пятница",
        },
        {
            id: "Saturday",
            name: "Суббота",
        },
        {
            id: "Sunday",
            name: "Воскресенье",
        },
    ];
    const onLoadFunc = () => {
        setLoading(true);
        api.getBaseObject("/reports/properties").then(response => {
            setLoading(false);
            if(response.status === 200) {
                if(response.data !== null && Object.keys(response.data).length > 0) {
                    setEmail(response.data.email);
                    setEnableMonthly(response.data.enableMonthly);
                    setEnableWeekly(response.data.enableWeekly);
                    setSelectedWeekday(response.data.weekday);
                }
            } else {
                setError(response.data);
            }
        });
    };

    const onSave = () => {
        api.setBaseObject("/reports/properties", {
            email: email,
            weekday: selectedWeekday,
            enableWeekly: enableWeekly,
            enableMonthly: enableMonthly,
        }, false).then(
            response => {
                if(response.status === 200) {
                    onLoadFunc();
                } else {
                    setError(response.data);
                }
            }
        );
    };

    return <>
        {loading && <span className={"title"}>Загрузка...</span>}
        {error && <span className={"title red"}>Ошибка: {error}</span>}

        {!loading && !error && <Wrapper navigate={props.navigate}>
            <SettingsItem
                name="Электронная почта"
                type="text"
                value={email}
                onChange={(v) => {
                    setEmail(v);
                }}
            />
            <SettingsItem
                value={enableMonthly}
                type="checkbox"
                name="Ежемесячная выгрузка"
                onChange={() => {
                    setEnableMonthly(!enableMonthly);
                }}
            />
            <SettingsItem
                value={enableWeekly}
                type="checkbox"
                name="Еженедельная выгрузка"
                onChange={() => {
                    setEnableWeekly(!enableWeekly);
                }}
            />
            <SettingsItem
                value={selectedWeekday}
                type="list"
                name="День еженедельной выгрузки"
                mandatory={false}
                options={weekdays}
                onChange={(v) => {
                    setSelectedWeekday(v);
                }}
            />
            <Button type="button" onClick={() => {
                onSave();
            }}>
                Сохранить
            </Button>
        </Wrapper>}
    </>
}

export default AccountingSettings;
