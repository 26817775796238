import Wrapper from '../../components/Wrapper'

import * as React from "react";
import Button from "react-bootstrap/Button";
import api from "../../services/api";
import withParams from "../../services/withParams";
import QRCode from "react-qr-code";

export default withParams(class TableQR extends React.Component {
    state = {
        loading: true,
        id: null,
        serial: null,
        type: null,
        tableNum: "0",
        restaurant: {}
    };

    constructor(props) {
        super(props);
        const { id } = this.props.params;

        api.getRestaurantTable(id).then(response => {
            if(response.status === 200) {
                this.setState({
                    table: response.data,
                    loading: false
                });
            }
            else {
                this.setState({
                    error: response.data,
                    loading: false
                });
            }
        });
    }


    render() {
        let url;

        if(!this.state.loading) {
            url = `${process.env.REACT_APP_MOBILE_APP_URL}?r=${this.state.table.restaurantId}&n=${this.state.table.number}${this.state.table.sectionId === undefined ? '' : '&sc='+this.state.table.sectionId}`;

        }
        return <Wrapper navigate={this.props.navigate}>
            {this.state.loading && <p>Загрузка ресторана...</p>}
            {this.state.error && <p>Ошибка: {this.state.error}</p>}


            {!this.state.loading && !this.state.error && <div className={"form form-wrapper"}>
                Ссылка для оплаты (нажмите для копирования):
                <div className={"flex"} style={{marginBottom: 40}}>
                    <Button onClick={() => {navigator.clipboard.writeText(url)}} className={"flex"}>
                        {url}
                    </Button>
                </div>

                <br /><br />

                <div style={{ height: "auto", margin: "0 auto", width: "50%" }}>
                    <QRCode
                        size={256}
                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        value={url}
                        viewBox={`0 0 256 256`}
                    />
                </div>

                <br/>
            </div>}
        </Wrapper>;
    }
})