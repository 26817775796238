import {useState} from "react";
import CurrencyFormat from 'react-currency-format';
import '../resources/styles/reports.css';

const ReportLine = ({keyPrefix, data, children, isChild}) => {

    const [collapsed, setCollapsed] = useState(true);
    const hasChildren = children !== undefined;

    return <><div className={"tbody"}  onClick={() => {if(hasChildren){setCollapsed(!collapsed);}}} >
            {data.map((i, idx) => {
                let isObject = typeof(i) == 'object';
                let type = isObject ? (i.type ?? "text") : "text";
                let val = isObject ? i.value : i;
                let collpaseButton = hasChildren && idx === 0;
                let childClass = isChild && idx === 0;

                return <div className={"td col-2" + (idx === 0 ? " word-wrap" : "") + (idx === data.length-1 ? " end" : "") + (collpaseButton ? " collapse-button" : "")} key={(keyPrefix ?? "rtd") + idx}>

                    {collpaseButton && <div style={{width: "10px"}}>
                            {(collapsed ? "+" : "-")}
                    </div>}
                    {type === "text" && <div className={(childClass ? " child" : "")}>{val}</div> }
                    {type === "percent" && <>{(val*1).toFixed(2)+" %"}</> }
                    {type === "money" && <CurrencyFormat value={(val*1).toFixed(2)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} />
                }
                </div>;
            })}
        </div>
        {!collapsed && children}
        </>
}

export default ReportLine;

