import * as React from "react";
import {useEffect, useState} from "react";
import Wrapper from "../../components/Wrapper";
import SettingsItem from "../../components/SettingsItem";
import Button from "react-bootstrap/Button";
import moment from "moment";
import api from "../../services/api";
import getUID from "../../services/randomValues";
import ReportTable from "../../components/ReportTable";
import ReportLine from "../../components/ReportLine";

const PayUReportOrder = (props) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [needConfirm, setNeedConfirm] = useState(false);
    const [reloadSwitcher, setReloadSwitcher] = useState(false);
    const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
    const [messages, setMessages] = useState([]);
    const [dataIsExist, setDataIsExist] = useState(false);

    const isAdmin = (window.user !==undefined && window.user.role === "ADMIN");
    const columns = ["Сообщение"];

    useEffect(() => {
        setLoading(true);
        api.getBaseObject(`/reports/payu-report-order/messages?date=${date}`).then(response => {
            if(response.status === 200) {
                const data = response.data.map(m => {
                    m.key = getUID();
                    return m;
                });
                setMessages(data);
                setLoading(false);
            } else {
                setError(response.data);
                setLoading(false);
            }
        });
    }, [date, reloadSwitcher]);

    useEffect(() => {
        setLoading(true);
        api.getBaseObject(`/reports/payu-report-order/data-by-order-finish-date-is-exist?date=${date}`).then(response => {
            if(response.status === 200) {
                setDataIsExist(response.data);
                setLoading(false);
            } else {
                setError(response.data);
                setLoading(false);
            }
        });
    }, [date, reloadSwitcher]);

    const onRefresh = () => {
        setLoading(true);
        api.setBaseObject(`/reports/payu-report-order/refresh-data`, { date: date, }).then(response => {
            if(response.status === 200) {
                setReloadSwitcher(!reloadSwitcher);
                setLoading(false);
            } else {
                setError(response.data);
                setLoading(false);
            }
        });
    };

    return <Wrapper navigate={props.navigate}>
        {loading && <span className={"title"}>Загрузка...</span>}
        {error && <span className={"title red"}>Ошибка: {error}</span>}

        {!loading && !error && isAdmin && <>
            <SettingsItem name="Дата" type="date" value={date} onChange={(v) => { setDate(v);}} />
            <ReportTable columns={columns}>
                { messages.length === 0 && <ReportLine data={["Сообщений нет"]} /> }
                { messages.length > 0 && messages.map(m => {
                    return <ReportLine key={m.key} data={[m.message]} />
                })}
            </ReportTable>

            <Button type="button" onClick={() => {
                if(dataIsExist === true) {
                    setNeedConfirm(true);
                } else {
                    onRefresh();
                }
            } }>
                Загрузить
            </Button>
        </>
    }

    {needConfirm && <div className='mail-confirm-popup'>
            <div className='mail-confirm-popup-body'>
                <div className='mail-confirm-text'>Вы уверены, что заново хотите загрузить и сверить данные?</div>
                <Button variant="primary" className='mail-confirm-cancel' onClick={() => {setNeedConfirm(false)}}>Отменить</Button>
                <Button variant="primary" onClick={() => {
                    setNeedConfirm(false);
                    onRefresh();
                }}>Подтвердить</Button>
            </div>
        </div>
    }
    </Wrapper>
}

export default PayUReportOrder;
