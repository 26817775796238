import '../../resources/styles/reports.css';
import '../../resources/styles/styles.css';
import ReportTable from '../../components/ReportTable';
import ReportLine from '../../components/ReportLine';

const ReportLikes = ({loading, data, isAdmin}) => {
    return <>
            <div className={"order-title reports-title"}>Обратная связь гостей</div>
            <ReportTable columns={["Официант", "Лайки", "Дизлайки", "Без отзывов"]}>
            {data.map((item, i) => {
                return <ReportLine key={"wtips_"+i} keyPrefix={"r"+i+"_"} data={[
                    item.fullName + (isAdmin ? `(${item.code ?? "~"})` : ""), 
                    item.likes, item.dislikes, item.none
                ]} />
            })}
            </ReportTable>
    </>;
};

export default ReportLikes;
