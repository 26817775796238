import Wrapper from '../../components/Wrapper';
import withParams from "../../services/withParams";
import * as React from "react";
import api from "../../services/api";
import {Link} from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {Alert} from "react-bootstrap";
import ToggleMenu from "../../components/ToggleMenu";
import "../../resources/styles/switch.css";
import "../../resources/styles/text-input.css";
import "../../resources/styles/restaurant.css";
import SettingsItem from '../../components/SettingsItem';
import Modal from 'react-modal';

export default withParams(class RestaurantEdit extends React.Component {
    state = {
        loading: true,
        id: null,
        address: "",
        title: "",
        organizationId: "",
        additionalOrganizationId: "",
        iikoApiLogin: "",
        paymentType: "PAYU",
        enabled: false,
        level: "Info",
        rkeeperAuth: "",
        rkeeperUrl: "",
        rkeeperXmlType: "655cee55-7bcd-4826-b6e0-a8ccf2017ef3",
        rkeeperPrepayId: 0,
        rkeeperReasonCode: 0,
        rkeeperLogin: "",
        rkeeperPassword: "",
        iikoPin: 0,
        rKeeperStation:  0,
        rKeeperCashier:  0,
        rKeeperBonusId: "",
        iikoAlcoholOrderType: "",
        iikoProductsOrderType: "",
        alcoCookingPlaceUuid: "",
        rKeeperLicenseToken: null,
        goodsTypes: [],
        goodsTypesChanged: false,
        rKeeperLicenseTokenExpire: null,
        iikoPaymentName: 'Оплата EatAndSplit',
        iikoBonusName: 'Бонусы Eat & Split',
        timeZone: "",
        startWorkingTimeStr: null,
        endWorkingTimeStr: null,
    };

    constructor(props) {
        super(props);


        api.getBaseObject("/restaurants/goods").then(response => {
            if(response.status === 200) {
                this.setState({
                    goodsTypes: response.data,
                });
            }
        });

        api.getRestaurant().then(response => {
            if(response.status === 200) {
                let startTime = response.data.startWorkingTime ?? null;
                if(startTime!=null)
                {
                    let nv = startTime.split('T')[1].split(':');
                    startTime = nv[0]+":"+nv[1];
                }
                let endTime = response.data.endWorkingTime ?? null;
                if(endTime!=null)
                {
                    let nv = endTime.split('T')[1].split(':');
                    endTime = nv[0]+":"+nv[1];
                }

                this.setState({
                    id: response.data.id,
                    address: response.data.address,
                    title: response.data.title,
                    organizationId: response.data.organizationId,
                    additionalOrganizationId: response.data.additionalOrganizationId,
                    enabled: response.data.enabled,
                    loading: false,
                    avatarBg: response.data.avatar ? "data:image/png;base64," + response.data.avatar : null,
                    backgroundBg: response.data.background ? "data:image/png;base64," + response.data.background : null,
                    iikoPin: response.data.iikoPin,
                    rKeeperAuth: response.data.rkeeperAuth,
                    rKeeperUrl: response.data.rkeeperUrl,
                    rKeeperLogin: response.data.rkeeperLogin,
                    rKeeperPassword: response.data.rkeeperPassword,
                    rKeeperXmlType: response.data.rkeeperXmlType,
                    rKeeperPrepayId: response.data.rkeeperPrepayId,
                    rKeeperBonusId: response.data.rkeeperBonusId,
                    rKeeperReasonCode: response.data.rkeeperReasonCode,
                    rKeeperStation: response.data.rkeeperStation,
                    rKeeperCashier: response.data.rkeeperCashier,
                    rKeeperLicenseToken: response.data.rkeeperLicenseToken,
                    rKeeperLicenseTokenExpire: response.data.rkeeperLicenseTokenExpire,
                    iikoProductsOrderType: response.data.iikoProductsOrderType ?? '',
                    iikoAlcoholOrderType: response.data.iikoAlcoholOrderType ?? '',
                    alcoCookingPlaceUuid: response.data.alcoCookingPlaceUuid ?? '',
                    avatarServer: response.data.avatar ?? '',
                    backgroundServer: response.data.background ?? '',
                    goodsTypesChanged: false,
                    iikoPaymentName: response.data.iikoPaymentName ?? '',
                    iikoBonusName: response.data.iikoBonusName ?? '',
                    timeZone: response.data.timeZone ?? '',
                    startWorkingTimeStr: startTime,
                    endWorkingTimeStr: endTime,
                });
            }
            else {
                this.setState({
                    error: response.data,
                    loading: false
                });
            }
        });
    }

    async submit(event) {
        event.preventDefault();

        this.setState({
            loading: true,
            error: false,
            premiumLoaded: false,
        });

        let response = await api.editAgentRestaurant({...this.state, startWorkingTime: "2000-01-01 "+(this.state.startWorkingTimeStr ?? "00:00")+":00", endWorkingTime: "2000-01-01 "+(this.state.endWorkingTimeStr ?? "00:00")+":00" });

        if(this.state.goodsTypesChanged)
        {
            api.setBaseObject("/restaurants/goods", {goods: this.state.goodsTypes}, false).then(
                response => {
                    if(response.status === 200) {
                        this.setState({goodsTypesChanged: false});
                        if(!this.state.mapLinksChanged && !this.state.premiumChanged)
                        {
                            window.location.reload();
                        }
                    } else {
                        this.setState({
                            error: response.data,
                            loading: false
                        });
                    }
                }
            )
        } else {
            this.setState({goodsTypesChanged: false});
        }

        this.setState({
            loading: false
        });

        if(response.status !== 200) {
            this.setState({
                error: response.data
            });
        }
        else {
            window.location.reload();
        }
    }

    changeGoodsValue(idx, field, val) {
        const goods = this.state.goodsTypes;
        goods[idx][field] = val;
        goods[idx]['changed'] = true;
        this.setState({goodsTypes: goods, goodsTypesChanged: true});
    }

    addGoodsType() {
        api.setBaseObject("/restaurants/add-type", {name: this.state.newTypeName}).then(response => {
            if(response.status === 200) {
                this.setState({
                    newTypeName: "",
                });
                window.location.reload();
            }
        });
    }

    render() {

        let logoImage = this.state.avatarImg!=null ? this.state.avatarImg :
            ( this.state.avatarServer !== "" ? api.getRestBrandingUrl('get-logo/'+this.state.avatarServer) :
                api.getRestBrandingUrl('/images/jpeg/'+this.state.id+'.avatar.jpg'));
        let backgroundImage = (this.state.backgroundImg!=null ? this.state.backgroundImg :
            ( this.state.backgroundServer !== "" ? api.getRestBrandingUrl('get-background/'+this.state.backgroundServer) :
                api.getRestBrandingUrl('/images/jpeg/'+this.state.id+'.bg.jpg')));

        return <Wrapper navigate={this.props.navigate}>
            {this.state.loading && <span className={"title"}>Загрузка...</span>}
            {this.state.error && <span className={"title red"}>Ошибка: {this.state.error}</span>}

            {!this.state.loading && <Form onSubmit={this.submit.bind(this)} className={"form form-wrapper"}>
                {!this.state.enabled && <Alert variant={"info"}>
                    Отредактируйте ресторан для активации администратором.
                </Alert>}

                {this.state.enabled && <Alert variant={"info"}>
                    Ресторан запущен, редактирование запрещено!
                </Alert>}

                <br/>

                <label>
                    Название
                    <input type={"hidden"}  value={this.state.id} />
                    <input readOnly={this.state.enabled} type={"text"} value={this.state.title} onChange={e => this.setState({ title: e.target.value })} />
                </label>

                <label>
                    Адрес
                    <input readOnly={this.state.enabled} type={"text"} value={this.state.address} onChange={e => this.setState({ address: e.target.value })} />
                </label>

                <label>
                    <div>Часовой пояс<span className={"gray"}>админ</span></div>
                    <input readOnly={this.state.enabled} type="number" min="-12" max="14" step="1" value={this.state.timeZone} onChange={e => this.setState({ timeZone: e.target.value })} />
                </label>

                <label>
                    <div>Начало работы<span className={"gray"}>админ</span></div>
                    <input readOnly={this.state.enabled} type="time" value={this.state.startWorkingTimeStr} onChange={e => {
                        this.setState({ startWorkingTimeStr: e.target.value });
                    }} />
                </label>

               <label>
                    <div>Окончание работы<span className={"gray"}>админ</span></div>
                    <input readOnly={this.state.enabled} type="time" value={this.state.endWorkingTimeStr} onChange={e => {
                        this.setState({ endWorkingTimeStr: e.target.value });
                    }} />
                </label>

                {<ToggleMenu title={"Логотип и фон"}>
                    <div style={{marginRight: '10px', width: 390}} className='images'>
                        <div>
                            <label>Логотип (старый дизайн - 200x65, новый дизайн - 83x40)
                                <div className='logo-container'>
                                    <input readOnly={this.state.enabled} type="file" accept="image/*" onChange={ev => {
                                        let reader = new FileReader();
                                        reader.readAsDataURL(ev.target.files[0]);
                                        reader.onloadend = (e) => {
                                            let img = new window.Image();

                                            img.onload = () => {
                                                this.setState({
                                                    avatar: ev.target.files[0],
                                                    avatarImg: [reader.result]
                                                })
                                            };
                                            img.src = reader.result;
                                        }
                                    }
                                    } />

                                    <div className='logo'>
                                        <div
                                            style={{backgroundImage: ('url('+logoImage+')')}} className='branding logo-image'/>
                                    </div>
                                </div>
                            </label>
                        </div>


                        <div>
                            <label>Фон (700x500)
                                <div className='logo-container'>
                                    <input readOnly={this.state.enabled} type="file" accept="image/*" onChange={ev => {
                                        let reader = new FileReader();
                                        reader.readAsDataURL(ev.target.files[0]);
                                        reader.onloadend = (e) => {
                                            let img = new window.Image();

                                            img.onload = () => {
                                                this.setState({
                                                    background: ev.target.files[0],
                                                    backgroundImg: [reader.result]
                                                })
                                            };
                                            img.src = reader.result;
                                        }
                                    }
                                    } />

                                    <div
                                        style={{backgroundImage: ('url('+backgroundImage+')')}} className='branding back'/>
                                </div>
                            </label>
                        </div>

                    </div>
                </ToggleMenu>}

                <div style={{clear: "both"}} />

                {<ToggleMenu title={"Общие настройки"}>
                    <label>
                        <div>Внешний идентификатор <span className={"gray"}>админ</span></div>
                        <input readOnly={true} type={"text"} value={this.state.organizationId} />
                    </label>

                    <label>
                        <div>Дополнительный внешний идентификатор <span className={"gray"}>админ</span></div>
                        <input readOnly={true} type={"text"} value={this.state.additionalOrganizationId} />
                    </label>
                </ToggleMenu>}

                {<ToggleMenu title={"Настройки R-Keeper"}>
                    <label>
                        <div>R-Keeper кассовый сервер <span className={"gray"}>админ</span></div>
                        <input readOnly={this.state.enabled} type={"text"} value={this.state.rKeeperUrl} onChange={e => this.setState({ rKeeperUrl: e.target.value })} />
                    </label>

                    <label>
                        <div>R-Keeper тип XML интерфейса <span className={"gray"}>админ</span></div>
                        <select readOnly={this.state.enabled} value={this.state.rKeeperXmlType} onChange={e => this.setState({ rKeeperXmlType: e.target.value })}>
                            <option readOnly={true}>-- Выберите --</option>
                            <option value="655cee55-7bcd-4826-b6e0-a8ccf2017ef3">12 месяцев</option>
                            <option value="4458d8b1-0019-4025-8c7a-60a83ad64b66">1 месяц</option>
                        </select>
                    </label>

                    <label>
                        <div>R-Keeper данные авторизации (логин:пароль) <span className={"gray"}>админ</span></div>
                        <input readOnly={this.state.enabled} type="text" value={this.state.rKeeperAuth} onChange={e => this.setState({ rKeeperAuth: e.target.value })} />
                    </label>

                    <label>
                        <div>R-Keeper логин авторизации токена<span className={"gray"}>админ</span></div>
                        <input readOnly={this.state.enabled} type="text" value={this.state.rKeeperLogin} onChange={e => this.setState({ rKeeperLogin: e.target.value })} />
                    </label>

                    <label>
                        <div>R-Keeper пароль авторизации токена<span className={"gray"}>админ</span></div>
                        <input readOnly={this.state.enabled} type="text" value={this.state.rKeeperPassword} onChange={e => this.setState({ rKeeperPassword: e.target.value })} />
                    </label>

                    <label>
                        <div>R-Keeper идентификатор валюты предоплаты <span className={"gray"}>админ</span></div>
                        <input readOnly={this.state.enabled} type="text" value={this.state.rKeeperPrepayId} onChange={e => this.setState({ rKeeperPrepayId: e.target.value })} />
                    </label>

                    <label>
                        <div>R-Keeper код бонусной валюты <span className={"gray"}>админ</span></div>
                        <input readOnly={this.state.enabled} type="text" value={this.state.rKeeperBonusId} onChange={e => this.setState({ rKeeperBonusId: e.target.value })} />
                    </label>

                    <label>
                        <div>R-Keeper код основания предоплаты <span className={"gray"}>админ</span></div>
                        <input readOnly={this.state.enabled} type="text" value={this.state.rKeeperReasonCode} onChange={e => this.setState({ rKeeperReasonCode: e.target.value })} />
                    </label>

                    <label>
                        <div>R-Keeper код кассира закрытия счета <span className={"gray"}>админ</span></div>
                        <input readOnly={this.state.enabled} type="text" value={this.state.rKeeperCashier} onChange={e => this.setState({ rKeeperCashier: e.target.value })} />
                    </label>

                    <label>
                        <div>R-Keeper код станции закрытия счета <span className={"gray"}>админ</span></div>
                        <inpu readOnly={this.state.enabled} type="text" value={this.state.rKeeperStation} onChange={e => this.setState({ rKeeperStation: e.target.value })} />
                    </label>

                    {this.state.rKeeperLicenseToken && this.state.rKeeperLicenseTokenExpire && <label>
                        <div>R-Keeper проверка лицензии</div>
                        <textarea style={{height: 50}} readOnly={true} value={
                            `Идентификатор: ${this.state.rKeeperLicenseToken}\nДата окончания: ${this.state.rKeeperLicenseTokenExpire}`}
                        />
                    </label>}
                </ToggleMenu>}

                {<ToggleMenu title={"Настройки Iiko"}>
                    <label>
                        <div>Iiko пин <span className={"gray"}>админ</span></div>
                        <input readOnly={this.state.enabled} type="text" value={this.state.iikoPin} onChange={e => this.setState({ iikoPin: e.target.value })} />
                    </label>

                    <label>
                        <div>Название валюты оплаты <span className={"gray"}>админ</span></div>
                        <input readOnly={this.state.enabled} type="text" value={this.state.iikoPaymentName} onChange={e => this.setState({ iikoPaymentName: e.target.value })} />
                    </label>

                    <label>
                        <div>Название бонусной валюты <span className={"gray"}>админ</span></div>
                        <input readOnly={this.state.enabled} type="text" value={this.state.iikoBonusName} onChange={e => this.setState({ iikoBonusName: e.target.value })} />
                    </label>

                </ToggleMenu>}

                {<ToggleMenu title={"Типы продуктов"}>
                    {this.state.goodsTypes.map((item, i) => {
                        return <ToggleMenu key={"goods-type-menu-"+i} title={item.name}>
                            <SettingsItem readOnly={this.state.enabled} name="Название" value={item.name} onChange={(v) => {
                                this.changeGoodsValue(i, "name", v);
                            }} />
                            <SettingsItem readOnly={this.state.enabled} name="По умолчанию" type="checkbox" value={item.isDefault ?? false} onChange={(v) => {
                                this.changeGoodsValue(i, "isDefault", v);
                            }} />
                            <SettingsItem readOnly={this.state.enabled} name="Юр.лицо" value={item.legalName} onChange={(v) => {
                                this.changeGoodsValue(i, "legalName", v);
                            }} />
                            <SettingsItem readOnly={this.state.enabled} name="Группы (через запятую без пробелов)" value={item.groups} onChange={(v) => {
                                this.changeGoodsValue(i, "groups", v);
                            }} />

                            <SettingsItem readOnly={this.state.enabled} name="Место приготовления для iiko / Код отдела ФР для R-Keeper" value={item.cookingPlaceUuid} onChange={(v) => {
                                this.changeGoodsValue(i, "cookingPlaceUuid", v);
                            }} />
                            <SettingsItem readOnly={this.state.enabled} name="Тип заказа для iiko" value={item.iikoOrderType} onChange={(v) => {
                                this.changeGoodsValue(i, "iikoOrderType", v);
                            }} />
                            <ToggleMenu title={"R-Keeper"}>
                                <SettingsItem readOnly={this.state.enabled} name="R-Keeper id валюты EatAndSplit" value={item.rkeeperCurrencyId} onChange={(v) => {
                                    this.changeGoodsValue(i, "rkeeperCurrencyId", v);
                                }} />
                                <SettingsItem readOnly={this.state.enabled} name="R-Keeper код причины внесения валюты EatAndSplit" value={item.rkeeperReasonCode} onChange={(v) => {
                                    this.changeGoodsValue(i, "rkeeperReasonCode", v);
                                }} />
                                <SettingsItem readOnly={this.state.enabled} name="R-Keeper id бонусной валюты" value={item.rkeeperBonusCurrencyId} onChange={(v) => {
                                    this.changeGoodsValue(i, "rkeeperBonusCurrencyId", v);
                                }} />
                                <SettingsItem readOnly={this.state.enabled} name="R-Keeper код причины внесения бонусной валюты" value={item.rkeeperBonusReasonCode} onChange={(v) => {
                                    this.changeGoodsValue(i, "rkeeperBonusReasonCode", v);
                                }} />
                            </ToggleMenu>

                        </ToggleMenu>
                    })}
                    <Button disabled={this.state.enabled}  type="button" onClick={() => {
                        this.setState({addTypeModal: true});
                    }}>
                        Добавить
                    </Button>
                </ToggleMenu>}

                <div className={"flex"}>
                     <Button type="submit" disabled={this.state.enabled} >Обновить ресторан</Button>
                </div>

                <br/>
                <br/>

                <Modal isOpen={this.state.addTypeModal}>
                    <Form className="add-type-modal">
                        <label>
                            Название:
                            <input type="text" readOnly={this.state.enabled} value={this.state.newTypeName} onChange={(e) => {
                                this.setState({newTypeName: e.target.value});
                            }} />
                        </label>
                        {!this.state.enabled && <div className='add-type-buttons'>
                            <Button type="button" onClick={() => { this.addGoodsType(); }}>
                                Создать
                            </Button>
                            <Button type="button"  onClick={() => {
                                this.setState({addTypeModal: false});
                            }}>
                                Отменить
                            </Button>
                        </div>}
                    </Form>
                </Modal>
            </Form>}
        </Wrapper>;
    }
})