import Wrapper from '../../../components/Wrapper'

import * as React from "react";
import {useState, useEffect} from "react";
import Button from "react-bootstrap/Button";
import api from "../../../services/api";
import {useSearchParams, useNavigate} from 'react-router-dom';
import TeamButtonsNav from './TeamButtonsNav';

const TeamSharing = (props) => {
    const [loading, setLoading] = useState(true);
    const [saveError, setSaveError] = useState('');
    const [hasChanges, setHasChanges] = useState(false);
    const [error, setError] = useState("");
    const [data, setData] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [needConfirm, setNeedConfirm] = useState(false); 

    useEffect(() => {
        setLoading(true);
        api.getPageableTeams({page: data.page, enabled: data.enabled, searchText: data.searchText}).then(response => {
            if(response.status === 200) {
                setData({
                    data: response.data,
                    loading: false
                });
                setLoading(false);
            }
            else {
                setError(response.data);
                setLoading(false);
            }
        });
    }, []);

    const onSaveClicked = () => {
        setLoading(true);
        let savingItems = [];
        let savedCnt = 0;
        let teamTipsPrcIsOk = true;
        let totalPrc = 0;
        for(var i=0;i<data.data.content.length;i++)
        {
            let item = data.data.content[i];
            if(!item.separateTips)
            {
                totalPrc += item.prc*1;
            }
            if(item['changed']!=undefined && item['changed']!=null && item.changed)
            {
                if(!item.separateTips && (item.prc*1<5 || item.prc*1>100))
                {
                    teamTipsPrcIsOk = false;
                }
                savingItems.push(i);
            }
        }
        if(totalPrc>100)
        {
            setLoading(false);
            setSaveError('Некорректное распределение - сумма процентов больше 100!');
        } else {
        if(teamTipsPrcIsOk)
        {
            for(var i=0;i<savingItems.length;i++)
            {
                let cItem = data.data.content[savingItems[i]];
                let cIndex = savingItems[i];
                api.editTeamSharing(cItem).then(response => {
                    savedCnt++;
                    if(response.status !== 200) {
                        setError(response.data == null ? "Ошибка при загрузке данных" : response.data);
                        if(savedCnt>=savingItems.length)
                        {
                            setLoading(false);
                        }
                    }
                    else {
                        let v = data.data.content;
                        v[cIndex].id = response.data.id;
                        v[cIndex].separateTips = response.data.separateTips;
                        v[cIndex].prc = response.data.prc;
                        v[cIndex].changed = false;
                        setData({data: {...data.data, content: v}});
                        if(savedCnt>=savingItems.length)
                        {
                            setLoading(false);
                            setHasChanges(false);
                        }
                    }
                });
            }
        } else {
            setLoading(false);
            setSaveError('Некорректное распределение');
        }
    }
}

    const onChangeTeamType = (i, teamType) => {
        let v = data.data.content;
        v[i].separateTips = teamType;
        v[i].changed = true;
        setData({data: {...data.data, content: v}});
        setHasChanges(true);
    }

    const onChangePrc = (i, prc) => {
        let v = data.data.content;
        v[i].prc = prc;
        v[i].changed = true;
        setData({data: {...data.data, content: v}});
        setHasChanges(true);
    }

    return <Wrapper navigate={navigate}>
        {loading && <p>Загрузка команд...</p>}

        {saveError != '' && <div className='mail-confirm-popup'>
                <div className='mail-confirm-popup-body'>
                    <div className='mail-confirm-text'>{saveError}</div>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <Button variant="primary" onClick={() => setSaveError('')}>Ок</Button>
                </div>
            </div>
        }

        {!loading && (window.user.role === "ADMIN") && <div className={"gray-wrapper gray-table"}>
            <TeamButtonsNav />
            {!data.loading && data.data.content.length === 0 && <p className={"table-not-found"}>Команд пока нет...</p>}

            {data.data.content.length > 0 && <div className={"gray-wrapper gray-table"}>
                <div className={"table"}>
                    <div className={"thead"}>
                        <div className={"th"}>Название</div>
                        <div className={"th"}>Тип распределения</div>
                        <div className={"th"}>Процент</div>
                    </div>

                    
                    {data.data.content.map((item, i) => {
                        return <div className={"tbody"} key={"sht"+i}>
                            <div className={"td"}>{item.name}</div>
                            <div className={"td end"}>
                                <select value={item.separateTips} onChange={e => onChangeTeamType(i, e.target.value == "true")}>
                                    <option value="false">Начислять</option>
                                    <option value="true">Не участвует</option>
                                </select>
                            </div>
                            <div className={"td break"}/>
                            <div className={"td"}>
                            {!item.separateTips && <input type={"number"} pattern="[0-9]*" value={item.prc} disabled={item.separateTips} onChange={(e) => {
                                onChangePrc(i, e.target.value.replace(/\D/g, ""));
                            }}/>}
                            </div>
                        </div>
                    })}
                </div>
                <div style={{paddingLeft: 20, paddingRight: 20, marginBottom: 20, fontSize: 12}}>* процент должен быть не меньше 5 и не больше 100</div>
                <div style={{paddingLeft: 20, paddingRight: 20}}>
                    <Button style={{marginBottom: 5}} disabled={!hasChanges} onClick={onSaveClicked}>Обновить данные</Button>
                </div>
                <div style={{paddingLeft: 20, paddingRight: 20, marginBottom: 20, fontSize: 12}}>В рапределении чаевых участвуют сотрудники не состоящие ни в одной из команд</div>
            </div>}
        </div>}
    </Wrapper>;
};

export default TeamSharing;

