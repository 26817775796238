import * as React from "react";
import {Button} from "react-bootstrap";
import Image from "react-bootstrap/Image";
import editIcon from '../../resources/images/edit.svg';
import arrowIcon from '../../resources/images/arrow.svg';
import removeIcon from '../../resources/images/remove.svg';

const Product = ({item, onEditProduct, onUp, onDown, isFirst, isLast, onDeleteProduct}) => {

    const [needConfirm, setNeedConfirm] = React.useState(false);

    return <div className="product-item">
            <div className={"product-name" + (item.enabled ? "" : " disabled")}>{item.name}</div>
            <Button onClick={() => {onEditProduct(item)}}>
                    <Image src={editIcon} className="edit-icon" />
                </Button>
                <Button disabled={isFirst} onClick={() => {onUp(item)}}>
                    <Image src={arrowIcon} className="arrow-up-icon" />
                </Button>
                <Button disabled={isLast} onClick={() => {onDown(item)}}>
                    <Image src={arrowIcon} className="arrow-down-icon" />
                </Button>
                <Image src={removeIcon} className="menu-remove-icon" disabled={(item.id ?? 0) > 0} onClick={() => {setNeedConfirm(true)}} />
                {needConfirm && <div className='mail-confirm-popup'>
                    <div className='mail-confirm-popup-body'>
                        <div className='mail-confirm-text'>Вы уверены, что хотите удалить позицию?</div>
                        <Button variant="primary" className='mail-confirm-cancel' onClick={() => {setNeedConfirm(false)}}>Отменить</Button>
                        <Button variant="primary" onClick={onDeleteProduct}>Подтвердить</Button>
                    </div>
                </div>
            }

        </div>
};

export default Product;

