import Wrapper from '../components/Wrapper'
import withParams from "../services/withParams";
import * as React from "react";
import api from "../services/api";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import Moment from "react-moment";
import calendar from '../resources/images/calendar.svg'
import Image from "react-bootstrap/Image";
import { Doughnut } from 'react-chartjs-2';
import {Chart, ArcElement} from 'chart.js'
import CurrencyFormat from 'react-currency-format';
import '../resources/styles/manager.css'
import Paginator from "../components/Paginator";
import like from '../resources/images/like.svg'
import dislike from '../resources/images/dislike.svg'
import face from '../resources/manager/face.svg'
import heart from '../resources/manager/heart.svg'
import {Link} from "react-router-dom";
import {FormCheck} from "react-bootstrap";

Chart.register(ArcElement);

export default withParams(class Manager extends React.Component {
    state = {
        loading: true,
        loadingTransactions: true,
        from: new Date(),
        to: new Date(),
        statsSession: false
    };

    constructor(props) {
        super(props);

        this.reload();
    }

    reload() {
        this.setState({
            loading: true
        });

        let aw = this.state.lastSession ? api.getStatsLastSession() : api.getStats(this.state.form, this.state.to);

        aw.then(response => {
            if(response.status === 200) {
                this.setState({
                    data: response.data,
                    loading: false
                });
            }
            else {
                this.setState({
                    tips: response.data,
                    loading: false
                });
            }
        });

        this.getTransactions(0);
    }

    getTransactions(page) {
        api.getPageableTransactions({
            page: page,
            date: moment(new Date()).format("YYYY-MM-DD"),
            paid: true,
            products: true,
            size: 4,
            lastSession: this.state.lastSession
        }).then(response => {
            if(response.status === 200) {
                this.setState({
                    transactions: response.data,
                    loadingTransactions: false
                });
            }
            else {
                this.setState({
                    error: response.data,
                    loadingTransactions: false
                });
            }
        });
    }

    render() {
        return <Wrapper navigate={this.props.navigate}>
            {this.state.loading && <span className={"title"}>Загрузка...</span>}
            {this.state.error && <span className={"title red"}>Ошибка: {this.state.error}</span>}


            {!this.state.loading && !this.state.loadingTransactions && !this.state.error && <div className={"row between  mobile"}>
                <div className={"col-2"}>
                    <div className={"title"}>
                        <div className={"row"}>
                            <div className={"col-2"}>
                                Выручка
                                <div style={{fontSize: 16, marginTop: -10}}>
                                    <span style={{textDecoration : !this.state.lastSession ? "underline" : "none", cursor: "pointer"}} onClick={() => {
                                        this.setState({
                                            lastSession: false
                                        }, () => this.reload());
                                    }}>за день</span>
                                    &nbsp;/&nbsp;
                                    <span style={{textDecoration : this.state.lastSession ? "underline" : "none", cursor: "pointer"}} onClick={() => {
                                        this.setState({
                                            lastSession: true
                                        }, () => this.reload());
                                    }}>за смену</span>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className={"manager-balance"}>
                        <CurrencyFormat value={Math.round(this.state.data.products)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} />
                    </div>

                    <div className={"title title-margin"}>Транзакции</div>
                    {!this.state.loadingTransactions && this.state.transactions.content.length === 0 && <p>Платежей пока нет...</p>}

                    {!this.state.loadingTransactions && this.state.transactions.content.length > 0 && <div>
                        {this.state.transactions.content.map((transaction, i) => {
                            return <Link className={"list row between middle"} to={"/orders/" + transaction.orderId} key={"man_tr_"+i}>
                                <div className={"flex"}>
                                    <Image src={calendar}/>
                                    <Moment className={"list-text"} format="DD.MM.YY&nbsp;&nbsp;&nbsp;HH:mm">{transaction.changed}</Moment>
                                    <div className={"list-text"}>{transaction.table != null && transaction.table.toLowerCase().indexOf("стол") < 0 && "Стол"} {transaction.table}</div>
                                </div>
                                <div className={"list-sum green"}>
                                    +<CurrencyFormat value={Math.floor(transaction.products + transaction.alcohol)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} />
                                </div>
                            </Link>
                        })}

                        <Paginator page={this.state.transactions} onChange={(handlePageChange) => {
                            this.getTransactions(handlePageChange)
                        }}/>
                    </div>}
                </div>
                <div className={"manager-margin-40"} />
                <div className={"col-2"}>
                    <div className={"title"}>{this.state.lastSession ? "Смена" : "Сегодня"}</div>

                    <div className={"row block manager-margin-30-top"}>
                        <div className={"col-2 relative"}>
                            {!this.state.lastSession && <div className={"middle"}>
                                <Image src={calendar}/> <Moment className={"list-text"} format="DD.MM.YY" />
                            </div>}
                            {this.state.lastSession && <div className={"middle"}>
                                <Image src={calendar}/>
                                <Moment className={"list-text"} format="DD.MM.YY">{this.state.data.sessionCreated}</Moment>
                                &nbsp;-&nbsp;
                                <Moment className={"list-text"} style={{marginLeft:0}} format="DD.MM.YY" />
                            </div>}

                            {window.user.restaurant.modelService !== "Pays" && <div className={"manager-tips"}>
                                Чаевые
                                <span><CurrencyFormat value={Math.floor(this.state.data.tips)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} /></span>
                            </div>}

                            {window.user.restaurant.modelService === "Pays" && <div className={"manager-tips"}>
                                Обратная связь
                                <span>{this.state.data.likes + this.state.data.dislikes}</span>
                            </div>}

                            <div className={"manager-likes"}>
                                <div><Image src={like}/> {this.state.data.likes}</div>
                                <div><Image src={dislike}/> {this.state.data.dislikes}</div>
                            </div>

                            <div className={"manager-pie"}>
                                <Doughnut width={150} height={150} options={{borderWidth: 10, borderColor: "#F5F5F5", maintainAspectRatio: false}}  data={{
                                    labels: [
                                        'Green',
                                        'Red'
                                    ],
                                    datasets: [{
                                        data: [this.state.data.likes, this.state.data.dislikes],
                                        backgroundColor: [
                                            'rgb(71, 197, 121)',
                                            'rgb(255, 99, 132)'
                                        ],
                                        hoverOffset: 2
                                    }]
                                }} />
                            </div>
                        </div>
                    </div>

                    <div className={"row between manager-margin-25-top"}>
                        <div className={"col-2 block relative"}>
                            <div className={"manager-icon"}>
                                <Image src={face} />
                            </div>
                            <div className={"manager-other"}>
                                Оплаты
                                <span>{this.state.transactions.totalElements}</span>
                            </div>
                        </div>
                        <div className={"manager-margin-33"}/>
                        <div className={"col-2 block relative"}>
                            <div className={"manager-icon"}>
                                <Image src={heart} />
                            </div>
                            <div className={"manager-other"}>
                                Столы
                                <span>{this.state.data.count}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </Wrapper>;
    }
})