import Wrapper from '../../components/Wrapper'

import * as React from "react";
import {useState, useEffect, useRef} from "react";
import api from "../../services/api";
import {useParams, useSearchParams, useNavigate} from 'react-router-dom';

const OrderDemo = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        let token = searchParams.get("token");
        let restaurant = searchParams.get("restaurant");
        api.generateDemoOrder(token, restaurant).then(response => {
                if(response.status === 200) {
                    console.log(JSON.stringify(response.data));
                    window.location.href = "https://eatandsplit.com/?u="+response.data.uuid+"&r="+restaurant;
                }
            });
    }, []);

    return <Wrapper navigate={navigate}></Wrapper>;
};

export default OrderDemo;

