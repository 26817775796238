import Wrapper from '../../components/Wrapper'

import * as React from "react";
import api from "../../services/api";
import withParams from "../../services/withParams";
import {FormCheck, Button} from "react-bootstrap";
import Paginator from "../../components/Paginator";
import "../../resources/styles/switch.css";
import "../../resources/styles/text-input.css"

export default withParams(class TelegramChatsList extends React.Component {
    state = {
        loading: true,
        loadingTelegram: true,
        data: [],
        telegramToken: null,
        telegramBot: null,
        enabled: false,
        waiterCallType: "NONE",
    };

    componentDidMount() {
        this.reload();

        api.getRestaurant().then(response => {
            if(response.status === 200) {
                this.setState({
                    telegramToken: response.data.telegramToken,
                    telegramBot: response.data.telegramBot,
                    waiterCallType: response.data.waiterCallType,
                    loadingTelegram: false,
                });
            }
        });

     }

    reload() {
        this.setState({
            loading: true
        });

        if(window.user.restaurantRole === "MANAGER")
        {
            api.getPageableTelegramChats({page: this.state.page, enabled: this.state.enabled}).then(response => {
                if(response.status === 200) {
                    this.setState({
                        data: response.data,
                        loading: false
                    });
                }
                else {
                    this.setState({
                        error: response.data,
                        loading: false
                    });
                }
            });
        }
    }

    deleteRow(id) {
       api.deleteTelegramChat(id).then(() => this.reload());
    }

    toggleEnabledRow(id, val) {
        api.setTelegramChatEnabled(id, val).then(() => this.reload());
    }

    toggleCanSeeAllRow(id, val) {
        api.setTelegramChatCanSeeAll(id, val).then(() => this.reload());
    }

    toggleCanSeeAllTipsRow(id, val) {
        api.setTelegramChatCanSeeAllTips(id, val).then(() => this.reload());
    }

    toggleCanSeeAllCallsRow(id, val) {
        api.setTelegramChatCanSeeAllCalls(id, val).then(() => this.reload());
    }

    changingName(id, val) {
        var o = this.state.data.content.find(a => a.id === id);
        if(o!=null)
        {
            if(o.changed === undefined)
            {
                o.changed = true;
            }
            o.realName = val;
            this.setState(this.state);
        }
    }

    saveName(id, val) {
        var o = this.state.data.content.find(a => a.id === id);
        if(o!=null && o.changed)
        {
            api.setTelegramChatName(id, val).then(() => this.reload());
        }
    }

    render() {
        return <Wrapper navigate={this.props.navigate}>
            {this.state.loadingTelegram && <p>Загрузка telegram-ссылки...</p>}
            {this.state.loading && window.user.restaurantRole === "MANAGER" && <p>Загрузка telegram-чатов...</p>}

            {!this.state.loadingTelegram && <label>
                {/* <div>Telegram уведомления об оплате счетов: <a className={"advanced"} href={"https://t.me/"+window.user.telegramBot+"?start=" + (window.user.restaurantRole === "MANAGER" || !(window.user.restaurant.telegramTipsRestrict!==undefined && window.user.restaurant.telegramTipsRestrict!==null && window.user.restaurant.telegramTipsRestrict) ? this.state.telegramToken : window.user.telegramToken)}>t.me/{window.user.telegramBot}</a></div> */}
                <div>Telegram уведомления об оплате счетов: <a className={"advanced"} href={"https://t.me/"+window.user.telegramBot+"?start=" + window.user.telegramToken}>t.me/{window.user.telegramBot}</a></div>
            </label>}

            {!this.state.loading && window.user.restaurantRole === "MANAGER" && <div className={"gray-wrapper gray-table"}>
                <div className={"table-search"}>
                    <div className={"table-checkbox"}>
                        <input type="checkbox"
                               checked={this.state.enabled}
                               onChange={e => {
                                   this.setState({ enabled: e.target.checked }, () => {
                                       this.reload();
                                   });
                               }} />
                        <span>Только включенные</span>
                    </div>
                </div>

                {!this.state.loading && this.state.data.content.length === 0 && <p className={"table-not-found"}>Привязанных telegram-чатов пока нет...</p>}

                {this.state.data.content.length > 0 && <div className={"gray-wrapper gray-table"}>
                    <div className={"table"}>
                        <div className={"thead"}>
                            <div className={"th"}>Имя</div>
                            <div className={"th"}>Username</div>
                            <div className={"th"}>Настоящее имя</div>
                            <div className={"th"}>Все чаевые</div>
                            <div className={"th"}>Все счета</div>
                            {this.state.waiterCallType !== "NONE" && <div className={"th"}>Все вызовы</div>}
                            <div className={"th"}>Вкл/выкл</div>
                            <div className={"th"}></div>
                        </div>

                        {this.state.data.content.map((item, i) => {
                            return <div className={"tbody"} key={item.id}>
                            <div className={"td"}>{item.firstName} {item.lastName}</div>
                            <div className={"td"}>{item.userName}</div>
                            <div className={"td"}>
                                {item.restaurantChat && <>
                                <input type="text" className="form-control" placeholder="Введите имя" value={item.realName} onChange={e => {this.changingName(item.id, e.target.value)}} /> {item.changed ? <Button variant="primary"  className="btn-primary" onClick={e => {this.saveName(item.id, item.realName)}}>Сохранить</Button> : null} </>
                                }
                                {!item.restaurantChat &&
                                <>{item.waiterName}</> 
                                }
                            </div>
                            <div className={"td break"}/>
                            <div className={"td row"}><span className='mobile'>Все чаевые</span><FormCheck disabled={item.restaurantChat} type="switch" id={`checksee${item.id}`} className="d-flex justify-content-center" checked={item.restaurantChat || item.canSeeAllTips} onChange={e => {this.toggleCanSeeAllTipsRow(item.id, e.target.checked)}}></FormCheck></div>
                            <div className={"td row"}><span className='mobile'>Все счета</span><FormCheck disabled={item.restaurantChat} type="switch" id={`checksee${item.id}`} className="d-flex justify-content-center" checked={item.restaurantChat || item.canSeeAllTransactions} onChange={e => {this.toggleCanSeeAllRow(item.id, e.target.checked)}}></FormCheck></div>
                            {this.state.waiterCallType !== "NONE" && <div className={"td row"}><span className='mobile'>Все вызовы</span><FormCheck disabled={item.restaurantChat} type="switch" id={`checksee${item.id}`} className="d-flex justify-content-center" checked={item.restaurantChat || item.canSeeAllCalls} onChange={e => {this.toggleCanSeeAllCallsRow(item.id, e.target.checked)}}></FormCheck></div> }
                            <div className={"td break"}/>
                            <div className={"td row"}><span className='mobile'>Вкл/выкл</span><FormCheck type="switch" id={`check${item.id}`} className="d-flex justify-content-center" checked={item.enabled} onChange={e => {this.toggleEnabledRow(item.id, e.target.checked)}}></FormCheck></div>
                            <div className={"td break"}/>
                            <div className={"td  end"}><Button variant="danger" className="btn-primary" onClick={e => {this.deleteRow(item.id)}}>Удалить</Button></div>
                            </div>
                        })}
                    </div>

                    <Paginator page={this.state.data} onChange={(handlePageChange) => {
                        this.setState({ page: handlePageChange }, () => {
                            this.reload();
                        });
                    }}/>
                </div>}
            </div>}
        </Wrapper>;
    }
});