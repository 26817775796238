import {useEffect, useState} from "react";
import * as React from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import {Button} from "react-bootstrap";
import api from "../../services/api";
import Wrapper from "../../components/Wrapper";

const Registers = (props) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [month, setMonth] = useState(moment(new Date(), 'YYYY-MM-DD'));
    const [switcherSettlements, setSwitcherSettlements] = useState(false);
    const [switcherSelling, setSwitcherSelling] = useState(false);

    const monthToDateFunc = (month) => {
        const date = moment(month).format('YYYY-MM-DD');
        return date;
    };

    useEffect(() => {
        if(switcherSettlements === false) {
            return;
        }

        setLoading(true);
        api.getBaseObjectAsBlob(`/registers/settlements?date=${monthToDateFunc(month)}`).then(response => {
            if(response.status === 200) {
                setLoading(false);
                const serverData = new Blob([response.data], { type: "application/vnd.ms-excel" });
                const blobUrl = URL.createObjectURL(serverData);

                const link = document.createElement("a");
                link.href = blobUrl;
                link.download = "Взаиморасчёты.xlsx";
                link.dispatchEvent(
                    new MouseEvent("click", {
                        bubbles: true,
                        cancelable: true,
                        view: window
                    })
                );
                setTimeout(() => {
                    window.URL.revokeObjectURL(blobUrl);
                    link.remove();
                }, 100);

                setSwitcherSettlements(false);
            } else {
                setError(response.data);
                setLoading(false);
                setSwitcherSettlements(false);
            }
        });
    }, [switcherSettlements]);

    useEffect(() => {
        if(switcherSelling === false) {
            return;
        }

        setLoading(true);
        api.getBaseObjectAsBlob(`/registers/selling?date=${monthToDateFunc(month)}`).then(response => {
            if(response.status === 200) {
                setLoading(false);
                const serverData = new Blob([response.data], { type: "application/vnd.ms-excel" });
                const blobUrl = URL.createObjectURL(serverData);

                const link = document.createElement("a");
                link.href = blobUrl;
                link.download = "Реализация.xlsx";
                link.dispatchEvent(
                    new MouseEvent("click", {
                        bubbles: true,
                        cancelable: true,
                        view: window
                    })
                );
                setTimeout(() => {
                    window.URL.revokeObjectURL(blobUrl);
                    link.remove();
                }, 100);

                setSwitcherSelling(false);
            } else {
                setError(response.data);
                setLoading(false);
                setSwitcherSelling(false);
            }
        });
    }, [switcherSelling]);

    return <>
        {loading && <span className={"title"}>Загрузка...</span>}
        {error && <span className={"title red"}>Ошибка: {error}</span>}

        {!loading && !error && <Wrapper navigate={props.navigate}>
            <Datetime
                value={month}
                locale={moment.locale("ru")}
                onChange={(val) => {
                    setMonth(val);
                }}
                closeOnSelect={ true }
                dateFormat="YYYY.MM.DD"
                timeFormat={false}
            />
            <div>
                <Button onClick={() => {
                    setSwitcherSettlements(true);
                }}>Взаиморасчёты</Button>
                <br/>
                <Button onClick={() => {
                    setSwitcherSelling(true);
                }}>Реализация</Button>
            </div>
        </Wrapper>}
    </>
}

export default Registers;
