import '../../resources/styles/reports.css';
import '../../resources/styles/styles.css';
import ReportTable from '../../components/ReportTable';
import ReportLine from '../../components/ReportLine';

const ReportProducts = ({loading, data, isAdmin}) => {
    return <>
            <div className={"order-title reports-title"}>Топ оплаченных блюд</div>
            <ReportTable columns={["Блюдо", "Количество заказов"]}>
            {data.map((item, i) => {
                return <ReportLine key={"wtips_"+i} keyPrefix={"r"+i+"_"} data={[ item.name, item.size]} />
            })}
            </ReportTable>
    </>;
};

export default ReportProducts;
