import Wrapper from '../../components/Wrapper'

import * as React from "react";
import api from "../../services/api";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import withParams from "../../services/withParams";

export default withParams(class TerminalAdd extends React.Component {


    constructor(props) {
        super(props);

        const { restaurant } = this.props.params;

        this.state = {
            restaurant: restaurant,
            serial: "",
            terminalType: "TEST",
            error: ""
        };
    }

    async submit(event) {
        event.preventDefault();

        let response = await api.addTerminal(this.state);

        if(response.status === 200) {
            this.props.navigate("/restaurants/" + this.state.restaurant + "/terminals/" + response.data.id);
        }
        else {
            this.setState({
                error: response.data
            });
        }
    }

    render() {
        return <Wrapper navigate={this.props.navigate}>
            <div style={{"fontSize": "1.25rem"}}>Добавление нового терминала</div>

            {this.state.error && <Alert variant='danger'>
                {this.state.error}
            </Alert>}

            <Form onSubmit={this.submit.bind(this)}>
                <Form.Group className="mb-3">
                    <Form.Label>Серийный номер</Form.Label>
                    <Form.Control type="text" onChange={e => this.setState({ serial: e.target.value })} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Тип</Form.Label>
                    <Form.Select onChange={e => this.setState({ terminalType: e.target.value })}>
                        <option value="TEST">Тест</option>
                        <option value="R-Keeper" disabled={true}>R-Keeper</option>
                        <option value="Iiko" disabled={true}>Iiko</option>
                    </Form.Select>
                </Form.Group>

                <Button variant="primary" type="submit">
                    Добавить
                </Button>
            </Form>

        </Wrapper>;
    }
})