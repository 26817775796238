import * as React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import Image from 'react-bootstrap/Image'
import api from '../services/api'
import {Link} from "react-router-dom";
import withParams from "../services/withParams";
import logo_panel from "../resources/images/logo_min.svg";
import logo_text from "../resources/images/logo-text.png";
import avatar from "../resources/images/avatar.png";


export default withParams(class Restore extends React.Component {
    state = {
        email: "",
        password: "",
        error: ""
    };

    constructor(props) {
        super(props);
        const [searchParams] = props.search;

        this.state = {
            email: "",
            password: "",
            error: "",
            token: searchParams.get("token")
        }
    }


    async submitToken(event) {
        event.preventDefault();

        this.setState({
            loading: true
        });

        let result = await api.restoreToken(this.state.token, this.state.password, this.state.newPassword);

        if(result.status === 200) {
            localStorage.setItem('Authorization', result.data.token);
            window.location.href = '/';
        }
        else {
            this.setState({
                error: result.data,
                loading: false
            });
        }
    }

    async submitEmail(event) {
        event.preventDefault();

        this.setState({
            loading: true
        });

        let result = await api.restoreEmail(this.state.email);

        if(result.status === 200) {
            this.setState({
                info: "Письмо с восстановлением пароля было отправлено на почту!",
                loading: false
            });
        }
        else {
            this.setState({
                error: result.data,
                loading: false
            });
        }
    }

    render() {
        return <Container style={{maxWidth: 600}}>
            <Row className="align-items-center" style={{"minHeight" :"100%"}}>
                <Col md>

                    <div className={"header"}>
                        <div className={"row middle"}>
                            <Image src={logo_panel} className={"header-logo"} />
                            Восстановление пароля
                        </div>
                    </div>

                    {this.state.error && <Alert variant='danger'>
                        {this.state.error}
                    </Alert>}

                    {this.state.info && <Alert variant='info'>
                        {this.state.info}
                    </Alert>}

                    {!this.state.info && !this.state.token && <Form onSubmit={this.submitEmail.bind(this)} className={"form form-wrapper"}>
                        <label>
                            Ваша почта
                            <input type={"email"} onChange={e => this.setState({ email: e.target.value })} />
                        </label>

                        <div className={"flex"} style={{marginBottom: 30, flexDirection: "column"}}>
                            <Button
                                disabled={this.state.email.indexOf("@") < 0 || this.state.loading}
                                variant="primary"
                                type="submit"
                                style={{minHeight: 55, marginBottom: 13}}
                            >
                                Отправить запрос
                            </Button>
                            <Link to={"/login"} className={"btn btn-outline-secondary"}>Назад</Link>
                        </div>
                    </Form>}

                    {!this.state.info && this.state.token && <Form onSubmit={this.submitToken.bind(this)} className={"form form-wrapper"}>
                        <label>
                            Новый пароль (не менее 8 символов)
                            <input type={"password"} required={true} onChange={e => this.setState({ password: e.target.value })} />
                        </label>

                        <label>
                            Новый пароль еще раз
                            <input type={"password"} required={true} onChange={e => this.setState({ newPassword: e.target.value })} />
                        </label>

                        <div className={"flex"} style={{marginBottom: 30}}>
                            <Button disabled={this.state.password.length < 8 || this.state.loading} variant="primary" type="submit" style={{float: 'right'}}>
                                Задать новый пароль
                            </Button>
                        </div>
                        <div className={"lic"}>
                            Нажимая кнопку «задать новый пароль», вы соглашаетесь с
                            <br/>
                            <a href={"/lic1.html"} target={"_blank"}>пользовательским соглашением</a> и &nbsp;
                            <a href={"/lic2.html"} target={"_blank"}>политикой конфиденциальности</a>
                        </div>
                    </Form>}
                </Col>
            </Row>
        </Container>;
    }
});