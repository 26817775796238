import axios, {CancelToken} from "axios";
import moment from "moment";
import { saveAs } from 'file-saver';

console.log(process.env);

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json'
    },
    transformRequest: [function (data, headers) {
        // Do whatever you want to transform the data
        if(headers['Content-Type'] && headers['Content-Type'] === "multipart/form-data") {
            return data;
        }

        return JSON.stringify(data);
    }],
});

const instanceForBlob = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
    responseType: "blob",
});

instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem('Authorization');
    config.headers.Authorization =  token ? `Bearer ${token}` : '';
    return config;
});

instanceForBlob.interceptors.request.use(function (config) {
    const token = localStorage.getItem('Authorization');
    config.headers.Authorization =  token ? `Bearer ${token}` : '';
    return config;
});

class ApiService {
    async request(req) {
        return new Promise((resolve, reject) => {
            req.then(value => {
                resolve({
                    status: value.status,
                    data: value.data,
                    headers: []
                });
            });

            req.catch(error => {
                resolve({
                    status: error.response ? error.response.status: 500,
                    data: error.response ? error.response.data.message : null,
                    json: error.response ? error.response.data : {}
                });
            });
        });
    }

    async login(data) {
        return this.request(instance.post('login', data));
    }

    async registration(data) {
        return this.request(instance.post('registration', data));
    }

    async logout() {
        return this.request(instance.post('logout'));
    }

    async getUserInfo() {
        return this.request(instance.get('users/info'));
    }

    async getPageableRestaurants(params) {
        let searchParams = new URLSearchParams();
        searchParams.set("sort", "ordr,title,address");
        searchParams.set("page", params.page);
        searchParams.set("enabled", params.enabled ?? false);
        searchParams.set("searchText", params.searchText);

        return this.request(instance.get('restaurants?' + searchParams.toString()));
    }

    async getPageableTerminals(params) {
        let searchParams = new URLSearchParams();
        searchParams.set("sort", "id,desc");
        searchParams.set("page", params.page);
        searchParams.set("online", params.online);
        searchParams.set("offline", params.offline);

        return this.request(instance.get('terminals?' + searchParams.toString()));
    }

    async addTerminal(data) {
        return this.request(instance.post('terminals/add', data));
    }

    async getTerminal(id) {
        return this.request(instance.get('terminals/' + id));
    }

    async addRestaurant(data) {
        return this.request(instance.post('restaurants/add', data));
    }

    async editRestaurant(data) {
        let formData = new FormData();

        for(let key in data) {
            formData.append(key, data[key]);
        }

        formData.delete("avatarBg");
        formData.delete("backgroundBg");

        return this.request(instance.post('restaurants/current', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }));
    }

    async getRestaurant() {
        return this.request(instance.get('restaurants/current'));
    }

    async addSurcharge(total) {
        return this.request(instance.post(`orders/surcharge?total=${total}`));
    }

    async getPageableOrders(params) {
        let searchParams = new URLSearchParams();
        searchParams.set("sort", "id,desc");
        searchParams.set("page", params.page ?? 0);
        searchParams.set("date", moment(params.date ?? new Date()).format("YYYY-MM-DD"));
        searchParams.set("visited", params.visited ?? true);

        return this.request(instance.get('orders?' + searchParams.toString()));
    }

    async getOrder(id) {
        return this.request(instance.get('orders/' + id));
    }

    async getPageableTransactions(params) {
        let searchParams = new URLSearchParams();
        searchParams.set("sort", "id,desc");
        searchParams.set("page", params.page ?? 0);
        searchParams.set("date", moment(params.date ?? new Date()).format("YYYY-MM-DD"));
        searchParams.set("paid", params.paid ?? true);
        // searchParams.set("tips", params.tips ?? true);
        // searchParams.set("products", params.products ?? true);
        searchParams.set("size", params.size ?? 20);
        searchParams.set("lastSession", params.lastSession ?? false);

        if(params.tips !== null && params.tips !== undefined) {
            searchParams.set("tips", params.tips ?? false);
        }

        if(params.products !== null && params.products !== undefined) {
            searchParams.set("products", params.products ?? false);
        }

        return this.request(instance.get('payments?' + searchParams.toString()));
    }

    async getPageableTips(params) {
        let searchParams = new URLSearchParams();
        searchParams.set("sort", "id,desc");
        searchParams.set("page", params.page ?? 0);
        searchParams.set("fromDate", moment(params.from ?? new Date()).format("YYYY-MM-DD"));
        searchParams.set("toDate", moment(params.to ?? new Date()).format("YYYY-MM-DD"));
        searchParams.set("paid", params.paid ?? true);
        searchParams.set("size", params.size ?? 20);
        searchParams.set("tips", params.tips ?? false);
        searchParams.set("myTips", params.myTips ?? false);

        return this.request(instance.get('tips?' + searchParams.toString()));
    }

    async getTransactionsByOrder(order) {
        return this.request(instance.get('payments/order/' + order));
    }

    getPaymentsByOrder(order) {
        return this.request(instance.get('payments/order/' + order + '/all'));
    }

    async getPageableComments(params) {
        let searchParams = new URLSearchParams();
        searchParams.set("sort", "id,desc");
        searchParams.set("page", params.page ?? 0);
        //searchParams.set("date", params.date ?? moment().format("YYYY-MM-DD"));
        searchParams.set("badly", params.badly ?? false);
        searchParams.set("fromDate", moment(params.from ?? new Date()).format("YYYY-MM-DD"));
        searchParams.set("toDate", moment(params.to ?? new Date()).format("YYYY-MM-DD"));
        searchParams.set("commented", params.commented ?? false);
        searchParams.set("myTips", params.myTips ?? false);

        return this.request(instance.get('comments?' + searchParams.toString()));
    }

    async getCommentsByOrder(order) {
        return this.request(instance.get('comments/order/' + order));
    }


    async getPageableTipsOut(params) {
        let searchParams = new URLSearchParams();
        searchParams.set("sort", "id,desc");
        searchParams.set("page", params.page ?? 0);
        searchParams.set("size", params.size ?? 20);

        return this.request(instance.get('tips/out?' + searchParams.toString()));
    }

    async getPageableEmployees(params) {
        let searchParams = new URLSearchParams();
        searchParams.set("sort", "id,desc");
        searchParams.set("page", params.page ?? 0);
        searchParams.set("registered", params.registered ?? 10);
        searchParams.set("enabled", params.enabled ?? false);
        searchParams.set("searchText", params.searchText ?? '');
        if(params.status!=null)
        {
            searchParams.set("status", params.status);
        }

        return this.request(instance.get('employees?' + searchParams.toString()));
    }

    async getEmployee(id) {
        return this.request(instance.get(`employees/${id}`));
    }

    async getRestaurantTables(params) {
        let searchParams = new URLSearchParams();
        searchParams.set("sort", "id,desc");
        searchParams.set("enabled", params.enabled ?? false);
        searchParams.set("onlyUsed", params.onlyUsed ?? false);

        return this.request(instance.get('restaurants/tables?' + searchParams.toString()));
    }

    getRestaurantTable(id) {
        return this.request(instance.get('restaurants/tables/' + id));
    }

    async requestTipsOut(data) {
        return this.request(instance.post(`tips/pay`, data));
    }

    async getReports(from, to, allRestaurants) {
        return this.request(instance.get(`reports?from=${moment(from).format("YYYY-MM-DD")}&to=${moment(to).format("YYYY-MM-DD")}&allRestaurants=${allRestaurants ? "1":"0"}`));
    }

    async getAccounting(params) {
        let searchParams = new URLSearchParams();
        searchParams.set("sort", "id,desc");
        searchParams.set("page", params.page ?? 0);
        searchParams.set("allRestaurants", params.allRestaurants ?? false);

        return this.request(instance.get('reports/accounting?' + searchParams.toString()));
    }

    async getDayAccounting(date) {
        return this.request(instance.get(`reports/accounting/day?date=${date}`));
    }

    async createNewReport(from, to) {
        return this.request(instance.post(`reports/accounting?from=${from.format("YYYY-MM-DD")}&to=${to.format("YYYY-MM-DD")}`));
    }

    async getAccountingById(id) {
        return this.request(instance.get(`reports/accounting/${id}`));
    }

    async getAccountingFileUrl(data) {
        return new Promise((resolve, reject) => {

            instance.get(`reports/accounting/${data.id}/file`, {responseType: 'blob'}).then((response) => {
                const fileName = `${window.user.restaurant.title}-report-${data.from}-${data.to}.xlsx`;
                resolve();
                // Let the user save the file.
                saveAs(response.data, fileName);
            }).catch((response) => {
                reject(response);
            });
        });
    }

    async getStats(from, to) {
        return this.request(instance.get(`stats?from=${moment(from).format("YYYY-MM-DD")}&to=${moment(to).format("YYYY-MM-DD")}`));
    }

    async getStatsLastSession() {
        return this.request(instance.get(`stats/lastSession`));
    }
    async getTipsAmount() {
        return this.request(instance.get(`tips/amount`));
    }

    async getMyTipsAmount() {
        return this.request(instance.get(`tips/amount/my`));
    }

    async restoreToken(token, password, newPassword) {
        return this.request(instance.post(`restore/token`, {
            token, password, newPassword
        }));
    }

    async restoreEmail(email) {
        return this.request(instance.post(`restore/email`, {
            email
        }));
    }

    async selectRestaurant(id) {
        return this.request(instance.post(`login/restaurant/${id}`));
    }

    async closeOrder(id) {
        return this.request(instance.post(`orders/${id}/close`));
    }

    async openOrder(id) {
        return this.request(instance.post(`orders/${id}/open`));
    }

    async copyOrder(id) {
        return this.request(instance.post(`orders/${id}/copy`));
    }

    async getPageablePackages(params) {
        let searchParams = new URLSearchParams();
        searchParams.set("sort", "id,desc");
        searchParams.set("page", params.page);

        if(params.restaurantId != null) {
            searchParams.set("restaurantId", params.restaurantId);
        }

        return this.request(instance.get('integrations/updater/packages?' + searchParams.toString()));
    }

    async getPageableLogging(params) {
        let searchParams = new URLSearchParams();
        searchParams.set("sort", "id,desc");
        searchParams.set("page", params.page);

        return this.request(instance.get('logging?' + searchParams.toString()));
    }

    async createPackage(data) {
        let formData = new FormData();

        for(let key in data) {
            formData.append(key, data[key]);
        }

        return this.request(instance.post('integrations/updater/packages', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }));
    }

    async getRestaurantProducts(id) {
        return this.request(instance.get('prodcats/products?id=' + id));
    }

    async getRestaurantCategories(id) {
        return this.request(instance.get('prodcats/categories?id=' + id));
    }

    async getProduct(id) {
        return this.request(instance.get(`prodcats/products/${id}`));
    }

    async getCategory(id) {
        return this.request(instance.get(`prodcats/categories/${id}`));
    }

    async editProduct(data) {
        return this.request(instance.post(`prodcats/products/${data.id}`, data));
    }

    async editCategory(data) {
        return this.request(instance.post(`prodcats/categories/${data.id}`, data));
    }



    async addTestOrder(waiterId, tableId) {
        return this.request(instance.post(`orders/test/${waiterId}/${tableId}`));
    }

    async payTransaction(id, pass) {
        return this.request(instance.post(`payments/${id}/pay?password=${pass}`));
    }

    async getPageableRKeeperOrders(params) {
        let searchParams = new URLSearchParams();
        searchParams.set("sort", "id,desc");
        searchParams.set("page", params.page);

        return this.request(instance.get('advanced/rkeeper/admin?' + searchParams.toString()));
    }

    async createRKeeperOrder() {
        return this.request(instance.post(`advanced/rkeeper/admin/create`));
    }

    async updateRKeeperOrder(id) {
        return this.request(instance.post(`advanced/rkeeper/admin/update`, {id: id}));
    }

    async closeRKeeperOrder(id) {
        return this.request(instance.post(`advanced/rkeeper/admin/close`, {id: id}));
    }

    getRKeeperOrder(id) {
        return this.request(instance.get('advanced/rkeeper/admin/order?id=' + id));
    }

    async getWaiterStatistics(from, to) {
        return this.request(instance.get(`statistics?from=${moment(from).format("YYYY-MM-DD")}&to=${moment(to).format("YYYY-MM-DD")}`));
    }

    async getWaiterTipsTable(from, to, page, size) {
        let searchParams = new URLSearchParams();
        searchParams.set("page", page ?? 0);
        searchParams.set("size", size ?? 20);
        searchParams.set("from", moment(from).format("YYYY-MM-DD"));
        searchParams.set("to", moment(to).format("YYYY-MM-DD"));
        return this.request(instance.get('statistics-tips?' + searchParams.toString()));
    }

    async getPageableTelegramChats(params) {
        let searchParams = new URLSearchParams();
        searchParams.set("sort", "id,desc");
        searchParams.set("page", params.page ?? 0);
        searchParams.set("enabled", params.enabled ?? false);

        return this.request(instance.get('telegram?' + searchParams.toString()));
    }

    async setTelegramChatEnabled(id, enable) {
        return this.request(instance.post('telegram/enable', {id: id, enabled: enable }));
    }

    async setTelegramChatCanSeeAll(id, enable) {
        return this.request(instance.post('telegram/seeall', {id: id, canSeeAllTransactions: enable }));
    }

    async setTelegramChatName(id, name) {
        return this.request(instance.post('telegram/setname', {id: id, name: name }));
    }
    async deleteTelegramChat(id) {
        return this.request(instance.post('telegram/delete', {id: id }));
    }

    async getEmailsReport() {
        return this.request(instance.get(`emails-report`));
    }

    async getErrorsByOrder(order) {
        return this.request(instance.get('orders/' + order + '/errors'));
    }

    async getTipsWish() {
        return this.request(instance.get(`tipswish`));
    }

    async setTipsWish(tipsName, tipsWish, defaultTipsName, defaultTipsWish, overrideTipsName, overrideTipsWish) {
        return this.request(instance.post('tipswish/save', {name: tipsName, wish: tipsWish, defaultTipsName: defaultTipsName, defaultTipsWish: defaultTipsWish, overrideTipsName: overrideTipsName, overrideTipsWish: overrideTipsWish }));
    }

    async setUserAvatar(data) {
        let formData = new FormData();

        for(let key in data) {
            formData.append(key, data[key]);
        }

        formData.delete("avatarBg");

        return this.request(instance.post('tipswish/saveAvatar', formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }));
    }



    async removeEmployeeMail(data) {
        return this.request(instance.post(`employees/remove-mail/${data.id}`, {id: data.id }));
    }
    async setTelegramChatCanSeeAllTips(id, enable) {
        return this.request(instance.post('telegram/seealltips', {id: id, canSeeAllTips: enable }));
    }

    async setTelegramChatCanSeeAllCalls(id, enable) {
        return this.request(instance.post('telegram/seeallcalls', {id: id, canSeeAllCalls: enable }));
    }

    async getPageableTeams(params) {
        let searchParams = new URLSearchParams();
        searchParams.set("sort", "id,desc");
        searchParams.set("page", params.page ?? 0);

        return this.request(instance.get('teams?' + searchParams.toString()));
    }

    async getTeam(id) {
        return this.request(instance.get(`teams/${id}`));
    }

    async editTeam(data) {
        return this.request(instance.post(`teams/${data.id}`, data));
    }

    async deleteTeam(data) {
        return this.request(instance.post(`teams/delete`, {id: data.id }));
    }

    async editTeamSharing(data) {
        return this.request(instance.post(`teams/sharing/${data.id}`, data));
    }

    async getUsers4Team(id) {
        return this.request(instance.get(`teams/users4team/${id}`));
    }

    async getBaseObject(uri) {
        return this.request(instance.get(uri));
    }

    async getBaseObjectAsBlob(uri) {
        return this.request(instanceForBlob.get(uri));
    }

    async setBaseObject(uri, data, sendAsForm = true) {
        if(sendAsForm)
        {
            let formData = new FormData();

            for(let key in data) {
                formData.append(key, data[key]);
            }
    
            return this.request(instance.post(uri, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }));
        }
        return this.request(instance.post(uri, data));
    }

    setTerminalRestart(id, need) {
        return this.request(instance.get('terminals/' + id + '/restart?need=' + need));
    }

    changeTerminalEnabled(id) {
        return this.request(instance.post(`terminals/${id}/change-enabled`));
    };

    addOrderProduct(id, total, goodsType, name) {
        return this.request(instance.post(`orders/${id}/product?total=${total}&type=${goodsType}&name=${name}`));
    }
    getAvatarUrl(iconUri) {
        return process.env.REACT_APP_API_URL+"tipswish/getavatar/"+iconUri;
    }

    async getTokenFromLink(shortLink) {
        return this.request(instance.post('sl', {link: shortLink ?? 'notvalidshortlink'}));
    }

    refund(id, refundGoods, refundTips, refundService, refundServiceSbp) {
        return this.request(instance.post(`payments/${id}/refund`, {
            goodsValues: refundGoods,
            tips: refundTips,
            service: refundService,
            serviceSBP: refundServiceSbp
        }));
    }

    refundBonuses(transactionId) {
        return this.request(instance.post(`payments/${transactionId}/refund-bonuses`));
    }

    getRefundsByOrder(order) {
        return this.request(instance.get('payments/order/' + order + '/refunds'));
    }

    getBaseUrl() {
        return process.env.REACT_APP_API_URL;
    }

    generateDemoOrder(token, restaurant) {
        return this.request(instance.post(`orders/demo-order/${token}/${restaurant}`, {}));
    }

    getRestBrandingUrl(iconUri) {
        if(iconUri.startsWith('/images'))
        {
            console.log("https://eatandsplit.com"+iconUri);
            return "https://eatandsplit.com"+iconUri;
        }
        return process.env.REACT_APP_API_URL+"restaurants/"+iconUri;
    }

    getMenuImageUrl(iconUri) {
        return process.env.REACT_APP_API_URL+"menu/product-image/"+iconUri;
    }

    async editAgentRestaurant(data) {
        let formData = new FormData();
        
        for(let key in data) {
            formData.append(key, data[key]);
        }
        
        formData.delete("avatarBg");
        formData.delete("backgroundBg");
        
        return this.request(instance.post('restaurants/agent', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }));
    }

    async getAgentStats(from, to) {
        return this.request(instance.get(`agent/stats?from=${from.format("YYYY-MM-DD")}&to=${to.format("YYYY-MM-DD")}`));
    }
    
    async getPageableAgents(params) {
        let searchParams = new URLSearchParams();
        searchParams.set("sort", "id,desc");
        searchParams.set("page", params.page);
        
        return this.request(instance.get('agents?' + searchParams.toString()));
    }
        
    async addAgent(data) {
        return this.request(instance.post('agents/add', data));
    }
        
    async getAgent(id) {
        return this.request(instance.get(`agents/${id}`));
    }
        
    async editAgent(data) {
        return this.request(instance.post(`agents/${data.id}`, data));
    }        

    async getMainReport(from, to, allRestaurants) {
        return this.request(instance.get(`main-report?from=${moment(from).format("YYYY-MM-DD")}&to=${moment(to).format("YYYY-MM-DD")}&allRestaurants=${allRestaurants ? "1":"0"}`));
    }
    async getTipsReport(from, to, allRestaurants) {
        return this.request(instance.get(`tips-report?from=${moment(from).format("YYYY-MM-DD")}&to=${moment(to).format("YYYY-MM-DD")}&allRestaurants=${allRestaurants ? "1":"0"}`));
    }
    async getLikesReport(from, to, allRestaurants) {
        return this.request(instance.get(`likes-report?from=${moment(from).format("YYYY-MM-DD")}&to=${moment(to).format("YYYY-MM-DD")}&allRestaurants=${allRestaurants ? "1":"0"}`));
    }
    async getProductsReport(from, to, allRestaurants) {
        return this.request(instance.get(`products-report?from=${moment(from).format("YYYY-MM-DD")}&to=${moment(to).format("YYYY-MM-DD")}&allRestaurants=${allRestaurants ? "1":"0"}`));
    }

    async getPageablePrepays(params) {
        let searchParams = new URLSearchParams();
        searchParams.set("sort", "id,desc");
        searchParams.set("page", params.page ?? 0);
        searchParams.set("from", moment(params.dateFrom ?? new Date()).format("YYYY-MM-DD"));
        searchParams.set("to", moment(params.dateTo ?? new Date()).format("YYYY-MM-DD"));
        searchParams.set("text", params.searchText ?? "");

        return this.request(instance.get('prepay?' + searchParams.toString()));
    }

    async getRestaurantTables4Prepay() {
        return this.request(instance.get('prepay/tables'));
    }


}

let api = new ApiService();

export default api;