import Wrapper from '../../components/Wrapper'
import {useState, useEffect, Fragment} from "react";
import {useSearchParams} from 'react-router-dom';
import api from "../../services/api";
import {Button, Image} from "react-bootstrap";
import Moment from "react-moment";
import moment from "moment";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import {Link} from "react-router-dom";
import prepayPaid from "../../resources/images/prepay-paid.svg";
import prepayFailed from "../../resources/images/prepay-failed.svg";
import Paginator from "../../components/Paginator";
import CurrencyFormat from "react-currency-format";

const PrepayList = (props) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [error, setError] = useState('');
    const [searchText, setSearchText] = useState('');
    const [page, setPage] = useState(searchParams.get('page') || 0);

    const [dateFrom, setDateFrom] = useState(moment(searchParams.get('date') ?? new Date(), 'YYYY-MM-DD'));
    const [dateTo, setDateTo] = useState(moment(searchParams.get('date') ?? new Date(), 'YYYY-MM-DD'));

    const isTest = window.user!=null && window.user.restaurant!=null && ((window.user.restaurant.title === "Демо" || window.user.restaurant.title === "Тест" || window.user.restaurant.title.startsWith('Демо ')) && !(window.user.restaurantRole === "WAITER"));
    const isAdmin = (window.user !==undefined && window.user.role === "ADMIN");
    const isSupport = (window.user !==undefined && window.user.role === "SUPPORT");

    useEffect(() => { reload();}, [dateFrom, dateTo, searchText, page]);

    const reload = () => {
        setLoading(true);

        setSearchParams({page: page, searchText: searchText, dateFrom: moment(dateFrom).format("YYYY-MM-DD"), dateTo: moment(dateTo).format("YYYY-MM-DD")});

        api.getPageablePrepays({page: page, searchText: searchText, dateFrom: dateFrom, dateTo: dateTo}).then(response => {
            if(response.status === 200) {
                setData(response.data);
                setError('');
            } else {
                setError(response.data);
            }
            setLoading(false);
        });
        setLoading(false);
    }

        return <Wrapper navigate={props.navigate}>
            <span className={"title"}>Создать ссылку на предоплату</span>
            <div style={{width: 341, marginTop: 32, marginBottom: 60}}>
                <Link to={"/prepay"}>
                    <Button>Создать</Button>
                </Link>
            </div>
            {!loading && <div style={{marginBottom: 32}}><span className={"title"}>История операций</span></div>}

            {loading && <span className={"title"}>Загрузка...</span>}
            {error && <span className={"title red"}>Ошибка: {error}</span>}

            {!loading && <div className={"gray-wrapper gray-table"}>
                <div className={"table-search"}>
                    <Datetime value={dateFrom}
                              locale={moment.locale("ru")}
                              onChange={(val) => { setDateFrom(val); }}
                              closeOnSelect={ true }
                              className='prepay-list-date'
                              dateFormat="DD.MM.YY" timeFormat={false} />
                    <Datetime value={dateTo}
                              locale={moment.locale("ru")}
                              onChange={(val) => { setDateTo(val); }}
                              closeOnSelect={ true }
                              className='prepay-list-date'
                              dateFormat="DD.MM.YY" timeFormat={false} />
                    <input type="text" placeholder='Поиск'
                               value={searchText}
                               autoFocus
                               className='prepay-list-search'
                               onChange={e => {
                                setSearchText(e.target.value);
                               }} style={{width: "auto"}} />

                </div>

                {!loading && (data === null || data.content.length === 0) && <p className={"table-not-found"}>Предоплат пока нет...</p>}

                {data !== null && data.content.length > 0 && <div className={"col"}>
                    <div className={"table"}>
                        <div className={"thead"}>
                            <div className={"th"}>Дата</div>
                            <div className={"th"}>Гость</div>
                            <div className={"th break"}/>
                            <div className={"th"}>Стол</div>
                            <div className={"th"}>Сумма</div>
                            <div className={"th"}>Статус</div>
                        </div>

                        {data.content.map((item, i) => {
                            return <Fragment key={"tkk"+i}>
                                <Link className={"tbody"} key={item.id} to={"/prepay/" + item.id}>
                                    <div className={"td col-2"}><Moment format="DD.MM.YY&nbsp;&nbsp;&nbsp;HH:mm">{item.reserveDate}</Moment></div>
                                    <div className={"td col-2 end xs-visible-bold"}>{item.name}</div>
                                    <div className={"td break"}/>
                                    <div className={"td"}><div className={"xs-visible"}>Стол</div>&nbsp;{item.tableName}</div>
                                    <div className={"td"}><div className={"xs-visible"}>Сумма</div>&nbsp;<CurrencyFormat value={Math.floor(item.total)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} /></div>
                                    <div className={"td"}><div className={"xs-visible"}></div>
                                    {item.status === "Created" && <div>Ожидает оплаты</div>}
                                    {(item.status === "Paid" || item.status === "PaidAndOrderCreated") && <Image src={prepayPaid} />}
                                    {(item.status === "Epired" || item.status === "Canceled") && <Image src={prepayFailed} />}
                                    </div>
                                </Link>
                            </Fragment>
                        })}
                    </div>

                    <Paginator page={data} onChange={(handlePageChange) => {
                        setPage(handlePageChange);
                    }}/>
                </div>}
            </div>}
        </Wrapper>;
};

export default PrepayList;
