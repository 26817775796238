import Datetime from "react-datetime";
import moment from "moment";

const DateRangeFilter = ({from, to, onChange, className, children}) => {

    const rangeChanged = (f, t) => {
        onChange(f, t);
    }

    return <div className={"table-search " + (className ?? "")}>
    <Datetime value={from}
              locale={moment.locale()}
              onChange={(val) => { rangeChanged(val, to); }}
              closeOnSelect={ true }
              dateFormat="DD.MM.YYYY" timeFormat={false} />
    <Datetime value={to}
              locale={moment.locale()}
              onChange={(val) => { rangeChanged(from, val); }}
              closeOnSelect={ true }
              dateFormat="DD.MM.YYYY" timeFormat={false} />
              {children}
    </div>
}

export default DateRangeFilter;

