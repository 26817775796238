import * as React from "react";
import {Link} from "react-router-dom";
import Button from "react-bootstrap/Button";

const TeamButtonsNav = (props) => {


    return <div className={"row mobile"} style={{marginTop: 20, marginRight: 20, marginLeft: 20}}>
                {!props.addSharing && <>
                <Link to={"/employees"} className={"flex"}><Button style={{marginBottom: 5}}>Сотрудники</Button></Link>
                &nbsp;
                <Link to={"/employees/teams"} className={"flex"}><Button style={{marginBottom: 5}}>Команды</Button></Link>
                &nbsp;
                <Link to={"/employees/teams/sharing"} className={"flex"}><Button style={{marginBottom: 5}}>Распределение чаевых</Button></Link>
                </>}
                {props.addSharing && <>
                    <Link to={"/employees/teams"} className={"flex"}><Button style={{marginBottom: 5}}>Список команд</Button></Link>
                    &nbsp;
                    <Link to={"/employees/teams/edit?id="+props.teamId} className={"flex"}><Button style={{marginBottom: 5}}>Команда</Button></Link>
                    &nbsp;
                    <Link to={"/employees/teams/sharing"} className={"flex"}><Button style={{marginBottom: 5}}>Распределение чаевых</Button></Link>
                </>}
            </div>;
};

export default TeamButtonsNav;
