import Wrapper from '../../components/Wrapper'

import * as React from "react";
import {useState, useEffect} from "react";
import {useParams, useSearchParams, useNavigate} from 'react-router-dom';
import Form from "react-bootstrap/Form";
import api from "../../services/api";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

const RestaurantAdd = (props) => {

    const [title, setTitle] = useState('');
    const [address, setAddress] = useState('');

    const [error, setError] = useState('');

    const addRest = () => {
        if(title == '' || address == '')
        {
            setError('Не заполнено поле "'+(title == '' ? 'Название' : 'Адрес')+'"');
        } else {
            api.setBaseObject('restaurants/add', {title: title, address: address}).then(
                response => {
                    if(response.status === 200) {
                        api.selectRestaurant(response.data.id).then(
                            res => {
                                if(res.status === 200)
                                {
                                    window.user.restaurant = res.data;
                                    props.navigate('/restaurant');
                                } else {
                                    setError(res.data);
                                }
                            }
                        )
                    } else {
                        setError(response.data);
                    }
                }
            )
        }
    };
    return <Wrapper navigate={props.navigate}>
        {error && <Alert variant='danger'>
            {error}
        </Alert>}

        <Form className={"form form-wrapper"}>
            <label>
                Название
                <input type={"text"} value={title} onChange={e => setTitle(e.target.value)} />
            </label>
            <label>
                Адрес
                <input type={"text"} value={address} onChange={e => setAddress(e.target.value)} />
            </label>

            <div className={"flex"}>
                {window.user.restaurantRole === "MANAGER" && <Button onClick={addRest}>
                    Добавить
                </Button>}
            </div>
        </Form>

    </Wrapper>;
}

export default RestaurantAdd;
