import Wrapper from '../../components/Wrapper'
import {useState, useEffect} from "react";
import api from "../../services/api";
import {Button} from "react-bootstrap";
import '../../resources/styles/styles.css';
import closeImage from '../../resources/images/close.svg';
import pencilimage from '../../resources/images/pencil.svg';
import fruitImage from '../../resources/images/avatar.png';
import { Link } from 'react-router-dom';
import {FormCheck} from "react-bootstrap";

const TipsWish = (props) => {

    const [tipsData, setTipsData] = useState({tipsName: "", tipsWish: "", avatarUrl: "", defaultTipsName: "", defaultTipsWish: "", overrideTipsName: false, overrideTipsWish: false, loading: true, changed: false});
    const [avatarImage, setAvatarImage] = useState(null);
    const [avatarChanged, setAvatarChanged] = useState(false);
    const [avatarImageData, setAvatarImageData] = useState(null);
    // const [avatarUploading, setAvatarUploading] = useState(false);

    useEffect(() => {
        api.getTipsWish().then(response => {
            if(response.status === 200) {
                setTipsData({tipsName: response.data.tipsName ?? "", tipsWish: response.data.tipsWish ?? "", defaultTipsName: response.data.defaultTipsName ?? "", defaultTipsWish: response.data.defaultTipsWish ?? "", overrideTipsName: response.data.overrideTipsName ?? false, overrideTipsWish: response.data.overrideTipsWish ?? false, loading: false, changed: false, avatarUrl: response.data.avatarFile ?? ""});
            }
        });
    }, []);

    let saveData = () => {
        setTipsData({...tipsData, loading: true});
        if(tipsData.changed)
        {
            api.setTipsWish(tipsData.tipsName, tipsData.tipsWish, tipsData.defaultTipsName, tipsData.defaultTipsWish, tipsData.overrideTipsName, tipsData.overrideTipsWish).then(
                setTipsData({...tipsData, loading: false, changed: false})
            );
        }
        if(avatarChanged)
        {
            // setAvatarUploading(true);
            api.setUserAvatar(avatarImage).then(() => {
                setAvatarChanged(false);
                setTipsData({...tipsData, loading: false});
                // setAvatarUploading(false);
            })
        }
    };

    let setName = (e) => {
        let newData = {tipsName: e.target.value};
        if(window.user.restaurantRole === "MANAGER")
        {
            newData.defaultTipsName = e.target.value;
        }
        setTipsData({...tipsData, ...newData, changed: true});
    };

    let setWish = (e) => {
        let newData = {tipsWish: e.target.value};
        if(window.user.restaurantRole === "MANAGER")
        {
            newData.defaultTipsWish = e.target.value;
        }
        setTipsData({...tipsData, ...newData, changed: true});
    };

    const onAvatarChange = (ev) => {
        let reader = new FileReader();
        reader.readAsDataURL(ev.target.files[0]);
        reader.onloadend = (e) => {
            let img = new window.Image();

            img.onload = () => {
                setAvatarChanged(true);
                setAvatarImage({
                    avatar: ev.target.files[0],
                    avatarBg: [reader.result]
                });
                setAvatarImageData(reader.result);
            };

            img.src = reader.result;
        }
    }

    return <Wrapper navigate={props.navigate}>
        {tipsData.loading && <span className={"title"}>Загрузка...</span>}
        {!tipsData.loading &&
        <>
    <div className='desktop-modal'>
        <div className='tipswish-modal-content'>
            <Link to='/' className='tipswish-header-back'>
                <span className='tipswish-header-back-text'>На главную</span>
                <img src={closeImage} alt=''></img>
            </Link>
            <div className='tipswish-title'>Настройки</div>
            <div className='tipswish-label'>
                <label>
                    <div>Выбрать аватар </div>
                    <div className='avatar-container'>
                        <input type='file' accept="image/*" onChange={onAvatarChange} />
                        <div className='avatar'>
                        {avatarImageData === null && tipsData.avatarUrl === "" && <div style={{backgroundImage: ('url('+fruitImage+')')}} className='avatar-icon'/> }
                        {avatarImageData !== null && <div style={{backgroundImage: ('url('+avatarImageData+')')}} className='avatar-icon'/> }
                        {avatarImageData === null && tipsData.avatarUrl !== "" && <div style={{backgroundImage: ('url('+api.getAvatarUrl(tipsData.avatarUrl)+')')}} className='avatar-icon'/>  }
                        <img src={pencilimage} alt='' className='avatar-pencil' />
                        </div>
                    </div>
                </label>
            </div>

            <div className='tipswish-label'>ФИО</div>
            <input className='tipswish-name-input' placeholder='Потапов Павел' 
            onChange={setName}
            value={tipsData.tipsName}></input>
            {window.user.restaurantRole === "MANAGER" && <>
                <div className='tipswish-label'>Отображать у всех</div>
                <FormCheck type="switch" checked={tipsData.overrideTipsName} onChange={e => { setTipsData({...tipsData, overrideTipsName:  e.target.checked, changed: true});}}></FormCheck>
            </>
            }
            <div className='tipswish-label'>Желание</div>
            <textarea className='tipswish-wish-input' placeholder='Ваше пожелание...' 
            onChange={setWish}
            value={tipsData.tipsWish}>
            </textarea>
            {window.user.restaurantRole === "MANAGER" && <>
                <div className='tipswish-label'>Отображать у всех</div>
                <FormCheck type="switch" checked={tipsData.overrideTipsWish} onChange={e => { setTipsData({...tipsData, overrideTipsWish:  e.target.checked, changed: true});}}></FormCheck>
            </>
            }
            <Button variant="primary" className='tipswish-save' disabled={(!tipsData.changed && !avatarChanged)}
            onClick={saveData}>Сохранить</Button>
        </div>
    </div>
    <div className='tipswish-modal'>
    <div className='tipswish-label'>
                <label>
                    <div>Выбрать аватар </div>
                    <div className='avatar-container'>
                        <input type='file' onChange={onAvatarChange} />
                        <div className='avatar'>
                        {avatarImageData === null && tipsData.avatarUrl === "" && <div style={{backgroundImage: ('url('+fruitImage+')')}} className='avatar-icon'/> }
                        {avatarImageData !== null && <div style={{backgroundImage: ('url('+avatarImageData+')')}} className='avatar-icon'/> }
                        {avatarImageData === null && tipsData.avatarUrl !== "" && <div style={{backgroundImage: ('url('+api.getAvatarUrl(tipsData.avatarUrl)+')')}} className='avatar-icon'/>  }
                        <img src={pencilimage} alt='' className='avatar-pencil' />
                        </div>
                    </div>
                </label>
            </div>
            <div className='tipswish-label'>ФИО</div>
            <input className='tipswish-name-input' placeholder='Потапов Павел' onChange={setName} value={tipsData.tipsName}></input>
            {window.user.restaurantRole === "MANAGER" && <>
                <div className='tipswish-label'>Отображать у всех</div>
                <FormCheck type="switch" checked={tipsData.overrideTipsName} onChange={e => { setTipsData({...tipsData, overrideTipsName:  e.target.checked, changed: true});}}></FormCheck>
            </>
            }
            <div className='tipswish-label'>Желание</div>
            <textarea className='tipswish-wish-input' placeholder='Ваше пожелание...'
            onChange={setWish} value={tipsData.tipsWish}></textarea>
            {window.user.restaurantRole === "MANAGER" && <>
                <div className='tipswish-label'>Отображать у всех</div>
                <FormCheck type="switch" checked={tipsData.overrideTipsWish} onChange={e => { setTipsData({...tipsData, overrideTipsWish:  e.target.checked, changed: true});}}></FormCheck>
            </>
            }
            <Button variant="primary" className='tipswish-save' disabled={(!tipsData.changed && !avatarChanged)}
            onClick={saveData}>Сохранить</Button>
    </div>
    </>}
    </Wrapper>
}

export default TipsWish;
