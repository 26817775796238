import Wrapper from '../../components/Wrapper'

import * as React from "react";
import Button from "react-bootstrap/Button";
import api from "../../services/api";
import Form from "react-bootstrap/Form";
import withParams from "../../services/withParams";
import QRCode from "react-qr-code";
import {Link} from "react-router-dom";
import {Alert} from "react-bootstrap";
import Image from "react-bootstrap/Image";
import Big from 'big.js';

export default withParams(class RestaurantSurcharge extends React.Component {
    state = {
        loading: false,
        sum: '0',
        percent: '1.7',
        total: new Big(0)
    };

    constructor(props) {
        super(props);
        const { id } = this.props.params;
    }

    async submit(event) {
        event.preventDefault();

        this.setState({
            loading: true,
            error: false
        });

        let response = await api.addSurcharge(this.state.total.toFixed(2));

        this.setState({
            loading: false
        });

        if(response.status === 200) {
            this.props.navigate("/orders/" + response.data.id);
        }
        else {
            alert("Ошибка при создании тестового счёта!");
        }
    }

    render() {
        return <Wrapper navigate={this.props.navigate}>
            {this.state.loading && <span className={"title"}>Загрузка...</span>}
            {this.state.error && <Alert variant={"danger"}>Ошибка: {this.state.error}</Alert>}

            {!this.state.loading && <Form onSubmit={this.submit.bind(this)} className={"form form-wrapper"}>
                <label>
                    Доплата позволяет доплатить недостающую часть денег ресторану из собственных средств.<br/>
                    После нажания кнопки "Создать", создается счёт с одним блюдом на сумму + % эквайринга.
                </label>

                <label>
                    Введите сумму
                    <input type={"number"}  value={this.state.sum} onChange={e => {
                        let val = new Big(e.target.value || '0');
                        let percent = new Big(this.state.percent);
                        let delim = new Big(1).minus(percent.div(100));

                        // Z=y/(1-x/100).
                        // X-ставка рест; y-сумма которая должна прийти в ресторан, z- сумму которую мы должны заплатить

                        this.setState({
                            sum: e.target.value,
                            total: val.div(delim)
                        })
                    }}  />
                </label>

                <label>
                    Процент эквайринга
                    <input type={"number"} value={this.state.percent} onChange={e => {
                        let val = new Big(this.state.sum || '0');
                        let percent = new Big(e.target.value);
                        let delim = new Big(1).minus(percent.div(100));

                        this.setState({
                            percent: e.target.value,
                            total: val.div(delim)
                        })
                    }} />
                </label>

                <label>
                    Конечная сумма (сумма * эквайринг)
                    <input type={"number"} value={this.state.total.toFixed(2)} onChange={e => this.setState({
                        total: new Big(e.target.value || '0'),
                        sum: '0',
                        percent: '0'
                    })} />
                </label>

                <div className={"flex"} style={{marginBottom: 40}}>
                    {<Button variant="primary" type="submit">
                        Создать
                    </Button>}
                </div>
            </Form>}

        </Wrapper>;
    }
})