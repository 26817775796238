import Wrapper from '../../../components/Wrapper'

import * as React from "react";
import {useState, useEffect} from "react";
import api from "../../../services/api";
import {useSearchParams, useNavigate} from 'react-router-dom';
import {Link} from "react-router-dom";
import Button from "react-bootstrap/Button";
import TeamButtonsNav from './TeamButtonsNav';

const TeamsList = (props) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const [data, setData] = useState({
        enabled: true,
        searchText: '',
        focusSearch: false,
    });

    useEffect(() => {reload();}, []);
    const reload = () => {
        if(!data.focusSearch)
        {
            setLoading(true);
        }

        setSearchParams({page: data.page, enabled: data.enabled, searchText: data.searchText});

        api.getPageableTeams({page: data.page, enabled: data.enabled, searchText: data.searchText}).then(response => {
            if(response.status === 200) {
                setData({
                    data: response.data,
                    loading: false
                });
                setLoading(false);
            }
            else {
                setError(response.data);
                setLoading(false);
            }
        });
    }

    return <Wrapper navigate={navigate}>
        {loading && <p>Загрузка команд...</p>}

        {!loading && (window.user.role === "ADMIN") && <div className={"gray-wrapper gray-table"}>
            <TeamButtonsNav />
            {!data.loading && data.data.content.length === 0 && <p className={"table-not-found"}>Команд пока нет...</p>}

            <div className={"gray-wrapper gray-table"}>
            {data.data.content.length > 0 && 
                <div className={"table"}>
                    <div className={"thead"}>
                        <div className={"th"}>Название</div>
                        <div className={"th"}>Тип распределения</div>
                        <div className={"th"}>Процент</div>
                    </div>

                    {data.data.content.map((item, i) => {
                        return <Link className={"tbody"} key={item.id} to={"/employees/teams/edit?id=" + item.id}>
                            <div className={"td"}>{item.name}</div>
                            <div className={"td end"}>{item.separateTips ? "Не участвует" : "Начислять"}</div>
                            <div className={"td break"}/>
                            <div className={"td"}>{item.separateTips ? "" : item.prc}</div>
                        </Link>
                    })}
                </div>
                }
                <Link to={"/employees/teams/edit?id=0"} className={"flex"}><Button style={{marginBottom: 5}}>Добавить команду</Button></Link>
            </div>
        </div>}
    </Wrapper>;
};

export default TeamsList;
