import 'normalize.css'; // Note this
import "./resources/styles/styles.css"
import "@fontsource/montserrat";
import "@fontsource/montserrat/500.css"
import "@fontsource/montserrat/600.css"
import "@fontsource/montserrat/700.css"
import "@fontsource/montserrat/300.css"
import {
    Routes,
    Route
} from "react-router-dom";

import {
    Login,
    Registration,
    NoMatch,
    Restore,

    RestaurantList,
    RestaurantEdit,
    RestaurantTables,
    RestaurantAdd,

    TerminalList,
    TerminalEdit,
    TableQR,

    OrderList,
    OrderEdit,
    TransactionList,
    TipsList,
    TipsOut,
    EmployeeList,
    EmployeeEdit,
    // EmployeeProfile,
    RKeeperInstruction,
    IikoInstruction,
    Instructions,
    RestaurantSurcharge,
    Accounting,
    TeamsList,
    TeamEdit,
    TeamSharing,
    AgentList,
    AgentEdit
} from "./views";

import React from "react";
import api from "./services/api";
import SelectRestaurant from "./views/SelectRestaurant";
import Reports from "./views/reports/Reports";
import WaiterStatistics from "./views/reports/WaiterStatistics";
import CommentList from "./views/order/CommentList";
import Manager from "./views/Manager";
import { useNavigate } from 'react-router-dom';
import Waiter from "./views/Waiter";
import Agent from "./views/Agent";
import ProductEdit from "./views/categories/ProductEdit";
import ProductList from "./views/categories/ProductList";
import CategoryEdit from "./views/categories/CategoryEdit";
import CategoryList from "./views/categories/CategoryList";
import Advanced from "./views/advanced/Advanced";
import Packages from "./views/advanced/Packages";
import Logging from "./views/advanced/Logging";
import TelegramChatsList from "./views/advanced/TelegramChatsList";
import RKeeperOrderList from "./views/advanced/RKeeperOrderList";
import RKeeperOrderEdit from "./views/advanced/RKeeperOrderEdit";
import TipsWish from './views/settings/TipsWish.js';
import AccountingList from "./views/reports/AccountingList";
import ShortLinkLogin from './views/ShortLinkLogin';
import AksPhone from './views/AskPhone';
import PnLReport from './views/reports/PnLReport.js';
import NoServiceReport from './views/reports/NoServiceReport.js';
import RestaurantCosts from './views/restaurant/RestaurantCosts.js';
import RestaurantContacts from './views/restaurant/RestaurantContacts.js';
import OrderDemo from './views/order/OrderDemo.js';
import Menu from './views/menu/Menu.js';
import MonitoringData from './views/monitoring/MonitoringData.js';
import RestaurantAgentEdit from "./views/restaurant/RestaurantAgentEdit";
import PayUReportOrder from './views/advanced/PayUReportOrder.js';
import ByRestaurants from "./views/reports/ByRestaurants";
import NoTransactionsReport from './views/reports/NoTransactionsReport.js';
import Registers from "./views/reports/Registers";
import RestaurantCashbackCheckActs from "./views/advanced/RestaurantCashbackCheckActs";
import AccountingSettings from "./views/reports/AccountingSettings";
import AccountingActs from "./views/reports/AccountingActs";
import PrepayList from './views/prepay/PrepayList.js';
import Prepay from './views/prepay/Prepay.js';

Array.prototype.totalSum = function (filter) {
    let fields = {last: true, goodsList: []};
    let size = 0;

    for(let i=0; i < this.length; i++) {
        let item = this[i];

        Object.keys(item).forEach(function(key) {
            if(filter === undefined || filter(item)) {
                if(key == "goodsList")
                {
                    let isSBP = item['sbp'];
                    for(let k=0;k<item[key].length;k++)
                    {
                        let goodTotal = 0, goodTotalSbp = 0, goodTotalBonus = 0;
                        let foundIndex = -1;
                        for(let j=0;j<fields.goodsList.length;j++)
                        {
                            if(item[key][k].name == fields.goodsList[j].name)
                            {
                                goodTotal = fields.goodsList[j].value;
                                if(isSBP)
                                {
                                    goodTotalSbp = fields.goodsList[j].valueSbp;
                                }
                                if(fields.goodsList[j].valueBonus !== undefined) {
                                    goodTotalBonus = fields.goodsList[j].valueBonus;
                                }
                                foundIndex = j;
                                break;
                            }
                        }
                        goodTotal += item[key][k].value;
                        if(isSBP)
                        {
                            goodTotalSbp += item[key][k].valueSbp;
                        }
                        if(item[key][k].valueBonus !== undefined) {
                            goodTotalBonus += item[key][k].valueBonus;
                        }
                        if(foundIndex<0)
                        {
                            fields.goodsList.push({name: item[key][k].name, value: goodTotal, valueSbp: goodTotalSbp, valueBonus: goodTotalBonus });
                        } else {
                            fields.goodsList[foundIndex] = {name: item[key][k].name, value: goodTotal, valueSbp: goodTotalSbp, valueBonus: goodTotalBonus };
                        }
                    }
                } else {
                    let parse = Number.parseFloat(item[key])

                    if (!Number.isNaN(parse)) {
                        size++;

                        if(fields[key] !== undefined) {
                            fields[key] += parseFloat((parse).toFixed(2));
                        }
                        else {
                            fields[key] = parseFloat((parse).toFixed(2));
                        }
                    }
                }
            }
        });
    }

    let new_array = [...this];

    if(size > 0) {
        new_array.push(fields);
    }

    return new_array;
}

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true
        }

        api.getUserInfo().then(result => {

            if(result.status === 401 && (window.location.pathname.indexOf('login') < 0
                    && window.location.pathname.indexOf('registration') < 0
                    && window.location.pathname.indexOf('sl') < 0
                    && window.location.pathname.indexOf('demo-order') < 0
                    && window.location.pathname.indexOf('restore') < 0)
                && window.location.pathname.indexOf('instructions') < 0) {
                this.props.navigate("/login");
            }
            else if(result.status === 400) {
                window.user = result.json;

                if(window.location.pathname.indexOf('/login/restaurant') < 0) {
                    this.props.navigate("/login/restaurant");
                }
            }
            else if (result.status === 200) {
                window.user = result.data;

                if(window.user)
                {
                    if(false && (window.user.phone === undefined || window.user.phone === null || window.user.phone === ""))
                    {
                        this.props.navigate("/askphone");
                    } else {
                        if(window.location.pathname.indexOf('login') >= 0 || window.location.pathname.indexOf('registration') >= 0) {
                            this.props.navigate("/");
                        }
                    }
                }
            }

            this.setState({
                loading: false
            });
        });
    }

    render() {
        if(this.state.loading) {
            return "Загрузка"
        }

        return (
            <Routes>
                {window.user && window.user.restaurantRole === "MANAGER" && <Route path="/" element={<Manager navigate={this.props.navigate} title="Домашняя :: Eat & Split"/>} />}
                {window.user && window.user.restaurantRole === "WAITER" && <Route path="/" element={<Waiter navigate={this.props.navigate} title="Домашняя :: Eat & Split"/>} />}
                {window.user && window.user.role === "AGENT" && <Route path="/" element={<Agent navigate={this.props.navigate} title="Домашняя :: Eat & Split"/>} />}

                <Route path="/login" element={<Login navigate={this.props.navigate} />} />
                <Route path="/login/restaurant" element={<SelectRestaurant navigate={this.props.navigate} />} />
                <Route path="/restore" element={<Restore navigate={this.props.navigate} />} />
                <Route path="/registration" element={<Registration navigate={this.props.navigate} />} />
                <Route path="/comments" element={<CommentList navigate={this.props.navigate} />} />
                <Route path="/statistics" element={<WaiterStatistics navigate={this.props.navigate} />} />

                <Route path="/restaurants" element={<RestaurantList navigate={this.props.navigate} />} />
                <Route path="/restaurant" element={window.user?.role === "AGENT" ? <RestaurantAgentEdit navigate={this.props.navigate} /> : <RestaurantEdit navigate={this.props.navigate} />} />
                <Route path="/restaurant/add" element={<RestaurantAdd navigate={this.props.navigate} />} />

                <Route path="/restaurant/tables" element={<RestaurantTables navigate={this.props.navigate} />} />
                <Route path="/restaurant/tables/:id" element={<TableQR navigate={this.props.navigate} />} />
                <Route path="/restaurant/surcharge" element={<RestaurantSurcharge navigate={this.props.navigate} />} />
                <Route path="/restaurant/costs" element={<RestaurantCosts navigate={this.props.navigate} />} />

                <Route path="/products" element={<ProductList navigate={this.props.navigate} />} />
                <Route path="/products/:id" element={<ProductEdit navigate={this.props.navigate} />} />
                <Route path="/categories" element={<CategoryList navigate={this.props.navigate} />} />
                <Route path="/categories/:id" element={<CategoryEdit navigate={this.props.navigate} />} />

                <Route path="/terminals" element={<TerminalList navigate={this.props.navigate} />} />
                <Route path="/terminals/:id" element={<TerminalEdit navigate={this.props.navigate} />} />

                <Route path="/employees" element={<EmployeeList navigate={this.props.navigate} />} />
                <Route path="/employees/:id" element={<EmployeeEdit navigate={this.props.navigate} />} />

                <Route path="/employees/teams" element={<TeamsList navigate={this.props.navigate} />} />
                <Route path="/employees/teams/:id" element={<TeamEdit navigate={this.props.navigate} />} />
                <Route path="/employees/teams/sharing" element={<TeamSharing navigate={this.props.navigate} />} />
                {/* <Route path="/employees/profile" element={<EmployeeProfile navigate={this.props.navigate} />} /> */}

                <Route path="/orders" element={<OrderList navigate={this.props.navigate} />} />
                <Route path="/orders/:id" element={<OrderEdit navigate={this.props.navigate} />} />

                <Route path="/transactions" element={<TransactionList navigate={this.props.navigate} />} />

                <Route path="/instructions" element={<Instructions navigate={this.props.navigate} />} />
                <Route path="/instructions/iiko" element={<IikoInstruction navigate={this.props.navigate} />} />
                <Route path="/instructions/rkeeper" element={<RKeeperInstruction navigate={this.props.navigate} />} />

                <Route path="/tips" element={<TipsList navigate={this.props.navigate} />} />
                <Route path="/tips/out" element={<TipsOut navigate={this.props.navigate} />} />
                <Route path="/reports" element={<Reports navigate={this.props.navigate} />} />
                <Route path="/accounting/:id" element={<Accounting navigate={this.props.navigate} />} />
                <Route path="/accounting" element={<AccountingList navigate={this.props.navigate} />} />
                <Route path="/accounting-settings" element={<AccountingSettings navigate={this.props.navigate} />} />
                <Route path="/accounting-acts" element={<AccountingActs navigate={this.props.navigate} />} />

                <Route path="/telegram" element={<TelegramChatsList navigate={this.props.navigate} />} />

                <Route path="/advanced" element={<Advanced navigate={this.props.navigate} />} />
                <Route path="/advanced/logging" element={<Logging navigate={this.props.navigate} />} />
                <Route path="/advanced/packages" element={<Packages navigate={this.props.navigate} />} />
                <Route path="/advanced/rkeeper" element={<RKeeperOrderList navigate={this.props.navigate} />} />
                <Route path="/advanced/rkeeper/:id" element={<RKeeperOrderEdit navigate={this.props.navigate} />} />
                <Route path="/advanced/agent" element={<AgentList navigate={this.props.navigate} />} />
                <Route path="/advanced/agent/add" element={<AgentEdit navigate={this.props.navigate} />} />
                <Route path="/advanced/agent/:id" element={<AgentEdit navigate={this.props.navigate} />} />

                <Route path="/tipswish" element={<TipsWish navigate={this.props.navigate} />} />
                <Route path="/sl" element={<ShortLinkLogin navigate={this.props.navigate} />} />
                <Route path="/askphone" element={<AksPhone navigate={this.props.navigate} />} />
                <Route path="/advanced/pnlreport" element={<PnLReport navigate={this.props.navigate} />} />
                <Route path="/advanced/noservicereport" element={<NoServiceReport navigate={this.props.navigate} />} />
                <Route path="/advanced/contacts" element={<RestaurantContacts navigate={this.props.navigate} />} />
                <Route path="/advanced/monitoring" element={<MonitoringData navigate={this.props.navigate} />} />
                <Route path="/advanced/processing-verification" element={<PayUReportOrder navigate={this.props.navigate} />} />
                <Route path="/advanced/by-restaurants" element={<ByRestaurants navigate={this.props.navigate} />} />
                <Route path="/advanced/notransactionsreport" element={<NoTransactionsReport navigate={this.props.navigate} />} />
                <Route path="/advanced/registers" element={<Registers navigate={this.props.navigate} />} />
                <Route path="/advanced/check-cachback-acts" element={<RestaurantCashbackCheckActs navigate={this.props.navigate} />} />

                <Route path="/demo-order" element={<OrderDemo navigate={this.props.navigate} />} />
                <Route path="/menu" element={<Menu navigate={this.props.navigate} />} />
                <Route path="/prepays" element={<PrepayList navigate={this.props.navigate} />} />
                <Route path="/prepay" element={<Prepay navigate={this.props.navigate} />} />
                <Route path="/prepay/:id" element={<Prepay navigate={this.props.navigate} />} />

                <Route path="*" element={<NoMatch navigate={this.props.navigate} />} />
            </Routes>
        );
    }
}


export default () => {
    const navigate = useNavigate();
    return (
        <App navigate={navigate} />
    )
}
