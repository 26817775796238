import Wrapper from '../../components/Wrapper'

import * as React from "react";
import {useState, useEffect, useRef} from "react";
import Button from "react-bootstrap/Button";
import api from "../../services/api";
import Form from "react-bootstrap/Form";
import {useParams, useSearchParams, useNavigate} from 'react-router-dom';
import {Alert} from "react-bootstrap";
import Image from "react-bootstrap/Image";
import deleteIcon from "../../resources/images/close.svg";
import QRCode from "react-qr-code";
import MaskedInput from "../../components/MaskedInput";

const EmployeeEdit = (props) => {
    const { id } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [employee, setEmployee] = useState({loading: true, 
        oldPhone: null,
        phone: null,
        fullName: null,
        enabled: false,
        role: null,
        manageTips: null,
        tipsName: null,
        team: null,
        manageTipsType: 1,
        noTipsOut: false,
    });
    const [teams, setTeams] = useState([]);
    const [needConfirm, setNeedConfirm] = useState(false); 
    const qrRef = useRef();

    useEffect(() => {
        if(searchParams.get("id")>0)
        {
            api.getEmployee(searchParams.get("id")).then(response => {
                if(response.status === 200) {
                    let v = response.data['user'] == undefined ? response.data : response.data.user;
                    setEmployee({
                        id: searchParams.get("id"),
                        oldPhone: v.phone,
                        phone: v.phone,
                        fullName: v.fullName,
                        enabled: v.enabled,
                        role: v.role,
                        loading: false,
                        manageTips: v.manageTips,
                        tipsName: v.tipsName,
                        team: v.team,
                        noTipsOut: v.noTipsOut,
                        manageTipsType: v.noTipsOut ?  1 : (v.manageTips ? 3 : 2),
                    });
                    setTeams(response.data.teams);
                }
                else {
                    setEmployee({
                        ...employee,
                        error: response.data == null ? "Ошибка при загрузке данных" : response.data,
                        loading: false
                    });
                }
            });
        } else {
            setEmployee({...employee, id: 0, role: 'WAITER', loading: false});
        }
    }, []);

    const onSaveClicked = () => {

        let realphone = 
            (employee.phone == undefined || employee.phone == null || employee.phone.length != 11) ?
            "" : employee.phone;
        setEmployee({...employee, phone: realphone, loading: true, error: false});

        api.setBaseObject('employees/'+employee.id, {...employee, phone: realphone}, false).then(response => {
            if(response.status !== 200) {
                setEmployee({ ...employee, error: response.data, loading: false });
            }
            else {
                let infoMessage = (employee.oldPhone!=null && employee.oldPhone!=response.data.phone) ?
                                             "Ссылка для активации отправлена в смс сообщении": "";
                if(employee.id == 0)
                {
                    props.navigate("/employees/edit?id="+response.data.id);
                }
                let v = response.data['user'] == undefined ? response.data : response.data.user;
                setEmployee({
                    ...employee,
                    error: false,
                    id: v.id,
                    oldPhone: v.phone ?? "",
                    phone: v.phone ?? "",
                    info: infoMessage,
                    team: v.team,
                    loading: false
                });
            }
        })
    }

    const onDeleteEmail = () => {
        setEmployee({...employee, loading: true, error: false});
        setNeedConfirm(false);

        api.removeEmployeeMail(employee).then(response => {
            if(response.status !== 200) {
                setEmployee({...employee, error: response.data, loading: false});
            } else {
                setEmployee({...employee, error: false, loading: false,
                    oldPhone: response.data.phone ?? "", phone: response.data.phone ?? ""});
            }
        });
    }

    const askDeleteConfirm = () => {
        setNeedConfirm(true);
    }
    const downloadQR = () => {
        const blob = new Blob([qrRef.current.innerHTML], { type: "image/svg+xml" });
        const link = document.createElement("a");
        const objectUrl = URL.createObjectURL(blob);
        link.href = objectUrl;
        link.download = "waiter_QR.svg";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setTimeout(() => URL.revokeObjectURL(objectUrl), 5000);
    }
    const waiterOrderURL = `${process.env.REACT_APP_MOBILE_APP_URL}?r=${window.user.restaurant.id}&wi=${employee.id}&m=1`;

    return <Wrapper navigate={navigate}>
            {employee.loading && <span className={"title"}>Загрузка...</span>}
            {employee.error && <Alert variant={"danger"}>Ошибка: {employee.error}</Alert>}
            {employee.info && <Alert variant={"info"}>Информация: {employee.info}</Alert>}

            {!employee.loading && !employee.error && <Form className={"form form-wrapper"}>
                {!employee.oldPhone && !employee.phone && <Alert variant={"warning"}>
                    <b>У сотрудника не указан телефон!</b><br /><br />
                    Задайте сотруднику телефон и ему придет sms-сообщение с активацией аккаунта.<br /><br />
                </Alert>}
                <label>
                    ФИО
                    <input type={"text"} value={employee.fullName} onChange={e => setEmployee({...employee, fullName: e.target.value })} />
                </label>

                <label>
                    Отображаемое имя при оплате чаевых
                    <input type={"text"} value={employee.tipsName} onChange={e => setEmployee({...employee, tipsName: e.target.value })} />
                </label>

                <label>
                    Телефон
                    <div style={{width: "100%", display: "flex", flexDirection: "row"}}>
                    <Form.Control className="long" mask="+1 (111) 111-11-11" as={MaskedInput} type="text" value={employee.phone} onChange={e => {setEmployee({...employee, phone: e.target.value.replace(/\D/g, '') });}} placeholder='+7 (999) 555-55-55'
                    style={{height: '60px', background: '#FFFFFF', borderRadius: '60px', fontWeight: '400', fontSize: '20px',letterSpacing: '-0.02em'}}
                    />

                    <Button onClick={askDeleteConfirm} style={{width: 26, height: 26, flex: 0, background: "transparent", boxShadow: "none", marginTop: "auto", marginBottom: "auto", paddingTop: 15}}>
                        <Image src={deleteIcon} />
                    </Button>
                    </div>
                </label>

                <label>
                    <div>Тип учетной записи</div>

                    <select value={employee.role} onChange={e => setEmployee({...employee, role: e.target.value })}>
                        <option disabled={true}>-- Выберите --</option>
                        <option value="WAITER">Официант</option>
                        <option value="MANAGER">Управляющий</option>
                    </select>
                </label>

                <label>
                    <div>Активен</div>
                    <select value={employee.enabled} onChange={e => setEmployee({...employee, enabled: e.target.value })}>
                        <option disabled={true}>-- Выберите --</option>
                        <option value="false">Нет</option>
                        <option value="true">Да</option>
                    </select>
                </label>

                <label>
                    <div>Тип вывода чаевых</div>
                    <select value={employee.manageTipsType} onChange={e => 
                        {
                            if(e.target.value*1 == 1)
                            {
                                setEmployee({...employee, noTipsOut: true, manageTips: false, manageTipsType: e.target.value });
                            } else {
                                setEmployee({...employee, noTipsOut: false, manageTips: e.target.value == 3, manageTipsType: e.target.value });
                            }
                        }
                        }>
                        <option disabled={true}>-- Выберите --</option>
                        <option value="1">Нет доступа</option>
                        <option value="2">Индивидуальный</option>
                        <option value="3">Общий</option>
                    </select>
                </label>

                <label>
                    <div>Команда</div>
                    <select value={employee.team} onChange={e => setEmployee({...employee, team: e.target.value })}>
                        <option value=''>-- Без команды --</option>
                        {teams.map((item, i) => {
                            return <option value={item.id} key={"teamop"+i}>{item.name}</option>
                        }) }
                    </select>
                </label>

            {(window.user.role === "ADMIN") && <><div style={{marginBottom: 30, textAlign: 'center'}}>
                    <a target='_blank' href={waiterOrderURL}>{waiterOrderURL}</a>
                    <br></br>
                    <br></br>
                    <Button className={"order-button"} onClick={() => {navigator.clipboard.writeText(waiterOrderURL)}}>
                        Скопировать ссылку на чаевые для официанта
                    </Button>
                    <br></br>
                    <br></br>
                    <QRCode ref={qrRef} onClick={downloadQR} level='H' value={waiterOrderURL} />
                </div>

                </>
            }
                <div className={"flex"} style={{marginBottom: 40}}>
                    <Button variant="primary" onClick={onSaveClicked}>{employee.id == 0 ? "Добавить сотрудника" : "Обновить данные сотрудика"}</Button>
                </div>
            </Form>}
            {needConfirm && <div className='mail-confirm-popup'>
                    <div className='mail-confirm-popup-body'>
                        <div className='mail-confirm-text'>Вы уверены, что хотите отвязать пользователя?</div>
                        <Button variant="primary" className='mail-confirm-cancel' onClick={() => {setNeedConfirm(false)}}>Отменить</Button>
                        <Button variant="primary" onClick={onDeleteEmail}>Подтвердить</Button>
                    </div>
                    </div>
            }

        </Wrapper>;
};

export default EmployeeEdit;

