import * as React from "react";
import '../../../resources/styles/order.css'

const OrderTransactionRefundItem = ({val, onChange }) => {

    return <div className={"td sx-height-51 word-no-wrap"}>
            <input type={"text"} placeholder={'Сумма'} value={val} onChange={(e) => {
                onChange(e.target.value);
            }} style={{padding: 2, height: 30, width: 80, fontSize: 12}} />
        </div>
}

export default OrderTransactionRefundItem;
