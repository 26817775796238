import * as React from "react";
import {useState, useEffect} from "react";
import Button from "react-bootstrap/Button";

import api from "../../services/api";
import getUID from "../../services/randomValues";

import ToggleMenu from "../../components/ToggleMenu";
import SettingsItem from '../../components/SettingsItem';

const RestaurantCashbacks = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [reloadSwitcher, setReloadSwitcher] = useState(false);
    const [cashbacks, setCashbacks] = useState([]);
    const [deleted, setDeleted] = useState([]);

    const onAddCashbackFunc = () => {
        const newCashback = {
            key: getUID(),
            startDate: "",
            cashbackIsPercent: false,
            items: [],
        };

        const newArray = [...cashbacks];
        newArray.push(newCashback);
        setCashbacks(newArray);
    };

    const onAddCashbackItemFunc = (cashbackIndex) => {
        const newCashbackItem = {
            key: getUID(),
            maxAmount: 0,
            value: 0,
        };

        const newArray = [...cashbacks];
        newArray[cashbackIndex].items.push(newCashbackItem);
        setCashbacks(newArray);
    };

    const onEditCashbackFunc = (index, newStartDate, newCashbackIsPercent) => {
        const newArray = [...cashbacks];

        if(newStartDate !== null) {
            newArray[index].startDate = newStartDate;
        }
        if(newCashbackIsPercent !== null) {
            newArray[index].cashbackIsPercent = newCashbackIsPercent;
        }

        setCashbacks(newArray);
    };

    const onEditCashbackItemFunc = (index, cashbackIndex, newMaxAmount, newValue) => {
        const newArray = [...cashbacks];

        if(newMaxAmount !== null) {
            newArray[cashbackIndex].items[index].maxAmount = newMaxAmount;
        }
        if(newValue !== null) {
            newArray[cashbackIndex].items[index].value = newValue;
        }

        setCashbacks(newArray);
    };

    const onDeleteCashbackFunc = (index) => {
        const deletedElement = cashbacks[index];

        if(deletedElement.id !== undefined) {
            const newArray = [...deleted];
            newArray.push({
                objectType: "cashback",
                id: deletedElement.id,
            });
            setDeleted(newArray);
        }

        setCashbacks(cashbacks.filter(p => p.key !== deletedElement.key));
    };

    const onDeleteCashbackItemFunc = (index, cashbackIndex) => {
        const deletedElement = cashbacks[cashbackIndex].items[index];

        if(deletedElement.id !== undefined) {
            const newArray = [...deleted];
            newArray.push({
                objectType: "item",
                id: deletedElement.id,
            });
            setDeleted(newArray);
        }

        const newCashbacksArray = [...cashbacks];
        newCashbacksArray[cashbackIndex].items = newCashbacksArray[cashbackIndex].items.filter(p => p.key !== deletedElement.key)
        setCashbacks(newCashbacksArray);
    };

    const onSaveFunc = () => {
        api.setBaseObject("/restaurants/cashbacks", {
            items: cashbacks,
            deletedItems: deleted,
        }, false).then(
            response => {
                if(response.status === 200) {
                    setReloadSwitcher(!reloadSwitcher);
                } else {
                    setError(response.data);
                }
            }
        );
    };

    useEffect(() => {
        setLoading(true);
        try {
            api.getBaseObject("/restaurants/cashbacks").then(response => {
                if(response.status === 200) {
                    const data = response.data.map(c => {
                        c.key = getUID();

                        c.items = c.items.map(i => {
                            i.key = getUID();
                            return i;
                        });

                        return c;
                    });
                    setCashbacks(data);
                    setDeleted([]);
                } else {
                    setError(response.data);
                }
            });
        } finally {
            setLoading(false);
        }
    }, [reloadSwitcher]);

    return <>
        {loading && <span className={"title"}>Загрузка...</span>}
        {error && <span className={"title red"}>Ошибка: {error}</span>}

        {!loading && !error && <>
            {cashbacks.map((c, ci) => {
                return <ToggleMenu key={c.key} title={c.startDate}>
                    <SettingsItem 
                        name="Начальная дата" 
                        type="date" 
                        value={c.startDate}
                        onChange={(v) => {
                            onEditCashbackFunc(ci, v, null);
                        }}
                    />
                    <SettingsItem 
                        name="Процент" 
                        type="checkbox" 
                        value={c.cashbackIsPercent ?? false} 
                        onChange={(v) => {
                            onEditCashbackFunc(ci, null, v);
                        }}
                    />
                    {c.items.map((i, ii) => {
                        return <ToggleMenu key={i.key} title={i.maxAmount}>
                            <SettingsItem 
                                name="Верхняя граница" 
                                type="number" 
                                value={i.maxAmount} 
                                onChange={(v) => {
                                    onEditCashbackItemFunc(ii, ci, v, null);
                                }}
                            />
                            <SettingsItem 
                                name={(c.cashbackIsPercent ?? false) === false ? "Сумма" : "Процент"} 
                                type="number" 
                                value={i.value} 
                                onChange={(v) => {
                                    onEditCashbackItemFunc(ii, ci, null, v);
                                }}
                            />
                            <Button type="button" onClick={() => {
                                onDeleteCashbackItemFunc(ii, ci);
                            }}>
                                Удалить границу
                            </Button>
                        </ToggleMenu>
                    })}
                    <Button type="button" onClick={() => {
                        onAddCashbackItemFunc(ci);
                    }}>
                        Добавить границу
                    </Button>
                    <br/>
                    <Button type="button" onClick={() => {
                        onDeleteCashbackFunc(ci);
                    }}>
                        Удалить кэшбек
                    </Button>
                </ToggleMenu>
            })}
            <Button type="button" onClick={() => {
                onAddCashbackFunc();
            }}>
                Добавить кэшбек
            </Button>
            <br/>

            <Button type="button" onClick={() => {
                onSaveFunc();
            }}>
                Сохранить
            </Button>
        </>} 
    </>
};

export default RestaurantCashbacks;