import Wrapper from '../../components/Wrapper'
import withParams from "../../services/withParams";
import * as React from "react";
import api from "../../services/api";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import CurrencyFormat from "react-currency-format";
import '../../resources/styles/reports.css'
import Moment from "react-moment";
import calendar from '../../resources/images/calendar.svg'
import Image from "react-bootstrap/Image";
import { Line, Doughnut } from 'react-chartjs-2';
import {Chart, registerables} from 'chart.js'
import {Form, InputGroup} from "react-bootstrap";
import Paginator from "../../components/Paginator";
import like from '../../resources/images/like.svg'
import dislike from '../../resources/images/dislike.svg'
import search from '../../resources/images/search.svg'
import reciept from '../../resources/images/reciept.svg';
import {Link} from "react-router-dom";

import '../../resources/styles/input-with-icon.css';

export default withParams(class WaiterStatistics extends React.Component {
    chartWidth = 254;
    state = {
        loading: true,
        loadingTips: true,
        dateFrom: new Date(),
        dateTo: new Date(),
        page: {
            number: 0,
            size: 20,
            totalElements: 0,
        },
        tableSearch: ""
    };

    constructor() {
        super();
        Chart.register(...registerables);
    }    

    componentDidMount()
    {
        this.reload();
        this.handleWindowSizeChange();
        window.removeEventListener('resize', this.handleWindowSizeChange);
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    getLocaleMonth(monthNumber)
    {
        const locale = () => (navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
        const date = new Date();
        date.setDate(1);
        date.setMonth(monthNumber);
        return date.toLocaleString(locale, { month: "short" }).replace('.', '');
    };

    handleWindowSizeChange = () => {
        this.setState({ isMobile: window.innerWidth <= 768 });
    }

    reload() {
        api.getWaiterStatistics(this.state.dateFrom, this.state.dateTo).then(response => {
            if(response.status === 200) {
                const months = [];
                const values = [];
                response.data.monthTips.forEach(e => {
                    months.push(this.getLocaleMonth(e.date.substr(5,2)*1-1));
                    e.date = this.getLocaleMonth(e.date.substr(5,2)*1-1);
                    values.push(e.amount);
                });
                this.setState({
                    chartData: {datasets: [{
                        label: 'Чаевые',
                        data: values,
                        borderColor: '#FF4ADD',
                        backgroundColor: '#FF4ADD',
                    }], labels: months},
                    likes: {
                        labels: [
                            'Dislikes',
                            'Likes',
                        ],
                        datasets: [{
                            data: [ response.data.likes[0].dislikes, response.data.likes[0].likes ],
                            backgroundColor: [
                                '#F04444',
                                '#47C579',
                            ],
                            hoverOffset: 2
                        }]
                    },
                    data: response.data,
                    loading: false
                });
            }
            else {
                this.setState({
                    tips: response.data,
                    loading: false
                });
            }
        });

        api.getWaiterTipsTable(this.state.dateFrom, this.state.dateTo, this.state.page.number, this.state.page.size).then(response => {
            if(response.status === 200) {
                this.setState({
                    tipsData: response.data,
                    loadingTips: false
                });
            }
            else {
                this.setState({
                    loadingTips: false
                });
            }
        });
    };

    // mobile - calc(100vw - 136px)
    render() {

        return <Wrapper navigate={this.props.navigate}>
            {this.state.loading && <span className={"title"}>Загрузка...</span>}
            {this.state.error && <span className={"title red"}>Ошибка: {this.state.error}</span>}

            {!this.state.loading && !this.state.loadingTips && <div className={"row between mobile wcharts-container"}>
                    <div className="tips-chart-container">
                        <div className={"title"}>Чаевые</div>
                        <div className={"block manager-margin-30-top"}>
                                {!this.state.loading && <Line height={this.chartWidth+"px"} width={this.chartWidth+"px"} data={this.state.chartData} 
                                    options={{ 
                                        maintainAspectRatio:false,
                                        plugins: { legend: {display: false }, tooltip: { displayColors: false } }, 
                                    scales: {y: { display: false, border: { display: false }, grid: { display: false } }, 
                                             x: { border: { display: false }, grid: { display: false } }
                                        }
                                     }} />}
                        </div>
                    </div>

                    <div className="likes-doughnut-container">
                        <div className={"title"}>Оценки</div>
                        <div className={"block manager-margin-30-top likes-doughnut"}>
                                {!this.state.loading && <>
                                
                                <Doughnut options={{
                                    borderWidth: 10, borderColor: "#F5F5F5", rotation: -45, maintainAspectRatio: false,
                                    plugins: { legend: {display: false }, tooltip: { displayColors: false } }, 
                                }} height={this.chartWidth+"px"} width={this.chartWidth+"px"} data={this.state.likes} />
                                <div className={"wcentered"}>
                                    <div>
                                        <p>
                                            <Image src={like} /> {this.state.likes.datasets[0].data[1]}
                                        </p>
                                        <p>
                                            <Image src={dislike} /> {this.state.likes.datasets[0].data[0]}
                                        </p>
                                    </div>
                                </div>
                            </>}
                        </div>
                    </div>
            </div>}

            {!this.state.loading && !this.state.loadingTips && <div className={"row between mobile"}>
                <div className={"title title-margin"} style={{marginTop: "25px"}}>Статистика</div>
                </div>}
            {!this.state.loading && !this.state.loadingTips && <div className={"row between mobile fluid-width"}>
                    <div className={"gray-wrapper gray-table"}>
                        <div className={"table-search"} style={{marginBottom: "29px"}}>
                        <div className={"row"}>
                                <Image src={calendar} className={"calendar-image"}></Image><p className={"calendar-text"}>Выбрать период</p>
                            </div>
                        </div>
                        <div>
                            <div className={"row mobile wmb-3"}>
                                <Datetime className={"wpicker first"} value={this.state.dateFrom}
                                locale={moment.locale()}
                                onChange={(val) => {
                                  this.setState({ dateFrom: val  }, () => {
                                      this.reload();
                                  });
                              }}
                              closeOnSelect={ true }
                              dateFormat="DD.MM.YY" timeFormat={false} />
                                <Datetime  className={"wpicker second"} value={this.state.dateTo}
                                locale={moment.locale()}
                                onChange={(val) => {
                                  this.setState({ dateTo: val  }, () => {
                                      this.reload();
                                  });
                              }}
                              closeOnSelect={ true }
                              dateFormat="DD.MM.YY" timeFormat={false} />
                                <InputGroup className={"wsearch"}>
                                    <InputGroup.Text id="searchText"><Image src={search} /> </InputGroup.Text>
                                    <Form.Control className={"wform-control"} placeholder="Поиск" aria-label="Поиск" aria-describedby="searchText"
                                     value={this.state.tableSearch} onChange={(val) => {
                                        this.setState({tableSearch: val.target.value})
                                    } } 
                                    />
                                </InputGroup>
                            </div>
                        </div>

                        {this.state.tipsData.content.length > 0 && <div className={"col"}>
                        <div className={"table"}>

                            {this.state.tipsData.content.filter(tip => this.state.tableSearch == "" || tip.table_num == this.state.tableSearch).map((item, i) => {
                                return <Link className={"tbody"} key={item.id} to={"/orders/" + item.order}>
                                    <div className="td"><Moment format="DD.MM.YY&nbsp;&nbsp;&nbsp;HH:mm">{item.created}</Moment></div>
                                    {!this.state.isMobile && <>
                                    <div className="td"># {item.table_num}</div>
                                    <div className="td xs-visible-bold"><CurrencyFormat value={item.tips.toFixed(2)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} /></div>
                                    <div className="td break"></div>
                                    </>}

                                    {this.state.isMobile && <>
                                    <div className="td xs-visible-bold" style={{marginLeft: "auto"}}><CurrencyFormat value={item.tips.toFixed(2)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} /></div>
                                    <div className="td break"></div>
                                    <div className="td" style={{paddingLeft: "15px"}}># {item.table_num}</div>
                                    </>}

                                    <div className="td">{item.liked == 1 ? <Image src={like} height={30}/> : item.liked == 0 ? <Image src={dislike} height={30}/> : null}</div>
                                    <div className="td end"><Image src={reciept} height={30}/></div>
                                </Link>
                            })}
                        </div>

                        <Paginator page={this.state.tipsData} onChange={(handlePageChange) => {
                            this.setState({ page: {number: handlePageChange} }, () => {
                                this.reload();
                            });
                        }}/>
                    </div>}
                    </div>
            </div>
            }
        </Wrapper>;
    }
})