import Wrapper from '../../components/Wrapper'
import '../../resources/styles/styles.css';
import OfflineTerminals from './OfflineTerminals';

const MonitoringData = (props) => {

    return <Wrapper navigate={props.navigate}>
        <OfflineTerminals />
    </Wrapper>
}

export default MonitoringData;
