import {useEffect, useState} from "react";
import * as React from "react";
import Wrapper from "../../components/Wrapper";
import api from "../../services/api";
import getUID from "../../services/randomValues";
import {Link} from "react-router-dom";

const AccountingActs = (props) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [acts, setActs] = useState([]);

    useEffect(() => {
        onLoadActs();
    }, []);

    const onLoadActs = () => {
        setLoading(true);
        api.getBaseObject(`/restaurants-cashback-acts/get-list-by-restaurant?restaurantId=${window.user.restaurant.id}`).then(response => {
            setLoading(false);
            if(response.status === 200) {
                if(response.data !== null && Object.keys(response.data).length > 0) {
                    let data = response.data;
                    for(const item of data) {
                        item.key = getUID();
                    }
                    setActs(data);
                }
            } else {
                setError(response.data);
            }
        });
    };

    const onDownloadFile = (fileName) => {
        api.getBaseObjectAsBlob(`/restaurants-cashback-acts/get-file?fileName=${fileName}`).then(response => {
            if(response.status === 200) {
                const fileNameParts = fileName.split(["."]);
                const newFileName = window.user.restaurant.title + "." + fileNameParts[fileNameParts.length - 1];

                const serverData = new Blob([response.data], { type: "application/octet-stream" });
                const blobUrl = URL.createObjectURL(serverData);

                const link = document.createElement("a");
                link.href = blobUrl;
                link.download = newFileName;
                link.dispatchEvent(
                    new MouseEvent("click", {
                        bubbles: true,
                        cancelable: true,
                        view: window
                    })
                );
                setTimeout(() => {
                    window.URL.revokeObjectURL(blobUrl);
                    link.remove();
                }, 100);
            } else {
                setError(response.data);
            }
        });
    };

    return <>
        {loading && <span className={"title"}>Загрузка...</span>}
        {error && <span className={"title red"}>Ошибка: {error}</span>}

        {!loading && !error && <Wrapper navigate={props.navigate}>
            <div className={"table"}>
                <div className={"thead"}>
                    <div className={"th"}>Дата создания</div>
                </div>
                <div className={"tbody"}>
                    {acts === null || acts.length === 0 && <div className={"tr"}>
                        <div className={"td"}>Актов нет</div>
                    </div>}

                    {acts !== null && acts.length > 0 && acts.map((a, ai) => {
                        return <div className={"tr"} key={a.key}>
                            <Link className={"td"} to={`#`} onClick={() => {
                                onDownloadFile(a.fileName);
                            }}>
                                {a.created}
                            </Link>
                        </div>
                    })}
                </div>
            </div>
        </Wrapper>}
    </>
};

export default AccountingActs;
