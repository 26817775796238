import Wrapper from '../../components/Wrapper'
import withParams from "../../services/withParams";
import * as React from "react";
import api from "../../services/api";
import "react-datetime/css/react-datetime.css";
import '../../resources/styles/reports.css';
import '../../resources/styles/styles.css';
import Moment from "react-moment";
import {Button} from "react-bootstrap";

export default withParams(class Accounting extends React.Component {

    constructor(props) {
        super(props);
        const { id } = this.props.params;

        this.state = {
            loading: true,
            data: {}
        };

        api.getAccountingById(id).then(response => {
            if(response.status === 200) {
                this.setState({
                    data: response.data,
                    loading: false
                });

                if(response.data.status === "ACTIVE" || response.data.status === "QUEUE") {
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                }
            }
            else {
                this.setState({
                    tips: response.data,
                    loading: false,
                    error: response.data
                });
            }
        });
    }

    render() {
        return <Wrapper navigate={this.props.navigate}>
            {this.state.loading && <span className={"title"}>Загрузка...</span>}
            {this.state.error && <span className={"title red"}>Ошибка: {this.state.error}</span>}

            {!this.state.loading && !this.state.error && <div className={"col"}>
                <div className={"row mobile"}>
                    <div className={"col-2"}>
                        <div className={"order-title"}>Отчет с&nbsp;
                            <Moment format="DD.MM.YY">{this.state.data.from}</Moment>
                            &nbsp;по&nbsp;
                            <Moment format="DD.MM.YY">{this.state.data.to}</Moment>
                        </div>
                        <div>
                            Статус создания отчета: {this.state.data.status === "ACTIVE" ? "в процессе" : this.state.data.status === "FINISHED" ? "готов" : this.state.data.status === "ERROR" ? "ошибка" : "в очереди"}
                            {this.state.data.status === "ACTIVE" && ` ${this.state.data.progress} из ${this.state.data.progressCount}`}
                        </div>
                    </div>
                    <div className={"manager-margin-40"} />
                    {this.state.data.status === "FINISHED" && <div className={"col-2"}>
                        <Button disabled={this.state.disabledButton} className={"order-button"}
                                onClick={() => {
                                    this.setState({
                                        disabledButton: true
                                    });

                                    api.getAccountingFileUrl(this.state.data).then(() => {
                                        this.setState({
                                            disabledButton: false
                                        })
                                    }).catch((e) => {
                                        alert("Ошибка: " + e);
                                        this.setState({
                                            disabledButton: false
                                        });
                                    });
                                }}>
                            Скачать xlsx
                        </Button>
                    </div>}
                </div>
            </div>}
        </Wrapper>;
    }
})