import Wrapper from '../../components/Wrapper'

import * as React from "react";
import api from "../../services/api";
import withParams from "../../services/withParams";
import Image from "react-bootstrap/Image";
import img_qr from '../../resources/images/qr.png'
import {Link} from "react-router-dom";
import Paginator from "../../components/Paginator";
import moment from "moment";
import {Button} from "react-bootstrap";

export default withParams(class ProductList extends React.Component {
    constructor(props) {
        super(props);
        const { restaurant } = this.props.params;
        const [searchParams] = this.props.search;

        this.state = {
            loading: true,
            products: [],
            categories: [],
            category: searchParams.get('id') || "",
            goodsTypes: [],
            //enabled: searchParams.get('enabled') || true,
        };

        this.reload();
        this.loadTypes();
    }

    loadTypes() {
        api.getBaseObject("/restaurants/goods").then(response => {
            if(response.status === 200) {
                this.setState({
                    goodsTypes: response.data,
                });
            }
        });
    }

    reload() {
        const [searchParams, setSearchParams] = this.props.search;
        setSearchParams({id: this.state.category});

        api.getRestaurantProducts(this.state.category).then(responseProducts => {
            if(responseProducts.status === 200) {
                api.getRestaurantCategories(this.state.category).then(responseCategories => {
                    if(responseCategories.status === 200) {
                        this.setState({
                            products: responseProducts.data,
                            categories: responseCategories.data,
                            loading: false
                        });
                    }
                    else {
                        this.setState({
                            error: responseCategories.data,
                            loading: false
                        });
                    }
                });
            }
            else {
                this.setState({
                    error: responseProducts.data,
                    loading: false
                });
            }
        });
    }

    render() {
        return <Wrapper navigate={this.props.navigate}>
            {this.state.loading && <p>Загрузка блюд и категорий...</p>}

            {!this.state.loading && <div className={"gray-wrapper gray-table"}>
                {!this.state.loading && this.state.products.length === 0 && this.state.categories.length === 0 && <p className={"table-not-found"}>Блюд или категорий пока нет...</p>}

                {(this.state.products.length > 0 || this.state.categories.length > 0) && <div className={"col"}>
                    <div className={"table"}>
                        <div className={"thead"}>
                            <div className={"th"}>Название</div>
                            <div className={"th"}>Место</div>
                            <div className={"th"}></div>
                        </div>

                        {this.state.categories.map((item, i) => {
                            return <div className={"tbody"} key={item.id}>
                                <a href={`/categories?id=${item.id}`} className={"td col-2 word-wrap"}><b>{item.name}</b></a>
                                <div className={"td col-2 word-wrap"}></div>
                            </div>
                        })}

                        {this.state.products.sort((a,b) => a.deleted - b.deleted).map((item, i) => {
                            return <Link className={"tbody"} key={item.id} to={`/products/${item.id}`} style={{opacity : item.deleted ? 0.3 : 1}}>
                                <div className={"td col-2 word-wrap"}>{item.name}</div>
                                <div className={"td col-2 word-wrap"}>{item.cookingPlaceName}</div>
                                <div className={"td"}></div>
                            </Link>
                        })}
                    </div>
                </div>}
            </div>}
        </Wrapper>;
    }
});