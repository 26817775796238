import Wrapper from '../../components/Wrapper'
import {useState, useEffect, Fragment} from "react";
import {useSearchParams} from 'react-router-dom';
import api from "../../services/api";
import {Button, Image} from "react-bootstrap";
import Moment from "react-moment";
import order_lock from "../../resources/images/keylock.svg"
import moment from "moment";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import {Link} from "react-router-dom";
import Paginator from "../../components/Paginator";
import CurrencyFormat from "react-currency-format";

const OrderList = (props) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [error, setError] = useState('');
    const [page, setPage] = useState(searchParams.get('page') || 0);
    const [waiters, setWaiters] = useState([]);
    const [tables, setTables] = useState([]);
    const [waiter, setWaiter] = useState(0);
    const [table, setTable] = useState(0);

    const [visited, setVisited] = useState(searchParams.get('visited') ? (searchParams.get('visited') === 'true') : true);
    const [date, setDate] = useState(moment(searchParams.get('date') ?? new Date(), 'YYYY-MM-DD'));
    const isTest = ((window.user.restaurant.title === "Демо" || window.user.restaurant.title === "Тест" || window.user.restaurant.title.startsWith('Демо ')) && !(window.user.restaurantRole === "WAITER"));
    const isAdmin = (window.user !==undefined && window.user.role === "ADMIN");
    const isSupport = (window.user !==undefined && window.user.role === "SUPPORT");

    useEffect(() => {
        if(isTest) {
            api.getPageableEmployees({page: 0, enabled: true}).then(response => {
                if(response.status === 200) {
                    api.getRestaurantTables({enabled: true}).then(response_tables => {
                        if(response_tables.status === 200) {
                            setWaiters(response.data.content);
                            setTables(response_tables.data);
                            setWaiter(response.data.content[0].id);
                            setTable(response_tables.data[0].id);
                            reload();
                        }
                        else {
                            setError(response_tables.data);
                            setLoading(false);
                        }
                    });
                }
                else {
                    setError(response.data);
                    setLoading(false);
                }
            });
        } else {
            reload();
        }
    }, []);

    useEffect(() => { reload();}, [date, visited, page]);

    const reload = () => {
        setLoading(true);

        setSearchParams({page: page, date: moment(date).format("YYYY-MM-DD"), visited: visited});

        api.getPageableOrders({page: page, date: date, visited: visited}).then(response => {
            if(response.status === 200) {
                setData(response.data);
                setError('');
            } else {
                setError(response.data);
            }
            setLoading(false);
        });
    }

        return <Wrapper navigate={props.navigate}>
            {loading && <span className={"title"}>Загрузка...</span>}
            {error && <span className={"title red"}>Ошибка: {error}</span>}

            {!loading && <div className={"gray-wrapper gray-table"}>
                <div className={"table-search"}>
                    <Datetime value={date}
                              locale={moment.locale("ru")}
                              onChange={(val) => { setDate(val); }}
                              closeOnSelect={ true }
                              dateFormat="YYYY.MM.DD" timeFormat={false} />

                    {(isAdmin || isSupport) &&  <div className={"table-checkbox"}>
                        <input type="checkbox"
                                    checked={visited}
                                    onChange={e => { setVisited(e.target.checked); }} />
                        <span>Только посещённые</span>
                    </div>}
                </div>


                {isTest && <div className={"table-search"} >
                    <select value={waiter} onChange={e => { setWaiter(e.target.value); }}>
                        {waiters.map(waiter => {
                            return <option key={"wk"+waiter.id} value={waiter.id} selected={waiter === waiter.id}>{waiter.fullName}</option>
                        })}
                    </select>
                    <select value={table} onChange={e => { setTable(e.target.value); }}>
                        {tables.map(table => {
                            return <option key={"tk"+table.id} value={table.id} selected={table === table.id}>{table.name} ({table.table})</option>
                        })}
                    </select>

                    <Button
                        onClick={() => api.addTestOrder(waiter, table).then(response => {
                            if(response.status === 200) {
                                props.navigate("/orders/" + response.data.id);
                            }
                            else {
                                alert("Ошибка при создании тестового счёта!");
                            }
                        })}>Тест счёт</Button>
                </div>}

                {!loading && data.content.length === 0 && <p className={"table-not-found"}>Счетов пока нет...</p>}

                {data.content.length > 0 && <div className={"col"}>
                    <div className={"table"}>
                        <div className={"thead"}>
                            <div className={"th"}>Дата</div>
                            <div className={"th"}>Официант</div>
                            <div className={"th break"}/>
                            <div className={"th"}>Стол</div>
                            <div className={"th"}>Сумма</div>
                            {(isAdmin || isSupport) && <div className={"th"}>Название</div>}
                            {(isAdmin || isSupport) && <div className={"th"}>Смена</div>}
                            <div className={"th"}></div>
                        </div>

                        {data.content.map((item, i) => {
                            return <Fragment key={"tkk"+i}>
                                <Link className={"tbody"} key={item.id} to={"/orders/" + item.id}>
                                    <div className={"td col-2"}><Moment format="DD.MM.YY&nbsp;&nbsp;&nbsp;HH:mm">{item.created}</Moment></div>
                                    <div className={"td col-2 end xs-visible-bold"}>{item.waiter}</div>
                                    <div className={"td break"}/>
                                    <div className={"td"}><div className={"xs-visible"}>Стол</div>&nbsp;{item.table} {item.section != null && "(" + item.section + ")"}</div>
                                    <div className={"td"}><div className={"xs-visible"}>Сумма</div>&nbsp;<CurrencyFormat value={Math.floor(item.total)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} /></div>
                                    {(isAdmin || isSupport) && <div className={"td"}><div className={"xs-visible"}>Название</div>&nbsp;{item.name}</div>}
                                    {(isAdmin || isSupport) && <div className={"td"}><div className={"xs-visible"}>Смена</div>&nbsp;{item.session}</div>}
                                    <div className={"td end"}>
                                        {item.closed !== undefined || (item.childs.length > 0 && item.childs.map(c => !!c.closed).reduce((a, b) => a + b, 0) === item.childs.length) ? <Image src={order_lock} /> : null}
                                    </div>
                                </Link>

                                {item.childs.map(child => {
                                    return <Link className={"tbody tchild"} key={child.id} to={"/orders/" + child.id}>
                                        <div className={"td col-2"}>
                                            <b>—</b>&nbsp;&nbsp;<Moment format="DD.MM.YY&nbsp;&nbsp;&nbsp;HH:mm">{child.created}</Moment>
                                        </div>
                                        <div className={"td col-2 end xs-visible-bold"}>{child.waiter}</div>
                                        <div className={"td"}></div>
                                        <div className={"td"}><div className={"xs-visible"}>Сумма</div>&nbsp;<CurrencyFormat value={Math.floor(child.total)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} /></div>
                                        {(isAdmin || isSupport) && <div className={"td"}></div>}
                                        {(isAdmin || isSupport) && <div className={"td"}></div>}
                                        <div className={"td end"}>{child.closed !== undefined ? <Image src={order_lock} /> : null}</div>
                                    </Link>
                                })}
                            </Fragment>
                        })}
                    </div>

                    <Paginator page={data} onChange={(handlePageChange) => {
                        setPage(handlePageChange);
                    }}/>
                </div>}
            </div>}
        </Wrapper>;
};

export default OrderList;
