import Wrapper from '../../components/Wrapper'
import {useState, useEffect, useRef} from "react";
import api from "../../services/api";
import "react-datetime/css/react-datetime.css";
import '../../resources/styles/reports.css';
import moment from "moment";
import Button from "react-bootstrap/Button";
import Workbook from 'react-excel-workbook'
import '../../resources/styles/styles.css';
import DateRangeFilter from '../../components/DateRangeFilter';
import ReportMainData from './ReportMainData';
import ReportWaitersTips from './ReportWaitersTips';
import ReportLikes from './ReportLikes';
import ReportProducts from './ReportProducts';

const Reports = (props) => {
    const [activeTab, setActiveTab] = useState(0);
    const [mainReportLoaded, setMainReportLoaded] = useState(false);
    const [tipsReportLoaded, setTipsReportLoaded] = useState(false);
    const [likesReportLoaded, setLikesReportLoaded] = useState(false);
    const [productsReportLoaded, setProductsReportLoaded] = useState(false);
    const xlsButtonRef = useRef(null);
    const xlsMailsButtonRef = useRef(null);
    const [downloadMailsClicked, setDownloadMailsClicked] = useState(false);
    const [downloadXlsClicked, setDownloadXlsClicked] = useState(false);

    const [mailsLoaded, setMailsLoaded] = useState(false);
    const [emails, setEmails] = useState([]);

    const [loading, setLoading] = useState(false);

    const [mainData, setMainData] = useState([]);
    const [tipsData, setTipsData] = useState([]);
    const [likesData, setLikesData] = useState([]);
    const [productsData, setProductsData] = useState([]);
    const [error, setError] = useState('');

    const [allRestaurants, setAllRestaurants] = useState(false);
    const [from, setFrom] = useState(new Date());
    const [to, setTo] = useState(new Date());

    const isAdmin = (window.user !==undefined && window.user.role === "ADMIN");

    useEffect(() => { reload(); }, [from, to, allRestaurants]);

    const loadMainReport = () => {
        setLoading(true);
        api.getMainReport(from, to, allRestaurants).then(response => {
            if(response.status === 200) {
                let data = response.data;

                data.sums.avgBillWithTips = 0;
                data.sums.avgOrder = 0;
                data.sums.avgEnSOrder = 0;
                data.sums.avgTransaction = 0;
                data.sums.prcTipsFromTrans = 0;
                data.sums.prcEnSInOrders = 0;
                data.sums.prcNotPaidService = 0;
                data.sums.prcTipsOnly = 0;
                data.sums.avgEnSPerTrans = 0;
                data.sums.prcNotPaidServiceBySum = 0;

                if(data.sums.countTransactionsWithProducts !== 0)
                {
                    data.sums.avgBillWithTips = data.sums.sumPaidWithTips / data.sums.countTransactionsWithProducts;
                }
                if(data.sums.countOrders !== 0)
                {
                    data.sums.avgOrder = data.sums.sumOrders / data.sums.countOrders;
                }
                if(data.sums.countTransactionsWithProducts !== 0)
                {
                    data.sums.avgEnSPerTrans = data.sums.sumPaid / data.sums.countTransactionsWithProducts;
                }
                if(data.sums.countOrdersWithProducts !== 0)
                {
                    data.sums.avgEnSOrder = data.sums.sumPaid / data.sums.countOrdersWithProducts;
                }
                if(data.sums.countTransactions !== 0)
                {
                    data.sums.avgTransaction = data.sums.sumPaidTrans / data.sums.countTransactions;
                }
                if(data.sums.sumPaid !== 0)
                {
                    data.sums.prcTipsFromTrans = 100 * data.sums.sumTipsWithOrders / data.sums.sumPaid;
                }

                if(data.sums.sumOrdersWithTips !== 0)
                {
                    data.sums.prcTipsOnly = 100 * data.sums.sumTipsWithoutOrders / data.sums.sumOrdersWithTips;
                }

                if(data.sums.sumOrders !== 0)
                {
                    data.sums.prcEnSInOrders = 100 * data.sums.sumPaidAIR / data.sums.sumOrders;
                }
                if(data.sums.countTransactions !== 0)
                {
                    data.sums.prcNotPaidService = 100 * data.sums.notPaidServiceCount / data.sums.countTransactions;
                }

                if((data.sums.notPaidService + data.sums.sumService) !== 0)
                {
                    data.sums.prcNotPaidServiceBySum = 100 * data.sums.notPaidService / (data.sums.notPaidService + data.sums.sumService);
                }

                setMainData(data);
                setMainReportLoaded(true);
                setError('');
            } else {
                setError('Error loading data!');
                setMainReportLoaded(true);
            }
            setLoading(false);
        });
    }

    const loadTipsReport = () => {
        setLoading(true);
        api.getTipsReport(from, to, allRestaurants).then(response => {
            if(response.status === 200) {
                setTipsData(response.data.tips);
                setTipsReportLoaded(true);
                setError('');
            } else {
                setError('Error loading data!');
                setTipsReportLoaded(true);
            }
            setLoading(false);
        });
    }

    const loadLikesReport = () => {
        setLoading(true);
        api.getLikesReport(from, to, allRestaurants).then(response => {
            if(response.status === 200) {
                setLikesData(response.data.likes);
                setLikesReportLoaded(true);
                setError('');
            } else {
                setError('Error loading data!');
                setLikesReportLoaded(true);
            }
            setLoading(false);
        });
    }

    const loadProductsReport = () => {
        setLoading(true);
        api.getProductsReport(from, to, allRestaurants).then(response => {
            if(response.status === 200) {
                setProductsData(response.data.topProducts);
                setProductsReportLoaded(true);
                setError('');
            } else {
                setError('Error loading data!');
                setProductsReportLoaded(true);
            }
            setLoading(false);
        });
    }

    useEffect(() => {
        if(activeTab === 0 && !mainReportLoaded) {
            loadMainReport();
        }
    }, [activeTab, mainReportLoaded]);

    useEffect(() => {
        if(activeTab === 1 && !tipsReportLoaded) {
            loadTipsReport();
        }
    }, [activeTab, tipsReportLoaded]);

    useEffect(() => {
        if(activeTab === 2 && !likesReportLoaded) {
            loadLikesReport();
        }
    }, [activeTab, likesReportLoaded]);

    useEffect(() => {
        if(activeTab === 3 && !productsReportLoaded) {
            loadProductsReport();
        }
    }, [activeTab, productsReportLoaded]);

    const reload = () => {
        setMainReportLoaded(false);
        setTipsReportLoaded(false);
        setLikesReportLoaded(false);
        setProductsReportLoaded(false);
    };

    const reports = mainReportLoaded ? [{
        name: "Сумма оплаченных транзакций",
        value: mainData.sums.sumPaid.toFixed(2)
    },{
        name: "Сумма чаевых со счетами",
        value: mainData.sums.sumTipsWithOrders.toFixed(2)
    },{
        name: "Сумма чаевых без счетов",
        value: mainData.sums.sumTipsWithoutOrders.toFixed(2)
    }, {
        name: "Средний чек",
        value:  mainData.sums.avgEnSOrder.toFixed(2)
    }, {
        name: "Средний чек на гостя",
        value:  mainData.sums.avgEnSPerTrans.toFixed(2)
    },{
        name: "Процент чаевых от суммы счетов",
        value: mainData.sums.prcTipsFromTrans.toFixed(2)
    }] : [];

    useEffect(() => {
        if(downloadXlsClicked && mainReportLoaded && tipsReportLoaded && likesReportLoaded && productsReportLoaded) {
            xlsButtonRef.current.click();
            setTimeout(() => {setDownloadXlsClicked(false);}, 500);
        }
    }, [downloadXlsClicked, mainReportLoaded, tipsReportLoaded, likesReportLoaded, productsReportLoaded]);

    const forceDownload = () => {
        if(!mainReportLoaded) {
            loadMainReport();
        }
        if(!tipsReportLoaded) {
            loadTipsReport();
        }
        if(!likesReportLoaded) {
            loadLikesReport();
        }
        if(!productsReportLoaded) {
            loadProductsReport();
        }
        setDownloadXlsClicked(true);
    }

    useEffect(() => {
        if(downloadMailsClicked && mailsLoaded) {
            xlsMailsButtonRef.current.click();
            setTimeout(() => {setDownloadMailsClicked(false);}, 500);
        }
    }, [downloadMailsClicked, mailsLoaded]);

    const forceDownloadMails = () => {
        if(isAdmin) {
            api.getEmailsReport().then(response => {
                if(response.status === 200) {
                    setEmails(response.data.emails);
                    setMailsLoaded(true);
                } else {
                    setMailsLoaded(false);
                }
            });
        }
        setDownloadMailsClicked(true);
    }

    return <Wrapper navigate={props.navigate}>
            <div className={"gray-wrapper gray-table"}>
            {isAdmin && !loading && <>
                <DateRangeFilter from={from} to={to} onChange={(f, t) => {
                    setFrom(f);
                    setTo(t);
                }} className="bottom30" />

                <div className={"table-search"} style={{marginTop: "0px", display: "flex", justifyContent: "space-between" }}>
                    <div className={"table-checkbox"}>
                        <input type="checkbox"
                                    checked={allRestaurants}
                                    onChange={() => setAllRestaurants(!allRestaurants)} />
                        <span>Все рестораны *</span>
                    </div>
                    {!loading && <Button onClick={() => {forceDownload();}} style={{marginBottom: 5}}>Выгрузить XLS</Button>}
                </div>
                </>}
                {!isAdmin && !loading && <>
                    <DateRangeFilter from={from} to={to} onChange={(f, t) => {
                        setFrom(f);
                        setTo(t);
                    }} className="bottom30">
                    {!loading && <Button onClick={() => {forceDownload();}} style={{marginBottom: 5}}>Выгрузить XLS</Button>}
                </DateRangeFilter>
                </>}
                </div>
                {loading && <span className={"title"}>Загрузка...</span>}
                {error && <span className={"title red"}>Ошибка: {error}</span>}

                <div className={"row mobile"} style={{marginBottom: 20}}>
                    <Button style={{marginBottom: 5}} onClick={() => { setActiveTab(0);}}>Общие значения</Button>
                    &nbsp;
                    <Button style={{marginBottom: 5}} onClick={() => { setActiveTab(1);}}>Чаевые официантов</Button>
                    &nbsp;
                    <Button style={{marginBottom: 5}} onClick={() => { setActiveTab(2);}}>Обратная связь</Button>
                    &nbsp;
                    <Button style={{marginBottom: 5}} onClick={() => { setActiveTab(3);}}>Топ блюд</Button>
                </div>
                {activeTab === 0 && <ReportMainData loading={!mainReportLoaded} data={mainData} isAdmin={isAdmin} />}
                {activeTab === 1 && <ReportWaitersTips loading={!tipsReportLoaded} data={tipsData} isAdmin={isAdmin} />}
                {activeTab === 2 && <ReportLikes loading={!likesReportLoaded} data={likesData} isAdmin={isAdmin} />}
                {activeTab === 3 && <ReportProducts loading={!productsReportLoaded} data={productsData} isAdmin={isAdmin} />}

                {isAdmin && <Button onClick={() => {forceDownloadMails();}} style={{marginBottom: 5}}>Выгрузить e-mail адреса в XLS</Button>}
                {isAdmin && mailsLoaded && <Workbook filename="emails-report.xlsx" element={<Button style={{width: 0, height: 0, display: 'none'}} ref={xlsMailsButtonRef}>Выгрузить e-mail адреса в XLS</Button>}>
            <Workbook.Sheet data={emails} name="email">
                <Workbook.Column label="Ресторан" value="restaurantName"/>
                <Workbook.Column label="Email" value="email"/>
                <Workbook.Column label="Оплачен" value={row => row.paid ? 'Да' : 'Нет'}/>
                <Workbook.Column label="Дата создания" value={row => moment(row.created).format('DD.MM.YYYY hh:mm:ss')}/>
                <Workbook.Column label="Сумма позиций в чеке" value="products"/>
                <Workbook.Column label="Чаевые" value="tips"/>
                <Workbook.Column label="Service" value="service"/>
            </Workbook.Sheet>
        </Workbook>}

            {downloadXlsClicked && !loading && <Workbook filename="reports.xlsx" element={<Button style={{width: 0, height: 0, display: 'none'}} ref={xlsButtonRef}>Выгрузить XLS</Button>}>
            <Workbook.Sheet data={reports} name="Общие значения">
                <Workbook.Column label="Параметр" value="name"/>
                <Workbook.Column label="Значение" value="value"/>
            </Workbook.Sheet>

            <Workbook.Sheet data={tipsData} name="Чаевые официантов">
                <Workbook.Column label="Официант" value="fullName"/>
                <Workbook.Column label="Чаевые " value="amount"/>
            </Workbook.Sheet>

            <Workbook.Sheet data={likesData} name="Обратная связь гостей">
                <Workbook.Column label="Официант" value="fullName"/>
                <Workbook.Column label="Лайки" value="likes"/>
                <Workbook.Column label="Дизлайки" value="dislikes"/>
                <Workbook.Column label="Без отзывов" value="none"/>
            </Workbook.Sheet>

            <Workbook.Sheet data={productsData} name="Топ оплаченных блюд">
                <Workbook.Column label="Блюдо" value="name"/>
                <Workbook.Column label="Количество заказов " value="size"/>
            </Workbook.Sheet>
            </Workbook>}
        </Wrapper>;
};

export default Reports;
