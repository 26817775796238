import * as React from "react";
import {useEffect, useState} from "react";
import Wrapper from "../../components/Wrapper";
import api from "../../services/api";

const ByRestaurants = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        setLoading(true);
        api.getBaseObjectAsBlob(`/report-by-restaurants`).then(response => {
            if(response.status === 200) {
                // Надпись ожидания загрузки должна быть убрана сразу, иначе ссылки не будет в компоненте
                setLoading(false);
                const serverData = new Blob([response.data], { type: "application/vnd.ms-excel" });
                const blobUrl = URL.createObjectURL(serverData);
                const link = document.getElementById("report_link");
                if(link !== undefined && link !== null) {
                    link.href = blobUrl;
                    link.download = "Результаты по ресторанам.xlsx";
                    link.click();
                }
                URL.revokeObjectURL(blobUrl);
            } else {
                setError(response.data);
                setLoading(false);
            }
        });
    }, []);


    return <Wrapper>
        {loading && <span className={"title"}>Загрузка...</span>}
        {error && <span className={"title red"}>Ошибка: {error}</span>}

        {!loading && !error && <>
            <a id={"report_link"}>Отчет сформирован</a>
        </>}
    </Wrapper>
};

export default ByRestaurants;