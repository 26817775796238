import Wrapper from '../../../components/Wrapper'

import * as React from "react";
import {useState, useEffect} from "react";
import {FormCheck} from "react-bootstrap";
import "../../../resources/styles/switch.css";

import Button from "react-bootstrap/Button";
import api from "../../../services/api";
import Form from "react-bootstrap/Form";
import {useSearchParams, useNavigate} from 'react-router-dom';
import {Alert} from "react-bootstrap";
import TeamButtonsNav from './TeamButtonsNav';

const TeamEdit = (props) => {
    const [loading, setLoading] = useState(true);
    const [loadingPopup, setLoadingPopup] = useState(true);
    const [error, setError] = useState("");
    const [data, setData] = useState({id: 0, name: "", separateTips: false, prc: 0, team: []});
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [needConfirm, setNeedConfirm] = useState(false);
    const [needConfirmRemoveFromTeam, setNeedConfirmRemoveFromTeam] = useState(false);
    const [popupTeam, setPopupTeam] = useState(false);
    const [users4team, setUsers4team] = useState([]);
    const [userFromTeam, setUserFromTeam] = useState(0);
    const [employeeListFilter, setEmployeeListFilter] = useState("");

    useEffect(() => {
        setLoading(true);
        let pId = searchParams.get("id");
        if(pId!="0")
        {
            api.getTeam(pId).then(response => {
                if(response.status === 200) {
                    setData({
                        id: response.data.id,
                        name: response.data.name,
                        team: response.data.users ?? [],
                    });
                    setLoading(false);
                }
                else {
                    setError(response.data == null ? "Ошибка при загрузке данных" : response.data);
                    setLoading(false);
                }
            });
        } else {
            setLoading(false);
        }
    }, []);

    const onSaveClicked = () => {
        if(data.name!="")
        {
            setLoading(true);
            api.editTeam(data).then(response => {
                if(response.status !== 200) {
                    setError(response.data == null ? "Ошибка при загрузке данных" : response.data);
                }
                else {
                    setData({
                        id: response.data.id,
                        name: response.data.name,
                        team: response.data.users ?? [],
                    });
                }
                setLoading(false);
            })
        }
    }

    const onDeleteTeam = () => {
        setLoading(true);
        setNeedConfirm(false);
        api.deleteTeam(data).then(response => {
            if(response.status !== 200) {
                setError(response.data);
            } else {
                if(response.data.message!=undefined && response.data.message!=null && response.data.message!="")
                {
                    if(response.data.message == "tips not paid")
                    {
                        setError("На балансе команды имеются не выведенные средства. Перед удалением команды,  выведите все доступные средства с баланса команды");
                    } else {
                        setError(response.data.message);
                    }
                } else {
                    navigate("/employees/teams");
                }
            }
            setLoading(false);
        });
    }

    const onRemoveFromTeam = () => {
        let newTeam = data.team.filter(item => item.id != userFromTeam);
        setData({...data, team: newTeam});
        setNeedConfirmRemoveFromTeam(false);
    }


    const askRemoveFromTeamConfirm = (userId) => {
        setUserFromTeam(userId);
        setNeedConfirmRemoveFromTeam(true);
    }

    const askDeleteConfirm = () => {
        setNeedConfirm(true);
    }

    const onAdd2Team = (user) => {
        let newTeam = data.team.filter(item => true);
        newTeam.push(user);
        setData({...data, team: newTeam});
        setPopupTeam(false);
    }

    const showPopupTeam = () => {
        // load employees for team
        setPopupTeam(true);
        setLoadingPopup(true);
        api.getUsers4Team(data.id).then(response => {
            if(response.status !== 200) {
                setError(response.data == null ? "Ошибка при загрузке данных" : response.data);
                setPopupTeam(false);
            }
            else {
                let newUsers = response.data.filter(item => {
                    let hasUser = false;
                    data.team.map( u => {
                        if(u.id == item.id)
                        {
                            hasUser = true;
                        }
                    } )
                    return !hasUser;
                } );
                setUsers4team(newUsers);
            }
            setLoadingPopup(false);
        })
    }

    const onManageTips = (index, manageTips) => {
        let newTeam = data.team;
        newTeam[index].manageTips = manageTips;
        setData({...data, team: newTeam});
    }

    return <Wrapper navigate={navigate}>
            {loading && <span className={"title"}>Загрузка...</span>}
            {error && <Alert variant={"danger"}>Ошибка: {error}</Alert>}

            {!loading && !error && (window.user.role === "ADMIN") && <Form className={"form form-wrapper"}>

                <TeamButtonsNav addSharing={data.id!=0} teamId={data.id} />

                <label>
                    Название
                    <input type={"text"} value={data.name} onChange={e => setData({...data, name: e.target.value })} />
                </label>

                <label>
                    Состав команды
                    <div style={{display: 'flex', flexFlow: 'column', marginTop: 20}}>
                        {data.team.map((item, i) => {
                            return <div key={"u"+i} className={"flex"} style={{marginTop: 15, alignItems: 'center'}}>
                                <span style={{flex: 2}}>{item.name}</span>
                                <span className='row' style={{paddingRight: 10}}><span className='mobile'>Снимать все чаевые</span><FormCheck type="switch" id={`check${item.id}`} className="d-flex justify-content-center" checked={item.manageTips} onChange={e => {onManageTips(i, !item.manageTips)}}></FormCheck></span>
                                <Button variant="primary" onClick={() => {
                                    askRemoveFromTeamConfirm(item.id);
                                }} style={{flex: 1}}>Удалить</Button>
                                </div>
                        } )}
                        {data.id > 0  && <div style={{marginTop: 20, marginBottom: 20}}>
                            <Button variant="primary" onClick={showPopupTeam}>Добавить в команду</Button>
                        </div>}
                    </div>
                </label>

                <div className={"flex"} style={{marginBottom: 40, gap: 40}}>
                    <Button variant="primary" disabled={data.id==0} onClick={askDeleteConfirm}>Удалить</Button>
                    <Button variant="primary" onClick={onSaveClicked}>Сохранить</Button>
                </div>
            </Form>}
            {popupTeam && <div className='mail-confirm-popup'>
                    <div className='mail-confirm-popup-body' style={{height: 312}}>
                        <input type={"text"} style={{padding: '0 0 10px 0'}}
                            value={employeeListFilter} onChange={e => setEmployeeListFilter(e.target.value)} 
                            placeholder='Поиск' />
                        <div className='mail-confirm-text' style={{overflow: 'auto', height: 'calc(100% - 55px - 70px)'}}>
                            {loadingPopup && <>Загружаю данные</>}
                            {!loadingPopup && users4team.map((item, i) => {
                                if((item.name+"").toLowerCase().indexOf(employeeListFilter.toLowerCase())>=0)
                                {
                                    return <div key={"tu"+i}>{item.name}
                                        <Button variant="primary" onClick={() => {onAdd2Team(item)}}>Добавить</Button>
                                    </div>
                                }
                            })}
                        </div>
                        <Button variant="primary" className='mail-confirm-cancel' onClick={() => {setPopupTeam(false)}}>Закрыть</Button>
                    </div>
                </div>
            }
            {needConfirm && <div className='mail-confirm-popup'>
                    <div className='mail-confirm-popup-body'>
                        <div className='mail-confirm-text'>Вы уверены, что хотите удалить команду?</div>
                        <Button variant="primary" className='mail-confirm-cancel' onClick={() => {setNeedConfirm(false)}}>Отменить</Button>
                        <Button variant="primary" onClick={onDeleteTeam}>Подтвердить</Button>
                    </div>
                </div>
            }
            {needConfirmRemoveFromTeam && <div className='mail-confirm-popup'>
                    <div className='mail-confirm-popup-body'>
                        <div className='mail-confirm-text'>Удалить сотрудника из команды?</div>
                        <Button variant="primary" className='mail-confirm-cancel' onClick={() => {setNeedConfirmRemoveFromTeam(false)}}>Отменить</Button>
                        <Button variant="primary" onClick={onRemoveFromTeam}>Подтвердить</Button>
                    </div>
                </div>
            }

        </Wrapper>;
};

export default TeamEdit;

