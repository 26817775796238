import Wrapper from '../components/Wrapper'
import withParams from "../services/withParams";
import * as React from "react";
import api from "../services/api";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import Moment from "react-moment";
import calendar from '../resources/images/calendar.svg'
import Image from "react-bootstrap/Image";
import {Chart, ArcElement} from 'chart.js'
import CurrencyFormat from 'react-currency-format';
import '../resources/styles/manager.css'
import Paginator from "../components/Paginator";
import like from '../resources/images/like.svg'
import dislike from '../resources/images/dislike.svg'
import {Link} from "react-router-dom";

Chart.register(ArcElement);

export default withParams(class Manager extends React.Component {
    state = {
        loadingTransactions: true,
        loadingAmount: true,
        loadingLikes: true,
        transactions: [],
        from: new Date(),
        to: new Date(),
        balance: 1000,
        myAmount: 0,
        allAmount: 0,
        premium: 0,
    };

    constructor(props) {
        super(props);

        api.getMyTipsAmount().then(responseMy => {
            if(responseMy.status === 200) {
                api.getTipsAmount().then(responseAll => {
                    if(responseAll.status === 200) {
                        this.setState({
                            loadingAmount: false,
                            allAmount: responseAll.data.total,
                            premium: responseAll.data.premium,
                            myAmount: responseMy.data
                        })
                    }
                    else {
                        this.setState({
                            error: responseAll.data,
                            loadingAmount: false
                        });
                    }
                });
            }
            else {
                this.setState({
                    error: responseMy.data,
                    loadingAmount: false
                });
            }
        });

        this.getTransactions(0);
    }

    getTransactions(page) {
        api.getPageableTransactions({page: this.state.page, date: this.state.date, products: null, tips: true, size: 10}).then(response => {
            if(response.status === 200) {
                this.setState({
                    transactions: response.data,
                    loadingTransactions: false
                });
            }
            else {
                this.setState({
                    error: response.data,
                    loadingTransactions: false
                });
            }
        });

        api.getPageableTransactions({page: page, date: moment(new Date()).format("YYYY-MM-DD"), paid: true, size: 50}).then(response => {
            if(response.status === 200) {
                this.setState({
                    likes: response.data,
                    loadingLikes: false
                });
            }
            else {
                this.setState({
                    error: response.data,
                    loadingLikes: false
                });
            }
        });
    }

    render() {
        return <Wrapper navigate={this.props.navigate}>
            {(this.state.loadingTransactions || this.state.loadingAmount || this.state.loadingLikes) && <span className={"title"}>Загрузка...</span>}
            {this.state.error && <span className={"title red"}>Ошибка: {this.state.error}</span>}


            {(!this.state.loadingTransactions && !this.state.loadingAmount && !this.state.loadingLikes) && !this.state.error && <div className={"row between mobile"}>
                <div className={"col-2"}>
                   {/* <div className={"title"}>Баланс</div>
                    <div className={"manager-balance"}>
                        <CurrencyFormat value={Math.round(this.state.balance)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} />
                    </div>
*/}
                    <div className={"title"}>Баланс</div>

                    <div className={"row block manager-margin-30-top"} style={{marginBottom: 40}}>
                        <div className={"col-2 relative"}>
                            <div className={"middle"}>
                                <Image src={calendar}/> <Moment className={"list-text"} format="DD.MM.YY" />
                            </div>

                            {window.user.restaurant.modelService !== "Pays" && <div className={"manager-tips"}>
                                <div>
                                    {window.user.manageTips ?  "Мои чаевые / Общие чаевые": "Мои чаевые"}
                                </div>
                                <div style={{display: "flex", flexDirection: "row", alignItems: "flex-end"}}>
                                    <span style={{display: "inline-block"}}><CurrencyFormat value={Math.floor(this.state.myAmount)} displayType={'text'} suffix={''} thousandSeparator={' '} /></span>
                                    {window.user.manageTips && <>
                                    <span style={{fontSize: 20, margin: '0 10px'}}>/</span>
                                    <Link to={"/tips"} className={"small"}><CurrencyFormat value={Math.floor(this.state.allAmount)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} /></Link>
                                    </>}
                                </div>
                            </div>}

                            {/* {window.user.restaurant.modelService === "Pays" && <div className={"manager-tips"}>
                                Обратная связь
                                <span>{this.state.data.sums.likes + this.state.data.sums.dislikes}</span>
                            </div>}

                            <div className={"manager-likes"}>
                                <div><Image src={like}/> {this.state.data.sums.likes}</div>
                                <div><Image src={dislike}/> {this.state.data.sums.dislikes}</div>
                            </div>

                            <div className={"manager-pie"}>
                                <Doughnut width={150} height={150} options={{borderWidth: 10, borderColor: "#F5F5F5", maintainAspectRatio: false}}  data={{
                                    labels: [
                                        'Green',
                                        'Red'
                                    ],
                                    datasets: [{
                                        data: [this.state.data.sums.likes, this.state.data.sums.dislikes],
                                        backgroundColor: [
                                            'rgb(71, 197, 121)',
                                            'rgb(255, 99, 132)'
                                        ],
                                        hoverOffset: 2
                                    }]
                                }} />
                            </div>*/}
                        </div>
                    </div>
                </div>
                <div className={"manager-margin-40"} />
                <div className={"col-2"}>
                    <div className={"title title-margin"} style={{marginTop: 0}}>Чаевые</div>
                    {!this.state.loadingTransactions && this.state.transactions.content.length === 0 && <p>Чаевых пока нет...</p>}

                    {!this.state.loadingTransactions && this.state.transactions.content.length > 0 && <div>
                        {this.state.transactions.content.map((transaction, i) => {
                            return <div className={"list row between middle"} key={"wa_tr_"+i}>
                                <div className={"flex"}>
                                    <Image src={calendar}/>
                                    <Moment className={"list-text"} format="DD.MM.YY&nbsp;&nbsp;&nbsp;HH:mm">{transaction.changed}</Moment>
                                    <div className={"list-text"}>{transaction.table != null && transaction.table.toLowerCase().indexOf("стол") < 0 && "Стол"} {transaction.table}</div>
                                </div>
                                <div className={"list-sum green"}>
                                    +<CurrencyFormat value={Math.floor(transaction.tips)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} />
                                </div>
                            </div>
                        })}

                        <Paginator page={this.state.transactions} onChange={(handlePageChange) => {
                            this.getTransactions(handlePageChange)
                        }}/>
                    </div>}

                    <div className={"title title-margin"}>Оценки</div>
                    {!this.state.loadingLikes && this.state.likes.content.filter(item => item.like !== undefined).length === 0 && <p>Оценок ещё нет...</p>}
                    {!this.state.loadingLikes && this.state.likes.content.filter(item => item.like !== undefined).length > 0 && <div>
                        {this.state.likes.content.filter(item => item.like !== undefined).map((item, i) => {
                            return <div className={"list row between middle"} key={"wa_l_"+i}>
                                <div className={"flex"}>
                                    <Moment className={"list-text"} format="DD.MM.YY&nbsp;&nbsp;&nbsp;HH:mm">{item.changed}</Moment>
                                </div>
                                <div className={"list-sum green"}>
                                    {item.like === true ? <Image src={like} height={30}/> : item.like === false ? <Image src={dislike} height={30}/> : "Без отзыва"}
                                </div>
                            </div>
                        })}

                        <Paginator page={this.state.transactions} onChange={(handlePageChange) => {
                            this.getTransactions(handlePageChange)
                        }}/>
                    </div>}
                </div>
            </div>}
        </Wrapper>;
    }
})