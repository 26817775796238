import Wrapper from '../../components/Wrapper';
import * as React from "react";
import api from "../../services/api";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import '../../resources/styles/reports.css';
import '../../resources/styles/styles.css';
import {useState, useEffect} from "react";
import DateRangeFilter from '../../components/DateRangeFilter';
import ReportTable from '../../components/ReportTable';
import ReportLine from '../../components/ReportLine';

const NoServiceReport = (props) => {
    const [loading, setLoading] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const [from, setFrom] = useState(new Date());
    const [to, setTo] = useState(new Date());
    const [data, setData] = useState([]);
    const [error, setError] = useState('');

    const isAdmin = (window.user !==undefined && window.user.role === "ADMIN");

    useEffect(() => {
        reload();
    }, []);

    useEffect(() => {
        if(!firstLoad)
        {
            reload();
        }
    }, [from, to]);

    const reload = () => {
        if(!loading)
        {
            setLoading(true);
            const params = new URLSearchParams({from: moment(from).format("YYYY-MM-DD"), to: moment(to).format("YYYY-MM-DD")});
            api.getBaseObject("/no-service-report?"+params.toString()).then(response => {
                if(response.status === 200) {
                    let report = [...response.data.rows];

                    setError('');
                    setData(report);
                    setLoading(false);
                    setFirstLoad(false);
                }
                else {
                    setError(response.data);
                    setLoading(false);
                    setFirstLoad(false);
                }
            });
        }
    };

    let totalNoService = 0;
    let totalTransactions = 0;
    let totalTitle = 'ИТОГО';
    if(data.length>0)
    {
        data.forEach((i) => {
            totalNoService+=i.noService;
            totalTransactions+=i.total;
        });
    }

    const columns = ["Ресторан", "Отжатия", "Общее количество", "%"];

    return <Wrapper navigate={props.navigate}>
            {loading && <span className={"title"}>Загрузка...</span>}
            {error && <span className={"title red"}>Ошибка: {error}</span>}

            {!loading && isAdmin && <div className={"gray-wrapper gray-table"}>
                <DateRangeFilter from={from} to={to} onChange={(f, t) => {setFrom(f); setTo(t);}} className="bottom30" />

                <ReportTable columns={columns}>

                    {data.length>0 && data.map((i, idx) => {
                        return <ReportLine key={"rl"+idx} keyPrefix={"r"+idx+"_"} data={[i.title, i.noService, i.total, (i.total === 0 ? 0 : 100*i.noService/i.total).toFixed(2)]} />
                    })}

                    <ReportLine keyPrefix="total" data={[totalTitle, totalNoService, totalTransactions, (totalTransactions === 0 ? 0 : 100*totalNoService/totalTransactions).toFixed(2)]} />
                </ReportTable>
            </div>
            }
        </Wrapper>;
}

export default NoServiceReport;
