import Wrapper from '../components/Wrapper'

import * as React from "react";
import {Link} from "react-router-dom";
import api from "../services/api";
import {Button, Container, Table} from "react-bootstrap";
import Moment from "react-moment";
import Pagination from "react-js-pagination";
import withParams from "../services/withParams";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import logo_panel from "../resources/images/logo_min.svg";
import logo_text from "../resources/images/logo-text.png";
import avatar from "../resources/images/avatar.png";
import CurrencyFormat from "react-currency-format";
import order_err from "../resources/images/order_err.png";
import order_ok from "../resources/images/order_ok.png";
import Paginator from "../components/Paginator";

export default withParams(class SelectRestaurant extends React.Component {
    state = {
        loading: true,
        data: []
    };

    constructor(props) {
        super(props);

        this.getPage(0);
    }

    getPage(number) {
        this.setState({
            loading: true
        });
        const [searchParams, setSearchParams] = this.props.search;
        setSearchParams({page: number});

        api.getPageableRestaurants({page: number, enabled: true}).then(response => {
            if(response.status === 200) {
                this.setState({
                    data: response.data,
                    loading: false
                });
            }
            else {
                this.setState({
                    error: response.data,
                    loading: false
                });
            }
        });
    }

    async selectRestaurant(id) {
        let result = await api.selectRestaurant(id);

        if(result.status === 200) {
            window.user.restaurant = result.data;
           // this.props.navigate("/");

            window.location.reload();
        }
        else {
            this.setState({
                error: result.data
            });
        }
    }

    render() {
        return <div className={"wrapper"}>

            <div className={"header"}>
                <div>
                    <Image src={logo_panel} className={"header-logo"} />
                    <Image src={logo_text} className={"d-none d-lg-inline"} />
                </div>

                <div className={"header-username"}>
                    {window.user.fullName}
                    <Image src={avatar} className={"header-avatar"} />
                </div>
            </div>

            {this.state.loading && <span className={"title"}>Загрузка доступных ресторанов...</span>}

            {!this.state.loading && <div className={"gray-wrapper gray-table"}>
                {this.state.data.length === 0 && <p className={"table-not-found"}>Ресторанов пока нет...</p>}

                {this.state.data.content.length > 0 && <div className={"col"}>
                    <div className={"order-title"} style={{margin: "30px 0 20px 30px"}}>Нажмите на ресторан для его выбора</div>

                    <div className={"table"}>
                        <div className={"thead"}>
                            <div className={"th"}>Название</div>
                            <div className={"th"}>Адрес</div>
                        </div>

                        {this.state.data.content.map((item, i) => {
                            return <div className={"tbody"} style={{cursor: "pointer"}} key={item.id} onClick={() => this.selectRestaurant(item.id)}>
                               <div className={"td"}>{item.title || item.name}</div>
                                <div className={"td"}>{item.address}</div>
                            </div>
                        })}
                    </div>

                    <Paginator page={this.state.data} onChange={(handlePageChange) => {
                        this.setState({ page: handlePageChange }, () => {
                            this.getPage(handlePageChange)
                        });
                    }}/>
                </div>}
            </div>}
        </div>;
    }
});