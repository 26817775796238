import Wrapper from '../../components/Wrapper'
import withParams from "../../services/withParams";
import * as React from "react";
import api from "../../services/api";
import {Link} from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {Alert} from "react-bootstrap";
import ToggleMenu from "../../components/ToggleMenu";
import "../../resources/styles/switch.css";
import "../../resources/styles/text-input.css";
import "../../resources/styles/restaurant.css";
import SettingsItem from '../../components/SettingsItem';
import Modal from 'react-modal';

export default withParams(class RestaurantEdit extends React.Component {
    state = {
        loading: true,
        id: null,
        address: "",
        title: "",
        organizationId: "",
        additionalOrganizationId: "",
        restaurantFee: 0,
        iikoApiLogin: "",
        paymentType: "PAYU",
        tips: 4,
        enabled: false,
        level: "Info",
        rkeeperAuth: "",
        rkeeperUrl: "",
        rkeeperXmlType: "655cee55-7bcd-4826-b6e0-a8ccf2017ef3",
        rkeeperPrepayId: 0,
        rkeeperReasonCode: 0,
        rkeeperLogin: "",
        rkeeperPassword: "",
        iikoPin: 0,
        iikoPinCashier: 0,
        payuMerchantId: "",
        payuMerchantKey: "",
        payuSbpMerchantId: "",
        payuSbpMerchantKey: "",
        payuAlcoholMerchantId: "",
        payuAlcoholMerchantKey: "",
        payuAlcoholSbpMerchantId: "",
        payuAlcoholSbpMerchantKey: "",
        rKeeperStation:  0,
        rKeeperCashier:  0,
        modelService: "PaysTips",
        rKeeperResponse: "",
        autoClose: true,
        disableDiscount: false,
        newFeatures: false,
        telegramToken: "",
        telegramBot: "",
        tip1: 7,
        tip2: 10,
        tip3: 15,
        tip4: 20,
        tipValue1: 50,
        tipValue2: 100,
        tipValue3: 200,
        tipValue4: 500,
        telegramTipsRestrict: false,
        rKeeperBonusId: "",
        disableOrderIfPrepayExists: false,
        premiumLoaded: false,
        premiumChanged: false,
        restaurantFeeMax: 499,
        paymentModel: "AllAndPartial",
        iikoAlcoholOrderType: "",
        iikoProductsOrderType: "",
        mapLinks: [],
        alcoCookingPlaceUuid: "",
        rKeeperLicenseToken: null,
        loyalSystem: "",
        loyalRestaurantId: "",
        loyalKey: "",
        loyalToken: "",
        loyalUrl: "",
        goodsTypes: [],
        addTypeModal: false,
        newTypeName: "",
        goodsTypesChanged: false,
        rKeeperLicenseTokenExpire: null,
        iikoPaymentName: 'Оплата EatAndSplit',
        iikoBonusName: 'Бонусы Eat & Split',
        menuType: 'NONE',
        waiterCallType: 'NONE',
        timeZone: "",
        startWorkingTimeStr: null,
        endWorkingTimeStr: null,
        allowInReports: true,
        rKeeperFRGroupCode: '',
        sendDailyReport: false,
        agentNumber: '',
    };

    constructor(props) {
        super(props);

        this.mapTypeNames = ['Яндекс', '2ГИС', 'Google'];
        this.mapTypes = ['yandex', '2gis', 'google'];

        api.getBaseObject("/restaurants/tipspremium").then(response => {
            if(response.status === 200) {
                this.setState({
                    premium: response.data,
                    premiumLoaded: true,
                    premiumChanged: false,
                });
            }
        });

        api.getBaseObject("/restaurants/goods").then(response => {
            if(response.status === 200) {
                this.setState({
                    goodsTypes: response.data,
                });
            }
        });

        api.getBaseObject("/restaurants/maplinks").then(response => {
            if(response.status === 200) {
                let links = {};
                for(let i=0;i<response.data.length;i++)
                {
                    links[response.data[i].mapType] = response.data[i].url;
                }
                this.setState({mapLinks: links, mapLinksChanged: false});
            }
        });

        api.getRestaurant().then(response => {
            if(response.status === 200) {
                let startTime = response.data.startWorkingTime ?? null;
                if(startTime!=null)
                {
                    let nv = startTime.split('T')[1].split(':');
                    startTime = nv[0]+":"+nv[1];
                }
                let endTime = response.data.endWorkingTime ?? null;
                if(endTime!=null)
                {
                    let nv = endTime.split('T')[1].split(':');
                    endTime = nv[0]+":"+nv[1];
                }
        
                this.setState({
                    id: response.data.id,
                    address: response.data.address ?? '',
                    title: response.data.title ?? '',
                    organizationId: response.data.organizationId,
                    additionalOrganizationId: response.data.additionalOrganizationId ?? '',
                    restaurantFee: response.data.restaurantFee ?? '',
                    restaurantTipsFee: response.data.restaurantTipsFee ?? '',
                    restaurantSbpFee: response.data.restaurantSbpFee ?? '',
                    iikoApiLogin: response.data.iikoApiLogin ?? '',
                    paymentType: response.data.paymentType ?? "PAYU",
                    tips: response.data.tips,
                    autoClose: response.data.autoClose ?? false,
                    enabled: response.data.enabled ?? false,
                    loading: false,
                    avatarBg: response.data.avatar ? "data:image/png;base64," + response.data.avatar : null,
                    backgroundBg: response.data.background ? "data:image/png;base64," + response.data.background : null,
                    iikoPin: response.data.iikoPin ?? '',
                    iikoPinCashier: response.data.iikoPinCashier ?? '',
                    level: response.data.level,
                    rKeeperAuth: response.data.rkeeperAuth ?? '',
                    rKeeperUrl: response.data.rkeeperUrl ?? '',
                    rKeeperLogin: response.data.rkeeperLogin ?? '',
                    rKeeperPassword: response.data.rkeeperPassword ?? '',
                    rKeeperXmlType: response.data.rkeeperXmlType ?? '',
                    rKeeperPrepayId: response.data.rkeeperPrepayId ?? '',
                    rKeeperBonusId: response.data.rkeeperBonusId ?? '',
                    rKeeperReasonCode: response.data.rkeeperReasonCode ?? '',
                    rKeeperStation: response.data.rkeeperStation ?? '',
                    rKeeperCashier: response.data.rkeeperCashier ?? '',
                    payuMerchantId: response.data.payuMerchantId ?? '',
                    payuMerchantKey: response.data.payuMerchantKey ?? '',
                    payuSbpMerchantId: response.data.payuSbpMerchantId ?? '',
                    payuSbpMerchantKey: response.data.payuSbpMerchantKey ?? '',
                    payuAlcoholMerchantId: response.data.payuAlcoholMerchantId ?? '',
                    payuAlcoholMerchantKey: response.data.payuAlcoholMerchantKey ?? '',
                    payuAlcoholSbpMerchantId: response.data.payuAlcoholSbpMerchantId ?? '',
                    payuAlcoholSbpMerchantKey: response.data.payuAlcoholSbpMerchantKey ?? '',
                    modelService: response.data.modelService ?? '',
                    rKeeperLicenseToken: response.data.rkeeperLicenseToken ?? '',
                    rKeeperLicenseTokenExpire: response.data.rkeeperLicenseTokenExpire ?? '',
                    disableDiscount: response.data.disableDiscount ?? false,
                    newFeatures: response.data.newFeatures ?? false,
                    tip1: response.data.tip1,
                    tip2: response.data.tip2,
                    tip3: response.data.tip3,
                    tip4: response.data.tip4,
                    tipValue1: response.data.tipValue1,
                    tipValue2: response.data.tipValue2,
                    tipValue3: response.data.tipValue3,
                    tipValue4: response.data.tipValue4,
                    reverseSelectButtons: response.data.reverseSelectButtons ?? false,
                    telegramTipsRestrict: response.data.telegramTipsRestrict ?? false,
                    disableOrderIfPrepayExists: response.data.disableOrderIfPrepayExists ?? false,
                    restaurantFeeMax: response.data.restaurantFeeMax ?? 499,
                    paymentModel: response.data.paymentModel ?? '',
                    iikoProductsOrderType: response.data.iikoProductsOrderType ?? '',
                    iikoAlcoholOrderType: response.data.iikoAlcoholOrderType ?? '',
                    alcoCookingPlaceUuid: response.data.alcoCookingPlaceUuid ?? '',
                    loyalSystem: response.data.loyalSystem ?? '',
                    loyalRestaurantId: response.data.loyalRestaurantId ?? '',
                    loyalKey: response.data.loyalKey ?? '',
                    loyalToken: response.data.loyalToken ?? '',
                    loyalUrl: response.data.loyalUrl ?? '',
                    avatarServer: response.data.avatar ?? '',
                    backgroundServer: response.data.background ?? '',
                    goodsTypesChanged: false,
                    iikoPaymentName: response.data.iikoPaymentName ?? '',
                    iikoBonusName: response.data.iikoBonusName ?? '',
                    menuType: response.data.menuType ?? 'NONE',
                    waiterCallType: response.data.waiterCallType ?? 'NONE',
                    timeZone: response.data.timeZone ?? '',
                    startWorkingTimeStr: startTime,
                    endWorkingTimeStr: endTime,
                    allowInReports: response.data.allowInReports ?? true, 
                    rKeeperFRGroupCode: response.data.rkeeperFRGroupCode ?? '',
                    sendDailyReport: response.data.sendDailyReport ?? false,
                    agentNumber:  response.data.agentNumber ?? '',
                });
            }
            else {
                this.setState({
                    error: response.data,
                    loading: false
                });
            }
        });
    }

    async submit(event) {
        event.preventDefault();

        this.setState({
            loading: true,
            error: false,
            premiumLoaded: false,
        });

        let response = await api.editRestaurant({...this.state, startWorkingTime: "2000-01-01 "+(this.state.startWorkingTimeStr ?? "00:00")+":00", endWorkingTime: "2000-01-01 "+(this.state.endWorkingTimeStr ?? "00:00")+":00" });

        if(this.state.premiumChanged)
        {
            api.setBaseObject("/restaurants/tipspremium", {premium: this.state.premium, id: this.state.id}, false).then(
                response => {
                    if(response.status === 200) {
                        this.setState({premiumLoaded: true, premiumChanged: false});
                        if(!this.state.mapLinksChanged && !this.state.goodsTypesChanged)
                        {
                            window.location.reload();
                        }
                    } else {
                        this.setState({
                            error: response.data,
                            loading: false
                        });
                    }
                }
            )
        } else {
            this.setState({premiumLoaded: true});
        }

        if(this.state.mapLinksChanged)
        {
            api.setBaseObject("/restaurants/maplinks", {...this.state.mapLinks, id: this.state.id}, false).then(
                    response => {
                    if(response.status === 200) {
                        this.setState({mapLinksChanged: false});
                        if(!this.state.premiumChanged && !this.state.goodsTypesChanged)
                        {
                            window.location.reload();
                        }
                    } else {
                        this.setState({
                            error: response.data,
                            loading: false
                        });
                    }
                }
            )
        }

        if(this.state.goodsTypesChanged)
        {
            api.setBaseObject("/restaurants/goods", {goods: this.state.goodsTypes, id: this.state.id}, false).then(
                response => {
                    if(response.status === 200) {
                        this.setState({goodsTypesChanged: false});
                        if(!this.state.mapLinksChanged && !this.state.premiumChanged)
                        {
                            window.location.reload();
                        }
                    } else {
                        this.setState({
                            error: response.data,
                            loading: false
                        });
                    }
                }
            )
        } else {
            this.setState({goodsTypesChanged: false});
        }

        this.setState({
            loading: false
        });

        if(response.status !== 200) {
            this.setState({
                error: response.data
            });
        }
        else {
            if(!this.state.premiumChanged && !this.state.mapLinksChanged && !this.state.goodsTypesChanged)
            {
                window.location.reload();
            }
        }
    }

    addPremium() {
        let p = this.state.premium;
        p.push({maxValue: 0, premium: 0, premiumIsPercent: true});
        this.setState({premium: p, premiumChanged: true});
    }

    changePremiumMax(idx, val) {
        let p = this.state.premium;
        p[idx].maxValue = val;
        this.setState({premium: p, premiumChanged: true});
    }

    changePremiumIsPercent(idx, val) {
        let p = this.state.premium;
        p[idx].premiumIsPercent = val;
        this.setState({premium: p, premiumChanged: true});
    }

    changePremiumVal(idx, val) {
        let v = val*1;
        if(v<0)
        {
            this.setState({error: "Процент комиссии не может быть меньше 0"})
        }
        if(v>100)
        {
            this.setState({error: "Процент комиссии не может быть больше 100"})
        }
        if(v>=0 && v<=100)
        {
            let p = this.state.premium;
            p[idx].premium = val;
            this.setState({premium: p, premiumChanged: true});
        }
    }

    delPremium(idx) {
        let p = this.state.premium;
        p.splice(idx, 1);
        this.setState({premium: p, premiumChanged: true});
    }

    addGoodsType() {
        api.setBaseObject("/restaurants/add-type", {name: this.state.newTypeName, id: this.state.id}).then(response => {
            if(response.status === 200) {
                this.setState({
                    newTypeName: "",
                });
                window.location.reload();
            }
        });
    }

    changeGoodsValue(idx, field, val) {
        const goods = this.state.goodsTypes;
        goods[idx][field] = val;
        goods[idx]['changed'] = true;
        this.setState({goodsTypes: goods, goodsTypesChanged: true});
    }

    render() {

        let logoImage = this.state.avatarImg!=null ? this.state.avatarImg :
            ( this.state.avatarServer !== "" ? api.getRestBrandingUrl('get-logo/'+this.state.avatarServer) :
            api.getRestBrandingUrl('/images/jpeg/'+this.state.id+'.avatar.jpg'));
        let backgroundImage = (this.state.backgroundImg!=null ? this.state.backgroundImg :
            ( this.state.backgroundServer !== "" ? api.getRestBrandingUrl('get-background/'+this.state.backgroundServer) :
            api.getRestBrandingUrl('/images/jpeg/'+this.state.id+'.bg.jpg')));

        const isAdmin = window.user.role === "ADMIN";
        const isSupport = window.user.role === "SUPPORT";
        const isManager = window.user.restaurantRole === "MANAGER";

        return <Wrapper navigate={this.props.navigate}>
            {this.state.loading && <span className={"title"}>Загрузка...</span>}
            {this.state.error && <span className={"title red"}>Ошибка: {this.state.error}</span>}

            {!this.state.loading && (isManager || isSupport || isAdmin) && <Form onSubmit={this.submit.bind(this)} className={"form form-wrapper"}>
                <div className={"row mobile"} style={{marginBottom: 20}}>
                    <Link to={"/terminals"} className={"flex"}><Button style={{marginBottom: 5}}>Терминалы</Button></Link>
                    &nbsp;
                    <Link to={"tables"} className={"flex"}><Button style={{marginBottom: 5}}>Столы</Button></Link>
                    &nbsp;
                    {isAdmin && <Link to={"surcharge"} className={"flex"}><Button style={{marginBottom: 5}}>Доплата</Button></Link>}
                    &nbsp;
                    {isAdmin && <Link to={`/advanced/packages/?rest_name=${this.state.title}&rest_id=${this.state.id}`} className={"flex"}><Button style={{marginBottom: 5}}>Плагины</Button></Link>}
                    &nbsp;
                    {isAdmin && <Link to={`costs`} className={"flex"}><Button style={{marginBottom: 5}}>Расходы</Button></Link>}
                </div>

                {!(isAdmin || isSupport) && !this.state.enabled && <Alert ariant={"info"}>
                        Ресторан ждет активации администратором
                </Alert>}

                <label>
                    Название
                    <input type={"hidden"}  value={this.state.id} />
                    <input type={"text"} value={this.state.title} onChange={e => this.setState({ title: e.target.value })} />
                </label>

                <label>
                    Адрес
                    <input type={"text"} value={this.state.address} onChange={e => this.setState({ address: e.target.value })} />
                </label>

                {(isAdmin || isSupport) && <label>
                    <div>Часовой пояс<span className={"gray"}>админ</span></div>
                    <input type="number" min="-12" max="14" step="1" value={this.state.timeZone} onChange={e => this.setState({ timeZone: e.target.value })} />
                </label>}

                {(isAdmin || isSupport) && <label>
                    <div>Начало работы<span className={"gray"}>админ</span></div>
                    <input type="time" value={this.state.startWorkingTimeStr} onChange={e => {
                        this.setState({ startWorkingTimeStr: e.target.value });
                    }} />
                </label>}

                {(isAdmin || isSupport) && <label>
                    <div>Окончание работы<span className={"gray"}>админ</span></div>
                    <input type="time" value={this.state.endWorkingTimeStr} onChange={e => {
                        this.setState({ endWorkingTimeStr: e.target.value });
                    }} />
                </label>}

                {(isManager || isSupport) && <label>
                    <div>Автоматическое закрытие счёта после полной оплаты</div>

                    <select value={this.state.autoClose} onChange={e => this.setState({ autoClose: e.target.value })}>
                        <option disabled={true}>-- Выберите --</option>
                        <option value="false">Нет</option>
                        <option value="true">Да</option>
                    </select>
                </label>}

                {(isAdmin || isSupport) && <label>
                    <div>Модель оплаты<span className={"gray"}>админ</span></div>

                    <select value={this.state.paymentModel} onChange={e => this.setState({ paymentModel: e.target.value })}>
                        <option disabled={true}>-- Выберите --</option>
                        <option value="AllAndPartial">Полная или частичная</option>
                        <option value="OnlyAll">Только полная</option>
                        <option value="OnlyPartial">Только частичная</option>
                    </select>
                </label>}

                {(isManager || isAdmin || isSupport) && <label>
                    <div>Отключить оплату блюд со скидками (для некорректных версий R-Keeper)</div>

                    <select value={this.state.disableDiscount} onChange={e => this.setState({ disableDiscount: e.target.value })}>
                        <option disabled={true}>-- Выберите --</option>
                        <option value="false">Нет</option>
                        <option value="true">Да</option>
                    </select>
                </label>}

                {(isManager || isAdmin || isSupport) && <label>
                    <div>Модель оплаты</div>

                    <select value={this.state.modelService} onChange={e => this.setState({ modelService: e.target.value })}>
                        <option disabled={true}>-- Выберите --</option>
                        <option value="PaysTips">Оплата счёта + чаевые с выбором действия</option>
                        <option value="TipsInPays">Оплата счёта + чаевые без выбора действия</option>
                        <option value="Pays">Только оплата счёта</option>
                        <option value="Tips">Только оплата чаевых</option>
                    </select>
                </label>}

                {(isManager || isAdmin || isSupport) && <label>
                    <div>Порядок выбора действия</div>

                    <select value={this.state.reverseSelectButtons} onChange={e => this.setState({ reverseSelectButtons: e.target.value })}>
                        <option disabled={true}>-- Выберите --</option>
                        <option value="false">Оплатить счёт | Оставить чаевые</option>
                        <option value="true">Оставить чаевые | Оплатить счёт</option>
                    </select>
                </label>}

                {(isAdmin || isSupport) && <ToggleMenu title={"Сервисный сбор"}>
                    <label>
                        <div>Сервисный сбор<span className={"gray"}>админ</span></div>
                        <input type={"text"} value={this.state.restaurantFee} onChange={e => this.setState({ restaurantFee: e.target.value })} />
                    </label>

                    <label>
                        <div>Максимальное значение серивсного сбора<span className={"gray"}>админ</span></div>
                        <input type={"text"} value={this.state.restaurantFeeMax} onChange={e => this.setState({ restaurantFeeMax: e.target.value })} />
                    </label>

                    <label>
                        <div>Процент сбора только чаевых<span className={"gray"}>админ</span></div>
                        <input type={"text"} value={this.state.restaurantTipsFee} onChange={e => this.setState({ restaurantTipsFee: e.target.value })} />
                    </label>
                </ToggleMenu>}

                {(isAdmin || isSupport) && <label>
                    <div>Ставка по эквайрингу (только СБП)<span className={"gray"}>админ</span></div>
                    <input type={"text"} value={this.state.restaurantSbpFee} onChange={e => this.setState({ restaurantSbpFee: e.target.value })} />
                </label>}

                {(isAdmin || isSupport) && <ToggleMenu title={"Чаевые"}>
                    <label>
                        <div>Процент чаевых</div>
                        <div className="tips-span-container">
                            <input type="text" className="tips-span" onChange={ e => this.setState({ tip1: e.target.value }) } value={this.state.tip1} />
                            <input type="text" className="tips-span" onChange={ e => this.setState({ tip2: e.target.value }) } value={this.state.tip2} />
                            <input type="text" className="tips-span" onChange={ e => this.setState({ tip3: e.target.value }) } value={this.state.tip3} />
                            <input type="text" className="tips-span" onChange={ e => this.setState({ tip4: e.target.value }) } value={this.state.tip4} />
                        </div>
                    </label>

                    <label>
                        <div>Значения чаевых для перевода без интеграции</div>
                        <div className="tips-span-container">
                            <input type="text" className="tips-span" onChange={ e => this.setState({ tipValue1: e.target.value }) } value={this.state.tipValue1} />
                            <input type="text" className="tips-span" onChange={ e => this.setState({ tipValue2: e.target.value }) } value={this.state.tipValue2} />
                            <input type="text" className="tips-span" onChange={ e => this.setState({ tipValue3: e.target.value }) } value={this.state.tipValue3} />
                            <input type="text" className="tips-span" onChange={ e => this.setState({ tipValue4: e.target.value }) } value={this.state.tipValue4} />
                        </div>
                    </label>

                    <label>
                        <div>Комиссия за вывод чаевых</div>
                        <div>
                            {this.state.premiumLoaded && this.state.premium.map((item, i) => {
                                return <div key={"prem"+i} style={{marginBottom: 25, marginLeft: 20, marginRight: 20}}>
                                    <label style={{marginLeft: 10}}>До</label>
                                    <input style={{marginLeft: 20}} type="text" value={item.maxValue}
                                                onChange={(e) => { this.changePremiumMax(i, e.target.value) }} />
                                    <div className={"table-checkbox"} style={{backgroundColor: "transparent"}}>
                                        <input type="checkbox"
                                            checked={item.premiumIsPercent}
                                            onChange={e => {
                                                this.changePremiumIsPercent(i, e.target.checked);
                                            }} />
                                        <span>Процент/рубли</span>
                                    </div>
                                    <label style={{marginLeft: 10}}>{item.premiumIsPercent ?? false ? "Процент" : "Рубли" }</label>
                                    <input style={{marginLeft: 20}} type="text" value={item.premium}
                                                onChange={(e) => { this.changePremiumVal(i, e.target.value)}} />
                                    <Button  style={{marginTop: 15}} type="button" onClick={() => {this.delPremium(i)}}>Удалить</Button>
                                </div>
                            })}
                        </div>
                        <div>
                            <Button type="button" onClick={() => {this.addPremium()}}>Добавить</Button>
                        </div>
                    </label>
                </ToggleMenu>}


                {(isAdmin || isSupport)&& <label>
                    <div>Номер агента<span className={"gray"}>админ</span></div>
                    <input type={"text"} value={this.state.agentNumber} onChange={e => this.setState({ agentNumber: e.target.value })} />
                </label>}

                {(isAdmin || isSupport) && <label>
                    <div>Платежная система <span className={"gray"}>админ</span></div>
                    <select value={this.state.paymentType} onChange={e => this.setState({ paymentType: e.target.value })} >
                        <option disabled={true}>--Выберите --</option>
                        <option value="PAYONLINE">PayOnline</option>
                        <option value="PAYMO">Paymo</option>
                        <option value="PAYU">PayU</option>
                        <option value="PAYU_TEST">PayU Тест</option>
                        <option value="FAKE">Демо</option>
                    </select>
                </label>}

                {(isAdmin || isSupport) && <label>
                    <div>Участие в бета-тестировании <span className={"gray"}>админ</span></div>
                    <select value={this.state.newFeatures} onChange={e => this.setState({ newFeatures: e.target.value })}>
                        <option disabled={true}>-- Выберите --</option>
                        <option value="false">Нет</option>
                        <option value="true">Да</option>
                    </select>
                </label>}

                {(isAdmin || isSupport) && <label>
                    <div>Активен <span className={"gray"}>админ</span></div>
                    <select value={this.state.enabled} onChange={e => this.setState({ enabled: e.target.value })}>
                        <option disabled={true}>-- Выберите --</option>
                        <option value="false">Нет</option>
                        <option value="true">Да</option>
                    </select>
                </label>}

                {(isAdmin || isSupport) && <label>
                    <div>Блокировать оплату, если в счёте есть другие оплаты <span className={"gray"}>админ</span></div>
                    <select value={this.state.disableOrderIfPrepayExists} onChange={e => this.setState({ disableOrderIfPrepayExists: e.target.value })}>
                        <option disabled={true}>-- Выберите --</option>
                        <option value="false">Нет</option>
                        <option value="true">Да</option>
                    </select>
                </label>}

                {(isAdmin || isSupport) &&
                    <label>
                        <div>Тип онлайн меню</div>

                        <select value={this.state.menuType} onChange={e => this.setState({ menuType: e.target.value })}>
                            <option value="NONE">Без онлайн меню</option>
                            <option value="OnlyMenu">Онлайн меню без оплаты</option>
                            <option value="MenuAndPayment">Онлайн меню с оплатой</option>
                        </select>
                    </label>}

                {(isAdmin || isSupport) &&
                    <label>
                        <div>Вызов официанта</div>

                        <select value={this.state.waiterCallType} onChange={e => this.setState({ waiterCallType: e.target.value })}>
                            <option value="NONE">Без вызова</option>
                            <option value="OnlyCall">Простой вызов</option>
                            <option value="CallWithMessage">Вызов + сообщение</option>
                        </select>
                    </label>}

                {(isAdmin || isSupport) && <label>
                    <div>Учитывать в отчетах <span className={"gray"}>админ</span></div>
                    <select value={this.state.allowInReports} onChange={e => this.setState({ allowInReports: e.target.value })}>
                        <option disabled={true}>-- Выберите --</option>
                        <option value="false">Нет</option>
                        <option value="true">Да</option>
                    </select>
                </label>}

                {isAdmin && <label>
                    <div>Отсылать ежедневный отчет через час после окончания работы <span className={"gray"}>админ</span></div>
                    <select value={this.state.sendDailyReport} onChange={e => this.setState({ sendDailyReport: e.target.value })}>
                        <option disabled={true}>-- Выберите --</option>
                        <option value="false">Нет</option>
                        <option value="true">Да</option>
                    </select>
                </label>}

                {(isAdmin || isSupport) && <ToggleMenu title={"Ссылки на карты"}>
                    {this.mapTypeNames.map((item, i) => {
                    let mapType = this.mapTypes[i];
                    return <label key={"map"+i}>
                        <div>Ссылка на {item} карту</div>
                        <input type={"text"} value={this.state.mapLinks[mapType]}
                        onChange={e => {
                                let newMaps = JSON.parse(JSON.stringify(this.state.mapLinks));
                                newMaps[mapType] = e.target.value;
                                this.setState({ mapLinks: newMaps, mapLinksChanged: true })
                            }} />
                </label>
                })}
                </ToggleMenu>}


                {(isAdmin || isSupport) && <ToggleMenu title={"Система лояльности"}>
                    <label>
                        <div>Система лояльности</div>

                        <select value={this.state.loyalSystem} onChange={e => this.setState({ loyalSystem: e.target.value })}>
                            <option value="">Без системы лояльности</option>
                            <option value="UDS">UDS</option>
                            <option value="PrimeHill">Prime Hill</option>
                            <option value="PremiumBonus">Premium Bonus</option>
                            <option value="LRG">LRG</option>
                            <option value="LoCards">LoCards</option>
                            <option value="Mindbox">Mindbox</option>
                        </select>
                    </label>

                    {((this.state.loyalSystem ?? "") === "UDS" || (this.state.loyalSystem ?? "") === "PrimeHill") &&
                        <SettingsItem name={(this.state.loyalSystem ?? "") === "PrimeHill" ? "Organization ID в системе лояльности" : "ID в системе лояльности"} value={this.state.loyalRestaurantId} onChange={(v) => {this.setState({loyalRestaurantId: v})}} />
                    }
                    {((this.state.loyalSystem ?? "") === "UDS" || (this.state.loyalSystem ?? "") === "Mindbox") &&
                        <SettingsItem name="API key в системе лояльности" value={this.state.loyalKey} onChange={(v) => {this.setState({loyalKey: v})}} />
                    }
                    {((this.state.loyalSystem ?? "") === "PremiumBonus" || (this.state.loyalSystem ?? "") === "PrimeHill" || (this.state.loyalSystem ?? "") === "LRG" || (this.state.loyalSystem ?? "") === "Mindbox") &&
                        <SettingsItem name="Token в системе лояльности" value={this.state.loyalToken} onChange={(v) => {this.setState({loyalToken: v})}} />
                    }
                    {(this.state.loyalSystem ?? "") === "UDS" &&
                        <SettingsItem name="Ссылка на ресторан в системе лояльности" value={this.state.loyalUrl} onChange={(v) => {this.setState({loyalUrl: v})}} />
                    }
                </ToggleMenu>}

                {(isAdmin || isSupport) && <ToggleMenu title={"Логотип и фон"}>
                    <div style={{marginRight: '10px', width: 390}} className='images'>
                        <div>
                            <label>Логотип (старый дизайн - 200x65, новый дизайн - 83x40)
                            <div className='logo-container'>
                                <input type="file" accept="image/*" onChange={ev => {
                                    let reader = new FileReader();
                                    reader.readAsDataURL(ev.target.files[0]);
                                    reader.onloadend = (e) => {
                                        let img = new window.Image();

                                        img.onload = () => {
                                            this.setState({
                                                avatar: ev.target.files[0],
                                                avatarImg: [reader.result]
                                            })
                                        };
                                        img.src = reader.result;
                                    }
                                }
                                } />

                                <div className='logo'>
                                <div
                                style={{backgroundImage: ('url('+logoImage+')')}} className='branding logo-image'/>
                                </div>
                            </div>
                            </label>
                        </div>


                        <div>
                            <label>Фон (700x500)
                            <div className='logo-container'>
                                <input type="file" accept="image/*" onChange={ev => {
                                    let reader = new FileReader();
                                    reader.readAsDataURL(ev.target.files[0]);
                                    reader.onloadend = (e) => {
                                        let img = new window.Image();

                                        img.onload = () => {
                                            this.setState({
                                                background: ev.target.files[0],
                                                backgroundImg: [reader.result]
                                            })
                                        };
                                        img.src = reader.result;
                                    }
                                }
                                } />

                                <div
                                style={{backgroundImage: ('url('+backgroundImage+')')}} className='branding back'/>
                            </div>
                            </label>
                        </div>

                    </div>
                </ToggleMenu>}

                <div style={{clear: "both"}} />

                {(isAdmin || isSupport) && <ToggleMenu title={"Общие настройки"}>
                    <label>
                        <div>Внешний идентификатор <span className={"gray"}>админ</span></div>
                        <input type={"text"} value={this.state.organizationId} />
                    </label>

                    <label>
                        <div>Дополнительный внешний идентификатор <span className={"gray"}>админ</span></div>
                        <input type={"text"} value={this.state.additionalOrganizationId} />
                    </label>

                    <label>
                        <div>Уровень логгирования плагина <span className={"gray"}>админ</span></div>
                        <select value={this.state.level} onChange={e => this.setState({ level: e.target.value })}>
                            <option disabled={true}>-- Выберите --</option>
                            <option value="Trace">Trace</option>
                            <option value="Debug">Debug</option>
                            <option value="Info">Info</option>
                            <option value="Warn">Warn</option>
                            <option value="Error">Error</option>
                        </select>
                    </label>

                    <label>
                        <div>Уведолмения о чаевых в telegram только самому официанту <span className={"gray"}>админ</span></div>
                        <select value={this.state.telegramTipsRestrict} onChange={e => this.setState({ telegramTipsRestrict: e.target.value })}>
                            <option disabled={true}>-- Выберите --</option>
                            <option value="false">Нет</option>
                            <option value="true">Да</option>
                        </select>
                    </label>

                </ToggleMenu>}

                {(isAdmin || isSupport) && <ToggleMenu title={"Настройки R-Keeper"}>
                    <label>
                        <div>R-Keeper кассовый сервер <span className={"gray"}>админ</span></div>
                        <input type={"text"} value={this.state.rKeeperUrl} onChange={e => this.setState({ rKeeperUrl: e.target.value })} />
                    </label>

                    <label>
                        <div>R-Keeper тип XML интерфейса <span className={"gray"}>админ</span></div>
                        <select value={this.state.rKeeperXmlType} onChange={e => this.setState({ rKeeperXmlType: e.target.value })}>
                            <option disabled={true}>-- Выберите --</option>
                            <option value="655cee55-7bcd-4826-b6e0-a8ccf2017ef3">12 месяцев</option>
                            <option value="4458d8b1-0019-4025-8c7a-60a83ad64b66">1 месяц</option>
                        </select>
                    </label>

                    <label>
                        <div>R-Keeper данные авторизации (логин:пароль) <span className={"gray"}>админ</span></div>
                        <input type="text" value={this.state.rKeeperAuth} onChange={e => this.setState({ rKeeperAuth: e.target.value })} />
                    </label>

                    <label>
                        <div>R-Keeper логин авторизации токена<span className={"gray"}>админ</span></div>
                        <input type="text" value={this.state.rKeeperLogin} onChange={e => this.setState({ rKeeperLogin: e.target.value })} />
                    </label>

                    <label>
                        <div>R-Keeper пароль авторизации токена<span className={"gray"}>админ</span></div>
                        <input type="text" value={this.state.rKeeperPassword} onChange={e => this.setState({ rKeeperPassword: e.target.value })} />
                    </label>

                    <label>
                        <div>R-Keeper идентификатор валюты предоплаты <span className={"gray"}>админ</span></div>
                        <input type="text" value={this.state.rKeeperPrepayId} onChange={e => this.setState({ rKeeperPrepayId: e.target.value })} />
                    </label>

                    <label>
                        <div>R-Keeper код бонусной валюты <span className={"gray"}>админ</span></div>
                        <input type="text" value={this.state.rKeeperBonusId} onChange={e => this.setState({ rKeeperBonusId: e.target.value })} />
                    </label>

                    <label>
                        <div>R-Keeper код причины внесения <span className={"gray"}>админ</span></div>
                        <input type="text" value={this.state.rKeeperReasonCode} onChange={e => this.setState({ rKeeperReasonCode: e.target.value })} />
                    </label>

                    <label>
                        <div>R-Keeper код кассира закрытия счета <span className={"gray"}>админ</span></div>
                        <input type="text" value={this.state.rKeeperCashier} onChange={e => this.setState({ rKeeperCashier: e.target.value })} />
                    </label>

                    <label>
                        <div>R-Keeper код станции закрытия счета <span className={"gray"}>админ</span></div>
                        <input type="text" value={this.state.rKeeperStation} onChange={e => this.setState({ rKeeperStation: e.target.value })} />
                    </label>

                    <label>
                        <div>R-Keeper код группы, используемой для разделения на ФР <span className={"gray"}>админ</span></div>
                        <input type="text" value={this.state.rKeeperFRGroupCode} onChange={e => this.setState({ rKeeperFRGroupCode: e.target.value })} />
                    </label>

                    {this.state.rKeeperLicenseToken && this.state.rKeeperLicenseTokenExpire && <label>
                        <div>R-Keeper проверка лицензии</div>
                        <textarea style={{height: 50}} disabled={true} value={
                            `Идентификатор: ${this.state.rKeeperLicenseToken}\nДата окончания: ${this.state.rKeeperLicenseTokenExpire}`}
                        />
                    </label>}
                </ToggleMenu>}

                {(isAdmin || isSupport) && <ToggleMenu title={"Настройки Iiko"}>
                    <label>
                        <div>Iiko пин <span className={"gray"}>админ</span></div>
                        <input type="text" value={this.state.iikoPin} onChange={e => this.setState({ iikoPin: e.target.value })} />
                    </label>
                    <label>
                        <div>Iiko пин кассира <span className={"gray"}>админ</span></div>
                        <input type="text" value={this.state.iikoPinCashier} onChange={e => this.setState({ iikoPinCashier: e.target.value })} />
                    </label>

                    {/* <label>
                        <div>Тип заказа для мерчанта блюд <span className={"gray"}>админ</span></div>
                        <input type="text" value={this.state.iikoProductsOrderType} onChange={e => this.setState({ iikoProductsOrderType: e.target.value })} />
                    </label>

                    <label>
                        <div>Тип заказа для мерчанта алкоголя <span className={"gray"}>админ</span></div>
                        <input type="text" value={this.state.iikoAlcoholOrderType} onChange={e => this.setState({ iikoAlcoholOrderType: e.target.value })} />
                    </label>

                    <label>
                        <div>Uuid типа места приготовления алко блюд <span className={"gray"}>админ</span></div>
                        <input type="text" value={this.state.alcoCookingPlaceUuid} onChange={e => this.setState({ alcoCookingPlaceUuid: e.target.value })} />
                    </label> */}

                    <label>
                        <div>Название валюты оплаты <span className={"gray"}>админ</span></div>
                        <input type="text" value={this.state.iikoPaymentName} onChange={e => this.setState({ iikoPaymentName: e.target.value })} />
                    </label>

                    <label>
                        <div>Название бонусной валюты <span className={"gray"}>админ</span></div>
                        <input type="text" value={this.state.iikoBonusName} onChange={e => this.setState({ iikoBonusName: e.target.value })} />
                    </label>

                    {/*

                    <label>
                        <div>Ориентир несколько ООО <span className={"gray"}>админ</span></div>
                        <select value={this.state.iikoSubOrderPaymentSelect} onChange={e => this.setState({ iikoSubOrderPaymentSelect: e.target.value })}>
                            <option value="NONE">Место приготовления</option>
                            <option value="ALCOHOL">Если заказ разделен - Акциз, не разделен - Блюда</option>
                            <option value="PRODUCTS">Если заказ разделен - Блюда, не разделен - Акциз</option>
                        </select>
                    </label>*/}
                </ToggleMenu>}

                {(isAdmin || isSupport) && 1 == 2 && <ToggleMenu title={"Настройки PayU"}>
                    <label>
                        <div>Мерчант ресторана <span className={"gray"}>админ</span></div>
                        <input type="text" value={this.state.payuMerchantId} onChange={e => this.setState({ payuMerchantId: e.target.value })} />
                    </label>

                    <label>
                        <div>Ключ мерчанта ресторана <span className={"gray"}>админ</span></div>
                        <input type="text" value={this.state.payuMerchantKey} onChange={e => this.setState({ payuMerchantKey: e.target.value })} />
                    </label>

                    <label>
                        <div>СБП мерчант ресторана <span className={"gray"}>админ</span></div>
                        <input type="text" value={this.state.payuSbpMerchantId} onChange={e => this.setState({ payuSbpMerchantId: e.target.value })} />
                    </label>

                    <label>
                        <div>СБП ключ мерчанта ресторана <span className={"gray"}>админ</span></div>
                        <input type="text" value={this.state.payuSbpMerchantKey} onChange={e => this.setState({ payuSbpMerchantKey: e.target.value })} />
                    </label>

                    <hr />

                    <label>
                        <div>Мерчант ресторана алкоголь <span className={"gray"}>админ</span></div>
                        <input type="text" value={this.state.payuAlcoholMerchantId} onChange={e => this.setState({ payuAlcoholMerchantId: e.target.value })} />
                    </label>

                    <label>
                        <div>Ключ мерчанта ресторана алкоголь <span className={"gray"}>админ</span></div>
                        <input type="text" value={this.state.payuAlcoholMerchantKey} onChange={e => this.setState({ payuAlcoholMerchantKey: e.target.value })} />
                    </label>

                    <label>
                        <div>СБП мерчант ресторана алкоголь <span className={"gray"}>админ</span></div>
                        <input type="text" value={this.state.payuAlcoholSbpMerchantId} onChange={e => this.setState({ payuAlcoholSbpMerchantId: e.target.value })} />
                    </label>

                    <label>
                        <div>СБП ключ мерчанта ресторана алкоголь <span className={"gray"}>админ</span></div>
                        <input type="text" value={this.state.payuAlcoholSbpMerchantKey} onChange={e => this.setState({ payuAlcoholSbpMerchantKey: e.target.value })} />
                    </label>
                </ToggleMenu>}

                {(isAdmin || isSupport) && <ToggleMenu title={"Типы продуктов"}>
                    {this.state.goodsTypes.map((item, i) => {
                        return <ToggleMenu key={"goods-type-menu-"+i} title={item.name}>
                                <SettingsItem name="Название" value={item.name} onChange={(v) => {
                                    this.changeGoodsValue(i, "name", v);
                                }} />
                                <SettingsItem name="Включен" type="checkbox" value={item.enabled ?? false} onChange={(v) => {
                                    this.changeGoodsValue(i, "enabled", v);
                                }} />
                                <SettingsItem name="По умолчанию" type="checkbox" value={item.isDefault ?? false} onChange={(v) => {
                                    this.changeGoodsValue(i, "isDefault", v);
                                }} />
                                <SettingsItem name="Группы (через запятую без пробелов)" value={item.groups} onChange={(v) => {
                                    this.changeGoodsValue(i, "groups", v);
                                }} />
                                <ToggleMenu title={"PAYU карты"}>
                                    <SettingsItem name="id продавца в PayU" value={item.payuMerchantId} onChange={(v) => {
                                        this.changeGoodsValue(i, "payuMerchantId", v);
                                    }} />
                                    <SettingsItem name="Key продавца в PayU" value={item.payuMerchantKey} onChange={(v) => {
                                        this.changeGoodsValue(i, "payuMerchantKey", v);
                                    }} />
                                </ToggleMenu>
                                <ToggleMenu title={"PAYU СБП"}>
                                    <SettingsItem name="id продавца в PayU при оплатах по СБП" value={item.payuSbpMerchantId} onChange={(v) => {
                                        this.changeGoodsValue(i, "payuSbpMerchantId", v);
                                    }} />
                                    <SettingsItem name="Key продавца в PayU при оплатах по СБП" value={item.payuSbpMerchantKey} onChange={(v) => {
                                        this.changeGoodsValue(i, "payuSbpMerchantKey", v);
                                    }} />
                                </ToggleMenu>

                                <SettingsItem name="Место приготовления для iiko / Код отдела ФР для R-Keeper" value={item.cookingPlaceUuid} onChange={(v) => {
                                    this.changeGoodsValue(i, "cookingPlaceUuid", v);
                                }} />
                                <SettingsItem name="Тип заказа для iiko" value={item.iikoOrderType} onChange={(v) => {
                                    this.changeGoodsValue(i, "iikoOrderType", v);
                                }} />
                                <ToggleMenu title={"R-Keeper"}>
                                    <SettingsItem name="R-Keeper id валюты EatAndSplit" value={item.rkeeperCurrencyId} onChange={(v) => {
                                        this.changeGoodsValue(i, "rkeeperCurrencyId", v);
                                    }} />
                                    <SettingsItem name="R-Keeper код причины внесения валюты EatAndSplit" value={item.rkeeperReasonCode} onChange={(v) => {
                                        this.changeGoodsValue(i, "rkeeperReasonCode", v);
                                    }} />
                                    <SettingsItem name="R-Keeper id бонусной скидки" value={item.rkeeperBonusCurrencyId} onChange={(v) => {
                                        this.changeGoodsValue(i, "rkeeperBonusCurrencyId", v);
                                    }} />
                                </ToggleMenu>

                            <ToggleMenu title={"Юридическая информация"}>
                                <SettingsItem name="Юр.лицо" value={item.legalName} onChange={(v) => {
                                    this.changeGoodsValue(i, "legalName", v);
                                }} />
                                <SettingsItem name="ИНН" value={item.inn} onChange={(v) => {
                                    this.changeGoodsValue(i, "inn", v);
                                }} />
                                <SettingsItem name="Договор" value={item.docNumber} onChange={(v) => {
                                    this.changeGoodsValue(i, "docNumber", v);
                                }} />
                                <SettingsItem name="Формирование акта" type="checkbox" value={item.formingAct ?? false} onChange={(v) => {
                                    this.changeGoodsValue(i, "formingAct", v);
                                }} />
                                <SettingsItem name="Следующий номер акта" value={item.nextActNumber} type={"number"} step={"1"} onChange={(v) => {
                                    this.changeGoodsValue(i, "nextActNumber", v);
                                }} />
                                <SettingsItem name="Дата дополнительного соглашения" type={"date"} value={item.additionalAgreementDate}
                                    onChange={(v) => {
                                        this.changeGoodsValue(i, "additionalAgreementDate", v);
                                }} />
                                <SettingsItem name="Индивидуальный предприниматель" type="checkbox" value={item.individualBusinessman ?? false}
                                    onChange={(v) => {
                                        this.changeGoodsValue(i, "individualBusinessman", v);
                                }} />
                                <SettingsItem name="Юридический адрес" value={item.legalAddress} onChange={(v) => {
                                    this.changeGoodsValue(i, "legalAddress", v);
                                }} />
                                <SettingsItem name="ФИО получателя акта" value={item.actRecipientFullName} onChange={(v) => {
                                    this.changeGoodsValue(i, "actRecipientFullName", v);
                                }} />
                            </ToggleMenu>

                            </ToggleMenu>
                    })}
                    <Button type="button" onClick={() => {
                        this.setState({addTypeModal: true});
                    }}>
                        Добавить
                    </Button>
                </ToggleMenu>}

                <div className={"flex"}>
                    {(isManager || isAdmin || isSupport) && <Button type="submit">
                        Обновить ресторан
                    </Button>}
                </div>

                <br/>
                <br/>

                <Modal isOpen={this.state.addTypeModal}>
                    <Form className="add-type-modal">
                        <label>
                            Название:
                        <input type="text" value={this.state.newTypeName} onChange={(e) => {
                            this.setState({newTypeName: e.target.value});
                        }} />
                        </label>
                        <div className='add-type-buttons'>
                        <Button type="button" onClick={() => { this.addGoodsType(); }}>
                            Создать
                        </Button>
                        <Button type="button"  onClick={() => {
                            this.setState({addTypeModal: false});
                        }}>
                            Отменить
                        </Button>
                        </div>
                    </Form>
                </Modal>
            </Form>}
        </Wrapper>;
    }
})