import Wrapper from '../../components/Wrapper'

import * as React from "react";
import {useState, useEffect} from "react";
import api from "../../services/api";
import {useSearchParams, useNavigate} from 'react-router-dom';
import {Link} from "react-router-dom";
import {Button} from "react-bootstrap";
import Paginator from "../../components/Paginator";
import TeamButtonsNav from "./teams/TeamButtonsNav";

const EmployeeList = (props) => {
    const [loading, setLoading] = useState(true);
    const [focusSearch, setFocusSearch] = useState(false);
    const [enabled, setEnabled] = useState(true);
    const [error, setError] = useState('');
    const [userStatus, setUserStatus] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const [data, setData] = useState({});

    const reload = (page) => {
        if(!focusSearch)
        {
            setLoading(true);
        }

        setSearchParams({page: page, enabled: enabled, searchText: searchText, status: userStatus ?? ""});

        api.getPageableEmployees({page: page, enabled: enabled, searchText: searchText, status: userStatus}).then(response => {
            if(response.status === 200) {
                setData({data: response.data});
                setLoading(false);
                setFocusSearch(false);
            } else {
                setError(response.data);
                setLoading(false);
                setFocusSearch(false);
            }
        });
    }

    useEffect(() => {reload(0);}, [searchText, enabled, userStatus]);
    const addEmployee = () => { props.navigate("/employees/edit?id=0"); }

    return <Wrapper navigate={navigate}>
        {loading && <p>Загрузка сотрудников...</p>}

        {!loading && <div className={"gray-wrapper gray-table"}>
            {(window.user.role === "ADMIN") &&
            <TeamButtonsNav />}

            {window.user.role === "ADMIN" && <div className={"table-search"} style={{marginBottom: 0}}>
                    <div>
                        <Button onClick={addEmployee}>Добавить</Button>
                    </div>
                </div>
                }

            <div className={"table-search"} style={{justifyContent: 'space-between'}}>
                <div className={"table-checkbox"}>
                    <input type="checkbox"
                            checked={enabled}
                            onChange={e => {
                                setEnabled(e.target.checked);
                            }} />
                    <span>Только активные</span>
                </div>
                <div className={"table-checkbox"}>
                    <input type="text" placeholder='Поиск'
                            value={searchText}
                            autoFocus
                            onChange={e => {
                                setFocusSearch(true);
                                setSearchText(e.target.value);
                            }} style={{width: "auto"}} />
                </div>
            </div>

            <div className={"table-search"}
                style={{marginTop: 0}}>
                <select value={userStatus ?? ""} onChange={e => setUserStatus(e.target.value == "" ? null : e.target.value)}
                style={{width: '100%'}}>
                    <option disabled={true}>-- Выберите --</option>
                    <option value="">Все</option>
                    <option value="NO_MAIL">Нет почты</option>
                    <option value="WAITING">Ожидает регистрации</option>
                    <option value="REGISTERED">Зарегистрирован</option>
                </select>
            </div>

            {!loading && data.data.content.length === 0 && <p className={"table-not-found"}>Сотрудников пока нет...</p>}

            {!loading && data.data.content.length > 0 && <div className={"gray-wrapper gray-table"}>
                <div className={"table"}>
                    <div className={"thead"}>
                        <div className={"th"}>ФИО</div>
                        <div className={"th"}>Роль</div>
                        <div className={"th"}>Телефон</div>
                        <div className={"th"}>Команда</div>
                    </div>

                    {data.data.content.map((item, i) => {
                        return <Link className={"tbody"} key={item.id} to={"/employees/edit?id=" + item.id}>
                            <div className={"td"}>{item.fullName} {window.user.role === "ADMIN" && `(${item.code ?? "~"})`}</div>
                            <div className={"td end"}>{item.role === "WAITER" ? "Официант" : "Управляющий"}</div>
                            <div className={"td break"}/>
                            <div className={"td"}><div className={"xs-visible"}>Телефон</div>&nbsp;{!item.phone ? "не указан" : item.phone}</div>
                            <div className={"td"}>{item.teamName}</div>
                        </Link>
                    })}
                </div>

                <Paginator page={data.data} onChange={(handlePageChange) => {
                    reload(handlePageChange);
                }}/>
            </div>}
        </div>}
    </Wrapper>;
};

export default EmployeeList;
