import Wrapper from '../../components/Wrapper'

import * as React from "react";
import api from "../../services/api";
import {Alert, Button} from "react-bootstrap";
import withParams from "../../services/withParams";
import Form from "react-bootstrap/Form";
import Paginator from "../../components/Paginator";
import {Link} from "react-router-dom";
import ToggleMenu from "../../components/ToggleMenu";
import Moment from "react-moment";

export default withParams(class Logging extends React.Component {
    state = {
        loading: true,
        data: [],
        current: [],
        name: "",
        installer: false
    };

    constructor(props) {
        super(props);

        this.getPage(0);
    }

    getPage(number) {
        this.setState({
            loading: true
        });
        const [searchParams, setSearchParams] = this.props.search;
        setSearchParams({page: number});

        api.getPageableLogging({page: number}).then(response => {
            if(response.status === 200) {
                this.setState({
                    data: response.data,
                    loading: false
                });
            }
            else {
                this.setState({
                    error: response.data,
                    loading: false
                });
            }
        });
    }

    render() {
        return <Wrapper navigate={this.props.navigate}>
            {this.state.loading && <span className={"title"}>Загрузка...</span>}
            {this.state.error && <span className={"title red"}>Ошибка: {this.state.error}</span>}

            {!this.state.loading && !this.state.error && this.state.data.content.length === 0 && <p>Сборок пока нет...</p>}
            <br/>

            {!this.state.loading && this.state.data.content.length > 0 && <div className={"gray-wrapper gray-table"}>
                <div className={"table-search"}>

                </div>

                <div className={"table"}>
                    <div className={"thead"}>
                        <div className={"th"}>Пользователь</div>
                        <div className={"th"}>Действие</div>
                        <div className={"th"}>Дата</div>
                    </div>
                    {this.state.data.content.map((item, i) => {
                        return <div className={"tbody"} key={item.id}>
                            <div className={"td"}>{item.user.fullName}</div>
                            <div className={"td"}>{item.action}</div>
                            <div className={"td end"}><Moment format="DD.MM.YY&nbsp;&nbsp;&nbsp;HH:mm">{item.created}</Moment></div>
                        </div>
                    })}
                </div>

                <Paginator page={this.state.data} onChange={(handlePageChange) => {
                    this.getPage(handlePageChange)
                }}/>
            </div>}
        </Wrapper>;
    }
});