import Wrapper from '../../components/Wrapper'

import * as React from "react";
import Button from "react-bootstrap/Button";
import api from "../../services/api";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import withParams from "../../services/withParams";
import QRCode from "react-qr-code";
import {Link} from "react-router-dom";

import iiko_logo from "../../resources/images/iiko-logo.png"
import rkeeper_logo from "../../resources/images/R-keeper_logo.svg.png"

export default withParams(class Instructions extends React.Component {
    state = {
        loading: true,
        id: null,
        serial: null,
        type: null,
        tableNum: "0",
        restaurant: {}
    };

    constructor(props) {
        super(props);
    }

    render() {
        return <Wrapper navigate={this.props.navigate}>
            <div className={"instruction-station-wrapper"}>
                {window.user.restaurantRole === "MANAGER" && <Link to='iiko' className={"instruction-station"}><Image src={iiko_logo} /> Настройка Iiko</Link>}
                {window.user.restaurantRole === "MANAGER" && <Link to='rkeeper' className={"instruction-station r-keeper"}><Image src={rkeeper_logo} /> Настройка R-Keeper</Link>}
            </div>

            {/*{window.user.restaurantRole === "MANAGER" && <div><Link to='admin'>Инструкция по личному кабинету администратора</Link></div>}
            {window.user.restaurantRole === "MANAGER"  && <div><Link to='owner'>Инструкция по личному кабинету управляющего</Link></div>}
            <div><Link to='waiter'>Инструкция по личному кабинету официанта</Link></div>*/}

        </Wrapper>;
    }
})