import Wrapper from '../../components/Wrapper'

import * as React from "react";
import api from "../../services/api";
import {Form, Image, Table} from "react-bootstrap";
import Moment from "react-moment";
import Datetime from "react-datetime";
import moment from "moment";
import ru from "moment/locale/ru"
import {Link} from "react-router-dom";
import order_err from "../../resources/images/order_err.png";
import order_ok from "../../resources/images/order_ok.png";
import withParams from "../../services/withParams";
import CurrencyFormat from "react-currency-format";
import Paginator from "../../components/Paginator";

export default withParams(class TransactionList extends React.Component {
    state = {
        loading: true,
        data: []
    };

    constructor(props) {
        super(props);

        const [searchParams] = this.props.search;

        this.state = {
            loading: true,
            data: [],
            page: searchParams.get('page') || 0,
            paid: true,
            filter: "0",
            date: moment(searchParams.get('date') ?? new Date(), 'YYYY-MM-DD')
        };

        this.reload();
    }

    reload() {
        this.setState({
            loading: true
        });

        const [searchParams, setSearchParams] = this.props.search;

        let f_tips = null;
        let f_products = null;
        switch(this.state.filter) {
            case "1":
                f_tips = true;
                f_products = false;
                break;
            case "2":
                f_products = true;
                break;
        }
        setSearchParams({page: this.state.page, date: moment(this.state.date).format("YYYY-MM-DD"), paid: this.state.paid, tips: f_tips, products: f_products, filter: this.state.filter});

        api.getPageableTransactions({page: this.state.page, date: this.state.date, paid: this.state.paid, tips: f_tips, products: f_products}).then(response => {
            if(response.status === 200) {
                this.setState({
                    data: response.data,
                    loading: false
                });
            }
            else {
                console.log(response);
                this.setState({
                    error: response.data,
                    loading: false
                });
            }
        });
    }

    render() {
        return <Wrapper navigate={this.props.navigate}>
            {this.state.loading && <span className={"title"}>Загрузка...</span>}
            {this.state.error && <span className={"title red"}>Ошибка: {this.state.error}</span>}

            {!this.state.loading && !this.state.error  && <div className={"gray-wrapper gray-table"}>
                <div className={"table-search"}>
                    <Datetime value={this.state.date}
                              locale={moment.locale()}
                              onChange={(val) => {
                                  this.setState({ date: val  }, () => {
                                      this.reload();
                                  });
                              }}
                              closeOnSelect={ true }
                              dateFormat="YYYY.MM.DD" timeFormat={false} />

                    <label style={{"flex": 1, marginRight: 5}}>
                        <select value={this.state.filter} onChange={e => this.setState({ filter: e.target.value }, () => { this.reload()})} style={{marginTop: 0}}>
                            <option value="0">Все</option>
                            <option value="1">Только чаевые</option>
                            <option value="2">Счета</option>
                        </select>
                    </label>
                </div>
                {this.state.data.content.length === 0 && <p className={"table-not-found"}>Транзакций пока нет...</p>}

                {this.state.data.content.length > 0 && <div className={"col"}>
                    <div className={"table"}>
                        <div className={"thead"}>
                            <div className={"th"}>Дата</div>
                            <div className={"th"}>Итого</div>
                            <div className={"th break"}/>
                            <div className={"th"}>Позиции</div>
                            {window.user.restaurant.tips && <div className={"th"}>Чаевые</div>}
                            {window.user.role === "ADMIN" && <div className={"th"}>Сервис</div>}
                            <div className={"th"}></div>
                        </div>

                        {this.state.data.content.map((item, i) => {
                            return <Link className={"tbody"} key={item.id} to={"/orders/" + item.orderId}>
                                <div className={"td col-2"}><Moment format="DD.MM.YY&nbsp;&nbsp;&nbsp;HH:mm">{item.changed}</Moment></div>
                                <div className={"td col-2 end xs-visible-bold"}><div className={"xs-visible"}>Итого</div>&nbsp;<CurrencyFormat value={Math.floor(item.products + item.alcohol + item.tips)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} /></div>
                                <div className={"td break"}/>
                                <div className={"td"}><div className={"xs-visible"}>Позиции</div>&nbsp;<CurrencyFormat value={Math.floor(item.products + item.alcohol)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} /></div>
                                {window.user.restaurant.modelService !== "Pays" && <div className={"td"}><div className={"xs-visible"}>Чаевые</div>&nbsp;<CurrencyFormat value={Math.floor(item.tips)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} /></div>}
                                {window.user.role === "ADMIN" &&  <div className={"td"}><div className={"xs-visible"}>Сервис</div>&nbsp;<CurrencyFormat value={Math.floor(item.service)} displayType={'text'} suffix={' ₽'} thousandSeparator={' '} /></div>}
                                <div className={"td end"}>{item.closed === null ? <Image src={order_err} /> : <Image src={order_ok} />}</div>
                            </Link>
                        })}
                    </div>

                    <Paginator page={this.state.data} onChange={(handlePageChange) => {
                        this.setState({ page: handlePageChange }, () => {
                            this.reload();
                        });
                    }}/>
                </div>}
            </div>}

        </Wrapper>;
    }
});