import Wrapper from '../../components/Wrapper'
import * as React from "react";
import {useState, useEffect} from "react";
import '../../resources/styles/reports.css';
import api from "../../services/api";
import ReportTable from '../../components/ReportTable';
import ReportLine from '../../components/ReportLine';
import RestaurantCashbacks from "./RestaurantCashbacks"
import SettingsItem from '../../components/SettingsItem';
import ToggleMenu from "../../components/ToggleMenu"
import Button from "react-bootstrap/Button";
import Datetime from "react-datetime";
import moment from "moment";
import "react-datetime/css/react-datetime.css";

const RestaurantCosts = ({navigate}) => {

    const [loading, setLoading] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const [orderPrc, setOrderPrc] = useState("");
    const [tipsPrc, setTipsPrc] = useState("");
    const [extraCosts, setExtraCosts] = useState([]);

    useEffect(() => {
        reload();
    }, []);

    const reload = () => {
        setLoading(true);
        api.getBaseObject("/restaurants/costs").then(response => {
            if(response.status === 200) {
                setOrderPrc(response.data.agentOrderCost);
                setTipsPrc(response.data.agentTipsCost);
                let costs =response.data.costs.map((i) => {
                    i.costDate = moment(i.costDate);
                    return i;
                });
                setExtraCosts(costs);
                setLoading(false);
            }
        });
    }

    const saveClicked = () => {
        setLoading(true);
        api.setBaseObject("/restaurants/costs", {agentOrderCost: orderPrc, agentTipsCost: tipsPrc, costs: extraCosts}, false).then(response => {
            if(response.status === 200) {
                setLoading(false);
                reload();
            }
        });
    }

    const addCosts = () => {
        let newExtraCosts = [...extraCosts];
        newExtraCosts.push({id: 0, costDate: new Date(), name: "", amount: 0.0});
        setExtraCosts(newExtraCosts);
    }

    const setCostValue = (idx, field, val) => {
        let newExtraCosts = [...extraCosts];
        newExtraCosts[idx][field] = val;
        setExtraCosts(newExtraCosts);
    }

    return <Wrapper navigate={navigate}>
            <ToggleMenu title={"Расходы"}>
                {loading && <p>Загрузка расходов...</p>}
                {!loading && <div className={"gray-wrapper gray-table"}>
                    <div className={"form form-wrapper"}>
                        <SettingsItem name="Комиссия агента со счета (%)" value={orderPrc} type="number" onChange={setOrderPrc} />
                        <SettingsItem name="Комиссия агента с чаевых (%)" value={tipsPrc} type="number" onChange={setTipsPrc} />
                    </div>            


                    <label>
                        <div>Дополнительные расходы</div>
                        <div>
                            <hr></hr>
                            {extraCosts.map((i, idx) => {
                                return <div key={"ec"+idx}>
                                    <label style={{marginLeft: 10, marginBottom: 10}}>Дата расхода
                                        <div><Datetime value={i.costDate}
                                            locale={moment.locale()} closeOnSelect={true}
                                            onChange={(val) => { setCostValue(idx, "costDate", val); }}
                                            dateFormat="DD.MM.YYYY" timeFormat={false} style={{marginLeft: 20}} />
                                            </div>
                                    </label>
                                    <label style={{marginLeft: 10, marginBottom: 10, marginTop: 10}}>Описание
                                        <input style={{marginLeft: 20}} type="text" value={i.name} 
                                                onChange={(e) => { setCostValue(idx, "name", e.target.value); }} />
                                    </label>
                                    <label style={{marginLeft: 10, marginBottom: 10, marginTop: 10}}>Сумма
                                        <input style={{marginLeft: 20}} type="numeric" value={i.amount} 
                                                onChange={(e) => { setCostValue(idx, "amount", e.target.value) }} />
                                    </label>
                                    <hr></hr>
                                </div>
                            })}
                        </div>
                        <div>
                            <Button type="button" onClick={addCosts}>Добавить</Button>
                        </div>
                    </label>

                    <div className={"flex"}>
                        {window.user !== undefined && window.user.role === "ADMIN" && <Button onClick={saveClicked} disabled={hasChanges}>
                            Сохранить
                        </Button>}
                    </div>

                </div>}
            </ToggleMenu>

            <ToggleMenu title={"Кэшбеки"}>
                <RestaurantCashbacks/>
            </ToggleMenu>
       </Wrapper>;
}

export default RestaurantCosts;
