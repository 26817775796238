import * as React from "react";
import {useState} from "react";
import {useNavigate} from 'react-router-dom';
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import MaskedInput from "../components/MaskedInput";
import Wrapper from '../components/Wrapper.js';
import '../resources/styles/styles.css';

import api from "../services/api";

export default function AksPhone () {
    const [phone, setPhone] = useState("");
    const [canSave, setCanSave] = useState(false);
    const navigate = useNavigate();

    const savePhone = () => {
        setCanSave(false);
        api.setBaseObject('users/setphone', {phone: phone}, false).then(
            response => {
                if(response.status === 200) {
                    navigate("/");
                } else {
                    setCanSave(true);
                }
            }
        )
    }

    const phoneChanged = (e) => {
        let newPhone = e.target.value.replace(/\D/g, '');
        setPhone(newPhone);
        if(newPhone!="" && newPhone.length == 11)
        {
            setCanSave(true);
        } else {
            setCanSave(false);
        }
    }

    return <Wrapper navigate={navigate}>
        <div className='desktop-modal'>
            <div className='askphone-modal-content'>
                <div className='askphone-title'>Мы переходим на авторизацию сотрудников по номеру телефона. </div>
                <div className="askphone-label">Пожалуйста введите ваши данные</div>
                    <Form.Control className="long askphone-phone" mask="+1 (111) 111-11-11" as={MaskedInput} type="text" value={phone} onChange={phoneChanged} placeholder='+7 (___) ___-__-__' />
                <Button variant="primary" className='tipswish-save' disabled={!canSave}
                onClick={savePhone}>Сохранить</Button>
            </div>
        </div>
        <div className='askphone-modal'>
                <div className='askphone-title'>Мы переходим на авторизацию сотрудников по номеру телефона. </div>
                <div className="askphone-label">Пожалуйста введите ваши данные</div>
                <Form.Control className="long askphone-phone" mask="+1 (111) 111-11-11" as={MaskedInput} type="text" value={phone} onChange={phoneChanged} placeholder='+7 (___) ___-__-__' />
                <Button variant="primary" className='tipswish-save' disabled={!canSave}
                onClick={savePhone}>Сохранить</Button>
        </div>
    </Wrapper>
    ;
}