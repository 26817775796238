import Wrapper from '../../components/Wrapper'
import * as React from "react";
import {useState, useEffect} from "react";
import '../../resources/styles/reports.css';
import api from "../../services/api";
import SettingsItem from '../../components/SettingsItem';
import Button from "react-bootstrap/Button";
import "react-datetime/css/react-datetime.css";
import { Link } from 'react-router-dom';

const RestaurantContacts = ({navigate}) => {

    const [loading, setLoading] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const [data, setData] = useState({});

    useEffect(() => {
        reload();
    }, []);

    const reload = () => {
        setLoading(true);
        api.getBaseObject("/restaurants/contacts").then(response => {
            if(response.status === 200) {
                if(response.data !== undefined && response.data != null)
                {
                    setData(response.data);
                }
                setLoading(false);
            }
        });
    }

    const saveClicked = () => {
        setLoading(true);
        api.setBaseObject("/restaurants/contacts", 
            {...data},
            true).then(response => {
            if(response.status === 200) {
                setLoading(false);
                reload();
            }
        });
    }

    const getVal = (param) => {
        if(data[param] !== undefined && data[param] != null)
        {
            return data[param];
        }
        return "";
    }

    const setVal = (param, val) => {
        let newData = {...data};
        newData[param] = val;
        setData(newData);
        setHasChanges(true);
    }

    const onFileChange = (ev) => {
        let reader = new FileReader();
        reader.readAsDataURL(ev.target.files[0]);
        reader.onloadend = (e) => {
            setData({...data, agreementFile: ev.target.files[0]});
            setHasChanges(true);
        }
    }

    const downloadFile = () => {
        api.getBaseObject("/restaurants/contacts/agreement").then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', getVal('agreementFileName'));
            document.body.appendChild(link);
            link.click();
        })
        .catch((error) => console.log(error));
    }

    return <Wrapper navigate={navigate}>
            {loading && <p>Загрузка...</p>}
            {!loading && <div className={"gray-wrapper gray-table"}>
                <div className={"form form-wrapper"}>
                    <SettingsItem name="Юр.лицо" value={getVal('legalName')} onChange={(v) => {setVal('legalName', v)}} />
                    <SettingsItem name="Название предприятия" value={getVal('organizationName')} onChange={(v) => {setVal('organizationName', v)}} />
                    <SettingsItem name="Фактический адрес" value={getVal('factAddress')} onChange={(v) => {setVal('factAddress', v)}} />
                    <SettingsItem name="Юридический адрес" value={getVal('legalAddress')} onChange={(v) => {setVal('legalAddress', v)}} />
                    <SettingsItem name="Банковские реквизиты" value={getVal('bankRequisites')} onChange={(v) => {setVal('bankRequisites', v)}} />
                    <SettingsItem name="Ген.директор" value={getVal('generalDirector')} onChange={(v) => {setVal('generalDirector', v)}} />
                    <SettingsItem name="Контакты" value={getVal('contacts')} onChange={(v) => {setVal('contacts', v)}} />
                    <SettingsItem name="Ставка" value={getVal('rate')} onChange={(v) => {setVal('rate', v)}} />
                    <SettingsItem name="Комментарии" value={getVal('comments')} onChange={(v) => {setVal('comments', v)}} />

                    <label>
                        <div style={{cursor: "pointer"}}>Загрузить файл с договором</div>
                        <div className='avatar-container'>
                            <input type='file' onChange={onFileChange} />
                        </div>
                        <div>
                            <br/>
                            {getVal('agreementFile') !== '' && <Link href="#" onClick={downloadFile}>{getVal('agreementFileName')}</Link>}
                        </div>
                    </label>

                </div>            


                <div className={"flex"}>
                    {window.user !== undefined && window.user.role === "ADMIN" && <Button onClick={saveClicked} disabled={!hasChanges}>
                        Сохранить
                    </Button>}
                </div>

            </div>}
       </Wrapper>;
}

export default RestaurantContacts;
