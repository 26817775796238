import * as React from "react";
import {Button} from "react-bootstrap";
import Image from "react-bootstrap/Image";
import arrowIcon from '../../resources/images/arrow.svg';

const ProductInList = ({item, onSelectProduct, onLoadInfo}) => {

    let chain = (item.categoriesChain ?? '').split('\n');
    chain = chain.join(" / ");
    chain = chain.substr(0, chain.length-3);
    return <div className="list-product-item">
            <label className="list-product-name">{item.name}
                <div className="list-product-category">{chain}</div>
            </label>
            <div className="list-product-button">
                <Button type="button" onClick={() => {onSelectProduct(item);onLoadInfo(item.id);}}>
                    <Image src={arrowIcon} className="arrow-right-icon" />
                </Button>
            </div>
    </div>;
};

export default ProductInList;

