import Wrapper from '../../components/Wrapper'

import * as React from "react";
import api from "../../services/api";
import {Link} from "react-router-dom";
import withParams from "../../services/withParams";
import Moment from "react-moment";
import Paginator from "../../components/Paginator";

export default withParams(class TerminalEdit extends React.Component {
    state = {
        loading: true,
        data: [],
        page: 1,
        online: true,
        offline: false
    };

    constructor(props) {
        super(props);

        this.reload();
    }

    reload() {
        this.setState({
            loading: true
        });

        const [searchParams, setSearchParams] = this.props.search;

        setSearchParams({page: this.state.page, online: this.state.online, offline: this.state.offline});

        api.getPageableTerminals({page: this.state.page, online: this.state.online, offline: this.state.offline}).then(response => {
            if(response.status === 200) {
                this.setState({
                    data: response.data,
                    loading: false
                });
            }
            else {
                this.setState({
                    error: response.data,
                    loading: false
                });
            }
        });
    }

    render() {
        return <Wrapper navigate={this.props.navigate}>
            {this.state.loading && <span className={"title"}>Загрузка...</span>}
            {this.state.error && <span className={"title red"}>Ошибка: {this.state.error}</span>}

            {!this.state.loading && <div className={"gray-wrapper gray-table"}>
                <div className={"table-search"}>
                    <div className={"table-checkbox"}>
                        <input type="checkbox"
                               checked={this.state.online}
                               onChange={e => {
                                   this.setState({ online: e.target.checked }, () => {
                                       this.reload();
                                   });
                               }} />
                        <span>Онлайн</span>
                    </div>
                    <div className={"table-checkbox"}>
                        <input type="checkbox"
                               checked={this.state.offline}
                               onChange={e => {
                                   this.setState({ offline: e.target.checked }, () => {
                                       this.reload();
                                   });
                               }} />
                        <span>Оффлайн</span>
                    </div>
                </div>
                {!this.state.loading && this.state.data.content.length === 0 && <p className={"table-not-found"}>Терминалов пока нет...</p>}

                {this.state.data.content.length > 0 && <div className={"col"}>
                    <div className={"table"}>
                        <div className={"thead"}>
                            <div className={"th"}>Название</div>
                            <div className={"th"}>Подключение</div>
                            <div className={"th end"}>Тип</div>
                            <div className={"td break"}/>
                            <div className={"th"}>Версия</div>
                            <div className={"th end"}>UUID</div>
                        </div>

                        {this.state.data.content.map((item, i) => {
                            return <Link className={"tbody"} key={item.id} to={'/terminals/' + item.id}>
                                <div className={"td"}>{item.name}</div>
                                <div className={"td end"}><Moment format="DD.MM.YY&nbsp;&nbsp;&nbsp;HH:mm">{item.connected}</Moment></div>
                                <div className={"td end"}>{item.type}</div>
                                <div className={"td break"}/>
                                <div className={"td"}>{item.version}</div>
                                <div className={"td end"}>{item.uuid}</div>
                            </Link>
                        })}
                    </div>

                    <Paginator page={this.state.data} onChange={(handlePageChange) => {
                        this.setState({ page: handlePageChange }, () => {
                            this.reload();
                        });
                    }}/>
                </div>}
            </div>}
        </Wrapper>;
    }
});