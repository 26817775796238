import '../../resources/styles/reports.css';
import '../../resources/styles/styles.css';
import ReportTable from '../../components/ReportTable';
import ReportLine from '../../components/ReportLine';

const ReportWaitersTips = ({loading, data, isAdmin}) => {
    return <>
            <div className={"order-title reports-title"}>Чаевые официантов</div>
            <ReportTable columns={["Официант", "Чаевые", "Выплачено"]}>
            {data.map((item, i) => {
                return <ReportLine key={"wtips_"+i} keyPrefix={"r"+i+"_"} data={[
                    item.fullName + (isAdmin ? `(${item.code ?? "~"})` : ""), 
                    {value: item.amount.toFixed(2), type: "money"},
                    {value: item.paid.toFixed(2), type: "money"}
                ]} />
            })}
            </ReportTable>
    </>;
};

export default ReportWaitersTips;
